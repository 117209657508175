@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
html {
  -webkit-text-size-adjust: none;
}
:focus {
  /* remember to define focus styles! */
  outline: 0;
}
ol,
ul {
  margin: 0;
  padding: 0px 0px 10px 40px;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
embed,
iframe,
object {
  max-width: 100%;
}

/* Global
----------------------------------------------- */
body {
  font-size: 15px;
  line-height: 1.3;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #444444;
  font-weight: 400;
  letter-spacing: 0;
  overflow-x: hidden;
  word-wrap: break-word;
}

/* Horizontal Line */
hr {
  border-color: #eaeaea;
  border-style: solid none none;
  border-width: 1px 0 0;
  height: 0;
  margin: 0 0 0px;
}

/* Text elements */
p {
  margin-bottom: 15px;
}
strong {
  font-weight: bold;
}
cite,
em,
i {
  font-style: italic;
}
span.error {
  color: #ef0000;
  font-size: 13px;
}
.cp-form .otp-error {
  text-align: center;
}
/* Forms */
input,
textarea {
  border-radius: 0px;
  -webkit-border-radius: 0px;
}
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select,
.react-tel-input .form-control {
  border-radius: 10px;
  border: 1px solid #4258bf;
  padding: 8px 20px;
  background: #fff;
  color: #000000;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  height: 44px;
  margin: 0 0 10px;
  width: 100%;
}
.react-tel-input .form-control {
  border-radius: 10px !important;
  border: 2px solid #4258bf !important;
  height: 50px !important;
  letter-spacing: 0.56px !important;
  margin: 0 0 10px !important;
  width: 100% !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  border-radius: 10px 0 0 10px !important;
}
.react-tel-input .flag-dropdown {
  border: 2px solid #4258bf !important;
  border-radius: 10px 0 0 10px !important;
}
textarea {
  resize: none;
  height: 90px;
}
.react-tel-input .flag-dropdown {
  border: 2px solid #4258bf !important;
  border-radius: 10px 0 0 10px !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  line-height: 25px;
}
.react-tel-input .flag-dropdown {
  border: 2px solid #4258bf !important;
  border-radius: 10px 0 0 10px !important;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  line-height: 25px;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
  color: #fff;
  display: inline-block;
  margin: 15px 0px 10px 0px;
  padding: 14px 27px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  border-radius: 20px;
  background: #4258bf;
  border: 1px solid #4258bf;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-transform: capitalize;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.7px;
}
input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover {
  background: #bdd0ff;
  color: #000;
  border-color: #bdd0ff;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}
.ghost-button {
  background: #fff;
  color: #212121;
  border-color: #4258bf;
}
.ghost-button:hover {
  background: #4258bf;
  color: #fff;
  border-color: #4258bf;
}
body .css-vj8t7z {
  border-radius: 10px;
  border: 1px solid #4258bf;
  padding: 8px 8px 8px 20px;
  background: #fff;
  color: #7d7d7d;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  height: 44px;
  margin: 0 0 15px 0;
}
body .css-1hwfws3 {
  padding: 0;
  position: relative;
  top: -5px;
  padding-left: 0px;
}
body .css-2o5izw {
  border: 1px solid #4258bf;
  border-radius: 10px;
  box-shadow: none;
  min-height: inherit;
  outline: 0 !important;
  height: 44px;
  padding: 8px 8px 8px 50px;
}
body .css-2o5izw:hover {
  border: 1px solid #4258bf;
}
body .css-1g6gooi {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}
body .css-1g6gooi input {
  height: auto;
}
body #react-select-2-input {
  height: auto;
}
body .css-xp4uvy {
  margin: 0;
  font-size: 14px;
}
body .css-1492t68 {
  font-size: 14px;
}
.css-1wy0on6 {
  position: relative;
  top: -4px;
}

/* Links */
a {
  color: #4258bf;
  text-decoration: none;
  cursor: pointer;
}
a:focus,
a:active,
a:hover {
  text-decoration: none;
}
a:hover {
  color: #000;
}

/* Images */
figure {
  margin: 0;
  text-align: center;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  font-weight: normal;
  line-height: 1.3;
  color: #212121;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.45px;
}

h1 {
  font-size: 42px;
}
h2 {
  font-size: 24px;
  letter-spacing: 1px;
}
h3 {
  font-size: 23px;
  letter-spacing: 0.8px;
}
h4 {
  font-size: 20px;
  letter-spacing: 0.6px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
}
/* clearfix hack */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* close commented backslash hack */
.clearfix-third,
.clear {
  clear: both;
}

.rel {
  position: relative;
}
.textcenter {
  text-align: center;
}
.textleft {
  text-align: left;
}
.textright {
  text-align: right;
}
.no-margin-btm {
  margin-bottom: 0 !important;
}
.no-padd-btm {
  padding-bottom: 0 !important;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.container {
  padding: 0;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 40px;
}
.container-overflow {
  overflow-x: hidden;
}

header {
  z-index: 91;
  width: 100%;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  position: sticky;
  top: 0;
  background: #fafbff;
  padding: 20px 0;
}
header.small_sticky {
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
header .container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
}

.header-lhs a img {
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  width: 140px;
}
.header-rhs {
  padding: 0;
}
.header-links ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 25px;
  position: relative;
}
.header-links ul li a {
  margin: 0;
  font-size: 14px;
  box-shadow: none;
  padding: 12px 26px;
}
.header-rhs {
  padding: 0;
  display: flex;
  align-items: center;
}
.header-rhs .search-icon {
  margin-bottom: -5px;
}

.header-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.currency-filter {
  position: relative;
}
.currency-filter a {
  color: #1b252c;
}

/* =======================================================================================
Humburger Aniamtion
========================================================================================== */

.humbur {
  display: none;
}
.humbur a {
  position: relative;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
}

.m-hidden {
  overflow: hidden;
}

.menu {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}
.menu > li {
  margin: 0px 17px 0;
  position: relative;
  list-style: none;
  vertical-align: middle;
  text-align: center;
  z-index: 9;
  line-height: normal;
  float: left;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.menu > li:last-child {
  margin-right: 0px;
}
.menu > li > a {
  color: #1b252c;
  font-size: 14px;
  padding: 10px 0 10px;
  line-height: 1;
  display: block;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  position: relative;
  text-transform: capitalize;
  letter-spacing: 0;
  display: flex;
  align-items: center;
}
.header-initial {
  position: initial;
}
.header-sticky {
  position: sticky;
}
.innerpage .menu > li > a {
  color: #000;
}
.menu > li > a span {
  position: relative;
  display: inline-block;
}
.menu > li > a span:before {
  content: "";
  position: absolute;
  left: 51%;
  right: 51%;
  bottom: -7px;
  background: #4258bf;
  height: 1px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.menu > li > a:hover span:before,
.menu > li.active > a span:before,
.menu > li:hover > a span:before {
  left: 0;
  right: 0;
}
.menu > li > a:hover,
.menu > li.active > a,
.menu > li:hover > a {
  color: #4258bf;
}
.menu > li:hover > ul,
.menu ul li:hover > ul {
  display: block;
}
.menu ul {
  margin: 0;
  padding: 0;
  background: #fff;
  z-index: 99;
  width: 200px;
  position: absolute;
  left: 0px;
  top: 100%;
  display: none;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
}
.menu ul ul {
  left: 100%;
  top: 0;
}
.menu ul li {
  margin: 0;
  float: none;
  display: block;
  line-height: normal;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.menu ul li a {
  display: block;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 8px 10px;
  color: #000;
}
.menu > li > ul > li:last-child > a,
.menu > li > ul > li > ul li:last-child > a {
  border-bottom: 0;
}
.menu ul li a:hover,
.menu ul li a.active,
.menu ul li:hover > a {
  color: #fff;
  background: #4258bf;
}
.menu ul,
.menu a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.menu > li:hover > ul,
.menu ul li:hover > ul {
  display: block;
  -webkit-animation: menu_up ease-out 0.4s 0s;
  -webkit-animation-fill-mode: both;
  -webkit-animation: menu_up ease-out 0.4s 0s;
  animation: menu_up ease-out 0.4s 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes menu_up {
  0% {
    -webkit-transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes menu_up {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
.banner {
  position: relative;
  z-index: 3;
}
.banner-form {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.banner-form-parent h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 2.6px;
  color: #fff;
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  line-height: 1;
  text-align: center;
}
.banner-form-parent {
  max-width: 1040px;
  margin: 0 auto;
}

.banner-form-inner > div,
.banner-form-inner {
  position: relative;
}
.banner-from img,
.banner-to img {
  width: 16px;
  height: 20px;
  position: absolute;
  left: 12px;
  top: 13px;
}
.banner-whom img {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 14px;
  top: 13px;
}
.banner-stay > img {
  position: absolute;
  left: 14px;
  width: 16px;
  top: 13px;
}
.banner-form-inner .button {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
}
.banner-form-inner .button:hover {
  background: #000;
}
.banner-form-inner input[type="text"] {
  margin: 0;
  font-size: 13px;
  padding: 8px 10px 7px 40px;
}
.banner-form-inner {
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}
.banner-from {
  width: 22.727272727272727%;
}
.banner-to {
  width: 22.727272727272727%;
}
.banner-whom {
  width: 29.545454545454547%;
}
.banner-stay {
  width: 18.181818181818182%;
}

.pp-activitie {
  padding: 50px 0 20px;
}
.pp-activitie ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pp-activitie ul li {
  width: 32.05%;
  margin: 0 0 38px 0;
}
.pp-activitie ul li a {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  display: block;
  transition: 0.3s linear all;
}

.promo-img img {
  opacity: 0.5;
}
.promo-img {
  background: #000;
  position: relative;
}
.promo-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.pp-activitie ul li:hover .promo-img::before {
  -webkit-animation: circle 0.85s;
  animation: circle 0.85s;
}
@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
.promo-def {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding: 10px;
  z-index: 2;
}
.promo-def h4 {
  margin: 0;
  line-height: 1;
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.25px;
}
.title-with-description {
  margin: 0 0 20px 0;
  padding: 0 120px 0 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.destination-trend {
  background: #f0fff1;
  border-radius: 16px;
  padding: 20px;
  margin-top: 25px;
}
.package-trend .main-content .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.package-trend .main-content .divider-line {
  background: transparent
    linear-gradient(270deg, #ffffff 0%, #c69f53 49%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  height: 1px;
  margin-bottom: 2rem;
}
.package-trend .main-content .owl-theme .owl-dots .owl-dot.active span {
  height: 5px;
  width: 15px;
}
.package-trend .main-content .owl-theme .owl-dots .owl-dot span {
  height: 6px;
  width: 6px;
  margin: 4px 2px;
}
.package-trend .popular-destination {
  background: #fff8f0;
  border-radius: 16px;
  padding: 20px 20px 1px 20px;
}
.package-trend .popular-destination .main-content {
  margin-bottom: 2rem;
}
.package-trend .popular-destination .card-main {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px;
  cursor: pointer;
}
.package-trend .popular-destination .card-main .content {
  border-radius: 20px;
  padding: 0 15px 15px 15px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: transparent
    linear-gradient(180deg, #59595900 0%, #0000002e 35%, #232323 100%) 0% 0%
    no-repeat padding-box;
}
.package-trend .popular-destination .card-main .content .from,
.curate-def .city {
  color: #ffffff;
  opacity: 0.8;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.package-trend .popular-destination .card-main .content .from .loc-icon,
.curate-def .city .loc-icon {
  color: #ffffff;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-right: 3px;
}
.package-trend .popular-destination .card-main .content .to {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.package-trend .popular-destination .card-main .content .price {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
.package-trend .top-10 {
  background: #fff8f0;
  border-radius: 16px;
  padding: 20px 20px 0 20px;
}
.package-trend .title-with-description .title-col {
  display: flex;
  align-items: center;
}
.package-trend .top-10 .title-with-description .title-col,
.package-trend .popular-destination .title-with-description .title-col {
  flex-direction: column;
  align-items: flex-start;
}
.package-trend .top-10 .title-with-description .title-col h2,
.package-trend .popular-destination .title-with-description .title-col h2 {
  margin-bottom: 0.5rem;
}
.title-with-description .title-col .package-discount {
  position: initial;
  margin-left: 4rem;
}
.package-trend .recommend-activities {
  background: #f0fff1;
  border-radius: 16px;
  padding: 20px 20px 0 20px;
}
.explr-dest {
  background: #f2f7ff;
  border-radius: 16px;
  padding: 20px;
}
.explr-dest .curate-def .price {
  padding-left: 3px;
}
.explr-dest .curate-def {
  padding-left: 15px;
}
.package-trend .themes-main {
  background: #f9f0ff;
  border-radius: 16px;
  padding: 20px 20px 0 20px;
  margin-bottom: 2rem;
}
.destination-list-main {
  background: #f0feff;
  padding: 20px 25px;
  border: 0.30000001192092896px solid #20848b;
  border-radius: 20px;
}
.destination-list-main .row button {
  background: transparent;
  box-shadow: none;
  border: none;
  margin: 0px;
  width: 100%;
  border-radius: 0;
  text-align: left;
  padding: 0px;
  letter-spacing: 0;
  line-height: 1.3;
  margin-bottom: 1rem;
}
.destination-list-main .title {
  color: #21314d;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}
.destination-list-main .row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 15px;
}
.package-trend .curated-main {
  background: #f2f7ff;
  border-radius: 16px;
  padding: 20px 20px 0 20px;
  margin-bottom: 2rem;
}
.package-trend .title-with-description,
.destination-trend .title-with-description,
.explr-dest .title-with-description {
  padding: 0;
}
.fsl-tt.new-tags {
  top: 1rem;
  display: block;
  margin-bottom: 1rem;
}

.title-with-description h2 {
  margin: 0;
  color: #212121;
  letter-spacing: 0px;
}
.title-with-description h1 {
  font-size: 24px;
  margin: 0 0 5px 0;
  color: #212121;
}
.package-trend .title-with-description h1,
.destination-trend .title-with-description h2 {
  margin: 0;
}
.title-with-description .sub-txt {
  letter-spacing: 0.8px;
  color: #444444;
  font-size: 16px;
  font-weight: normal;
}
.title-with-description .view-more-link {
  display: flex;
  align-items: center;
  color: #4258bf;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  top: 0px;
}
.title-with-description .view-more-link svg {
  color: #4258bf;
  font-size: 1rem;
  margin-left: 5px;
}

.title-with-description h2 span {
  color: #4258bf;
}

.title-with-description p {
  letter-spacing: 0.8px;
  margin: 0;
  color: #1b252c;
}
.creator-dashboard-main .title-with-description {
  flex-direction: column;
  align-items: flex-start;
}
.explore {
  padding: 0 0 50px 0;
}
.explore-inner-mobile {
  display: none;
}
.explore-inner ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.explore-inner ul li {
  width: 17.5%;
  margin: 0 3.125% 0 0;
}
.explore-inner ul li:last-child {
  margin-right: 0;
}
.explore-inner a,
.explore-inner-mobile .owl-item a {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  display: block;
}
.explore-def {
  position: absolute;
  bottom: 30px;
  left: 10px;
  right: 10px;
  text-align: center;
  transition: 0.35s linear all;
}

.explore-def h4 {
  margin: 0;
  line-height: 1;
  color: #ffffff;
}
.explore-inner ul li a:hover .explore-def {
  bottom: 50%;
  transform: translateY(50%);
}
.bottom-button {
  padding: 38px 0 0 0;
  text-align: center;
}
.bottom-button .button {
  margin: 0;
  box-shadow: none;
}

.explore-img {
  position: relative;
}

.explore-img:after {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border: 1px solid #fff;
  box-shadow: 0 0 0 30px rgb(0 0 0 / 20%);
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
  pointer-events: none;
  border-radius: 20px;
}

.explore-inner ul li a:hover .explore-img:after {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

/* =======================================================================================
Responsive Navigation
========================================================================================== */
.overlay {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 999;
  color: #fff;
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.pattern {
  padding: 80px 15px 30px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.device_nav {
  padding: 0;
}
.device_nav li {
  list-style: none;
  text-align: center;
}
.device_nav > li > a {
  color: #222;
  font-size: 22px;
  padding: 5px 0;
  display: inline-block;
  transition: 0.3s linear all;
  -webkit-transition: 0.3s linear all;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.device_nav li a:hover {
  color: #408bf9;
}
.device_nav li ul {
  display: none;
  margin: 0;
  padding: 15px 10px;
}
.device_nav > li ul li a {
  color: #222;
  display: inline-block;
  line-height: 1.3;
  padding: 5px 0;
  letter-spacing: 0.5px;
  font-size: 19px;
}
.device_nav > li ul li li a {
  font-size: 18px;
}
.device_nav li a.subicon {
  padding: 1px 8px;
  text-align: center;
  vertical-align: middle;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  line-height: normal;
}
.subicon:after {
  content: "\f0d7";
  font-family: "FontAwesome";
  display: block;
  -webkit-transition: 0.4s linear all;
  transition: 0.4s linear all;
  color: #222;
}
.subicon.open:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.over-close {
  width: 35px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}
.over-close:hover {
  opacity: 0.6;
}
.hassub_menu.active > a {
  color: #408bf9;
}
.nav-logo {
  margin: 0 0 30px 0;
}
.device_nav > li {
  opacity: 0;
  -webkit-transform: translate3d(0, -36px, 0);
  transform: translate3d(0, -36px, 0);
}
.overlay.open .device_nav > li:nth-child(1) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.overlay.open .device_nav > li:nth-child(2) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}
.overlay.open .device_nav > li:nth-child(3) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}
.overlay.open .device_nav > li:nth-child(4) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}
.overlay.open .device_nav > li:nth-child(5) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}
.overlay.open .device_nav > li:nth-child(6) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}
.overlay.open .device_nav > li:nth-child(7) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}
.overlay.open .device_nav > li:nth-child(8) {
  -webkit-transition-delay: 0.95s;
  transition-delay: 0.95s;
}
.overlay.open .device_nav > li {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
}
.carousel-divider-web {
  min-width: calc(100vw - 30px);
  margin: 50px 0;
  display: flex;
  align-items: center;
  min-height: 1px;
  background: linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0.05) -0.13%,
    hsla(0, 0%, 88%, 0.05) 0,
    #e0e0e0 49.17%,
    hsla(0, 0%, 88%, 0.05)
  );
}

@media (max-width: 780px) {
  .device_nav li ul {
    padding: 10px;
  }
  .device_nav li a {
    font-size: 20px;
    padding: 5px 0;
  }
  .device_nav > li ul li a {
    font-size: 17px;
  }
  .device_nav > li ul li li a {
    font-size: 16px;
  }
  .sub-header-main {
    padding: 0.5rem 1rem !important;
  }
}

@media (max-width: 480px) {
  header {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .nxt-row-btn {
    position: initial !important;
  }
  .nxt-row-btn .button {
    margin: 0px !important;
  }
  .ttd-package-card .ttd-p-rhs {
    width: 100% !important;
  }
  #select-passenger-popup .add-room-container {
    padding: 0px;
  }
  .day-wise
    .up-accordion-content.last-day
    .hotel-search-result
    ul
    li
    .check-out {
    padding-right: 0px !important;
  }
  .day-wise
    .up-accordion-content.last-day
    .hotel-search-result
    .active
    .hsr-lhs-img {
    width: 80px !important;
    height: 80px !important;
  }
  .day-wise
    .up-accordion-content.last-day
    .hotel-search-result
    .active
    .hsr-lhs-desc {
    width: calc(100% - 90px) !important;
  }
  .day-wise
    .up-accordion-content.last-day
    .hotel-search-result
    .active
    .hsr-lhs {
    width: 80%;
  }
  .flight-banner .col4 .text {
    font-size: 14px !important;
  }
  .flight-banner .col4 .text .head {
    font-size: 16px !important;
  }
  .flight-banner .col4 .text .sub {
    font-size: 12px !important;
  }
  .flight-banner .searchBtn {
    padding: 9.5px !important;
    min-height: 32px !important;
    width: 100%;
    font-size: 14px !important;
    font-family: "poppins";
  }
  .day-wise .up-accordion-parent {
    margin-bottom: 1rem !important;
  }
  .hyper-customize-main {
    flex-direction: column;
    padding: 1rem !important;
  }
  .hyper-customize-main .col-1 .heading {
    padding: 0 1.5rem;
    text-align: left;
  }
  .hyper-customize-main .col-1 {
    width: 100% !important;
    text-align: center;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem !important;
  }
  .hyper-customize-main .col-1 .list {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem !important;
    row-gap: 0.5rem;
  }
  .hyper-customize-main .col-1 .list div {
    width: 40%;
    margin-bottom: 0.5rem;
  }
  .hyper-customize-main .col-1 .watch-btn {
    width: 100%;
  }
  .hyper-customize-main .col-2 {
    width: 100% !important;
  }
  .searchContainer__bottomSheetMain__container.ttd-pk-select
    .step-2
    .ttd-p-rhs-select {
    align-items: flex-start;
  }
  .searchContainer__bottomSheetMain__container.ttd-pk-select .actions {
    position: absolute;
    bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    background-color: #fff;
  }
  .searchContainer__bottomSheetMain__container.ttd-pk-select {
    height: max-content;
    overflow-y: auto;
  }
  .ttd-pk-select .step-2 .slot-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin: 1rem 10px;
    margin-bottom: 1.5rem;
  }
  .ttd-pk-select .step-2 .slot-list .slot-col {
    border-radius: 20px;
    border: 1px solid #737373;
    text-align: center;
    padding: 8px 0px;
    cursor: pointer;
  }
  .ttd-pk-select .step-2 .slot-list .slot-col.selected {
    background: #4258bf;
    color: #fff;
  }
  .ttd-pk-select .searchContainer__bottomSheetMain__container__date {
    margin-top: 0px;
  }
  .ttd-pk-select .searchContainer__bottomSheetMain__container__date .s-title {
    margin-bottom: 1rem;
  }
  .ttd-pk-select .ttd-p-rhs ul {
    flex-direction: column;
  }
  .ttd-pk-select .step-content-search {
    display: block !important;
    box-shadow: none;
  }
  .ttd-pk-select button {
    border-radius: 20px !important;
  }
  .ttd-pk-select .s-title {
    font-size: 16px;
    color: #212121;
    font-weight: 600;
    margin: 1rem 0;
  }
  .ttd-pk-select .rdrMonthAndYearPickers {
    display: none;
  }
  .ttd-pk-select .ttd-p-lhs h4 {
    margin-bottom: 5px;
  }
  .currency-filter a {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    color: #342f41;
    font-weight: 500;
    padding: 0 1rem;
  }
  .header-main .heading {
    font-size: 20px !important;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-from {
    border-right: 0.6px solid #b2b7c1;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-swap {
    width: auto;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-swap-img {
    position: absolute;
    top: 50%;
    left: 85%;
    margin: auto;
    transform: rotate(90deg);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .fs-search-cntnr.flight-search-container .flight-search .fs-swap .vl {
    display: none;
  }
  .fs-search-cntnr .flight-search-container .flight-search {
    border: none !important;
  }
  .company-banner {
    flex-direction: column;
    padding: 2rem 1.5rem !important;
  }
  .company-banner .text {
    font-size: 22px !important;
    margin-bottom: 2rem;
  }
  .reviews-main {
    display: flex;
    flex-direction: column;
  }
  .reviews-main .col1 {
    align-items: center;
    justify-content: flex-start !important;
    padding-left: 0px !important;
    flex: 0.25 !important;
    margin-bottom: 1rem;
  }
  .reviews-main .col1 .text {
    margin-top: 1rem;
    font-size: 24px !important;
  }
  .reviews-main .col1 img {
    width: 18%;
  }
  .reviews-main .col2 .review-row .col4 .name {
    flex-wrap: wrap;
  }
  .reviews-main .col2 .review-row .col4 {
    padding-left: 5px;
  }
  .reviews-main .col1 .text1 {
    font-size: 24px !important;
  }
  .reviews-main .col2 {
    flex: 0.7 !important;
    padding: 0 1rem !important;
  }
  .search-popup .search-with-img-result {
    max-height: 100vh;
  }
  .category-box {
    padding: 12px 12px !important;
    text-align: center !important;
    border-radius: 43px !important;
    border: 0.4000000059604645px solid #dbe2ff;
    /* width: 50% !important; */
  }
  .category-box .ctitle {
    font-size: 16px !important;
    margin-bottom: 0 !important;
    color: #2f3e88;
    font-weight: 500;
  }
  .category-box .cdesp {
    font-size: 12px !important;
  }
  #calendar-date-popup {
    height: 100vh !important;
    overflow-y: auto;
  }
  #calendar-date-popup {
    padding: 0 1rem;
  }
  #calendar-date-popup .rdrMonthAndYearWrapper {
    display: none;
  }
  .search-popup .destin-title {
    margin: 0px !important;
  }
  .sub-header-main .col2 .actionBtn {
    padding: 0.2rem 1rem !important;
  }
  #calendar-date-popup .continue-btn {
    position: fixed;
    width: 100%;
    bottom: 1rem;
    z-index: 1;
    margin-left: -1rem;
  }
  #calendar-date-popup .title {
    margin: 1rem 0;
  }
  .search-popup .actions {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background: #fff;
    margin-left: -25px;
    padding: 1rem;
  }
  .step-banner-rounded.vacation-bg {
    background-image: url("https://images.fizota.com/Landing-image-mobile.png") !important;
  }
  .sub-header-main {
    padding: 0.5rem 1rem !important;
  }
  .sub-header-main .col1 {
    display: none !important;
  }
  .sub-header-main .divider {
    display: none;
  }
  .sub-header-main .col2 .cc-search {
    width: 100%;
  }
  .sub-header-main .col2,
  .sub-header-main .col3 {
    flex: 1 !important;
  }
  .mb-airport-popup {
    height: 100vh !important;
  }
  .title-with-description .view-more-link {
    font-size: 14px;
  }
  .title-with-description .view-more-link svg {
    font-size: 0.8rem;
  }
  #edit_calendar_popup .step-content-search,
  #select-source-airort-popup .step-content-search {
    display: inline !important;
  }
  #edit_calendar_popup .step-content-search .rdrCalendarWrapper {
    margin-top: 3rem;
  }
  #select-source-airort-popup .search-with-img-result {
    max-height: calc(100vh - 280px) !important;
  }
  #select-source-airort-popup .step-content-search svg {
    width: 100%;
  }
  .package-trend .main-content .owl-item {
    opacity: 1 !important;
  }
  .carousel-divider {
    min-width: calc(100vw - 30px);
    margin: 30px 0;
    display: flex;
    align-items: center;
    min-height: 1px;
    background: linear-gradient(
      270deg,
      hsla(0, 0%, 100%, 0.05) -0.13%,
      hsla(0, 0%, 88%, 0.05) 0,
      #e0e0e0 49.17%,
      hsla(0, 0%, 88%, 0.05)
    );
  }
  .filterSheetMain svg {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
  .filter-row .head-row {
    display: flex;
    justify-content: space-between;
  }
  .package-list-row {
    display: block !important;
  }
  .up-accordion-parent {
    margin: 0 !important;
    padding: 0 !important;
  }
  .main-content .owl-theme .owl-dots {
    bottom: -30px;
  }
  .control-label {
    text-align: left;
  }
  .form-group .error {
    text-align: left;
  }
  .ml-view .sites-content.mb {
    margin-bottom: 2rem;
  }
  .ml-view .sites-content ul li {
    flex-direction: row;
  }
  .ml-view .sites-content ul li figcaption {
    padding: 0px 0 0 10px;
    font-size: 12px;
    color: #33425b;
    font-weight: 400;
  }
  .ml-view .product-list-parent .cp-main button {
    border-radius: 48px;
    font-family: "poppins";
  }
  .ml-view.package-ul > li .pdr-rhs em {
    color: #6a7487 !important;
  }
  .ml-view.package-ul > li .pdr-rhs strong {
    font-size: 20px;
    color: #21314d;
    font-weight: 600;
  }
  .ml-view .product-description .sites-content ul li {
    margin: 0 0px 0px 0;
  }
  .sites-content ul li figcaption:before {
    display: none;
  }
  .ml-view .product-list-parent .footer-main {
    width: 94% !important;
  }
  .ml-view .product-list-parent .footer-main .product-description-review {
    padding-top: 5px;
  }
  .ml-view.package-ul > li {
    overflow: visible;
    box-shadow: none;
    border: 0.6000000238418579px solid #dfe1e5;
    border-radius: 20px;
    padding: 12px;
  }
  .ml-view .product-description .tags-row {
    padding-left: 0px !important;
  }
  .package-trend .title-with-description .title-col,
  .destination-trend .title-with-description .title-col {
    flex: 1;
    padding-right: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .vacation-banner-main1 .content .img-group {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .vacation-banner-main1 button {
    margin-left: 0px !important;
    margin-top: 15px;
  }
  .title-with-description .title-col .package-discount {
    margin-left: 0px;
    margin-top: 10px;
  }
  .package-trend .title-with-description .sub-txt {
    font-size: 14px;
  }
  .flight-banner .col1 {
    flex: 1 !important;
    align-items: flex-start !important;
  }
  .flight-search-result.cus-pack .flight-banner .col1 .col3 img {
    width: 50%;
  }
  .flight-search-result.cus-pack .flight-banner .col2 {
    width: 100%;
  }
  .flight-search-result.cus-pack .flight-banner .col2 button {
    width: 100%;
  }
  .flight-banner-cntnr {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 1rem;
  }
  .flight-banner {
    padding: 12px 16px !important;
    height: auto !important;
  }
  .unique-package-stay .hsr-rhs .recommend-tag {
    top: 10px !important;
    right: 10px;
  }
  .flight-banner .col4 {
    width: 80%;
  }
  .package-transfer .options .option {
    width: 180px !important;
  }
  .flight-banner .col2 {
    margin-top: 1rem;
    right: -70px !important;
    bottom: auto !important;
    top: -35% !important;
  }
  .flight-banner .col2 > img {
    width: 170px !important;
  }
  .package-details .package-details-lhs .detail-top-header h1 {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .package-details .package-details-lhs .package-def-es p {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 17px;
    font-family: Poppins;
    letter-spacing: 0px;
    opacity: 1;
  }
  .package-details .package-details-lhs .pack-creator-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    box-shadow: none;
    margin: 25px 0;
  }
  .package-details .package-details-lhs .pack-creator-box .pack-creator-detail {
    display: flex;
    gap: 10px;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def
    div:first-child
    h5 {
    height: 16px;
    margin-bottom: 0;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 17px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #373b4d;
    opacity: 1;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def
    div:last-child {
    display: flex;
    gap: 7px;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def
    div:last-child
    strong
    a {
    height: 17px;
    width: max-content;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #121833;
    opacity: 1;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def
    div:last-child
    .star-ratin-creator {
    display: flex;
    gap: 3px;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def
    div:last-child
    .star-ratin-creator
    span
    strong {
    height: 16px;
    text-align: left;
    font-style: normal !important;
    font-variant: normal;
    font-weight: 900 !important;
    font-size: 11px !important;
    line-height: 17px;
    font-family: Poppins !important;
    letter-spacing: 0.55px !important;
    color: #5a6080;
    opacity: 1;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .pack-creator-detail
    .pcreator-def
    div:last-child
    .star-ratin-creator
    .star-rating
    i {
    text-align: left;
    font-style: normal !important;
    font-variant: normal;
    font-weight: 900 !important;
    font-size: 10px !important;
    line-height: 20px;
    letter-spacing: 0.55px !important;
    opacity: 1;
  }
  .package-details .package-details-lhs .pack-creator-box .pcreator-img {
    width: 32px;
    height: 32px;
    border-radius: 999px;
  }
  .package-details .package-details-lhs .pack-creator-box .pcreator-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 999px;
  }
  .package-details .package-details-lhs .pack-creator-box .main-creator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 85px;
    margin-top: 0;
    background: #f0f3ff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 31px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #111733;
  }
  .package-details
    .package-details-lhs
    .pack-creator-box
    .main-creator
    .badge-font-var {
    color: #4258bf;
  }
  .package-details .package-details-lhs .sumry-box-top {
    padding: 0 !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-bottom: 18px;
    border: 0.1px solid #344698;
    border-radius: 4px;
    opacity: 1;
    box-shadow: none;
  }
  .package-details .package-details-lhs .sumry-box-top .mini-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    margin-bottom: 0 !important;

    background: #f0f3ff 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    padding: 0 12px;
  }
  .package-details .package-details-lhs .sumry-box-top .mini-header h5 {
    height: 20px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 900 !important;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #173536;
    opacity: 1;
  }
  .package-details .package-details-lhs .sumry-box-top .mini-box-body {
    display: flex;
    flex-direction: column;
    padding: 8px 12px 12px 12px;
    gap: 24px;
    height: auto;
  }
  .package-details
    .package-details-lhs
    .sumry-box-top
    .mini-box-body
    .summary-body {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }
  .package-details
    .package-details-lhs
    .sumry-box-top
    .mini-box-body
    .summary-body
    .summary-top {
    height: 11px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 8px !important;
    line-height: 12px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #99a4ba;
    opacity: 1;
  }
  .package-details
    .package-details-lhs
    .sumry-box-top
    .mini-box-body
    .summary-body
    .summary-top.summary-date {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 90%;
    gap: 5vw;
  }
  .package-details
    .package-details-lhs
    .sumry-box-top
    .mini-box-body
    .summary-body
    .summary-bottom {
    height: 20px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #374c4d;
    opacity: 1;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .package-details
    .package-details-lhs
    .sumry-box-top
    .mini-box-body
    .summary-body
    .summary-bottom
    span {
    width: 100%;
    display: block;
  }
  .package-details .package-details-lhs .detail-top-detail {
    background: none;
    margin-bottom: 0;
    padding: 0;
  }
  .package-details
    .package-details-lhs
    .detail-top-detail
    .detail-top-detail-parent {
    padding: 0;
  }
  .package-details
    .package-details-lhs
    .detail-top-detail
    .detail-top-detail-parent
    .sites-content {
    padding: 0;
  }
  .package-details
    .package-details-lhs
    .detail-top-detail
    .detail-top-detail-parent
    .sites-content
    ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .package-details
    .package-details-lhs
    .detail-top-detail
    .detail-top-detail-parent
    .sites-content
    ul
    li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: auto;
    background: #f0f3ff 0% 0% no-repeat padding-box;
    padding: 0 6px;
    border-radius: 4px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .package-details
    .package-details-lhs
    .detail-top-detail
    .detail-top-detail-parent
    .sites-content
    ul
    li
    div {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 22px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #111733;
  }
  .package-details
    .package-details-lhs
    .detail-top-detail
    .detail-top-detail-parent
    .sites-content
    ul
    li
    .sites-content-number {
    color: #344698;
    font-weight: 600;
  }
  .unique-package-thing .flag-stay .title {
    padding-left: 5px;
    margin-bottom: 12px !important;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #484e65 !important;
    opacity: 1;
  }
  .unique-package-tab {
    padding-top: 0 !important;
  }
  .unique-package-thing .date-scroll {
    flex-direction: row;
    display: flex;
    overflow-x: auto;
    column-gap: 2px;
  }
  .unique-package-thing .date-scroll::-webkit-scrollbar {
    display: none;
  }
  .unique-package-thing .date-scroll .date {
    color: #bbc0d6;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
  }
  .unique-package-thing .date-scroll .date.active {
    color: #4258bf;
  }
  .unique-package-thing .date-scroll .date {
    padding: 5px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 16px;
    font-family: Poppins;
    letter-spacing: 0px;
    opacity: 1;
  }
  .unique-package-thing .date-scroll .date.active {
    height: 30px;
    padding: 0 15px;
    background: #f5f7ff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }
  .unique-package-thing .up-accordion-content {
    margin-top: 15px;
  }
  .unique-package-thing .up-accordion-content .package-card-row {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .unique-package-thing .up-accordion-content .package-card-row li {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.1px solid #344698;
    border-radius: 8px;
    overflow: hidden;
    opacity: 1;
    margin-bottom: 1rem !important;
  }
  .unique-package-thing .up-accordion-content .package-card-row li .hsr-lhs {
    margin-bottom: 0 !important;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    li
    .hsr-lhs
    .hsr-lhs-img {
    border-radius: 6px 6px 0px 0px !important;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    li
    .hsr-lhs
    .hsr-lhs-desc
    h5 {
    text-align: left;
    font: normal normal 600 14px/21px Poppins;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #121833;
    opacity: 1;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .hsr-lhs-desc
    .product-description-tag {
    gap: 8px;
  }
  .unique-package-thing .up-accordion-content .package-card-row .hsr-lhs-desc {
    height: auto;
    padding: 12px 12px 0px 12px !important;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .hsr-lhs-desc
    .product-description-tag
    span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    padding: 0 14px;
    background: #f5f7ff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #4258bf;
    opacity: 1;
  }
  .unique-package-thing .up-accordion-content .package-card-row .hsr-rhs {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding: 26px 12px 12px 12px !important;
    margin: 0;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .hsr-rhs
    .distance {
    padding-left: 7px;
    font-style: italic;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 18px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #444444;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .hsr-rhs
    .distance
    div {
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #121833;
  }
  .unique-package-thing .package-card-row .hsr-rhs .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px !important;
    height: 48px;
    border: 0.3px solid #db4437 !important;
    border-radius: 6px;
    text-align: left;
    font: normal normal bold 14px/21px Poppins;
    font-style: normal;
    font-variant: normal;
    font-weight: 900 !important;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #db4437;
    opacity: 1;
  }
  .unique-package-thing .package-card-row .hsr-rhs .button.remove-btn:hover {
    color: #fff;
  }
  .package-card-row .add-new-box {
    /* height: 120px; */
    border-radius: 6px;
    padding: 20px 10px 10px 10px !important;
    background: #f5f7ff;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .up-accordion-content
    .add-new-box {
    width: 100%;
    height: 120px;
    padding: 0 !important;
    background: #f5f7ff 0% 0% no-repeat padding-box;
    border: 1px dashed #4258bf;
    margin-top: 5px;
    border-radius: 6px;
    opacity: 1;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .up-accordion-content
    .add-new-box
    .add-new-box-lhs {
    height: 50%;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .up-accordion-content
    .add-new-box
    .add-new-box-lhs
    .action-name {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .up-accordion-content
    .add-new-box
    .add-new-box-rhs {
    height: 50%;
  }
  .unique-package-thing
    .up-accordion-content
    .package-card-row
    .up-accordion-content
    .add-new-box
    .add-new-box-rhs
    .desp {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 15px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 16px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #484e65;
    opacity: 1;
  }
  .hsr-rhs {
    border-top: none !important;
  }
  .unique-package-stay.cus-stay-detail {
    margin-top: 32px;
  }
  .unique-package-stay .hsr-lhs .hsr-lhs-img {
    border-radius: 6px 6px 0px 0px;
  }
  .unique-package-stay .hsr-lhs-desc {
    margin: 2px 12px 0 12px;
  }
  .unique-package-stay .hsr-lhs .recommend-tag {
    background: #ebeeff;
    border-radius: 10px;
    padding: 5px 12px;
    color: #111733;
    font-size: 12px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .unique-package-stay.cus-stay-detail .hotel-search-result .flag-stay {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: 13px;
  }
  .unique-package-stay.cus-stay-detail
    .hotel-search-result
    .flag-stay
    .stay-duration {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .unique-package-stay .hotel-search-result .flag-stay .title {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #484e65 !important;
    opacity: 1;
  }
  .unique-package-stay .hotel-search-result .flag-stay .view-more-link {
    position: relative !important;
    top: 10px !important;
    right: 0px;
  }
  .unique-package-stay .hotel-search-result .flag-stay {
    flex-wrap: wrap !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .unique-package-list .package-transfer .flag-stay {
    align-items: center;
  }
  .unique-package-list .package-transfer .options {
    display: flex;
    justify-content: space-between;
  }
  .unique-package-list .package-transfer .options .option {
    height: auto;
  }
  .unique-package-list .package-transfer .options .option .text1,
  .unique-package-list .package-transfer .options .option .text2,
  .unique-package-list .package-transfer .options .option .text3 {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    line-height: 18px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #1849a9;
    opacity: 1;
  }
  .unique-package-list .package-transfer .options .option .text1,
  .unique-package-list .package-transfer .options .option .text3 {
    font-size: 12px !important;
  }
  .unique-package-list .package-transfer .options .option .text2 {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #243069;
  }
  .unique-package-list .package-transfer .options .option .text3 {
    font-weight: 300 !important;
    color: #3f4d65 !important;
  }
  .unique-package-stay .hotel-search-result .flag-stay .option {
    margin: 10px 0px 0px 0px !important;
    width: 150px;
    height: 30px;
    background: #f5f7ff 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: 500 !important;
    line-height: 16px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #4258bf !important;
    opacity: 1;
  }
  .unique-package-stay.transfer-list .hotel-search-result .flag-stay .title {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #484e65 !important;
    opacity: 1;
  }
  .unique-package-stay .hotel-search-result .flag-stay-transfer li {
    background: #fff 0% 0% no-repeat padding-box;
    border: 0.1px solid #344698;
    border-radius: 6px;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs
    .hsr-lhs-img {
    height: 143px;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs
    .hsr-lhs-img
    img {
    background: transparent center center/cover no-repeat padding-box;
    height: 143px;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .transfer-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .transfer-info
    h4 {
    width: 100%;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #121833;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .transfer-info
    .star-rating-review.cus-transfer-detail
    strong {
    height: 16px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 17px;
    font-family: Poppins;
    letter-spacing: 0.55px;
    color: #5a6080;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .star-rating-review.cus-transfer-detail
    strong {
    height: 16px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 17px;
    font-family: Poppins;
    letter-spacing: 0.55px;
    color: #5a6080;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .star-rating-review {
    margin-top: 5px;
  }
  .star-rating-review {
    margin-left: 12px;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .transfer-info
    .star-rating-review.cus-transfer-detail
    .star-rating
    i {
    height: 16px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.55px;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .star-rating-review.cus-transfer-detail
    .star-rating
    i {
    height: 16px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.55px;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .vehicle-info {
    display: flex;
    justify-content: flex-start;
    column-gap: 11px;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .vehicle-info
    .vehicle-label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    padding: 0 4px;
    margin-top: 8px;
    background: #e0f7ff 0% 0% no-repeat padding-box;
    border: 0.2px solid #bbc0d6;
    border-radius: 2px;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 8px !important;
    line-height: 12px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #374c4d;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-lhs-desc
    .vehicle-info
    .vehicle-label.sub-label {
    font-weight: 500 !important;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-rhs
    .transfer-charge
    p {
    margin-bottom: 0px;
  }
  .unique-package-stay .hotel-search-result .flag-stay-transfer .hsr-rhs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-rhs
    .transfer-btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-rhs
    .transfer-btn
    .button.add-btn {
    height: 48px;
    background: #4258bf 0% 0% no-repeat padding-box;
    border-radius: 6px;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #f4f6ff;
    opacity: 1;
  }
  .unique-package-stay
    .hotel-search-result
    .flag-stay-transfer
    .hsr-rhs
    .transfer-btn
    .button.ghost-button {
    margin-top: 0;
    width: auto;
    border-color: #db4437;
    background: #fff;
    color: #db4437;
    box-shadow: none;
    font-weight: 900;
    border: 0.3px solid #db4437 !important;
  }
  .offer-main {
    margin: 0px !important;
  }
  .offer-main .offer-design-card {
    padding: 1rem 1.5rem 4rem 1.5rem !important;
  }
  .offer-main .offer-design-card .cy-pack {
    bottom: 1rem;
    right: 15px !important;
  }
  .mobile-banner-search.flight-search-main .container {
    max-height: 720px;
  }
  .mobile-banner-search .fs-stay .dateMain,
  .fs-stay .dateMain {
    width: 100%;
  }
  .mobile-banner-search .fs-swap,
  .fs-swap {
    top: 38%;
  }
  .mobile-banner-search .fs-whom .pass-dropdown,
  .fs-whom .pass-dropdown {
    width: 100%;
  }
  .mobile-banner-search.flight-search-main .step-banner-lhs {
    margin-bottom: 25px;
  }
  .mobile-banner-search .flight-search .fs-whom,
  .flight-search .fs-whom {
    opacity: 1 !important;
  }
  .mobile-banner-search .flight-way-search .flight-search .fs-stay,
  .flight-search .fs-stay {
    opacity: 1 !important;
  }
  .mobile-flight-way-search {
    margin-top: 240px !important;
  }
  .mobile-flight-way-search .container {
    overflow-x: initial;
  }
  .title-with-description {
    padding: 0;
    align-items: flex-start;
  }
  .title-with-description h1 {
    font-size: 18px;
  }
  .package-trend .owl-carousel.owl-loaded {
    display: block !important;
  }
  .package-trend .owl-theme .owl-dots {
    bottom: 0rem !important;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .package-trend .owl-nav {
    display: none;
  }
  /* .package-trend .prevBtn,
  .package-trend .nextBtn {
    display: none !important;
  } */
  .uss-about {
    -webkit-line-clamp: 3 !important;
  }
  .uss-about.active {
    -webkit-line-clamp: unset !important;
  }

  .detail-bottom .detail-bottom-lhs .arrow-back-title {
    align-items: flex-start;
  }
  .detail-bottom .detail-bottom-lhs .arrow-back-title svg {
    margin-top: 5px;
  }
  /* .container {
    overflow-x: hidden;
  } */
  .profile-detail-footer .form-group {
    flex-direction: column;
  }
  .deactive-form .form-group label.control-label {
    width: 100% !important;
  }
  .deactive-form .form-group select {
    margin-top: 1rem;
    width: 60%;
  }
  .password-con-box .with-chk-save {
    flex-direction: column;
    padding: 0px;
  }
  .password-con-box .with-chk-save p {
    width: 100%;
  }
  .password-con-box .with-chk-save .dis-button {
    margin-top: 1rem;
  }
  .showMoreBtn {
    text-decoration: underline;
    color: #3c50ae;
    cursor: pointer;
    font-size: 12px;
  }
  .ttd-p-rhs {
    width: auto !important;
    background-color: transparent !important;
  }
  .ttd-p-rhs ul {
    display: flex;
    flex: 1;
  }
  .ttd-p-rhs ul li {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  .ttd-p-rhs .ad-ingo {
    display: flex;
    align-items: center;
  }
  .ttd-p-lhs {
    width: 100% !important;
    border-right: none !important;
  }
  .ttd-p-lhs .ttd-action-btn {
    text-align: center;
  }
  .ttd-p-lhs .ttd-action-btn .packageinfo {
    margin-bottom: 0px;
  }
  .ttd-p-lhs .row {
    display: flex;
  }
  .ttd-pk-select .ttd-p-lhs .row {
    border-radius: 10px;
    background: #f5f7ff;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .ttd-pk-select .ttd-p-lhs .col2 a {
    white-space: nowrap;
  }
  .ttd-pk-select .ttd-p-lhs .col1 {
    flex: 1;
  }
  .ttd-p-lhs .col1 {
    flex: 0.8;
  }
  .ttd-p-lhs .col2 {
    flex: 0.2;
    text-align: right;
  }
  .ttd-p-lhs .col2 span {
    text-decoration: underline;
  }
  .vist-info ul {
    flex-direction: column;
  }
  .slick-dots {
    bottom: 17px !important;
  }
  .slick-dots button {
    box-shadow: none !important;
  }
  .admin-profile .admin-profile-rhs {
    box-shadow: none;
  }
  .abt-banner.rel img {
    height: 280px;
  }
  .abt-banner h1 {
    font-size: 35px !important;
  }
  .abt-why ul {
    flex-direction: column;
    display: flex !important;
  }
  .map-banner-parent .banner-map {
    grid-template-columns: 100% 0%;
  }
  .map-banner-parent .banner-map .bm-lhs {
    border-right: none;
  }
  .traveler-main-package .traveler-main-package-list ul {
    flex-direction: column;
  }
  .traveler-main-package .traveler-main-package-list ul li {
    width: 100%;
  }
  .traveler-main-package .traveler-main-package-list .sites-content ul {
    flex-direction: row;
    justify-content: flex-start !important;
  }
  .traveler-main-package .traveler-main-package-list .sites-content ul li {
    width: auto !important;
  }
  .package-detail .unique-package-stay .hotel-search-result .hsr-rhs {
    /* justify-content: space-between; */
  }
  .unique-package-stay .hotel-search-result ul li,
  .unique-package-stay .hotel-search-result ul li.active {
    padding: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.1px solid #eff2f8 !important;
    margin-bottom: 20px !important;
    border-radius: 6px;
    opacity: 1;
  }
  .unique-package-stay .hotel-search-result ul li .hsr-lhs .hsr-lhs-desc ul li {
    margin: 0 8px 0 0 !important;
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  .unique-package-stay .hotel-search-result ul li .hsr-rhs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0px 12px 0px 12px;
  }
  .unique-package-stay .hotel-search-result ul li.active {
    border: 0.1px solid #344698 !important;
  }
  .unique-package-stay .hotel-search-result ul li.active .hsr-rhs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .unique-package-stay .hotel-search-result .hsr-rhs .stay-price .amt-txt {
    width: auto;
    height: 23px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #444444;
  }
  .unique-package-stay .hotel-search-result .hsr-rhs .service-amount.new-amnt {
    text-align: left;
    width: auto;
    margin-bottom: 0px;
    height: 25px;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #121833;
  }
  .unique-package-stay .hotel-search-result .hsr-rhs .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 48px;
    border: 0px;
    margin: 17px 0 10px 0;
    border: 1px solid #4258bf;
    border-radius: 6px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
  }
  .package-details.package-details-lhs .timer-rhs.pac-inner-a {
    margin-bottom: 1.5rem;
  }
  .package-details .package-details-lhs .rhs-white-box {
    padding-bottom: 50px;
  }
  .package-details .package-details-lhs .clk-red {
    left: 0;
    right: 0 !important;
    width: 0;
    margin: 0 auto;
  }
  .bk-modify {
    padding: 20px 0px 15px !important;
  }
  .why-book ul li {
    padding: 0 10px 0 20px !important;
  }
  .clk-red {
    right: 10px !important;
    bottom: 10px;
    top: auto !important;
  }
  .timer-sticky {
    top: 60px !important;
    margin: 0 0 5px 0 !important;
  }
  .destin-reult-show {
    height: 85% !important;
  }
  .step-content-passenger .child-age-select label {
    font-size: 15px !important;
  }
  .step-content-passenger {
    padding: 0px 0px 20px 0px !important;
  }
  .step-content-passenger .passenger-body ul li {
    padding: 15px 10px !important;
  }
  .step-content-passenger .passenger-body .sel-quality .price-select,
  .step-content-passenger .child-age-select label span {
    color: #bbc0d6 !important;
    font-size: 12px !important;
  }
  .step-content-passenger .sel-quality {
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  .step-content-passenger .label-col {
    flex: 1;
  }
  .step-indicator-merge {
    margin-top: 0.3rem;
    padding: 0px 0 20px 0 !important;
  }
  .step-indicator ul li {
    margin: 0 8px 0 0 !important;
  }
  .step-indicator ul li img {
    width: 12px !important;
    height: 10px !important;
  }
  .step-indicator ul li.active span {
    width: 42px !important;
  }
  .step-indicator ul li span {
    height: 10px !important;
    width: 12px !important;
    margin: 0 !important;
  }
  .hmd-trip {
    text-align: right;
  }
  .hero-content-search input[type="text"] {
    height: 50px;
    font-size: 14px !important;
    border: none;
  }
  .mobile-banner-search .m-search {
    padding-left: 16px !important;
  }
  .step-banner-steps .container {
    background: #fafbfe;
  }
  .hero-content-promt {
    position: absolute;
    bottom: 8%;
  }
  .hero-content-promt ul {
    justify-content: flex-start !important;
  }
  .hero-content-promt ul {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  .hero-content-promt ul li a {
    padding: 8px 10px 8px 15px !important;
    white-space: nowrap;
    background: #e1e0df !important;
    mix-blend-mode: hard-light;
    border-radius: 16px !important;
    opacity: 0.7;
  }
  .hero-content-promt ul li a span {
    font-size: 12px;
    color: #111733;
  }
  .step-banner-steps {
    padding: 0 0 70px 0 !important;
  }
  .step-banner-steps .container .step-indicator,
  .s-title-back,
  .step-content-search {
    display: none !important;
  }
  #calendar_popup {
    width: 100% !important;
  }
  #calendar_popup .step-content-search {
    display: block !important;
    text-align: center;
  }
  .step-banner-rounded {
    padding: 0px !important;
    /* height: 280px !important; */
  }
  .step-banner-rounded .container {
    display: none;
  }
  .step-banner-rounded .img-abs .img-fl,
  .img-fl2,
  .img-fl4 {
    display: none;
  }
  .hero-content-search {
    padding: 2px !important;
    box-shadow: rgba(66, 88, 191, 0.1) 0px 0px 8px 0px !important;
    border-radius: 24px !important;
  }
  .step-banner-rounded .img-abs .img-fl3 {
    left: 30px;
    width: 160px;
    height: 160px;
    top: 150px;
  }
  .hero-content-search input {
    font-size: 10px !important;
  }
  .step-banner-rounded .img-abs .img-fl1 {
    right: -100px;
    width: 160px;
    height: 160px;
    top: 10px;
  }
  .chkout-title {
    font-size: 20px !important;
  }
  .avlbt-checking {
    height: 500px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .package-ul .sites-content ul {
    justify-content: flex-start !important;
  }
  .sites-content ul {
    justify-content: space-between !important;
  }
  .suggestion-list {
    position: initial !important;
    top: auto !important;
    max-height: fit-content !important;
  }
  .search-place {
    min-width: auto !important;
    top: auto !important;
    position: initial !important;
    box-shadow: none !important;
    background-color: #fff !important;
  }
  .search-place ul {
    max-height: fit-content !important;
  }
  .booked-ticket .tickt-content ul {
    flex-direction: column;
  }
  .booked-ticket .tickt-content ul li {
    display: flex;
    width: 100%;
  }
  .booked-ticket .tickt-content ul li:nth-child(1) span {
    width: 38%;
    white-space: nowrap;
  }
  .booked-ticket .tickt-content ul li:nth-child(2) span {
    width: 64%;
    white-space: nowrap;
  }
  .booked-ticket .tickt-content ul li:nth-child(3) span {
    width: 38%;
    white-space: nowrap;
  }
  .booked-ticket .tickt-content ul li:nth-child(3) {
    margin-bottom: 1rem;
  }
  .booking-screen .booked-ticket .booked-ticket-top {
    flex-direction: column;
  }
  .booking-screen .booked-ticket .booked-ticket-top .btt-lhs {
    width: 100%;
  }
  .booking-screen .booked-ticket .booked-ticket-top .btt-rhs {
    width: 100%;
  }
  .booking-screen .booked-ticket .booked-ticket-btm .booked-ticket-btm-rhs {
    text-align: center;
    margin-top: 2rem;
  }
  .booking-screen
    .booked-ticket
    .booked-ticket-btm
    .booked-ticket-btm-rhs
    .button {
    margin-bottom: 0.5rem;
  }
  .booking-screen .flight-step1 .flight-step-lhs {
    width: 100%;
  }
  .booking-screen .flight-step1 .flight-step-lhs.step-inner-lhs {
    border-right: none;
  }
  .booking-screen .flight-step1 .flight-step-lhs .fsl-top .fsl-path {
    width: 100%;
  }
  .booking-screen .flight-step1 .flight-step-lhs .fsl-btm .fsl-path {
    width: 100%;
  }
  .booking-screen .flight-step1 .flight-step-rhs {
    margin-top: 3rem;
    width: 100%;
  }
  .booking-screen .flight-step1 .flight-step-rhs.step-inner-rhs {
    padding-left: 12px;
  }
  .booking-screen .flight-step2 .transport-step-inner .hsr-lhs {
    flex-direction: column;
  }
  .booking-screen .flight-step2 .transport-step-inner .hsr-lhs.step-inner-lhs {
    border-right: none;
  }
  .booking-screen .flight-step2 .transport-step-inner .hsr-lhs .hsr-lhs-img {
    width: 100%;
  }
  .booking-screen .flight-step2 .transport-step-inner .hsr-lhs .hsr-lhs-desc {
    width: 100%;
    margin-top: 1rem;
  }
  .booking-screen
    .flight-step2
    .transport-step-inner
    .transport-step-rhs.step-inner-rhs {
    width: 100%;
    margin-top: 1rem;
    padding-left: 16px;
  }
  .booking-screen .flight-step3 .stay-step-inner .hsr-lhs {
    flex-direction: column;
  }
  .booking-screen .flight-step3 .stay-step-inner .hsr-lhs .hsr-lhs-img {
    width: 100%;
  }
  .booking-screen .flight-step3 .stay-step-inner .hsr-lhs .hsr-lhs-desc {
    width: 100%;
  }
  .booking-screen .flight-step3 .stay-step-inner .stay-step-rhs.step-inner-rhs {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .booking-screen .flight-step3 .stay-step-inner .hsr-lhs.step-inner-lhs {
    border-right: none;
  }
  .booking-screen .flight-step4 .stay-step-inner {
    margin-bottom: 1rem;
  }
  .booking-screen .flight-step4 .stay-step-inner .hsr-lhs {
    flex-direction: column;
  }
  .booking-screen .flight-step4 .stay-step-inner .hsr-lhs .hsr-lhs-img {
    width: 100%;
  }
  .booking-screen .flight-step4 .stay-step-inner .hsr-lhs .hsr-lhs-desc {
    width: 100%;
  }
  .booking-screen .flight-step4 .stay-step-inner .stay-step-rhs.step-inner-rhs {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 1rem;
  }
  .booking-screen .flight-step4 .stay-step-inner .hsr-lhs.step-inner-lhs {
    border-right: none;
  }
  .booking-screen .flight-step4 .step-success-msg {
    margin-top: -1rem;
  }
  .own-package-not-clicked .col-1 {
    flex: 0.4 !important;
  }
  .own-package-not-clicked .col-1 .title h5 {
    margin-bottom: 0px;
  }
  .own-package-not-clicked .col-2 {
    flex: 0.6 !important;
  }
  .own-package-not-clicked .msg {
    padding: 0rem 0rem 1rem 1.5rem;
  }
  .blue-step .stepper-wrapper .stepper-item .step-name {
    font-size: 13px;
  }
  .hotel-search-result .hsr-rhs {
    justify-content: space-between;
  }
  .travler-box .div-group {
    flex-direction: column;
  }
  .travler-box .div-group .form-group {
    width: 100%;
  }
  .travler-box .tb-inner-top {
    flex-direction: column;
  }
  .travler-box .tb-inner-top .form-group {
    width: 100%;
  }
  .travler-box .tb-inner-btm {
    flex-direction: column;
  }
  .travler-box .tb-inner-btm .form-group {
    width: 100%;
  }
  .package-details .package-details-lhs .summary-box {
    flex-direction: column;
  }
  .package-details .package-details-lhs .summary-box .summary-rhs-img {
    width: 100%;
  }
  .package-details .package-details-lhs .summary-box .summary-rhs-content {
    width: 100%;
    padding: 0px;
    margin-top: 8px;
  }
  .unique-package-list .add-new-box {
    padding: 10px 10px 0px 10px;
    flex-direction: column;
    align-items: initial;
  }
  .unique-package-list .add-new-box .add-new-box-lhs {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .unique-package-list .add-new-box .add-new-box-lhs .action-name {
    display: flex;
    flex: 1;
    font-size: 14px;
    color: #4258bf;
    font-weight: bold;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.7px;
  }
  .unique-package-list .add-new-box .add-new-box-rhs {
    display: flex;
    flex: 1;
  }
  .unique-package-list .add-new-box .add-new-box-rhs .desp {
    display: flex;
    flex: 1;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .room-choice-desc .room-choice-four {
    position: initial;
  }
  .package-details .things-change-pack {
    margin: 0px;
  }
  .flight-way-search {
    margin: 0 0 30px 0 !important;
  }
  .detail-bottom-package .package-description-inner {
    margin-bottom: 0px;
  }
  .package-info .common-popup {
    height: 100vh;
    margin: 0px !important;
    border-radius: 0px;
    overflow-y: auto;
    padding: 50px 20px 100px 20px;
  }
  .package-info {
    top: 0px !important;
    background: #fff;
  }
  .package-info .mfp-container {
    padding: 0px !important;
  }
  .package-info .avail-btm {
    position: fixed;
    bottom: 0px;
    width: 90%;
    background: #fff;
  }
  .package-info a.date-package-popup {
    right: 20px;
    top: 15px;
  }
  .detail-bottom-package .package-rhs .package-rhs-inner-btn {
    width: 100%;
  }
  .things-change-pack .hotel-search-result > ul > li {
    margin: 0 0 50px 0;
  }
  .things-change-pack .hsr-lhs-img {
    width: 100% !important;
  }
  .things-change-pack .hsr-lhs-desc {
    width: 100% !important;
  }
  .things-change-pack .hotel-search-result .best-flag {
    right: 5px;
    top: -30px;
  }
  .things-change-pack .hotel-search-result .mb15 {
    margin-bottom: 40px;
  }
  .things-change-pack .hsr-rhs {
    justify-content: flex-end;
  }
  .things-change-pack .hotel-search-result .hsr-rhs {
    border-top: none;
    margin-top: 0px;
  }
  .things-change-pack .hotel-search-result .hsr-rhs .button {
    padding: 15px 12px;
  }
  .things-do-list-search .flight-search .choose-loc,
  .flight-way-search.choose-stay .choose-loc {
    width: 80% !important;
  }
  .things-do-list-search .flight-search .fg-btn,
  .flight-way-search.choose-stay .fg-btn {
    width: 17%;
  }
  .flight-way-search {
    padding: 20px 15px 10px 15px !important;
  }
  .priceBreakdown__bottomSheetMain [data-rsbs-overlay] {
    z-index: 100 !important;
    height: auto;
  }
  .priceBreakdown__bottomSheetMain [data-rsbs-backdrop] {
    z-index: 99 !important;
  }
  .priceBreakdown__bottomSheetMain
    .detail-bottom-rhs-price
    > ul
    > li:last-child {
    border-top: none;
    padding-top: 0px;
  }
  .flight-way-search .flight-search .fs-stay,
  .flight-way-search .flight-search .fs-whom {
    opacity: 0.5;
  }
  .flight-way-search.onway-frm .fs-location {
    width: 100% !important;
  }
  .flight-way-search.choose-transport .flight-way {
    padding: 0px;
  }
  .flight-way-search.choose-transport .flight-way ul {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .flight-way-search.choose-transport .flight-way ul li .button {
    width: 290px;
    margin-top: 2rem;
  }
  .filters-main {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  .filters-main .col-1 {
    flex: 0.3;
    flex-direction: column;
  }
  .filters-main .col-2 {
    flex: 0.7;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: auto;
  }
  .filters-main .col {
    flex: 1 !important;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
  }
  .filters-main .col .options {
    flex-direction: row;
    display: flex;
  }
  .filters-main .col .options div {
    margin-right: 0.5rem;
  }
  .filters-main .col .options div {
    white-space: nowrap;
  }
  .filters-main .col-2 .options {
    flex-direction: row;
    display: flex;
  }
  .filters-main .col-2 .options div {
    margin-right: 0.5rem;
  }
  .filters-main .col-1 .button {
    padding: 14px 20px !important;
  }
  .filters-main .col-1 .filter-icon {
    font-size: 1rem;
    vertical-align: middle;
    margin-left: 0.3rem;
  }
  .filters__bottomSheetMain .flight-way-search {
    margin: 0px;
  }
  .filters__bottomSheetMain .fs-swap {
    top: 51px;
    left: 20px;
    right: auto;
  }
  .filters-main__bottomSheetMain .container {
    padding: 1rem 1rem 0.5rem 1rem;
  }
  .filters-main__bottomSheetMain .option {
    background: #f0f2ff;
    padding: 13px 15px;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .filters-main__bottomSheetMain .active {
    background: #4258bf !important;
    color: #fff;
  }
  .filters-main__bottomSheetMain .rating-option .active {
    border-radius: 10px;
  }
  .filters-main__bottomSheetMain .container {
    margin-bottom: 4rem;
  }
  .filters-main__bottomSheetMain .container .actionMain {
    position: fixed;
    bottom: 0px;
    width: 90%;
    background: #fff;
    justify-content: space-between;
  }
  .filters-main__bottomSheetMain .container .actionMain .button {
    width: 100%;
  }
  .package-details {
    &.pb40 {
      padding-bottom: 0px;
    }
  }
  .Help {
    padding-left: 0px !important;
    flex-wrap: wrap;
  }
  .FAQ-container {
    width: 100% !important;
  }
  .feedbackcontainer {
    width: 100% !important;
  }
  .package-details-lhs .flight-search-result > ul {
    position: relative;
    top: 8px;
  }
  .package-details-lhs .flight-search-result > ul .best-flag {
    display: inline-flex;
    right: 10px;
    top: -35px;
  }

  .package-transfer .flight-search-result > ul > li {
    border-radius: 8px;
  }

  .flight-search-rhs {
    padding-top: 15px !important;
  }
  .fsl-path:after {
    left: 80px;
    right: 80px;
  }
  .ufs-inner-top {
    padding: 10px 0 0 0 !important;
  }
  .ufs-box .ufs-box-lhs .ufs-info {
    display: flex;
  }
  .view-more-link .button {
    margin: 0px;
    padding: 10px 20px;
    background: #eff2f8;
    border-radius: 6px;
    opacity: 1;
    border: 0px;
  }
  .view-more-link .button.ghost-button:hover {
    background: #4258bf;
    color: #fff;
    border-color: #4258bf;
  }
  .ffs-icon {
    width: 15px !important;
    height: 15px !important;
    margin-left: 0.5rem;
  }
  .hfs-icon {
    width: 15px !important;
    height: 15px !important;
    margin-left: 0.5rem;
  }
  .cfs-icon {
    width: 15px !important;
    height: 15px !important;
    margin-left: 0.5rem;
  }
  .tfs-icon {
    width: 15px !important;
    height: 15px !important;
    margin-left: 0.5rem;
  }
  .device_nav li a {
    font-size: 18px;
  }
  .home-page .ptf-options ul li a img {
    margin-left: 0;
    width: 12px;
    height: 12px;
  }
  .home-page .ptf-options ul li a {
    padding: 8px 12px;
    border: 1.2999999523162842px solid #eceef9;
    border-radius: 48px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background: #fff;
    font-weight: 500;
    font-size: 12px;
    color: #2f3e88;
  }
  .home-page .sub-txt {
    margin: 10px 0 10px 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    color: #3b436b;
  }
  .home-page .sub-txt img {
    margin-right: 5px;
  }
  .home-page .color-header {
    font-size: 14px !important;
    font-weight: 400;
  }
  .home-page .color-header span {
    font-size: 15px;
    font-weight: 600;
  }
  .home-page .ptf-options ul li a span {
    display: flex;
    align-items: center;
  }
  .home-page .ptf-options.fIcon {
    margin-right: 8px;
  }
  .home-page .package-av-list h2 {
    margin-bottom: 0px;
  }
  .home-page .ptf-options.fIcon img {
    margin-right: 5px;
  }
  .category-box.active {
    background: radial-gradient(134% 235% at 48% 28%, #4258bf 0%, #1b37bd 100%);
  }
  .category-box.active .ctitle {
    color: #fff;
  }
  .home-page .fRow {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .home-page .fRow .package-top-filters {
    width: 50%;
    justify-content: flex-end;
  }
  .package-top-filters {
    top: 60px !important;
    width: 100%;
    padding: 16px 0 0px 0 !important;
    margin-bottom: 1rem;
  }
  .create_package {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2;
  }
  .create_package button {
    background: #4258bf !important;
    text-transform: initial;
  }
  .det-banner-lhs {
    width: 100% !important;
    height: 195px !important;
  }
  .det-banner-lhs img {
    width: 100%;
    height: 100%;
  }
  .package-av-list h2 {
    font-size: 22px !important;
  }
  .flag-stay span {
    min-width: 70px !important;
    padding: 8.25px 19px !important;
    font-size: 12px;
  }
  .trans-invi {
    visibility: hidden;
  }
  .map-banner-parent .banner-map .bm-lhs {
    border-radius: 0px;
    z-index: 0;
  }
  .slider-gallery {
    border-radius: 0 !important;
  }
  .innersection {
    border-radius: 15px;
    margin-top: -27px;
    background: #fff;
    position: relative;
    z-index: 2;
  }
  .innersection .pl-name {
    position: relative;
    z-index: 2;
    padding: 0 0 10px 0;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 28px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
  }
  .innersection .pl-name h2 {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
  }
  .unique-package-list .package-transfer .flag-stay .title {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #484e65 !important;
    opacity: 1;
  }
  .flight-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    height: 43px;
    width: 100%;
    padding: 0 12px;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .flight-info .flight-trip,
  .flight-trip {
    width: fit-content;
    background: #e0f7ff 0% 0% no-repeat padding-box;
    border: 0.20000000298023224px solid #bbc0d6;
    border-radius: 4px;
    opacity: 1;
    padding: 4.5px 8px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 450;
    font-size: 9px;
    line-height: 16px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #374c4d;
    opacity: 1;
    white-space: nowrap;
  }

  .flight-info .flight-trip .row {
    display: flex;
    align-items: center;
  }
  .flight-info .flight-trip .row i {
    margin-right: 5px;
  }

  .flight-search-lhs {
    padding: 0px !important;
  }
  .fsl-logo {
    display: flex;
  }
  .flight-step-inner .step-inner-lhs {
    padding-right: 0px !important;
  }
  .flight-step-lhs .fsl-logo {
    width: 100% !important;
  }
  .flight-search-lhs .fsl-btm {
    padding-top: 0 !important;
  }
  .flight-search-result.cus-pack .fsl-top li {
    padding-bottom: 24px !important;
  }
  .flight-search-result.cus-pack .fsl-btm {
    padding-bottom: 0;
  }
  .flight-search-lhs .fsl-top .fsl-logo,
  .flight-search-lhs .fsl-btm .fsl-logo,
  .flight-step-lhs .fsl-top .fsl-logo,
  .flight-step-lhs .fsl-btm .fsl-logo {
    flex-direction: row;
    justify-content: space-between;
  }
  .flight-search-lhs .fsl-top .fsl-logo,
  .flight-search-lhs .fsl-btm .fsl-logo {
    padding-bottom: 12px;
  }

  .flight-search-lhs .fsl-logo .fsl-logo-main {
    display: flex;
    flex-direction: row;
  }

  .flight-search-lhs .fsl-logo .fsl-logo-main .operating-carrier {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flight-search-lhs
    .fsl-logo
    .fsl-logo-main
    .operating-carrier
    .operating-carrier-code {
    width: max-content;
    height: 11px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #5a6080;
    opacity: 1;
  }

  .flight-search-lhs .fsl-top .fsl-logo .fsl-logo-main .operating-carrier,
  .flight-search-lhs .fsl-btm .fsl-logo .fsl-logo-main .operating-carrier,
  .flight-search-lhs .fsl-top .fsl-logo .stop-duration,
  .flight-search-lhs .fsl-btm .fsl-logo .stop-duration {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #111733;
    opacity: 1;
  }

  .flight-search-lhs .fsl-top .fsl-logo .stop-duration .flight-stop,
  .flight-search-lhs .fsl-btm .fsl-logo .stop-duration .flight-stop {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #4258bf;
    opacity: 1;
    padding: 0 0 0 5px;
  }

  .flight-search-lhs .fsl-top .fsl-path .fsl-from,
  .flight-search-lhs .fsl-btm .fsl-path .fsl-from,
  .flight-search-lhs .fsl-top .fsl-path .fsl-to,
  .flight-search-lhs .fsl-btm .fsl-path .fsl-to {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: baseline;
    width: max-content;
  }

  .flight-search-lhs .fsl-top .fsl-path .fsl-from,
  .flight-search-lhs .fsl-btm .fsl-path .fsl-from {
    align-items: flex-start;
  }

  .flight-search-lhs .fsl-top .fsl-path .fsl-to,
  .flight-search-lhs .fsl-btm .fsl-path .fsl-to {
    align-items: flex-end;
  }

  .flight-search-lhs .flight-date {
    display: flex;
    flex-direction: column;
    width: max-content;
    height: 15px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 16px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #484e65;
    opacity: 1;
  }

  .flight-search-lhs .flight-city {
    display: flex;
    flex-direction: column;
    width: max-content;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 1000;
    font-size: 14px;
    line-height: 27px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #111733;
    opacity: 1;
  }

  .flight-search-lhs .flight-city1 {
    display: flex;
    flex-direction: column;
    width: max-content;
    text-align: right;
    font-style: normal;
    font-variant: normal;
    font-weight: 1000;
    font-size: 14px;
    line-height: 27px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #111733;
    opacity: 1;
  }

  .flight-search-lhs .flight-city .flight-location,
  .flight-search-lhs .flight-city1 .flight-location {
    width: max-content;
    height: 11px;
    margin-top: -5px;
    align-self: center;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #5a6080;
    opacity: 1;
  }

  .flight-search-lhs .flight-time {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 15px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 16px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #111733;
    opacity: 1;
  }

  .fsl-path:after {
    width: 38%;
  }

  .stop-duration {
    display: flex;
    align-items: center;
    background: #ebeeff;
    padding: 4.5px 8px;
    border-radius: 8px;
  }
  .fsl-logo .Airline-Image {
    margin-right: 0.5rem;
    border-radius: 5px;
  }
  .fsl-logo-main {
    display: flex;
  }
  .flight-search-result.cus-pack > ul > li {
    padding: 0 0 12px 0 !important;
    margin-bottom: 22px !important;
  }
  .flight-search-result .flight-search-rhs {
    margin-top: 1rem;
    padding: 15px 12px 0 12px !important;
    display: flex;
    align-items: center;
  }
  .flight-search-result .flight-search-rhs .flight-button {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    width: 100%;
  }
  .flight-search-result .flight-search-rhs .view-more-link .button {
    margin: 0px;
    width: 146px;
    padding: 10px 20px;
    background: #eff2f8 !important;
    border-radius: 6px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins";
    letter-spacing: 0px;
    color: #373b4d;
    opacity: 1;
    border: 0px;
  }
  .flight-search-result .flight-search-rhs .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 146px;
    background: #4258bf 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    border-radius: 20px;
  }
  .flight-search-result .flight-search-rhs .button.remove-btn {
    width: 100% !important;
    padding: 12px 20px;
    background: #fef2f1 !important;
    border-radius: 48px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    font-weight: 500;
    font-size: 16px !important;
    line-height: 1.5;
    color: #d92c20;
    border: 0;
    margin-bottom: 1rem;
  }

  .flight-search-result .flight-search-rhs .button.ghost-button {
    background: #fff;
  }
  .flight-search-rhs {
    justify-content: space-between !important;
    align-items: center;
  }
  .flight-search-rhs strong {
    margin-bottom: 0px !important;
  }
  .new-tags {
    margin-top: 1rem;
    position: initial !important;
  }
  .hsr-lhs-desc .hotel-detail {
    display: flex;
    justify-content: space-between;
  }
  .hsr-lhs-desc .hotel-detail h4 {
    width: 214px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #373b4d;
    opacity: 1;
  }
  .hsr-lhs-desc .hotel-detail .star-rating-review {
    margin-bottom: 0px;
  }
  .hsr-lhs-desc .hotel-detail .star-rating-review .sr-review strong {
    margin-right: 4px;
    text-align: left;
    font-style: normal !important;
    font-variant: normal;
    font-weight: 1000 !important;
    font-size: 11px;
    line-height: 17px;
    font-family: Poppins !important;
    letter-spacing: 0.55px;
    color: #5a6080 !important;
    opacity: 1;
  }
  .hsr-lhs-desc .hotel-detail .star-rating-review .star-rating i {
    width: auto;
    height: auto;
    font-style: normal !important;
    font-variant: normal;
    font-weight: 100 !important;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -2px;
    opacity: 1;
  }
  .hsr-lhs-desc span {
    height: 15px;
    padding-top: 2px !important;
    text-align: left;
    font-style: normal !important;
    font-variant: normal;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px;
    font-family: Poppins !important;
    letter-spacing: 0px;
    color: #373b4d;
    opacity: 1;
  }
  .hsr-lhs-desc ul li:first-child {
    margin-left: 0px !important;
  }
  .hsr-lhs-desc ul {
    margin: 9.6px 0px 11.4px 0px !important;
    padding: 0 !important;
  }
  .hsr-lhs-desc ul li {
    margin: 0 8px !important;
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  .hsr-lhs-desc .text1,
  .hsr-lhs-desc .text2 {
    height: 15px;
    margin-bottom: 2px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 16px;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #373b4d !important;
    opacity: 1;
  }
  .hsr-lhs-desc .amenities-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hsr-lhs-desc .product-activity-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .hsr-lhs-desc .product-activity-container .button {
    margin: 0px;
  }
  .hsr-lhs-desc .product-description-tag {
    padding-top: 0px !important;
  }
  .hsr-lhs-desc .product-description-tag span {
    margin: 0px;
  }
  .hsr-lhs-desc .row {
    display: flex;
    width: 100%;
  }
  .hsr-lhs-desc .row .col-1 {
    width: 72%;
  }
  .hsr-lhs-desc .row .col-1 h5 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .hsr-lhs-desc .row .col-1 h5 {
    margin-bottom: 5px;
  }
  .hsr-lhs-desc .row .col-2 {
    width: 28%;
  }
  .hsr-lhs-desc .row .col-2 .product-description-tag {
    float: right;
  }
  .flight-search-result > ul > li {
    padding-top: 0px !important;
  }
  .unique-package-list:before {
    left: 35px !important;
  }
  .fsl-logo {
    font-size: 12px;
  }
  .fsl-path .fsl-direct {
    font-size: 12px;
  }
  .fsl-path .fsl-from {
    min-height: 50px;
  }
  .fsl-path .fsl-to {
    min-height: 50px;
  }
  .fsl-path .fsl-time {
    font-size: 12px;
  }
  .flight-search-lhs .fsl-btm {
    padding-bottom: 5px;
  }
  .fsl-btm {
    padding-top: 20px !important;
  }
  .priceDetail-bottomSheetMain {
    margin-top: 8rem;
  }
  .priceDetail-bottomSheetMain .fareDetails {
    position: fixed;
    bottom: 0;
    box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
      0 -1px 0 rgba(38, 89, 115, 0.05);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #fff;
    width: 100%;
    z-index: 91;
  }
  .asst-main {
    position: relative;
  }
  .asst-cntnr {
    width: 100%;
    position: absolute;
    top: -40px;
    background: #122e9c;
    border-radius: 1rem 1rem 0 0;
    height: 54px;
    z-index: -1;
  }
  .asst-row {
    display: flex;
    align-items: center;
    justify-content: left;
    color: #ffffff;
    padding: 9px 0.75rem;
    column-gap: 0.5rem;
  }
  .asst-row .css-1wlk0hk-MuiAvatar-root,
  .asst-row .css-3i9vrz {
    width: 18px;
    height: 18px;
  }
  .asst-row > img {
    margin: 0.25rem 0.3rem;
  }
  .asst-row > span {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    color: #ffffff;
    width: 80%;
  }
  .asst-empty {
    width: 100%;
    height: 20px;
    background: #fff;
    position: relative;
    border-radius: 1rem 1rem 0 0;
  }
  .asst-empty::before {
    position: absolute;
    content: "";
    display: block;
    width: 36px;
    height: 4px;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: hsla(0, 0%, 0%, 0.14);
  }
  .priceDetail-bottomSheetMain .fareDetails .line {
    position: absolute;
    content: "";
    display: block;
    width: 36px;
    height: 4px;
    top: calc(8px + env(safe-area-inset-top));
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: hsla(0, 0%, 0%, 0.14);
    background-color: var(--rsbs-handle-bg, hsla(0, 0%, 0%, 0.14));
    z-index: 1;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-1 .price {
    color: #120c0f;
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.1px;
    line-height: 45px;
  }
  .priceDetail-bottomSheetMain .fareDetails .row {
    margin-top: 5px;
    flex-direction: row;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1.5rem;
    background: #ffffff;
    border-radius: 1rem 1rem 0 0;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-1 {
    flex: 0.6;
    display: flex;
    flex-direction: column;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-1 .title {
    color: #120c0f;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.56px;
    line-height: normal;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-1 .title span {
    color: #4258bf;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-1 .breakdown {
    color: #4258bf;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.56px;
    line-height: normal;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-2 {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .priceDetail-bottomSheetMain
    .fareDetails
    .check-availability
    .row
    .col-2
    .action {
    width: 100%;
    height: 50px;
    border-radius: 20px !important;
    background-color: #4258bf !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: initial !important;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.7px;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-2 {
    flex: 0.45;
  }
  .priceDetail-bottomSheetMain .fareDetails .row .col-2 .action {
    width: 120px;
    height: 50px;
    border-radius: 20px !important;
    background-color: #4258bf !important;
    color: #fff !important;
    font-size: 16px !important;
    text-transform: initial !important;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.7px;
  }
  .priceDetail-bottomSheetMain .fareDetails .own-package {
    padding: 1.5rem 1rem 0.5rem 1rem;
  }
  .priceDetail-bottomSheetMain .fareDetails .own-package .breakdown {
    color: #4258bf;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.56px;
    line-height: normal;
    padding-top: 0.5rem;
  }
  .priceDetail-bottomSheetMain .fareDetails .own-package .msg {
    text-align: center;
    padding: 10px 0;
  }
  .priceBreakdown__bottomSheetMain .container {
    padding: 0.8rem 1.5rem;
  }
  .priceBreakdown__bottomSheetMain .container .details {
    flex: 1;
    flex-direction: row;
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .priceBreakdown__bottomSheetMain .container .details .title {
    color: #120c0f;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.64px;
    line-height: normal;
  }
  .priceBreakdown__bottomSheetMain .container .details .title span {
    color: #4258bf;
  }
  .priceBreakdown__bottomSheetMain .container .col-2 {
    flex: 1;
    flex-direction: column;
    letter-spacing: 0px;
    align-items: flex-end;
    display: flex;
    color: #120c0f;
    font-size: 14px;
    font-family: "Poppins";
  }
  .priceBreakdown__bottomSheetMain .container .col-2 .price {
    color: #120c0f;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.08px;
    line-height: normal;
  }
  .priceBreakdown__bottomSheetMain .container .action {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
  }
  .priceBreakdown__bottomSheetMain .container .btn {
    width: 250px;
    height: 50px;
    border-radius: 20px !important;
    background-color: #4258bf !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 16px !important;
    text-transform: initial !important;
  }
}

/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #4258bf;
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, 0.3);
    --border: #4258bf;
    --border-hover: #333333;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #999;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 20px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 2px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="radio"] {
    width: 22px;
    height: 22px;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: -1px;
    top: -1px;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="radio"]:checked {
    --b: var(--active-inner);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"] {
    width: 20px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    cursor: pointer;
  }

  input[type="checkbox"]:not(.switch) {
    border-radius: 3px;
  }
  input[type="checkbox"]:not(.switch):after {
    width: 4px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--active);
    opacity: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0px;
    top: 0px;
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}

footer {
  position: relative;
  background: #fff;
  padding: 75px 0 0 0;
  font-size: 15px;
  font-weight: 400;
}
.footer-top {
  padding: 0 0 40px 0;
  display: flex;
  justify-content: space-between;
}
.footer-logo {
  width: 340px;
  padding-right: 80px;
}
.footer-logo .float-logo {
  display: block;
  margin: 0 0 20px 0;
  width: 150px;
}
.footer-nav {
  width: calc(100% - 340px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-nav-inner {
  width: 24%;
  padding-right: 20px;
}
.footer-nav-inner:last-child {
  width: 28%;
  padding-right: 0;
}

.footer-nav h5 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
  text-transform: inherit;
}
.footer-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-nav ul li a {
  color: #444444;
  display: inline-flex;
  margin-bottom: 15px;
  letter-spacing: 0.1px;
}
.footer-nav ul li a:hover {
  color: #4258bf;
}

.social-media-parent h6 {
  letter-spacing: 0.8px;
  margin: 0 0 15px 0;
}
.social-media a img {
  height: 36px;
  width: 36px;
}
.social-media a {
  display: block;
  margin-right: 15px;
}
.social-media {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.social-media a:last-child {
  margin-right: 0;
}
.social-media img:hover {
  transition: all 0.2s cubic-bezier(0.63, 0.05, 0.43, 1.7);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.social-media-parent p {
  margin: 0;
}
.copyright-section {
  padding: 10px 10px;
  color: #342f41;
  letter-spacing: 0.12px;
}
.copyright-section p {
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
}

body .react-datepicker {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 0;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.18);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #ec194b !important;
}
body .react-datepicker-popper {
  z-index: 2;
}
body .react-datepicker__day-names,
body .react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

body .react-datepicker__current-month {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.17px;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

#scrollbutton {
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: auto;
  height: auto;
  z-index: 999;
  opacity: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#scrollbutton.show {
  display: inline-block;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#scrollbutton a {
  background: #4258bf;
  display: flex;
  position: relative;
  color: #fff;
  overflow: hidden;
  border-radius: 50%;
  font-size: 18px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.outlet-listing-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.outlet-listing-lhs {
  width: 318px;
  border-radius: 20px;
  border: 2px solid #4258bf;
  padding: 30px 23px;
  position: sticky;
  top: 90px;
}

.outlet-listing-rhs {
  width: calc(100% - 340px);
}
.outlet-listing-rhs > ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.outlet-listing-rhs > ul > li {
  width: 30.90128755364807%;
  margin: 0 3.648068669527897% 40px 0;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.3s linear all;
  padding-bottom: 60px;
  position: relative;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}
.product-list-parent {
  padding-bottom: 60px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.3s linear all;
  height: 100%;
}
.main-content .product-list-parent,
.ml-view .product-list-parent {
  overflow: initial;
}
.product-list-parent .footer-main {
  position: absolute;
  width: 100%;
  bottom: 0px;
}
.product-list-parent .cp-main {
  /* position: absolute; */
  bottom: -45px;
  width: 100%;
}
.product-list-parent .cp-main button {
  background: #4258bf;
  border-radius: 12px;
  text-transform: initial;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  box-shadow: none;
}

.outlet-listing-rhs > ul > li:hover {
  box-shadow: 1px 0 15px rgb(0 0 0 / 15%);
}
.outlet-listing-rhs > ul > li:nth-child(3n) {
  margin-right: 0;
}

.funthings .owl-item:hover .product-list-parent {
  border: 1px solid #4258bf;
}
.product-img {
  position: relative;
}
.ml-view.package-ul .product-img {
  height: 240px !important;
  border-radius: 12px !important;
}
.ml-view.package-ul .product-img,
.main-content .product-img {
  position: relative;
  height: 150px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.product-img img {
  width: 100%;
}
.package-discount {
  right: 10px;
  bottom: 10px;
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  background: transparent linear-gradient(91deg, #ff1c00 0%, #ff8f10 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  border-radius: 8px;
  padding: 6px 10px;
  &::after {
    content: " *";
    color: #fff;
  }
}
.package-original-amt {
  text-decoration: line-through;
  font-size: 0.8rem !important;
}
.main-content .product-description {
  padding: 15px 15px 5px 0px;
}
.main-content .product-description .tags-row,
.ml-view .product-description .tags-row {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
}
.main-content .product-description .days-col,
.ml-view .product-description .days-col {
  color: #373b4d;
  font-size: 12px;
  font-weight: 500;
}
.product-description {
  padding: 0 0 0 1rem;
}
.main-content .product-description-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-description-title {
  margin-bottom: 15px;
}
.main-content .product-description-title {
  margin-left: 12px;
}
.product-description-title .title-col {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.product-description-title .review-col {
  flex: 0.48;
  text-align: right;
}
.product-description-title .title-col .cityname {
  margin-top: 5px;
  color: #4258bf;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.product-description-title .title-col .cityname img {
  margin-right: 3px;
}
.recommend-activities .product-list-parent,
.activity-themes .product-list-parent {
  padding-bottom: 50px;
  border: 1px solid #48914d2b;
}
.product-description-title h5 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #21314d;
}
.product-description-title h5 span {
  color: #444;
  transition: 0.3s linear all;
  font-weight: 600;
}
.product-description-title h5 span:hover {
  color: #4258bf;
}
.product-description-tag,
.product-additional-tag {
  display: flex;
  flex-wrap: wrap;
}
.product-additional-tag {
  padding: 10px 0 0 0;
}
.product-description-tag span {
  border-radius: 20px;
  background-color: #4258bf;
  color: #ffffff;
  font-size: 10px;
  letter-spacing: normal;
  padding: 7px 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 5px 0;
  line-height: 1.1;
}
.product-additional-tag span {
  border-radius: 5px;
  background-color: #eef3ff;
  color: #444444;
  font-size: 10px;
  letter-spacing: normal;
  padding: 7px 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 5px 0;
  line-height: 1.1;
}
.main-content .product-description-review,
.ml-view .product-description-review {
  left: 3px;
  margin-bottom: 1rem;
  padding: 0 10px;
}

.product-description-review {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  margin: auto;
  align-items: flex-end;
}
.main-content .product-description-review,
.ml-view .product-description-review {
  position: initial;
}
.pdr-lhs strong {
  color: #4258bf;
  font-size: 14px;
  font-weight: 600;
}

.pdr-lhs span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.12px;
  display: block;
  margin: 0 0 1px 0;
}
.pdr-rhs span {
  display: block;
  font-size: 12px;
  letter-spacing: 0;
}
.pdr-rhs strong {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #121833;
}
.invisible {
  visibility: hidden;
}
.home-page .showing-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.showing-header {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  margin: 1rem 0 0.25rem 0;
  position: relative;
}

.showing-header h4 {
  color: #6d6d6d;
  font-size: 16px;
  margin: 0;
}
.showing-header > a {
  display: inline-flex;
  align-items: center;
  color: #0000ee;
  letter-spacing: 0.8px;
}
.showing-header > a > img {
  margin-left: 12px;
}

ul.sort-by {
  display: none;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 1px solid #e4e4e4;
  position: absolute;
  top: 100%;
  right: 0;
  list-style: none;
  width: 160px;
  z-index: 2;
}
ul.sort-by.active {
  display: block;
}
ul.sort-by li a {
  display: block;
  padding: 8px 10px;
  line-height: 1.2;
  border-bottom: 1px solid #f4f4f4;
  font-size: 13px;
  font-weight: 500;
  color: #444444;
}
ul.sort-by li a:hover,
ul.sort-by li.active a {
  background: #4258bf;
  color: #fff;
}
.innersection {
  padding: 24px 0 40px 0;
}
.outlet-listing-filter {
  padding: 0px 0 40px 0;
}
.outlet-listing-filter h2 span,
#date-popup .cp-header h3 span {
  color: #4258bf;
}
.outlet-listing-filter h2 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.outlet-listing-filter ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.outlet-listing-filter ul li a {
  border-radius: 20px;
  border: 1px solid #707070;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #444444;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.7px;
  padding: 5px 10px;
  height: 50px;
  line-height: 1;
}
.outlet-listing-filter ul li.active a {
  border: 1px solid #4258bf;
  background-color: #4258bf;
  color: #fff;
}

.outlet-listing-filter ul li {
  width: 11.71875%;
  margin: 0 2.34375% 5px 0;
}
.outlet-listing-filter ul li a img {
  margin-left: 8px;
}
.outlet-listing-filter ul li.e-all a {
  color: #0000ee;
}
.detail-top {
  margin: 0 0 30px 0;
}

.detail-top-lhs {
  width: 100%;
  margin: 0 0 40px 0;
}

.detail-top-rhs {
  width: 49.609375%;
}
.detail-top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detail-top-header h1 {
  color: #342f41;
  font-size: 30px;
  letter-spacing: 1.6px;
  margin: 0 20px 15px 0;
  line-height: 1.1;
}
.detail-top-header .product-description-tag {
  margin: 0 0 5px 0;
}
.detail-top-header p {
  margin: 0;
}
.detail-top-detail {
  margin: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 20px;
  background-color: #dce1fa;
  padding: 30px 30px 25px 30px;
}

.detail-top-detail h5 {
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.8px;
  color: #444444;
  width: 100%;
}
.detail-top-detail ul {
  list-style: none;
  padding: 0;
}
.detail-top-detail ul li {
  position: relative;
  padding: 0 0 0 30px;
  margin: 0 0 20px 0;
}
.detail-top-detail ul li:last-child {
  margin-bottom: 0;
}
.detail-top-detail ul li img {
  position: absolute;
  left: 0;
  top: 2px;
}
ul.detail-top-detail-lhs {
  width: 55%;
  padding-right: 20px;
}

ul.detail-top-detail-rhs {
  width: 45%;
}
.detail-top-detail-parent {
  width: calc(100% - 220px);
  border-right: 3px solid #4258bf;
  padding-right: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.detail-top-detail-parent-rating {
  width: 220px;
  padding-left: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.detail-top-rhs .owl-carousel {
  border-radius: 20px;
  overflow: hidden;
}
.detail-top-rhs .owl-carousel .owl-prev,
.detail-top-rhs .owl-carousel .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 42px;
  height: 41px;
  font-size: 0;
}
.detail-top-rhs .owl-carousel .owl-prev:hover,
.detail-top-rhs .owl-carousel .owl-next:hover {
  opacity: 0.8;
}
.detail-top-rhs .owl-carousel .owl-prev {
  left: 16px;
  background: url(../images/slider-arrow.svg) no-repeat;
}
.detail-top-rhs .owl-carousel .owl-next {
  right: 16px;
  background: url(../images/slider-arrow1.svg) no-repeat;
}
.detail-bottom-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.detail-bottom-rhs {
  width: 338px;
  position: sticky;
  top: 85px;
}

.detail-bottom-lhs {
  width: calc(100% - 368px);
}
.detail-bottom-package > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.detail-bottom-package > ul > li {
  border-radius: 20px;
  border: 1px solid #4258bf;
  position: relative;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 26px 0;
}
.detail-bottom-package > ul > li:last-child {
  margin-bottom: 0;
}

.include-box {
  max-width: 500px;
  border-radius: 20px;
  border: 1px solid #4258bf;
  background-color: #eef3ff;
  padding: 25px 35px 25px 35px;
  margin: 0 0 25px 0;
}

.include-box ul {
  padding: 0;
  list-style: none;
}

.include-box ul li {
  margin: 0 0 8px 0;
  position: relative;
  padding-left: 20px;
}

.include-box ul li:last-child {
  margin-bottom: 0;
}

.include-box ul li:before {
  width: 8px;
  height: 8px;
  content: "";
  background: #4258bf;
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50%;
}
.include-box h5 {
  margin-bottom: 15px;
}
.detail-bottom-package-title .bold-with-blue {
  margin-bottom: 0;
}
.detail-bottom-package-title {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.detail-bottom-package-title .form-payment-discount {
  margin-top: 0;
}

.package-lhs {
  width: 100%;
}
.package-description-inner {
  max-height: 100px;
  overflow: hidden;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  margin-bottom: 20px;
}
.package_description.active .package-description-inner {
  max-height: 100%;
}
.loadmore {
  margin: 5px 0 0;
  display: block;
  font-weight: normal;
  color: #4258bf;
  cursor: pointer;
}
.package-rhs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.package-rhs-top span,
.package-rhs-top strong {
  display: block;
}
.package-rhs .button {
  width: 47.5%;
  padding: 12px 5px;
  margin-bottom: 0;
  font-size: 13px;
}
.package-rhs .packageinfo {
  margin-top: 10px;
  border: 1px solid #4258bf;
  background-color: #ffffff;
  color: #4258bf;
  width: 100%;
}
.package-rhs strong {
  font-weight: 600;
  font-size: 20px;
  color: #120c0f;
  letter-spacing: 0;
  margin: 3px 0 10px 0;
}
.package-rhs span {
  display: block;
  padding-bottom: 5px;
}
.package-rhs span em {
  color: #4258bf;
  font-style: normal;
}
.package-rhs-inner i {
  color: #db4437;
  margin-right: 5px;
  font-size: 16px;
}
.package-rhs-inner p {
  font-size: 12px;
  margin: 0;
  padding-top: 15px;
}
.package-rhs-inner-btn {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.package-lhs h6 {
  color: #444444;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
}
.package-lhs h6 {
  color: #444444;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin-bottom: 15px;
}
.package-lhs p {
  line-height: normal;
}
.package-lhs p:last-of-type {
  margin-bottom: 0;
}
.bold-with-blue {
  color: #444444;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: normal;
}
.bold-with-blue span {
  color: #4258bf;
}
.rhs-white-box,
.package-starts {
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px;
  margin: 0 0 25px 0;
}
.detail-bottom-lhs .package-starts {
  display: none;
}
.detail-bottom-rhs-promo {
  margin-bottom: 30px;
  text-align: center;
}
.qty-bx {
  width: 110px;
  position: relative;
  padding: 0 25px;
  height: 25px;
}
.qty-bx span {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 2px solid #4258bf;
  background: #fff;
  top: 0;
  color: #4258bf;
  text-align: center;
  cursor: pointer;
  transition: 0.3s linear all;
}
.qty-bx .qty-input {
  height: 25px;
  border: 0;
  padding: 0;
  margin: 0;
  text-align: center;
}
.qty-bx .qty-input {
  height: 25px;
  border: 0;
  padding: 0;
  margin: 0 !important;
  text-align: center;
  border-radius: 0;
  color: #120c0f;
  letter-spacing: 0;
}
.qty-minus {
  left: 0;
}
.qty-plus {
  right: 0;
}

.step-banner-stay ul li.disable,
.step-banner-whom ul li.disable {
  opacity: 0.5;
  cursor: not-allowed;
}
.qty-bx span i {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 5px;
  font-size: 14px;
}
.qty-bx span:hover {
  background: #4258bf;
  color: #fff;
}
.detail-bottom-rhs-select ul {
  padding: 0;
  list-style: none;
}
.rhs-white-box h5,
.prb-top h5 {
  font-size: 18px;
  color: #4258bf;
  letter-spacing: 0;
  font-weight: 500;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #4258bf;
  padding-bottom: 10px;
}
.detail-bottom-rhs-price ul,
.prb-btm ul,
.pack-info-box ul {
  padding: 0;
  list-style: none;
}

.detail-bottom-rhs-price ul li,
.prb-btm ul li,
.pack-info-box ul li {
  padding: 0 10px 0 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 7px 0;
}
.pack-info-box ul li {
  padding: 0;
  color: #767676;
  font-size: 14px;
}
.pack-info-box ul li span {
  width: 49%;
}
.pack-info-box ul li span:last-child {
  text-align: right;
}
.detail-bottom-rhs-price ul li .service-content,
.prb-btm ul li .service-content {
  width: calc(100% - 130px);
}
.detail-bottom-rhs-price ul li .service-content strong,
.prb-btm ul li .service-content strong,
.service-amount strong {
  font-weight: normal;
}
.pack-info-box ul li img {
  max-width: 14px;
  margin: 0 8px 0 0;
}

.detail-bottom-rhs-price ul li .service-amount {
  width: 130px;
  text-align: right;
}
.prb-btm ul li .service-amount {
  width: 130px;
}
.prb-btm ul li .service-amount {
  text-align: right;
}
.detail-bottom-rhs-price > ul > li:last-child,
.prb-btm ul li:last-child,
.prb-btm .subtotal {
  border-top: 1px solid #4258bf;
  padding-top: 15px;
}
/* .detail-bottom-rhs-price > ul > li:last-child {
  font-weight: bold;
  font-size: 17px;
} */
/* .detail-bottom-rhs-price > ul > li:last-child .service-amount {
  color: #4258bf;
} */
.qty-plus {
  right: 0;
}
.addcart_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;
}
.booknow-btm {
  text-align: center;
  margin: 40px 0 0 0;
}
.booknow-btm .button {
  margin: 0 auto;
  width: 100%;
  max-width: 70%;
}
.policy-details {
  padding: 40px 0 0 0;
  letter-spacing: 0;
}
.policy-details p:last-of-type {
  margin-bottom: 0;
}
.policy-details strong {
  display: block;
  font-weight: 600;
}
.detail-reviews {
  padding: 40px 0 0 0;
  letter-spacing: 0;
}
.detail-reviews ul {
  padding: 0;
  list-style: none;
}

.detail-reviews ul li {
  padding-left: 70px;
  position: relative;
  margin: 0 0 25px 0;
  padding-top: 15px;
}
.reviewer-av {
  position: absolute;
  left: 0;
  top: 0;
  width: 51px;
  height: 51px;
  overflow: hidden;
  border-radius: 50%;
}
.reviewe-date {
  padding: 0 0 20px 0;
}
.reviewe-date span {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-right: 8px;
}
.reviewe-date data {
  color: #a7a7a7;
  font-size: 14px;
}
.reviewe-rate span {
  border-radius: 9px;
  background: rgb(189 208 255 / 40%);
  color: #4258bf;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  line-height: 1;
  margin: 0 15px 0 0;
}
.reviewe-rate {
  display: flex;
  align-items: center;
}
.reviewe-rate small {
  color: #a7a7a7;
  font-size: 16px;
  margin-left: 10px;
}
.reviewe-desc {
  margin: 15px 0 0 0;
  line-height: 1.5;
}
.show-more {
  border-top: 1px solid rgba(66, 88, 191, 0.64);
  padding: 27px 0 0 0;
  margin: 35px 0 0 0;
  letter-spacing: 0.32px;
  font-weight: 500;
}
.share-options {
  display: flex;
  justify-content: space-between;
}
.common-popup {
  max-width: 450px;
  margin: 0 auto;
  background: #fff;
  padding: 50px 50px 30px 50px;
  width: 100%;
  border-radius: 20px;
  position: relative;
}
.flight-popup {
  /* text-align: center; */
}
.flight-popup div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.common-popup.pacakge_popup {
  max-width: 1000px;
  margin: 20px auto;
}
.cp-header h3,
.cancel-header h3 {
  color: #342f41;
  font-size: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.cancel-header p {
  margin: 0;
}
.cancel-header {
  padding-bottom: 35px;
}
#ticketcanellconfirm-popup .control-label {
  font-size: 16px;
  text-align: left;
  padding-left: 14px;
}
#date-popup .cp-header {
  position: relative;
  padding-right: 20px;
}
#date-popup .cp-header h3 {
  font-size: 24px;
  letter-spacing: 1.25px;
}
.cp-header p {
  font-size: 14px;
  letter-spacing: 0.7px;
  margin: 0;
}
.cp-header {
  margin: 0 0 30px 0;
}
.cp-social {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
}

.cp-social .google-btn,
.cp-social .kep-login-facebook.metro {
  color: #342f41 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.7px !important;
  border-radius: 20px !important;
  border: 2px solid #4258bf !important;
  padding: 5px 13px !important;
  display: flex !important;
  line-height: 1 !important;
  align-items: center !important;
  height: 50px !important;
  transition: 0.3s linear all;
  font-family: "Poppins", sans-serif !important;
  opacity: 1 !important;
  width: 100% !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  margin: 0 !important;
  justify-content: flex-start;
  text-align: left;
}
/* .cp-social .google-btn,
.cp-social .fb-btn {
  width: 49% !important;
} */
.cp-social .google-btn {
  width: 100% !important;
}
.cp-social .google-btn svg {
  padding: 0 !important ;
}
.cp-social .kep-login-facebook.metro {
  background: #fff !important;
}
.cp-social .google-btn img,
.cp-social .google-btn svg {
  width: 25px !important;
}
.cp-social .google-btn:hover,
.cp-social .kep-login-facebook.metro:hover {
  background: #b3c0ff !important;
  color: #000 !important;
}
/* .cp-social .google-btn > div, .kep-login-facebook.metro i{ display: none;} */
.cp-social .kep-login-facebook.metro {
  padding-left: 55px !important;
}
.cp-social .google-btn:before {
  width: 25px;
  height: 25px;
  background: url(../images/google-logo.svg) no-repeat;
  content: "";
  position: absolute;
  left: 23%;
  top: 50%;
  transform: translateY(-50%);
}
.cp-social .kep-login-facebook.metro:before {
  width: 28px;
  height: 29px;
  background: url(../images/facebook.svg) no-repeat;
  content: "";
  position: absolute;
  left: 13px;
  top: 50%;
  background-size: contain;
  transform: translateY(-50%);
}
.kep-login-facebook.metro i {
  display: none;
}
.google-btn {
  position: relative;
}
.google-btn #signInDiv,
.google-btn #signInDiv1,
.google-btn #signInDiv2,
.google-btn #signInDiv3 {
  width: 100% !important;
  opacity: 0 !important;
  z-index: 2;
}
.google-btn span {
  position: absolute;
  left: 33%;
  z-index: 1;
}
.or-break {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.or-break::before,
.or-break::after {
  content: "";
  flex: 1;
  border-top: 1px solid #dadce0; /* Horizontal line */
}
.or-break span {
  color: #ccc;
  background-color: white; /* Make the background of the text white to show border clearly */
  padding: 0 5px; /* Add padding around the text */
}

.form-group {
  margin-bottom: 10px;
  position: relative;
}

.form-group-input {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.otp-input {
  /* width: 10% !important; */
  /* margin: auto !important; */
  font-size: 1rem !important;
  padding: 10px !important;
  text-align: center;
  caret-color: transparent;
  background-color: #f7f9ff !important;
}
.otp-input:focus {
  outline: none;
  animation: blink 1s infinite;
}
@keyframes blink {
  0% {
    border: 2px solid transparent;
  }
  50% {
    border: 2px solid #4258bf;
  }
  100% {
    border: 2px solid transparent;
  }
}
.forgot-link {
  padding: 5px 0 0 0;
}

.forgot-link a {
  color: #444444;
  font-size: 14px;
}
.forgot-link a:hover {
  color: #4258bf;
}

.form-button {
  padding: 30px 0 25px 0;
  text-align: center;
}
.form-button .button {
  margin: 0;
  min-width: 120px;
}
.form-login-btn {
  padding: 30px 0 20px 0;
}
.form-guest {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.form-or-text {
  padding: 0 10px;
}
.form-guest-btn button {
  color: #444444bf !important;
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-transform: none;
  padding-top: 5px;
}
.form-guest-btn button:hover {
  color: #4258bf !important;
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
}

.cp-form-footer p {
  margin: 0;
  font-size: 14px;
  text-align: center;
}
.link-disabled {
  color: #342f41;
}
.cp-form-footer p span {
  margin-left: 0.5rem;
  color: red;
}
.mfp-close {
  width: 18px;
  height: 18px;
  margin: 0;
  line-height: 1;
  font-size: 0;
  opacity: 1;
  right: 40px;
  top: 25px;
}
.mfp-close:after {
  position: absolute;
  content: "";
  background: url(../images/close-icon.svg) no-repeat;
  width: 18px;
  height: 18px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: contain;
}
.mfp-close:active {
  top: 25px;
}
.pwd-group > span {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.pwd-group input {
  padding-right: 45px;
  margin-bottom: 0px;
}
.pwd-group,
.change-group {
  position: relative;
}
.control-label {
  display: block;
  padding: 0 0 8px 6px;
  line-height: 1.2;
  font-size: 14px;
}
.help-text {
  padding: 15px 0 0 0;
  font-size: 14px;
  letter-spacing: 0.7px;
}
#signup-popup .cp-form-footer p {
  font-size: 12px;
  line-height: 1.6;
}
.change-group input {
  padding-right: 80px;
}
.change-txt {
  text-decoration: underline;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.payment-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.payment-lhs {
  width: calc(100% - 383px);
}
.payment-rhs {
  width: 338px;
  position: sticky;
  top: 100px;
}
.form-one,
.form-two-inner,
.form-three-inner,
.form-payment-inner {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 35px 30px 30px 30px;
}
.form-one {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 45px 0;
}
.form-two-inner,
.form-three-inner {
  margin: 0 0 50px 0;
}
.form-one .form-group {
  width: 48%;
  margin-bottom: 32px;
}
.form-two h4,
.form-three h4,
.form-payment h4 {
  font-size: 25px;
  letter-spacing: 0;
  font-weight: 500;
}
.form-two h4 span,
.form-three h4 span,
.form-payment h4 span {
  color: #4258bf;
}
.form-group-content {
  font-size: 13px;
}
.fg-textarea textarea {
  border-radius: 10px;
  border: 1px solid #cccccc;
  margin-bottom: 0;
  height: 170px;
}

.fg-textarea .control-label {
  margin: 0 0 25px 0;
  line-height: 1.5;
  letter-spacing: 0;
  padding: 0;
}
.form-three-inner {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.form-three-lhs {
  width: 48.5%;
  position: relative;
}
.form-group-apply .button {
  margin: 0;
  width: 100%;
  height: 34px;
  background: #4258bf;
  color: #fff;
  border-color: #4258bf;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
  padding: 5px;
  border-radius: 4px;
  font-size: 13px;
}
.form-group-apply {
  width: 85px;
  position: absolute;
  top: 32px;
  right: 5px;
}
.form-three-inner .form-group {
  width: 100%;
}
.form-three-inner .form-group input,
.form-one .form-group input,
.form-payment-fullinner input {
  border-color: #cccccc;
}
.payment-header {
  margin: 0 0 30px 0;
}
.payment-header h2 {
  color: #342f41;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: 1.6px;
  margin: 0;
}
.payment-header h2 span {
  color: #4258bf;
}
.payment-rhs-box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 25px 25px;
}
.prb-top {
  padding-bottom: 30px;
}
.prb-top strong {
  display: block;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.prb-top p {
  margin-bottom: 0;
}
.form-payment-inner {
  margin-bottom: 40px;
}
.form-payment-inner > ul {
  padding: 0;
  list-style: none;
}
.form-payment-inner > ul > li {
  margin: 0 0 40px 0;
  position: relative;
}
.form-three-lhs .control-label {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.64px;
}
.form-three-lhs .control-label span {
  color: #28ca28;
  letter-spacing: normal;
}

/****** Profile ********/

.admin-profile {
  padding: 10px 0 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.admin-profile-lhs {
  width: 250px;
}

.profile-menu {
  list-style: none;
  padding: 20px 20px 20px 30px;
  border-radius: 20px;
  background-color: #eef3ff;
}

.profile-menu li {
  position: relative;
}

.profile-menu li > a {
  padding: 15px 0px 15px 0px;
  color: #333333;
  display: block;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
}

.profile-menu li a img {
  margin-right: 15px;
}

.profile-menu li:hover a,
.profile-menu li.active a {
  color: #4258bf;
}
.profile-menu li:hover a img,
.profile-menu li.active a img {
  filter: invert(37%) sepia(12%) saturate(5399%) hue-rotate(200deg)
    brightness(90%) contrast(90%);
}
.profile-menu li:hover a img,
.profile-menu li.active a img {
  filter: invert(37%) sepia(12%) saturate(5399%) hue-rotate(200deg)
    brightness(90%) contrast(90%);
}

/******
.profile-menu li:hover a:after, .profile-menu li.active a:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: url(../images/arrow.svg) no-repeat center;
    border: 0;
    width: 13px;
    height: 16px;
}
******/

.admin-profile-rhs {
  width: calc(100% - 340px);
  padding: 0 0px 30px 0px;
}

.admin-profile-rhs h5,
.wishlisth-header h5 {
  margin: 25px 0 35px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

.admin-profile-rhs h5 span,
.wishlisth-header h5 span {
  color: #4258bf;
}

.profile-detail-innerdiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-detail-left {
  width: 250px;
}

.profile-imgdiv {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
}

.profile-edtbtn {
  margin: 70px 0 0;
}

.profile-edtbtn button {
  background: #fff;
  border: 1px solid #4258bf;
  box-shadow: none;
  color: #4258bf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.profile-edtbtn button img {
  margin-right: 10px;
  width: 19px;
}

.profile-detail-right {
  border-bottom: 1px solid #e5e5e5;
  width: calc(100% - 250px);
  height: 100%;
  padding-bottom: 20px;
}

.profile-detail-right ul {
  list-style: none;
  padding: 0;
}

.profile-detail-right li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.profile-detail-right li:first-child {
  padding-top: 10px;
}

.profile-Infield {
  color: rgba(33, 33, 33, 0.7);
  font-weight: 400;
  letter-spacing: -0.32px;
  width: 200px;
}

.profile-Outfield {
  color: #000;
  width: calc(100% - 200px);
}

.profile-Outfield a {
  color: #333333;
}

.profile-Outfield a:hover {
  color: #4258bf;
}

.profile-detail-footer {
  padding: 50px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.profile-detail-footer p {
  margin: 0;
  color: #333333;
  width: calc(100% - 180px);
  font-size: 14px;
}

.profile-detail-footer p a {
  color: #4258bf;
}

.profile-detail-footer button {
  width: 180px;
  background: #fff3f2;
  border-radius: 10px;
  border: 1px solid #fff3f2;
  box-shadow: none;
  color: #db4437;
  letter-spacing: normal;
  margin: 0;
}
.profile-detail-footer button:hover {
  background: #db4437;
  color: #fff;
  border-color: #db4437;
}

.wishlish-buttondiv {
  text-align: center;
}

.wishlish-buttondiv button {
  border: 1px solid #4258bf;
  background: #fff;
  color: #333333;
  box-shadow: none;
  letter-spacing: normal;
}

.wishlisth-rsh,
.mybooking-rhs {
  box-shadow: 0 20px 40px rgb(0 0 0 / 8%);
  padding: 20px 25px;
  border-radius: 15px;
}

.mybooking-inner-main {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mybooking-rhs ul {
  list-style: none;
  padding: 0;
}

.mybooking-inner-left h5 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0 0 20px;
}

.mybooking-inner-left li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.mybooking-inner-left .package-lhs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.packagelist-lhs span {
  font-size: 14px;
}

.packagelist-lhs strong {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  margin: 3px 0 0px 0;
}

.packagelist-lhs {
  display: flex;
  flex-flow: column;
  margin-right: 30px;
  justify-content: center;
}

.mybooking-inner-left {
  border-right: 1px solid #e5e5e5;
  width: calc(100% - 200px);
}

.mybooking-inner-righ {
  width: 180px;
}

.mybooking-btn-div .button {
  width: 100%;
  padding: 14px 20px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mybooking-btn-div .cancel-btn {
  background: #fff;
  color: #db4437;
}

.mybooking-btn-div .cancel-btn:hover {
  background: #bdd0ff;
  color: #000;
}

.mybooking-inner-righ.mycalcel-right {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.mycalcel-btn-div a {
  color: #333333;
}

.bottom-pagenation ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.bottom-pagenation ul li {
  margin: 0 12px;
}
.bottom-pagenation ul li a {
  color: #172026;
  font-size: 16px;
}
.bottom-pagenation ul li a:hover,
.bottom-pagenation ul li.active a {
  color: #4258bf;
}
.bottom-pagenation ul li a i {
  font-size: 22px;
}
.bottom-pagenation ul li.disabled a {
  opacity: 0.5;
  cursor: not-allowed;
}
.bottom-pagenation {
  padding: 30px 0 0 0;
}
.mobile-filter {
  text-align: center;
  padding: 20px 0 0 0;
}

.filter-group {
  width: 230px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: #4258bf;
}

.filter-group a {
  width: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: relative;
  line-height: 1;
}
.filter-group a.sorting {
  padding: 12px 5px 12px 10px;
}
.filter-group a.fitering {
  padding: 12px 10px 12px 5px;
}
.filter-group a.fitering img {
  width: 17px;
  margin: 0 8px 0 0;
}
.filter-group a.sorting img {
  width: 16px;
  margin-right: 8px;
}
.filter-group a.sorting:after {
  content: "";
  position: absolute;
  right: -1px;
  width: 2px;
  background-color: #ffffff;
  top: 5px;
  bottom: 5px;
  margin: auto;
}
.mobile-filter {
  display: none;
}
.filter-option h5 {
  color: #120c0f;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1.04px;
  display: flex;
  align-items: center;
  position: relative;
}
.filter-option h5 img {
  margin-right: 10px;
  max-width: 20px;
}
.filter-option h5:after {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: rgba(189, 208, 255, 0.5);
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  cursor: pointer;
}
.filter-option h5:before {
  position: absolute;
  right: 4px;
  top: -1px;
  cursor: pointer;
  content: "\f107";
  font-family: FontAwesome;
  color: #000;
  transition: 0.3s linear all;
}
.filter-option h5.accordion-header.active:before {
  transform: rotate(180deg);
}

.filter-option ul {
  list-style: none;
  padding: 0;
}
.star-rating i {
  color: #f7a928;
  margin: 0 5px 0 0;
  font-size: 17px;
}

div.error {
  background: #ffbaba;
  color: #d8000c;
  padding: 4px 10px;
  font-size: 11px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  z-index: 4;
  line-height: 1.1;
  border-radius: 4px;
  margin-top: 1px;
}
li.error {
  color: #d8000c;
  padding: 4px 0px;
  font-size: 11px;
  font-weight: 400;
  z-index: 4;
  line-height: 1.1;
}
.pwd-group ul {
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.pwd-group ul li {
  position: initial !important;
}
.pwd-group-mb {
  margin-bottom: 0px;
}
.cp-form .submit-btn-main {
  text-align: center;
}
div.error:before {
  content: "";
  border-bottom: 5px solid #ffbaba;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 0;
}
.form-group .error {
  position: absolute;
  left: 5px;
  bottom: -20px;
}
.form-group .error p {
  margin-bottom: 0;
}
#reset-password-popup .form-group .error {
  position: static;
}

.filter-option .ghost-button {
  color: #4258bf;
  font-size: 13px;
  margin: 10px 0 0 0;
  border-radius: 10px;
  padding: 8px 18px;
  box-shadow: none;
  font-weight: 500;
}
.filter-option .ghost-button:hover {
  color: #fff;
}
.pricerange-option p {
  font-size: 14px;
}
.filter-option {
  margin-bottom: 30px;
}
.pricerange-option h5 {
  margin-bottom: 15px;
}
.pricerange-option .button {
  margin-top: 30px;
}
.rating-option ul,
.cat-option ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rating-option ul li,
.cat-option ul li {
  display: inline-block;
  margin: 0 9px 10px 0;
  vertical-align: top;
}
.cat-option ul li {
  margin-bottom: 10px;
}

.rating-option ul li a,
.cat-option ul li a {
  border-radius: 10px;
  border: 1px solid #4258bf;
  background: #ffffff;
  color: rgb(18 12 15 / 70%);
  font-size: 12px;
  letter-spacing: 0.24px;
  display: block;
  padding: 8px 8px;
}
.cat-option ul li a {
  padding: 7px 20px;
}
.rating-option ul li.active a,
.rating-option ul li a:hover,
.cat-option ul li.active a,
.cat-option ul li a:hover {
  background: #4258bf;
  color: #ffffff;
}

/* Skin details */

.irs {
  height: 55px;
}
.irs-with-grid {
  height: 60px;
}
.irs-line {
  height: 6px;
  top: 10px;
}

.irs-bar {
  height: 5px;
  top: 10px;
  background: #4258bf;
}
.irs-bar-edge {
  top: 25px;
  height: 12px;
  width: 9px;
  background-position: 0 -90px;
}

.irs-shadow {
  height: 3px;
  top: 34px;
  background: #000;
  opacity: 0.25;
}
.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25);
}

.irs-slider {
  width: 21px;
  height: 21px;
  border: 3px solid #4258bf;
  background-color: #ffffff;
  border-radius: 50%;
  top: 2px;
}
.irs-slider.state_hover,
.irs-slider:hover {
  background-color: #bdd0ff;
}

.irs-min,
.irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from,
.irs-to {
  line-height: 1;
  padding: 6px 7px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: rgb(18 12 15 / 70%);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.24px;
}
.irs-from:after,
.irs-to:after,
.irs-single:after {
  position: absolute;
  display: block;
  content: "";
  top: 6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #fff;
  display: none;
}

.irs-grid-pol {
  background: #e1e4e9;
}
.irs-grid-text {
  color: #999;
}

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}
.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  display: block;
  top: 0;
  height: 5px;
  border-radius: 5px;
  border: 1px solid #4258bf;
  background-color: #ffffff;
}
.irs-line-left {
  left: 0;
  width: 11%;
}
.irs-line-mid {
  left: 9%;
  width: 82%;
  border-right: 0;
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.irs-line-right {
  right: 0;
  width: 11%;
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}
.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
}

.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}
.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-from,
.irs-to {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}
.irs-with-grid .irs-grid {
  display: block;
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}
.irs-grid-pol.small {
  height: 4px;
}
.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}
.irs-disabled {
  opacity: 0.4;
}
.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40);
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}
.irs-min,
.irs-max {
  display: none !important;
}
.like {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 18px;
  z-index: 1;
}
/*body .like img:last-child {
  display: none;
}
body .like.active img:last-child {
  display: block;
}
body .like.active img:first-child {
  display: none;
}
*/
.white-popup.alert_popup {
  padding: 0;
}
.alert_header {
  font-size: 20px;
  padding: 12px 14px;
  position: relative;
  text-align: center;
  font-weight: 600;
}
.alert_body {
  text-align: center;
  padding: 20px;
}
.alert_body p {
  margin: 0;
}
.alert_popup .mfp-close {
  display: none;
}
.page404-main-div {
  text-align: center;
  padding: 30px;
}

.profile-imgdiv span {
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}
.custom-file-input {
  color: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #4258bf;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "";
  background: url(../images/white-pen.svg);
  width: 16px;
  height: 16px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.custom-file-input:active {
  outline: 0;
}
.pt-img {
  display: block;
  position: relative;
  height: 0;
  padding-bottom: 60.2%;
  border-radius: 20px;
  overflow: hidden;
}
.main-content .pt-img,
.ml-view .pt-img {
  position: initial;
}
.pt-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 250ms linear;
}

body .react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 0px;
  padding-top: 12px;
}
body .react-datepicker__month-container {
  width: 100%;
}

.mobile-menu {
  position: fixed;
  padding: 0;
  overflow: auto;
  background-color: #fff;
  height: 100vh;
  right: 0;
  top: 0;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transition: opacity 0.2s linear, transform 0.3s ease-in-out;
  -webkit-transition: opacity 0.2s linear, transform 0.3s ease-in-out;
  width: 100vw;
  z-index: 99999;
  max-width: 420px;
  padding: 30px;
  font-size: 15px;
  letter-spacing: 0.7px;
}
.mobile-menu.active {
  transform: translateX(0);
}
.mobile-menu-body {
  padding: 40px 0;
}
.mobile-menu-body ul {
  padding: 0;
  list-style: none;
}
.mobile-menu-body > ul > li {
  margin: 0 0 20px 0;
  position: relative;
}
.mobile-menu-body > ul > li:last-child {
  margin-bottom: 0;
}
.mobile-menu-body a {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  color: #342f41;
  font-weight: 500;
}
.mobile-menu-body a:hover {
  color: #4258bf;
}
.mobile-menu-body ul ul {
  padding: 5px 0 0 20px;
  display: none;
}
.mobile-menu-body ul li.active > ul {
  display: block;
}
.mobile-menu-body > ul > li.active span {
  transform: rotate(180deg);
}
.mobile-menu-body ul ul li {
  margin: 10px 0 5px;
}
.mobile-menu-body ul ul li a {
  font-size: 14px;
}
.mobile-menu-logo img {
  width: 120px;
}
.mobile-footer {
  text-align: center;
}
.mobile-footer .button {
  padding: 12px 28px;
}
.mobile-footer p {
  margin: 0;
  padding: 5px 0 0 0;
}
.mobile-menu-header {
  position: relative;
}
.mobile-menu-close {
  width: 30px;
  height: 30px;
  padding: 7.5px;
  position: absolute;
  top: -2px;
  right: 0;
  z-index: 2;
  cursor: pointer;
}
.mobile-menu-close:hover {
  opacity: 0.5;
}
.mobile-menu-body > ul > li span {
  position: relative;
  width: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  height: 15px;
  transition: 0.3s linear all;
}

.popup-filter.active {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.popup-filter {
  position: fixed;
  top: 50px;
  left: 0;
  background: #fff;
  z-index: 991;
  padding: 20px;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  right: 0;
  border-radius: 20px 20px 0 0;
  overflow-y: auto;
  bottom: 0;
  box-shadow: 0 -10px 55px rgba(0, 0, 0, 0.16);
}
.open-filter {
  overflow: hidden !important;
}
.open-filter:after {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(18, 12, 15, 0.3);
  z-index: 99;
}
.filter-option-reset .button {
  margin: 0;
  text-align: center;
  width: 48%;
  padding: 12px 10px;
  border-radius: 5px;
}
.filter-option-reset {
  display: flex;
  justify-content: space-between;
}
.filter-option-reset .button:first-child {
  background-color: #8394a3;
  border-color: #8394a3;
}
body .react-datepicker__current-month {
  padding-left: 40px;
  padding-right: 40px;
}
body .react-datepicker__navigation--previous {
  left: 0;
  background: url(../images/arrow-blue-left.svg) no-repeat;
}
body .react-datepicker__navigation--next {
  right: 0;
  background: url(../images/arrow-blue-right.svg) no-repeat;
}
body .react-datepicker__navigation {
  box-shadow: none;
  height: 20px;
  width: 20px;
  border: 0;
  top: 10px;
  margin: 0 4px;
  border-radius: 0;
  background-size: contain;
}
body .react-datepicker__day-name,
body .react-datepicker__day,
body .react-datepicker__time-name {
  margin-top: 2px;
  margin-bottom: 2px;
}
body .react-datepicker__day--keyboard-selected,
body .react-datepicker__month-text--keyboard-selected,
body .react-datepicker__quarter-text--keyboard-selected {
  background-color: #4258bf !important;
}

body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle,
body
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #ffffff;
}

a.date-close {
  position: absolute;
  right: 0px;
  width: 18px;
  top: 5px;
}
.mobilepick,
.formone-header {
  display: none;
}
.pricing-details {
}
.res-header {
  color: #120c0f;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.04px;
  margin: 0 0 20px 0;
  display: none;
}
.menu > li > a .search-glass {
  padding-left: 32px;
}

.search-glass img {
  position: absolute;
  left: 0;
  top: -5px;
}

.owl-carousel .owl-stage {
  display: flex;
  flex-wrap: wrap;
}
.owl-carousel .owl-stage .owl-item > div {
  height: 100%;
}
.pwd-group > span i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
}
.pwd-group > span i:first-child {
  display: block;
}
.pwd-group > span i:last-child {
  display: none;
}

.pwd-group > span.active i:first-child {
  display: none;
}
.pwd-group > span.active i:last-child {
  display: block;
}

#dvLoading {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  background: #fbfbfbe8;
  z-index: 99999;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  --path: #000000;
  --dot: #4258bf;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}
.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  -webkit-animation: dotRect var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}
.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}
.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  -webkit-animation: pathTriangle var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}
.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  -webkit-animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}
.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  -webkit-animation: pathCircle var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}
.loader.triangle {
  width: 48px;
}
.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  -webkit-animation: dotTriangle var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

@-webkit-keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}
@-webkit-keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }
  66% {
    transform: translate(10px, -18px);
  }
  100% {
    transform: translate(-10px, -18px);
  }
}
@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }
  66% {
    transform: translate(10px, -18px);
  }
  100% {
    transform: translate(-10px, -18px);
  }
}
@-webkit-keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }
  50% {
    stroke-dashoffset: 128;
  }
  75% {
    stroke-dashoffset: 192;
  }
  100% {
    stroke-dashoffset: 256;
  }
}
@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }
  50% {
    stroke-dashoffset: 128;
  }
  75% {
    stroke-dashoffset: 192;
  }
  100% {
    stroke-dashoffset: 256;
  }
}
@-webkit-keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(18px, -18px);
  }
  75% {
    transform: translate(0, -36px);
  }
  100% {
    transform: translate(-18px, -18px);
  }
}
@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(18px, -18px);
  }
  75% {
    transform: translate(0, -36px);
  }
  100% {
    transform: translate(-18px, -18px);
  }
}
@-webkit-keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }
  50% {
    stroke-dashoffset: 175;
  }
  75% {
    stroke-dashoffset: 225;
  }
  100% {
    stroke-dashoffset: 275;
  }
}
@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }
  50% {
    stroke-dashoffset: 175;
  }
  75% {
    stroke-dashoffset: 225;
  }
  100% {
    stroke-dashoffset: 275;
  }
}
.loader {
  display: inline-block;
  margin: 0 16px;
}

.aproduct-img {
  width: 90px;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 20px 0 0;
}

#ticketcanellconfirm-popup {
  text-align: center;
}

#ticketcanellconfirm-popup .booknow-btm a,
.crbox .booknow-btm a {
  padding: 13px 20px;
  width: 48%;
}

#ticketcanellconfirm-popup .booknow-btm,
.crbox .booknow-btm {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 0;
}
#ticketcanellconfirm-popup .booknow-btm a:first-child,
.crbox .booknow-btm a:first-child {
  background: #fff;
  color: #000;
}
.cancel-ul {
  padding: 0;
  list-style: none;
}
.cancel-ul li {
  margin-bottom: 20px;
}
.cancel-ul .custom_checkbox {
  display: flex;
  flex-wrap: wrap;
}
.cancel-ul .custom_checkbox .sign_reg p {
  margin-bottom: 0;
}
.cancel-ul .custom_checkbox input {
  margin-right: 10px;
}
.thankyou-header {
  text-align: center;
}

.thankyou-box {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  padding: 35px;
  border-radius: 10px;
  margin-bottom: 35px;
}

.thankyou-top {
  max-width: 724px;
  margin: 0 auto 65px auto;
}
.thankyou-box-inner {
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  padding: 20px 20px 15px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.thankyou-box-inner-rhs {
  width: 218px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.thankyou-box-inner-lhs {
  width: calc(100% - 218px);
  border-right: 1px solid #d6d6d6;
  padding-right: 10px;
}
.thankyou-box-inner-rhs figure {
  border-radius: 10px;
  overflow: hidden;
}
.thankyou-box-inner-rhs figure img {
  width: 100%;
}
.thankyou-box-inner-lhs ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  justify-content: space-between;
}

.thankyou-box-inner-lhs ul li {
  display: flex;
  flex-flow: column wrap;
  margin: 0 10px 15px 0;
}

.thankyou-box-inner-lhs ul li span {
  font-size: 13px;
  letter-spacing: 0;
}

.thankyou-box-inner-lhs ul li strong {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
}

.tbi-lhs-header h6 {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.8px;
  color: #000;
  margin-bottom: 5px;
}

.tbi-lhs-header {
  margin-bottom: 25px;
}
.tbi-lhs-header p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}
.thankyou-boxbtm {
  color: #1b252c;
  font-size: 14px;
  padding: 0 20px;
}
.thankyou-box h5 {
  font-size: 20px;
  font-weight: 400;
}
.thankyou-box h5 span,
.thankyou-header h3 span,
.expolre-thank > h4 span {
  color: #4258bf;
}
.thankyou-header {
  margin: 0 0 40px 0;
}
.thankyou-header h3 {
  font-size: 25px;
  margin: 0 0 5px 0;
}
.thankyou-header p {
  margin: 0;
}
.thankyou-box-btm-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 0 0;
  flex-wrap: wrap;
}
.thankyou-box-btm-lhs {
  display: flex;
  flex-flow: column wrap;
}
.thankyou-box-btm-lhs strong {
  font-size: 20px;
  font-weight: 600;
}
.thankyou-box-btm-rhs .button {
  margin-top: 0;
  margin-bottom: 0;
}
.thankyou-box-btm-rhs .button:first-child {
  margin-right: 10px;
}
.expolre-thank h4 {
  margin-bottom: 30px;
  font-size: 25px;
}

.loader-main-cls {
  position: relative;
  pointer-events: none;
}

.loader-sub-div,
.loader-product-sub-div {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
}

.loader-sub-div:after,
.loader-product-sub-div:after {
  width: 35px;
  height: 35px;
  border: 4px solid rgba(30, 29, 29, 0.49);
  border-top-color: #1e1d1d;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 0.3s linear infinite;
  animation: loader-rotate 0.3s linear infinite;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
body .css-15k3avv {
  z-index: 5;
}
#date-popup .css-10nd86i {
  margin-top: 25px;
}
.pay-conf-popup {
  max-width: 600px;
  padding: 60px 20px 20px 20px;
}
.pay-conf-popup .mfp-close {
  right: 40px;
  top: 20px;
}
.hero-content-search-parent {
  position: relative;
}
.content-search-parent {
  position: relative;
  width: 85%;
}
.content-search-parent .row {
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  height: 64px;
}
.content-search-parent .col1 {
  flex: 0.4;
  display: flex;
  padding-left: 1rem;
}
.content-search-parent .col1 input,
.content-search-parent .col1 div {
  border: none;
  margin: 0px;
  color: #5a6080;
  font-size: 16px;
  font-weight: normal;
  padding: 8px 20px;
}
.content-search-parent .col2 {
  flex: 0.1;
  display: flex;
  justify-content: flex-end;
}
.content-search-parent .divider {
  flex: 0.1;
  margin-right: 1rem;
  border-right: 0.1px solid #707070;
  height: 40px;
}
.content-search-parent .search-results {
  position: absolute;
  z-index: 9999;
  width: 442px;
  border-radius: 16px;
  left: 25px;
  top: 5rem;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16); */
  background: #fff;
}
.content-search-parent .date-popup-menu {
  position: absolute;
  z-index: 9999;
  width: 675px;
  border-radius: 16px;
  right: 0;
  top: 5rem;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16); */
  background: #fff;
}
.content-search-parent .date-popup-menu .rdrMonthAndYearWrapper {
  padding: 0 1.2rem;
  margin-top: -5px;
}
.content-search-parent
  .date-popup-menu
  .step-content-calender
  .rdrCalendarWrapper {
  padding: 10px;
}
.content-search-parent .date-popup-menu .step-content-calender .rdrMonthName {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}
.content-search-parent .date-popup-menu .step-content-calender .rdrMonth {
  padding: 0 0.833em 0em 0.833em;
}
.content-search-parent
  .date-popup-menu
  .step-content-calender
  .rdrMonth
  .rdrWeekDays {
  margin: 0px;
}
.content-search-parent .date-popup-menu .step-content-calender .rdrDay {
  height: 4em;
}
.content-search-parent .date-popup-menu .textcenter {
  overflow: hidden;
  border-radius: 16px;
}
.content-search-parent .date-popup-menu svg {
  top: -1.8rem;
  position: absolute;
  right: 8rem;
  font-size: 3rem;
  color: #fff;
}
.content-search-parent .search-results svg {
  top: -1.8rem;
  position: absolute;
  left: 0.2rem;
  font-size: 3rem;
  color: #fff;
}
.content-search-parent .search-results .search-with-padd {
  padding: 10px 0 0px 0;
}
.content-search-parent .search-results .search-with-img-result ul li a {
  justify-content: flex-start;
  align-items: center;
}
.content-search-parent .search-results .search-with-img-result ul li a img {
  width: 38px;
  height: 38px;
  margin-right: 1rem;
}
.content-search-parent
  .search-results
  .search-with-img-result
  ul
  li
  span
  strong {
  font-size: 14px;
  color: #373b4d;
}
.content-search-parent .search-results .search-with-img-result ul li span em {
  font-size: 12px;
  color: #5a6080;
  font-weight: normal;
}
.content-search-parent .col2 .exploreBtn {
  box-shadow: none;
  text-transform: initial;
  font-size: 16px;
  font-weight: normal;
  background: #4258bf;
  border-radius: 20px;
  padding: 0.7rem 1.5rem;
}
.info-list-main {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.info-list-main .row {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.info-list-main .col,
.info-list-main .col1 {
  margin-right: 1.5rem;
}
.info-list-main .col .c-row,
.info-list-main .col1 .c-row {
  display: flex;
  align-items: center;
  background: #e1f5f7;
  border-radius: 33px;
  padding: 16px 60px 16px 25px;
}
.info-list-main .col .c-row .c-col,
.info-list-main .col1 .c-row .c-col {
  padding-left: 1rem;
}
.info-list-main .col .c-row .text1,
.info-list-main .col1 .c-row .text1 {
  font-size: 12px;
  font-weight: 600;
  color: #111733;
}
.info-list-main .col .c-row .text2,
.info-list-main .col1 .c-row .text2 {
  font-size: 10px;
  font-weight: normal;
  color: #111733;
  opacity: 0.8;
}
.overflow-initial {
  overflow: initial !important;
}
.search-place {
  position: absolute;
  top: 65px;
  left: 0;
  min-width: 720px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 20px rgb(0 0 0 / 8%);
  box-shadow: 0 6px 20px rgb(0 0 0 / 8%);
  overflow: hidden;
  padding: 15px 10px 5px 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  z-index: 9;
  margin-top: 1.5rem;
}
.search-place-top {
  max-width: 700px;
  margin: 0 auto;
  right: 0;
  top: 55px;
}
.search-place.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.search-place ul {
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 320px;
}
.search-place ul li,
.suggestion-list li {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  margin: 0 8px;
  border-radius: 6px;
}
.search-place ul li {
  margin-bottom: 1rem;
}
.search-place ul li a {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  /* padding: 6px 0px; */
}
.search-place ul li:hover {
  background-color: #fafafa;
}
.search-place ul li figure,
.suggestion-list li figure {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
  overflow: hidden;
  background: #f8f7ff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-place ul li figure img {
  height: 100%;
}
.banner-form-rel {
  position: relative;
  box-shadow: 0 20px 40px rgb(0 0 0 / 16%);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px;
}
.search-place a {
  color: #4258bf;
}
.search-show {
  text-align: left;
  width: 100%;
}
.search-show p {
  margin-bottom: 0px;
}
.search-show-inner {
  display: flex;
  justify-content: space-between;
}
.search-show .search-show-product {
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
  width: calc(100% - 120px);
  margin: 0;
}
.search-show span {
  font-size: 12px;
  line-height: 1.2;
  color: #999;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 3px;
}
.search-price {
  font-size: 12px;
  width: 120px;
  text-align: right;
}
.search-price em {
  font-style: normal;
  margin: 0 5px 0 0;
  color: #999;
  display: block;
}
.search-price strong {
  color: #4258bf;
}
.search-show-product p {
  margin-bottom: 0;
}
.successtick {
  width: 100px;
}
.review-form-inner {
  padding: 40px;
  border: 1px solid #e4e4e4;
  border-radius: 15px;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}
.review-form-inner .control-label {
  margin: 0 0 5px 0;
  display: block;
  font-weight: 500;
}
.review-form-inner .button {
  margin: 0;
  width: 180px;
}
.review-form-inner .start-rating i {
  margin: 0 2px;
}
.top-search-place {
  padding: 20px;
}
.top-search-place input[type="text"] {
  box-shadow: none;
  border: 2px solid #4258bf;
}
.payment-footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.payment-footer ul li {
  width: 33.3%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.Help {
  padding-left: 60px;
  margin-top: 20px;
  display: flex;
}
.feedbackcontainer {
  width: 100%;
}
.FAQ-container {
  width: 47%;
  padding-top: 20px;
}
.embedded-wrapper .modal-header {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  padding-bottom: 10px;
  background: transparent;
  padding: 6px 0;
  border-bottom: 1px solid #999;
}
.close-promo {
  margin-left: 5px;
  color: #fff;
  border-radius: 5px;
  background: #000;
  height: 19px;
  padding: 0px 5px 3px 5px;
}
.close-promo:hover {
  color: #fff;
  background: #000;
}
.expolre-promo > ul {
  display: flex;
  padding: 20px 0 0 0;
  list-style: none;
  flex-wrap: wrap;
}

.expolre-promo > ul > li {
  width: 23.046875%;
  margin: 0 2.578125% 40px 0;
  position: relative;
  padding-bottom: 60px;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.3s linear all;
  border: 1px solid #e4e4e4;
}
.expolre-promo > ul > li:hover {
  box-shadow: 1px 0 15px rgb(0 0 0 / 15%);
}

.expolre-promo > ul > li:nth-child(4n) {
  margin-right: 0;
}
.termsection {
  padding: 30px 0 40px 0;
  background: rgb(189 208 255 / 25%);
  margin: 0 0 40px 0;
}

.termsection ul {
  padding: 0 0 0 20px;
}

.termsection ul li {
  line-height: 25px;
  margin: 0 0 2px 0;
}

.promo-banner {
  background-color: #f0f5ff;
  position: relative;
}
.dbs-promo {
  background-color: #ffeacc;
}
.promo-banner .container {
  display: flex;
  flex-wrap: wrap;
}
.promo-banner-lhs {
  width: 50%;
  position: relative;
  padding: 50px 30px 50px 0;
  z-index: 2;
}
.promo-banner-rhs {
  width: 50%;
}

.hidden-tag {
  visibility: hidden;
}
.promo-banner-rhs img {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.promo-banner-lhs h1 {
  color: #212121;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.2;
}
.promo-banner-lhs h1 span {
  color: #4258bf;
}
.promo-banner-lhs:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #f0f5ff;
  transform-origin: bottom left;
  -ms-transform: skew(-12deg, 0deg);
  -webkit-transform: skew(-12deg, 0deg);
  transform: skew(-12deg, 0deg);
}
.dbs-promo .promo-banner-lhs:after {
  background: #ffeacc;
}
.promo-banner-lhs > p {
  font-weight: 600;
  margin-bottom: 25px;
  color: #342f41;
  letter-spacing: 0.9px;
  font-size: 18px;
}
.promo-banner-lhs > p > span {
  text-transform: uppercase;
  color: #4258bf;
}
.promo-banner-lhs strong {
  color: #212121;
  font-size: 10px;
  font-weight: 400;
}
.more-coming {
  color: #444;
  letter-spacing: 0.7px;
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
}
.expolre-list h4 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 20px 0;
  letter-spacing: 1.25px;
  color: #342f41;
}

.form-payment-inner input[type="radio"] {
  border: 2px solid #4258bf;
}
.form-payment-inner input[type="radio"]:after {
  background: #4258bf;
  left: -2px;
  margin: auto;
  bottom: 0;
  top: 0;
}
.form-payment-inner input[type="radio"]:checked {
  background: #fff;
}

.form-payment-inner > ul > li {
  padding-left: 40px;
}
.form-payment-inner > ul > li input[type="radio"] {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}
.form-payment-inner > ul > li label {
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form-payment-inner > ul > li:last-child {
  margin-bottom: 0;
}

.form-payment-fullinner {
  padding-top: 30px;
}
.form-payment-inputform p {
  margin-bottom: 10px;
}
.form-payment-discount {
  margin: 15px 0 0 0;
  border-radius: 10px;
  border: 1px solid #4258bf;
  background-color: #eef3ff;
  font-size: 14px;
  min-height: 42px;
  padding: 7px 10px;
  position: relative;
  display: flex;
  align-items: center;
}
.form-payment-discount.discount-error {
  border: 1px solid #bf4242;
  background-color: #ffeeee;
}
.form-payment-discount img {
  margin-right: 12px;
  width: 22px;
}
.form-payment-fullinner input {
  max-width: 394px;
}

.form-payment-inner > ul > li label ul {
  display: flex;
  align-items: center;
  padding: 0 0 0 25px;
  list-style: none;
}

.form-payment-full {
  position: relative;
}
.form-payment-inner > ul > li label ul li {
  margin-right: 15px;
  line-height: 1;
}
.blue-promobox {
  border-radius: 20px;
  border: 1px solid #4258bf;
  background-color: #eef3ff;
  padding: 20px 16px 20px 64px;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.6px;
  line-height: 20px;
  margin: 20px 0 0 0;
}

.blue-promobox img {
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
}
.blue-promobox span {
  color: #54c054;
  text-transform: uppercase;
}

.aexplore-def {
  background-color: #eef3ff;
  display: inline-flex;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 25px 10px 30px;
  border-radius: 0 20px 0 20px;
  line-height: 1.2;
  transition: 0.3s linear all;
}

.aexplore-def p {
  margin: 0;
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s linear all;
}

.aexplore-def p strong {
  display: block;
  color: #4258bf;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0.9px;
}

.banner .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.banner .owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  background: rgb(66 88 191 / 0%);
  border-radius: 50%;
  margin: 0 4px;
  border: 2px solid #fff;
  box-shadow: 1px 0 5px rgb(0 0 0 / 20%);
}
.banner .owl-dots .owl-dot.active {
  background: #fff;
}
.newsletter-lhs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sel-quality .price-select {
  display: block;
  font-size: 15px;
}
.detail-bottom-rhs-map {
  overflow: hidden;
  border-radius: 20px;
  margin: 30px 0 0 0;
}
.package-starts h5 {
  font-weight: 500;
  margin-bottom: 10px;
}
.package-starts p {
  color: #120c0f;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: normal;
  margin: 0;
}
.package-starts p span,
.addcart_row .price-select span {
  color: #6f6f6f;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
  text-decoration: line-through;
}
.package-starts .button {
  margin-bottom: 0;
}

.det-banner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.det-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

.det-banner-lhs {
  width: 55%;
  max-height: 412px;
}

.det-banner-rhs {
  width: 45%;
  max-height: 412px;
}
.det-banner-rhs-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 50%;
}
.det-banner-rhs-top {
  height: 50%;
}
.det-banner-rhs-bottom-lhs {
  width: 50%;
  height: 100%;
}
.det-banner-rhs-bottom-rhs {
  width: 50%;
  height: 100%;
}

.three-gallery .det-banner-rhs-bottom-lhs {
  width: 100%;
}
.two-gallery .det-banner-lhs {
  width: 50%;
}
.two-gallery .det-banner-rhs {
  width: 50%;
}
.single-gallery .det-banner-lhs {
  width: 100%;
}
.det-banner-lhs,
.det-banner-rhs-bottom-lhs,
.det-banner-rhs-top,
.det-banner-rhs-bottom-rhs {
  border-radius: 0;
  overflow: hidden;
}

.coming-soon {
  position: absolute;
  background-color: #db4437;
  top: 0;
  right: 0;
  z-index: 1;
  color: #ffffff;
  padding: 11px 16px;
  font-size: 11px;
  line-height: 1;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  font-weight: 600;
}
.promo-banner.couples {
  background: #aaecaa;
}
.promo-banner.couples .promo-banner-lhs:after {
  background: #aaecaa;
}
.promo-banner.groups {
  background: #feaba4;
}
.promo-banner.groups .promo-banner-lhs:after {
  background: #feaba4;
}
.product-image-more {
  position: relative;
  overflow: hidden;
}
.product-image-more:after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.51);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 10px;
  transition: 0.3s linear all;
}
.product-image-more span {
  position: absolute;
  color: #ffffff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  z-index: 2;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.9px;
  cursor: pointer;
}
.product-image-more:hover:after {
  background: rgba(0, 0, 0, 0.71);
}
.promo-banner.singapore {
  background: #fdefcb;
}
.promo-banner.singapore .promo-banner-lhs:after {
  background: #fdefcb;
}
.singapore .promo-banner-lhs {
  padding: 70px 30px 70px 0;
}
.product-more-image {
  display: none;
}
.mfp-gallery .mfp-close {
  width: auto;
  right: 0px;
}
.mfp-gallery .mfp-close:after {
  filter: invert(1);
  -webkit-filter: invert(1);
}
.mfp-gallery .mfp-close {
  cursor: pointer !important;
}
.aexplore-img img {
  width: 100%;
}
.pp-activitie ul li:hover a {
  box-shadow: 2px 0 20px rgb(0 0 0 / 30%);
}
.funthings .owl-dots {
  display: flex;
  justify-content: center;
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 30px;
}
.funthings .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border: 1px solid #000;
  border-radius: 50%;
  margin: 0 3px;
  background: #fff;
}
.funthings .owl-dots .owl-dot.active {
  background: #4258bf;
  border-color: #4258bf;
}

.bread-crumbs {
  padding: 10px 0 0;
  background: #fff;
  margin: 0px 0 0 0;
}
.bread-crumbs ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.bread-crumbs ul li {
  letter-spacing: 0.14px;
  font-size: 14px;
  color: #4258bf;
  margin: 0 5px;
  position: relative;
  padding-right: 15px;
}
.bread-crumbs ul li a {
  color: #444444;
}
.bread-crumbs ul li::after {
  background: url(../images/bread-crumbs-arrow.svg) no-repeat;
  content: "";
  width: 7px;
  height: 14px;
  display: inline-flex;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 2px;
}
.bread-crumbs ul li a:hover {
  color: #4258bf;
}
.bread-crumbs ul li:last-child:after {
  display: none;
  padding-right: 0;
}
.custom-alrt-popupcls,
.pay-conf-popup {
  border-radius: 20px;
}
.crbox {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  max-width: 480px;
  margin: 50px auto;
  border-radius: 20px;
  padding: 30px;
}
.crbox ul {
  list-style: none;
  padding: 0;
  text-align: center;
}
.crbox ul li {
  margin: 0 0 15px;
}
.crbox ul li strong {
  display: block;
}
.crbox h3 {
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
  border-top: 1px solid #e4e4e4;
  margin-top: 20px;
}
body .mfp-bg {
  background: rgba(0, 0, 0, 0.6);
}
body img.mfp-img {
  padding: 0;
}
body .mfp-gallery .mfp-close {
  right: 20px;
  top: 20px;
  width: 22px;
  height: 22px;
}

a.popup-filter-close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
}
a.popup-filter-close:after {
  position: absolute;
  content: "";
  background: url(../images/close-icon.svg) no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.reward-main-rhs {
  width: calc(100% - 250px);
  padding: 0 0 0 6%;
}
.rtl-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reward-total-lhs {
  width: 60%;
}

.reward-total-rhs {
  width: 40%;
  padding: 0 0 0 50px;
}
.reward-total-rhs ul {
  list-style: none;
  padding: 20px 0 0 0;
}
.reward-total-rhs ul li {
  position: relative;
  margin: 0 0 25px 0;
  color: #212121;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 20px;
  padding: 0 0 0 36px;
  min-height: 25px;
}
.reward-total-rhs ul li img {
  position: absolute;
  left: 0;
  top: 0px;
}

.reward-total-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  padding: 30px 30px 35px 30px;
  z-index: 1;
}

.reward-total-card .rewardbg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  z-index: -1;
}
.reward-total-card strong {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.36px;
}
.reward-total-card h2 {
  color: #4258bf;
  font-size: 50px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: normal;
  line-height: 62px;
}

.reward-total-card p {
  color: #4258bf;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: normal;
  padding-bottom: 10px;
}

.reward-total-card span {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
}
.yellowd {
  position: absolute;
  right: 40px;
  top: 18px;
  width: 44px;
  z-index: 2;
}
.reward-total-note {
  color: #737373;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 15px;
  padding: 15px 10px 0 20px;
  width: 80%;
}
.product-mobile-gallery {
  display: none;
}
.product-mobile-gallery .owl-item > div {
  height: 280px !important;
  overflow: hidden;
  position: relative;
}
.product-mobile-gallery .owl-item > div img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.why-airvays {
  border-radius: 20px;
  border: 1px solid #4258bf;
  background-color: #f0ffee;
  margin: 30px 0 0 0;
  padding: 24px;
}
.why-airvays ul {
  padding: 0;
  list-style: none;
}
.why-airvays ul li {
  font-size: 14px;
  color: #444;
  letter-spacing: 0;
  position: relative;
  margin: 0 0 17px 0;
  padding-left: 24px;
}
.why-airvays ul li:last-child {
  margin-bottom: 0;
}
.why-airvays ul li:before {
  width: 14px;
  height: 11px;
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  background: url(../images/why-tick.svg) no-repeat;
}
.why-airvays h4 {
  font-weight: 600;
  font-size: 16px;
  color: #444444;
  margin: 0 0 20px 0;
}
.earn-green {
  border-radius: 20px;
  background-color: #d3dfff;
  color: #444444;
  font-size: 14px;
  padding: 8px 15px;
  max-width: 100%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
}
.earn-green img {
  width: 25px;
  margin: 0 13px 0 0;
}
.chekout-page .earn-green {
  max-width: 100%;
  margin: 0 auto 1px;
}
.date-warning {
  font-size: 11px;
  color: #444444;
  line-height: 15px;
  letter-spacing: 0.12px;
  margin: 30px 0 0 0;
  padding-left: 30px;
  position: relative;
}
.date-warning span {
  font-size: 14px;
}
.date-warning img {
  position: absolute;
  left: 0;
  top: 5px;
}
.detail-bottom-package li.active {
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  background: #e2e7ff;
}
.payment-method-header {
  padding-bottom: 30px;
}
.payment-method-header h4 {
  margin-bottom: 5px;
}
.payment-method-header p {
  font-size: 14px;
  letter-spacing: 0.56px;
  margin: 0;
}
.not-available .product-img,
.not-available .product-description {
  opacity: 0.8;
  filter: grayscale(1);
}
span.no-package {
  position: absolute;
  left: 0;
  top: 15px;
  background: #4258bf;
  font-size: 11px;
  line-height: 1;
  padding: 3px 5px;
  color: #ffffff;
  z-index: 2;
  text-transform: uppercase;
}
.pacakge_popup h3 span {
  color: #4258bf;
}
.pp-info-blue {
  border-radius: 20px;
  background-color: #eef3ff;
  padding: 25px 30px 20px 40px;
  margin: 0 0 20px 0;
}

.pp-info-blue ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-between;
}

.pp-info-blue ul li {
  position: relative;
  padding: 0 0 0 25px;
  margin: 0 0 8px 0;
}
.pp-info-blue ul li img {
  position: absolute;
  left: 0;
  top: 2px;
}
.pp-info-text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}
.pp-info-text h5 {
  font-weight: 600;
  letter-spacing: 0.9px;
  padding: 20px 0 0 0;
  margin: 0 0 15px 0;
}
.pp-info-text h5 span {
  color: #4258bf;
}
.pp-info-text ul {
  padding: 3px 0 0 0;
  list-style: none;
}

.pp-info-text ul li {
  margin: 0 0 15px 0;
  position: relative;
  padding: 0 0 0 14px;
}
.pp-info-text ul li:before {
  position: absolute;
  left: 0;
  top: 6px;
  content: "";
  width: 6px;
  height: 6px;
  background: #444444;
  border-radius: 50%;
}
.pp-info-text ul li a {
  color: #444444;
}
.pp-info-text ul li a:hover {
  color: #4258bf;
}
.avail-btm {
  padding: 30px 0 0 0;
  margin: 0 auto;
}

.avail-btm .button {
  margin: 0 0 10px 0;
}
.avail-btm p {
  margin: 0;
  font-size: 12px;
}
.avail-btm p img {
  margin: 0 8px 0 0;
  width: 16px;
}

.blue-nav {
  background-color: #e9efff;
  padding: 24px 0;
}

.blue-nav ul {
  padding: 0;
  list-style: none;
  display: flex;
}

.blue-nav ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #1b252c;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
}
.blue-nav ul li a:hover,
.blue-nav ul li.active a {
  color: #4258bf;
}

.blue-nav ul li {
  margin: 0 50px 0 0;
}

.blue-nav ul li a img {
  margin: 0 10px 0 0;
}
.package-table {
  box-shadow: 0 10px 40px rgba(143, 143, 143, 0.16);
  border-radius: 20px;
  padding: 25px 35px;
}
.package-table table {
  width: 100%;
  text-align: left;
  color: #1b252c;
}
.package-table table thead th {
  border-bottom: 2px solid #e0e0e0;
  padding: 10px 10px 12px 10px;
  font-weight: 500;
}

.package-table table tbody td {
  padding: 15px 10px;
  line-height: 1.1;
  vertical-align: middle;
}
.package-table table tbody td:first-child,
.package-table table thead th:first-child {
  text-align: center;
}
.package-table table tbody tr:first-child td {
  padding-top: 30px;
}
.package-trend .carousel-section {
  overflow: hidden;
}
.main-content {
  position: relative;
}
.title-with-description .prevBtn {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 0.4px solid #969eab;
  z-index: 1;
}
.title-with-description .action-btns {
  display: flex;
}
.title-with-description .nextBtn {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 0.4px solid #969eab;
  z-index: 1;
}
/* .main-content .prevBtn {
  position: absolute;
  top: 40%;
  left: -1rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: -2px 1px 10px #bdbdbd;
  z-index: 1;
  box-shadow: none;
  &:hover {
    box-shadow: -2px 1px 10px #bdbdbd;
  }
} */
/* .main-content .nextBtn {
  z-index: 1;
  position: absolute;
  top: 40%;
  right: -1rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: none;
  &:hover {
    box-shadow: -2px 1px 10px #bdbdbd;
  }
  .fa {
    color: #4258bf;
  }
} */
.main-content .nextBtn i,
.main-content .prevBtn i {
  font-size: 1.2rem;
}
.main-content .owl-theme .custom-nav {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
}
.main-content .owl-theme .custom-nav .owl-prev,
.main-content .owl-theme .custom-nav .owl-next {
  position: absolute;
  height: 100px;
  color: inherit;
  background: none;
  border: none;
  z-index: 100;
}
.main-content .owl-theme .custom-nav .owl-prev i,
.main-content .owl-theme .custom-nav .owl-next i {
  font-size: 2.5rem;
  color: #cecece;
}
.main-content .owl-theme .custom-nav .owl-prev {
  left: 0;
}
.main-content .owl-theme .custom-nav .owl-next {
  right: 0;
}
.destination-trend .owl-carousel .owl-stage,
.package-trend .owl-carousel .owl-stage,
.step-slide .owl-carousel .owl-stage {
  padding-left: 0 !important;
}
.package-trend .product-description-title h5 {
  font-weight: 700;
}
.package-trend .pdr-rhs strong {
  font-size: 22px;
  line-height: 17px;
  margin: 0 3px;
}
.package-trend .pdr-rhs {
  display: flex;
  align-items: flex-end;
}
.package-trend .pdr-rhs span {
  font-size: 10px;
}
.sites-content,
.product-description-content {
  font-size: 13px;
}
.sites-content ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}
.main-content .product-description .sites-content ul li,
.ml-view .product-description .sites-content ul li {
  background-color: transparent;
}

.product-description .sites-content ul li {
  padding: 7px 12px;
  color: #444444;
  background-color: #f2f2f2;
  border-radius: 10px;
  font-size: 12px;
  margin: 0 5px 5px 0;
}
.sites-content ul li figcaption {
  padding: 0px 0 0 20px;
  position: relative;
  text-align: left;
}
.package-ul1 .sites-content ul li figcaption:before {
  display: none;
}
.package-ul1 .sites-content ul li figcaption {
  padding: 0px 0 0 7px;
  font-size: 12px;
  color: #33425b;
  font-weight: 400;
}
.sites-content ul li figcaption:before {
  width: 13px;
  height: 10px;
  background: url(../images/why-tick.svg) no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
}
.sites-content ul li figure img {
  transform: rotate(-135deg);
}
.sites-content ul .sc1 figure img {
  width: 19px;
}
.sites-content ul .sc2 figure img {
  width: 16px;
}
.sites-content ul .sc3 figure img {
  width: 17px;
}
.sites-content ul .sc4 figure img {
  width: 17px;
}
.sites-content ul li figure {
  margin: 0px auto;
  width: 40px;
  height: 40px;
  transform: rotate(135deg);
  border-radius: 6px;
  background-color: #d4edbc;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.package-ul1 .sites-content ul li {
  flex-direction: row;
}
.sites-content ul li {
  display: flex;
  flex-flow: column wrap;
  padding: 5px 5px 5px 0;
  text-align: center;
  width: auto;
}
.sites-content {
  padding: 10px 0 0 0;
}
.main-content .sites-content {
  min-height: 9rem;
}
.star-rating {
  line-height: 1;
}
.package-trend .package-ul > li {
  width: 100%;
}
.package-trend .package-ul > li.ttd-pack-card {
  max-height: 512px;
  min-height: 512px;
}
.package-trend .color-header {
  padding-bottom: 10px;
}
.package-trend .product-list-parent {
  box-shadow: rgba(66, 88, 191, 0.17) 0px 8px 40px;
}
.main-content .product-list-parent,
.ml-view .product-list-parent {
  box-shadow: none;
  background: #fff;
}
/* .package-trend .owl-stage-outer {
  overflow: visible !important;
} */
.package-trend .owl-item {
  opacity: 0;
  transition: opacity 500ms;
}
.package-trend .owl-item.active {
  opacity: 1;
}
.color-header span {
  color: #4258bf;
}
.color-header {
  font-size: 24px;
}
.package-trend {
  padding: 25px 0 20px 0;
}
.package-tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flight-search-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fs-search-cntnr .flight-search-container .flight-search-box {
  background: #fff;
  border-radius: 20px;
  position: relative;
  padding: 24px 24px 52px;
}
.flight-search-main.flight-bg {
  background-image: url("https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/new/Flight.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.flight-search-main.step-banner-rounded {
  overflow: initial !important;
}
.flight-search-main .hero-content {
  margin-top: -3rem;
}
.flight-search-main .rdrDays button {
  box-shadow: none !important;
}
.flight-search-main .rdrDay {
  height: 4em;
  margin: 0px;
}
.flight-search-main .rdrDayHovered {
  border: none;
}
.flight-search-main .rdrDayNumber::after {
  border: none !important;
}
.flight-search-main .rdrDayEndPreview,
.flight-search-main .rdrDayHovered,
.flight-search-main .rdrDayEndPreview {
  border: none !important;
}
.flight-search-main .rdrStartEdge,
.flight-search-main .rdrInRange,
.flight-search-main .rdrEndEdge {
  border-radius: 1.042em;
  width: 89.8%;
}
.flight-search-main .rdrEndEdge + .rdrDayNumber {
  background: #f0f2ff;
}
.flight-search-main .rdrDayActive .rdrDayNumber span {
  background: rgb(66, 88, 191);
  width: 89.8%;
  border-radius: 1.042em;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.flight-search-main .rdrMonthAndYearPickers {
  display: none;
}
.flight-search-main .rdrMonthAndYearWrapper button {
  box-shadow: none;
}
.flight-search-main .rdrCalendarWrapper,
.flight-search-main .rdrMonth {
  width: 100% !important;
  padding-bottom: 0px;
}
.flight-search-main .rdrMonthName {
  text-align: center;
}
.flight-search-main .rdrDayDisabled {
  background: transparent;
}
.flight-search-main .rdrEndEdge + .rdrDayNumber span,
.flight-search-main .rdrStartEdge + .rdrDayNumber span {
  background: rgb(66, 88, 191);
  width: 92.8%;
  border-radius: 1.042em;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.flight-way-search {
  box-shadow: 0 10px 40px rgb(123 123 123 / 16%);
  border-radius: 20px;
  padding: 30px 30px 20px 30px;
  margin: 0 0 50px s0;
}
.fs-search-cntnr {
  background: #1b286c;
  position: relative;
  padding: 4rem;
}
.fs-search-cntnr .flight-search-container {
  margin-top: 0px;
  box-shadow: none;
}
.fs-search-cntnr .fs-search-bg-asst {
  position: absolute;
  top: 0;
  right: 0;
}
.fs-search-cntnr h3 {
  width: 50%;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  margin: 0;
}
.fs-search-cntnr h4 {
  width: 70%;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
  margin: 0;
}
.fs-search-cntnr .flight-search-container input {
  border: none;
}
.fs-search-cntnr .flight-search-container input[type="radio"] {
  width: 0px;
}
.fs-search-cntnr .flight-search-container .flight-search {
  padding-right: 0px;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location .fs-from {
  position: relative;
  padding: 1rem;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location .sub-text,
.fs-search-cntnr .flight-search-container .flight-search .fs-stay .sub-text {
  color: #4e5b71;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location input {
  height: auto !important;
  padding: 0.25rem 0;
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: #2f3e88;
}
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-location
  input::placeholder,
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-stay
  input::placeholder,
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-whom
  input[type="text"]::placeholder {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  color: #87909f;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location .fs-to {
  padding: 1rem;
  position: relative;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-stay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-right: 1.5rem; */
  width: 15%;
  position: relative;
  border: 1px solid transparent;
}
.fs-stay-active {
  border: 1px solid #2f3e88;
  background: rgba(244, 246, 255, 0.5);
}
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-stay
  .flight-results {
  right: 0px;
  left: auto;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-stay .input-main {
  position: relative;
  padding-right: 1.5rem;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-stay input {
  height: auto;
  padding: 0.25rem 0 !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #2f3e88;
}
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-stay
  .input-main
  img {
  position: absolute;
  right: 10px;
  top: 12px;
}
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-whom
  input[type="text"] {
  height: auto;
  padding: 0.25rem 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #2f3e88;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-whom {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 15%;
  position: relative;
}
.fs-search-cntnr
  .flight-search-container
  .flight-search
  .fs-whom
  .flight-results {
  right: 0px;
  left: auto;
  padding: 0;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-whom .p-main {
  position: relative;
  padding-right: 1.5rem;
}
.fs-search-cntnr .flight-search-container .flight-search .label {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #21314d;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-swap {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  top: 0px;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-swap img {
  z-index: 1;
  cursor: pointer;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-swap .vl {
  border-left: 0.699999988079071px solid #b2b7c1;
  height: 100%;
  position: absolute;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location-return {
  width: 55%;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location-oneway {
  width: 70%;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-stay,
.fs-search-cntnr .flight-search-container .flight-search .fs-whom {
  padding: 1rem;
}
@media (min-width: 481px) {
  .bdr-x {
    border-top: 0.699999988079071px solid #b2b7c1 !important;
    border-bottom: 0.699999988079071px solid #b2b7c1 !important;
  }
  .bdr-x-s {
    border-top: 0.699999988079071px solid #2f3e88 !important;
    border-bottom: 0.699999988079071px solid #2f3e88 !important;
  }
  .bdr-rht {
    border-right: 0.699999988079071px solid #b2b7c1 !important;
  }
  .bdr-rht-s {
    border-right: 0.699999988079071px solid #2f3e88 !important;
  }
  .bdr-lft {
    border-left: 0.699999988079071px solid #b2b7c1 !important;
  }
  .bdr-lft-s {
    border-left: 0.699999988079071px solid #2f3e88 !important;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-from:hover,
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-to:hover,
  .fs-search-cntnr .flight-search-container .flight-search .fs-stay:hover,
  .fs-search-cntnr .flight-search-container .flight-search .fs-whom:hover {
    background: rgba(244, 246, 255, 0.5);
  }
  /* .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-from,
  .fs-search-cntnr .flight-search-container .flight-search .fs-location .fs-to,
  .fs-search-cntnr .flight-search-container .flight-search .fs-stay,
  .fs-search-cntnr .flight-search-container .flight-search .fs-whom {
    border: 0.699999988079071px solid #b2b7c1;
  }*/
}
.fs-search-cntnr .flight-search-container .flight-search .fs-location .fs-from {
  border-radius: 12px 0 0 12px;
}
.fs-search-cntnr .flight-search-container .flight-search .fs-whom {
  border-radius: 0 12px 12px 0;
}
.fs-search-cntnr .flight-search-container .search_flight {
  text-transform: initial;
  font-size: 16px;
  font-weight: normal;
  background: #4258bf;
  border-radius: 48px;
  box-shadow: 0px 1px 4px #1018280d;
  padding: 0.7rem 3rem;
  margin: 0 auto;
  position: absolute;
  bottom: -25px;
  left: 0px;
  width: 154px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
}
.fs-search-cntnr .flight-search-container .search_flight:hover {
  color: #fff;
}
.search_flight--disable {
  background-color: #cccccc !important;
  color: #ffffff !important;
}
.fs-search-cntnr .flight-search-container .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row-reverse;
  padding: 0.75rem 1rem;
  column-gap: 0.5rem;
  border-radius: 12px;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #ffffff;
}

.fs-search-cntnr
  .flight-search-container
  .MuiFormControlLabel-root
  .MuiTypography-root {
  font-size: 14px;
}
.fs-search-cntnr .flight-search-container .selected-type {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 1rem !important;
  background: #f4f6ff;
  border: 1px solid #6879cc;
  border-radius: 12px;
  color: #2f3e88;
}
.fs-search-cntnr .flight-way {
  margin: 2rem 0 1.5rem;
  padding: 0 1rem;
}
.flight-way .css-11zohuh-MuiSvgIcon-root {
  fill: #4258bf;
}
.flight-way .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0;
}
.flight-search-container {
  color: rgba(0, 0, 0, 0.54);
  padding: 0px;
  z-index: 2;
  /* position: relative; */
  box-shadow: 0px 2px 10px #00000029;
  font-family: "Poppins", sans-serif;
  /* border-color: #ffffff; */
  /* background: #fff; */
  width: 100%;
  margin: 0 auto;
  margin-top: -10rem;
  border-radius: 20px;
}
.flight-way {
  padding: 0 0 30px 0;
  margin-left: -1.4rem;
}
.disable-container {
  position: relative;
}
.disable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  cursor: not-allowed;
  opacity: 0.5;
}
.flight-way ul {
  padding: 0;
  list-style: none;
  display: flex;
}

.flight-way ul li {
  margin: 0 40px 0 0;
  position: relative;
  padding: 0 0 0 38px;
}

.flight-way ul li input {
  position: absolute;
  left: 0;
  top: -1px;
}
.fs-stay input[type="text"],
.fs-whom input[type="text"] {
  margin: 0;
  font-size: 14px;
  padding: 8px 10px 7px 48px;
}
.flight-search > div {
  position: relative;
}
.fs-whom > img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 14px;
  top: 12px;
}
.fs-stay > img {
  position: absolute;
  right: 14px;
  width: 19px;
  height: 21px;
  top: 12px;
}
.flight-search {
  display: flex;
  justify-content: left;
  /* flex-wrap: wrap; */
  padding-right: 80px;
  /* gap: 5px;
  @media (max-width: 480px) {
    gap: 20px;
  } */
}

.flight-search .fg-btn {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  border-radius: 10px;
  height: 44px;
  padding: 10px 5px;
}
.fs-location {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.fs-stay {
  width: 25%;
}
.flight-lbl-pot {
  position: relative;
}
.flight-lbl {
  position: absolute;
  top: -25px;
  left: 15px;
}
.flight-lbl-2 {
  position: absolute;
  top: -25px;
  left: 45px;
  @media (max-width: 480px) {
    left: 15px;
  }
}
.fs-stay input {
  padding-left: 16px !important;
}
.fs-stay .btnMain {
  margin-right: 0px !important;
  margin-bottom: 1rem;
}
.fs-stay .dateMain {
  right: 0px;
  width: auto;
}
.dateMain .rdrDay {
  box-shadow: none;
  background-color: #ffffff;
  margin: 0;
  height: 4em;
  border-radius: 0;
}
.datemain .rdrDay:hover {
  background: rgba(219, 226, 255, 0.5) !important;
  border: 0;
  border-radius: 4px !important;
}
.dateMain .rdrMonthAndYearPickers {
  display: none;
}
.dateMain .rdrMonthName {
  text-align: center;
}
.dateMain .rdrStartEdge,
.rdrEndEdge {
  border-radius: 4px;
}
.fs-whom {
  width: 25%;
}
.fs-from {
  width: 50%;
  padding-right: 26px;
}
.fs-whom .pass-dropdown {
  right: 0px;
  width: 400px;
}
.fs-whom .pass-dropdown .qty-input {
  padding: 0px;
}
.flight-results {
  position: absolute;
  z-index: 9999;
  width: 350px;
  left: 0px;
  margin-top: 1.5rem;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 0 20px;
  padding-top: 20px;
}
.fs-landing-cntnr {
  padding: 0;
  width: 500px;
}
@media (max-width: 480px) {
  .fs-result-to {
    top: 95%;
  }
}
@media (min-width: 480px) {
  .fs-result-to {
    top: 95%;
    left: 55%;
  }
}
.scroll-view {
  max-height: 60vh;
  overflow-y: scroll;
}
.scroll-view::-webkit-scrollbar {
  display: none;
}
/* .scroll-view::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 20px;
}
.scroll-view::-webkit-scrollbar-thumb:hover {
  background-color: #888;
} */
.flight-results.step-content-passenger .passenger-body ul li {
  padding: 10px 25px !important;
}
.flight-results .btnMain {
  text-align: center;
}
@media (min-width: 481px) {
  .flight-results .btnMain {
    margin-bottom: 1rem;
  }
}
.flight-results .btnMain .doneBtn {
  background-color: #4258bf;
  border-radius: 20px;
  color: #fff;
  text-transform: initial;
  width: 80px;
}
.flight-results .row {
  flex-direction: row;
  display: flex;
  flex: 1;
  border-radius: 20px !important;
  margin-bottom: 20px;
  cursor: pointer;
}
.flight-results .row .col-1 {
  flex-direction: column;
  flex: 0.8;
}
.flight-results .row .col-1 .city {
  margin-bottom: 0.2rem;
  color: #342f41;
  font-size: 16px;
}
.flight-results .row .col-1 .airport {
  color: #342f41;
  font-size: 14px;
}
.flight-results .row .col-2 {
  flex-direction: column;
  flex: 0.2;
  display: flex;
  font-weight: bold;
  align-items: flex-end;
  font-size: 16px;
  justify-content: center;
}

.fs-to {
  width: 50%;
  padding-left: 26px;
}

.fs-swap {
  position: absolute;
  left: 0;
  right: 0;
  width: 26px;
  margin: auto;
  top: 12px;
  cursor: pointer;
}
.flight-way-search.onway-frm .fs-location {
  width: 64%;
}

.outlet-listing-lhs > h4 {
  color: #342f41;
  font-size: 18px;
  letter-spacing: 0.9px;
}

.flight-option ul li input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
}
.flight-option ul li {
  margin: 0 0 23px;
  position: relative;
  min-height: 22px;
  padding-left: 36px;
  display: flex;
  align-items: center;
}
.flight-option ul li input[type="checkbox"]:not(.switch):after {
  width: 5px;
  height: 10px;
  left: 7px;
  top: 3px;
}
.flight-option ul li:last-child {
  margin-bottom: 0;
}
.time-option ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.time-option ul li {
  margin: 0 0 15px 0;
  width: 48.5%;
}
.time-option ul li a {
  border-radius: 10px;
  border: 1px solid #4258bf;
  background: #fff;
  color: #120c0f;
  font-size: 14px;
  letter-spacing: 0.32px;
  display: block;
  padding: 7px 10px;
  text-align: center;
}
.time-option ul li a:hover,
.time-option ul li.active a {
  background: #4258bf;
  color: #fff;
}
.flight-search-result > ul {
  padding: 0;
  list-style: none;
}
.flight-search-result > ul > li {
  border-radius: 20px;
  box-shadow: 0 2px 23px rgb(66 88 191 / 20%);
  padding: 15px 15px 50px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 24px 0;
  background: #fff;
}
.package-transfer .flight-search-result > ul > li {
  margin: 0 0 50px 0; /*background-image: linear-gradient(-2deg, #ffffff 0%, #ebeeff 250%);*/
}
.package-transfer .flag-stay .title {
  color: #111733;
  font-size: 16px;
  font-weight: 500;
}
.package-transfer .options {
  display: flex;
  margin-bottom: 1rem;
}
.day-wise .package-transfer .options .option {
  background: #fff;
}
.package-transfer .options .option {
  border-radius: 20px;
  width: 200px;
  background: #f5f7ff;
  padding: 15px 20px;
  margin-right: 1rem;
  cursor: pointer;
}
.package-transfer .options .option.active {
  border: 1px solid #4258bf;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
}
.package-transfer .options .option .text1 {
  color: #4258bf;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.package-transfer .options .option .text1 svg {
  font-size: 14px;
}
.package-transfer .options .option .text2 {
  color: #120c0f;
  font-size: 16px;
  font-weight: 600;
}
.package-transfer .options .option .text3 {
  color: #4258bf;
  font-size: 14px;
}
.unique-package-stay .hotel-search-result .flag-stay {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.unique-package-transfer .hotel-search-result .flag-stay {
  justify-content: space-between;
}
.unique-package-transfer .hotel-search-result .flag-stay .view-more-link {
  top: auto;
}
.unique-package-stay .hotel-search-result .flag-stay .view-more-link {
  top: auto;
  right: 0px;
  position: absolute;
}
.unique-package-stay .hotel-search-result .flag-stay .title {
  color: #111733;
  font-size: 16px;
  font-weight: 500;
}
.unique-package-stay .hotel-search-result .flag-stay .option {
  border-radius: 20px;
  background: #f5f7ff;
  color: #4258bf;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  margin-left: 10px;
  white-space: nowrap;
}
.unique-package-stay .hsr-rhs .recommend-tag {
  background: #ebeeff;
  border-radius: 10px;
  padding: 5px 12px;
  color: #111733;
  font-size: 12px;
  position: absolute;
  top: 0px;
}
.unique-package-stay .hsr-rhs .stay-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  padding-right: 10px;
}
.unique-package-stay .hsr-rhs .amt-txt {
  font-size: 10px;
}
.unique-package-stay .hsr-lhs .hsr-lhs-desc .text1 {
  font-size: 14px;
  color: #444444;
}
.unique-package-thing .flag-stay .title {
  color: #111733;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
  padding-left: 1rem;
}
.unique-package-thing .up-accordion-header {
  background-color: #f5f7ff;
  display: flex;
  align-items: center;
  padding: 0px;
}
.unique-package-thing .up-accordion-header:after {
  top: auto;
}
.unique-package-thing .date-box {
  border-radius: 20px;
  width: auto;
  padding: 15px 18px;
  color: #4258bf;
  font-weight: bold;
}
.unique-package-thing .up-accordion-content .package-card-row {
  /* background-color: #f5f7ff; */
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  padding-bottom: 18px;
}
.unique-package-thing .up-accordion-content .package-card-row li {
  flex-direction: column;
  padding: 0px !important;
}
.unique-package-thing .up-accordion-content .package-card-row li .hsr-lhs {
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
}
.unique-package-thing
  .up-accordion-content
  .package-card-row
  li
  .hsr-lhs
  .hsr-lhs-img {
  width: 100%;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.package-card-row .time-tag {
  background: #f5f7ff;
  border: 0.5px solid #4258bf;
  border-radius: 20px;
  color: #4258bf;
  font-size: 12px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 8px 15px;
}
.day-wise .package-card-row .add-pk {
  background: #fff;
}
.package-card-row .add-pk {
  border: 1px dashed #4258bf;
  box-shadow: none !important;
  border-radius: 20px !important;
  background: #f5f7ff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #4258bf;
  width: 100% !important;
  margin-bottom: 0px !important;
}
.package-card-row .add-pk svg {
  margin-bottom: 0.2rem;
}
.package-card-row .add-pk {
  min-height: 258px;
}
.package-card-row .hsr-lhs-img img {
  height: 150px;
}
.unique-package-thing .package-card-row h5 {
  font-size: 14px !important;
}
.unique-package-thing .package-card-row .product-description-tag span {
  background: #f5f7ff;
  color: #4258bf;
  font-size: 12px;
  font-weight: 500;
}
.unique-package-thing
  .up-accordion-content
  .package-card-row
  .hsr-lhs-desc
  .product-description-tag {
  padding: 0px;
}
.unique-package-thing
  .up-accordion-content
  .package-card-row
  li
  .hsr-lhs
  .hsr-lhs-desc {
  width: 100%;
  padding: 10px;
}
.unique-package-thing .package-card-row .hsr-rhs {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 10px 15px 10px;
  justify-content: space-between;
}
.unique-package-thing .package-card-row .hsr-rhs .distance,
.things-do-list .product-description .distance {
  font-style: italic;
  font-size: 10px;
  color: #444444;
  text-align: left;
}
.unique-package-thing .package-card-row .hsr-rhs .distance div,
.things-do-list .product-description .distance div {
  color: #121833;
  font-weight: 500;
  font-size: 16px;
  font-style: initial;
}
.unique-package-thing .package-card-row .hsr-rhs .button {
  width: 38%;
  padding: 10px;
}
.unique-package-thing .package-card-row .hsr-rhs .change-btn {
  margin-bottom: 0px;
}
.unique-package-thing .package-card-row .hsr-rhs .remove-btn {
  border: 1px solid #db4437;
  color: #db4437;
  box-shadow: none;
  &:hover {
    color: #fff;
  }
}
.flight-search-result .flight-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flight-search-result .flight-loader svg {
  color: #4258bf;
}
.flight-banner {
  position: relative;
  height: 190px;
  overflow: hidden;
  background: #3f59fc;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.flight-banner .col1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0.7;
}
.flight-banner .col3 {
  flex: 0.15;
}
.flight-banner .col4 {
  flex: 1;
}
.flight-banner .col4 .text {
  letter-spacing: 0.27px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.flight-banner .col4 .text .head {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 5px;
  color: #fff;
}
.flight-banner .col4 .text .sub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.800000011920929);
  margin-bottom: 2px;
}
.flight-banner .col4 .text span {
  color: #4258bf;
}
.flight-banner .col4 .text1 {
  color: #4258bf;
  font-size: 14px;
  font-weight: normal;
  margin-top: 2px;
}
.flight-banner .col2 {
  display: flex;
  flex: 0.3;
  position: absolute;
  right: -58px;
  bottom: -36%;
}
.flight-banner .col2 {
  right: -110px;
  bottom: -41%;
}
.flight-banner .col2 > img {
  width: 270px;
}
.flight-banner .col2 .searchBtn {
  background: #4258bf;
  border: 1px solid #4258bf;
  text-transform: initial;
  font-size: 14px;
  color: #ffffff;
  border-radius: 20px;
  padding: 7px 40px 7px 22px;
}
.flight-banner .searchBtn {
  background: #fff;
  padding: 10px 24px;
  border: none;
  text-transform: initial;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #101b5d;
  border-radius: 20px;
  box-shadow: none;
  margin-top: 10px;
}
.flight-banner .searchBtn:hover {
  background: #fff;
  box-shadow: none;
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #4258bf;
  color: #4258bf;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  margin-left: 15px;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.flight-search-rhs {
  width: 145px;
  padding: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  justify-content: center;
}
.flight-price-pass-mobile {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
  gap: 5px;
  padding: 0 0 0 1rem;
}
.flight-price-pass {
  font-size: 18px !important;
  text-align: left !important;
}
.flight-price-total {
  display: flex;
  gap: 5px;
  align-items: baseline;
  justify-content: left;
}
.flight-price-total > strong {
  font-size: 14px !important;
  font-weight: 200 !important;
}
.summary-flight-lhs {
  border-right: 0 !important;
  flex: 1 !important;
}
.summary-fsl-top {
  display: flex;
  flex-wrap: wrap;
}
.summary-fsl-top .fsl-flight {
  width: 100%;
  border-bottom: 0.6px solid #ebedef;
}
.summary-fsl-top .price {
  width: 30%;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #21314d;
  padding: 0 1rem 1rem 0;
  display: flex;
  align-items: end;
  justify-content: end;
}
.summary-price {
  display: flex;
  flex-direction: column-reverse;
}
.summary-price > p {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #33425b;
}
.summary-scroll-cntnr {
  width: 70%;
  display: flex;
  justify-content: left;
  align-items: baseline;
}
.summary-scroll-cntnr > .scroll-row {
  border: 0 !important;
}
.summary-transfer-card {
  width: 95%;
  margin-left: auto;
}
.summary-transfer-col {
  padding: 1rem 0 !important;
}
.summary-transfer-lhs {
  padding: 0 1rem;
}
.summary-transfer-footer {
  padding: 1rem 0 0 1rem;
}
.summary-transfer-price {
  width: 30% !important;
  padding-right: 1rem;
}
.flight-search-lhs {
  width: calc(100% - 170px);
  padding: 10px 0px 10px 0;
}
.flight-search-rhs .button {
  margin: 0 0 15px 0;
  padding: 13px 10px;
  font-size: 12px;
  min-width: 120px;
  width: 100%;
  line-height: 1.1;
}
.flight-search-rhs .button:last-child {
  margin-bottom: 0;
}
.fsl-top,
.fsl-btm {
  padding: 0;
  list-style: none;
}
.fsl-top-space {
  margin: 0 0 2rem 0;
}
.fsl-top li,
.fsl-btm li {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.fsl-logo {
  width: 140px;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.fsl-path {
  width: calc(95% - 150px);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.fsl-path:after {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(50%, #8e94a3),
    color-stop(0%, transparent)
  );
  background-image: linear-gradient(to right, #8e94a3 50%, transparent 0%);
  background-size: 26px 2px;
  background-repeat: repeat-x;
  background-position: 0% bottom;
  -webkit-animation-name: border-dance;
  animation-name: border-dance;
  -webkit-animation-duration: 45s;
  animation-duration: 45s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  content: "";
  position: absolute;
  left: 130px;
  display: block;
  height: 1px;
  right: 130px;
  margin: auto;
}

@-webkit-keyframes border-dance {
  from {
    background-position: 0% bottom;
  }
  to {
    background-position: 100% bottom;
  }
}

@keyframes border-dance {
  from {
    background-position: 0% bottom;
  }
  to {
    background-position: 100% bottom;
  }
}
.fsl-from,
.fsl-to {
  width: 120px;
  padding: 5px;
  background-color: #ebeeff;
  border-radius: 10px;
  color: #120c0f;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.fsl-direct {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -10px;
}
.fsl-time {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  /* font-weight: 600; */
  color: #120c0f;
  letter-spacing: 0;
}
.fsl-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
.fsl-logo span {
  display: block;
  padding: 10px 0 0 0;
}
.fsl-btm {
  padding-top: 55px;
}
.search-number {
  padding: 0 0 15px 0;
  display: flex;
  justify-content: space-between;
}
.search-number span {
  font-weight: bold;
}

.flight-search-rhs strong {
  color: #120c0f;
  font-size: 16px;
  font-weight: 600;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
}
.best-flag {
  border-radius: 8px;
  background-color: #bdd0ff;
  color: #120c0f;
  position: absolute;
  right: 30px;
  top: 10px;
  padding: 7px 15px;
  line-height: 1;
  font-size: 12px;
}
.hotel-search-result > ul {
  padding: 0;
  list-style: none;
}

.hotel-search-result > ul > li {
  border-radius: 20px;
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);*/
  box-shadow: 0 2px 23px rgb(66 88 191 / 20%);
  padding: 15px;
  margin: 0 0 30px 0;
  letter-spacing: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  z-index: 1;
}

.hsr-rhs {
  width: 130px;
  text-align: center;
  position: relative;
  display: flex;
  padding: 10px 0px 0px 0px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column;
}

.hsr-lhs {
  width: calc(100% - 130px);
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.hsr-lhs-img {
  width: 280px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.hsr-lhs-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 220px;
  transition: 0.3s linear all;
}
.ttd-bg-img {
  background: linear-gradient(
      180deg,
      rgba(34, 34, 34, 0) 0%,
      rgba(27, 27, 27, 0) 31.82%,
      rgba(0, 0, 0, 0) 49%,
      rgba(34, 34, 34, 0.8) 100%
    ),
    #d9d9d9;
}
.hsr-lhs-desc {
  width: calc(100% - 280px);
  padding: 0 0 0 20px;
}
.hsr-lhs-desc h4 {
  margin: 0;
  color: #000;
  font-size: 17px;
  letter-spacing: 0;
}
.hsr-lhs-desc h4 a {
  color: #000;
}
.hsr-lhs-desc h4 a:hover {
  color: #4258bf;
}
.hsr-lhs-desc > span {
  font-size: 14px;
  display: block;
  padding: 8px 0 0 0;
}
.hsr-lhs-desc ul {
  padding: 15px 0;
  list-style: none;
}
.hsr-lhs-desc ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}
.sr-review span {
  text-decoration: underline;
  font-size: 13px;
}
.star-rating-review {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.sr-review strong {
  color: #a7a7a7;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  margin-right: 7px;
}
.free-box {
  padding: 9px 16px;
  display: inline-flex;
  border-radius: 9px;
  background-color: #f2fffd;
  line-height: 1;
  color: #09b7a3;
  font-size: 14px;
}
.hsr-rhs .like {
  top: 0;
}
.hsr-rhs p {
  font-size: 16px;
  font-weight: 600;
  color: #1c2460;
  margin: 0 0 10px 0;
  line-height: 1;
}
.hsr-rhs p span {
  opacity: 0.5;
  color: #1c2460;
  font-size: 16px;
  font-weight: normal;
}
.hsr-rhs .button {
  margin: 0 0 15px 0;
  padding: 13px 10px;
  font-size: 12px;
  width: 100%;
}
.hsr-rhs .button:last-child {
  margin-bottom: 0;
}
.hsr-rhs figure {
  color: #f02e88;
  display: flex;
  align-items: center;
}
.hsr-rhs figure img {
  margin-right: 5px;
}
.choose-loc {
  width: 34%;
  margin: 0 2% 0 0;
}
.choose-stay .fs-whom {
  width: 18%;
}
.choose-stay .fs-stay {
  width: 21%;
  margin-right: 2%;
}
.choose-transport {
  max-width: 860px;
  margin: 0 0 1rem 0;
}
.choose-transport .choose-loc {
  width: 64%;
}
.choose-transport .fs-whom {
  width: 33%;
}

.transport-search-result .hsr-lhs-desc {
  padding: 10px 0 0 0;
}
.transport-search-result .hsr-lhs-img {
  border-radius: 0;
}
.transport-search-result .hsr-lhs-img img {
  height: auto;
}
.hsr-lhs-img figcaption strong {
  font-size: 16px;
  display: block;
  color: #1c2460;
}
.hsr-lhs-img figcaption span {
  font-weight: 500;
  display: block;
  font-size: 14px;
}
.transport-search-result .hsr-rhs {
  align-items: center;
  text-align: left;
}
.transport-search-result .hsr-rhs .button {
  margin: 0;
}
.transport-search-result .best-flag {
  right: 10px;
}
ul.mainpoints {
  padding: 20px 0 0 0;
}
ul.mainpoints li {
  display: flex;
  width: 100%;
  margin: 0 0 12px 0;
  align-items: flex-start;
}
ul.mainpoints li:last-child {
  margin-bottom: 0;
}
ul.mainpoints li img {
  margin-right: 15px;
  position: relative;
  top: 2px;
}
ul.mainpoints li a {
  color: #4258bf;
  border-bottom: 1px solid #4258bf;
}
.title-with-nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 25px 0;
  align-items: center;
}
.title-with-nav .title-with-description {
  margin: 0;
}
.title-nav ul {
  padding: 0;
  list-style: none;
  display: flex;
}

.title-nav ul li {
  margin: 0 15px;
}
.title-nav ul li a {
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.32px;
}
.unique-package {
  box-shadow: 0 10px 40px rgba(140, 140, 140, 0.16);
  border-radius: 20px;
  border: 1px solid #4258bf;
  padding: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.unique-package-title {
  width: 100%;
}

.unique-package-lhs {
  width: 48%;
}

.unique-package-rhs {
  width: 48%;
}
.form-group.inline-chkbox ul {
  padding: 0;
  list-style: none;
}

.form-group.inline-chkbox ul li {
  display: inline-block;
  margin: 0 25px 20px 0;
}
.form-group.inline-chkbox ul li span {
  margin-left: 10px;
}
.form-group.inline-chkbox label {
  padding-bottom: 25px;
}
.unique-package .form-group .control-label,
.password-con-box .control-label {
  font-size: 15px;
  font-weight: 500;
}
.unique-package-info {
  color: #868e96;
  font-size: 12px;
}
.unique-package-rhs textarea {
  height: 220px;
}
.fg-half {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fg-half .form-group {
  width: 48%;
}
.inline-chkbox {
  margin-bottom: 5px;
}

.unique-package-title span {
  border-radius: 10px;
  background-color: #e9efff;
  display: inline-block;
  color: #1b252c;
  letter-spacing: 0.8px;
  padding: 6px 15px;
  margin: 0 20px 5px 0;
}

.unique-package-title {
  padding: 0 0 25px 0;
}
.center-btn {
  padding: 20px 0 10px 0;
}
.unique-package-rhs .unique-package-info {
  padding: 0 20px;
}

.cover-parent {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  display: block;
  cursor: pointer;
}
.cover-parent .curate-img img {
  height: 200px;
  width: 100%;
}
.curate-def {
  background: transparent
    linear-gradient(180deg, #59595900 0%, #000000b3 85%, #000000 100%) 0% 0%
    no-repeat padding-box;
  position: absolute;
  bottom: 0px;
  height: 100%;
  left: 0px;
  right: 0px;
  color: #fff;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 0 0 15px 10px;
  transition: color 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
.curate-def p {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 3px;
}
.curate-def span {
  font-size: 18px;
  font-weight: 600;
}
.curate-def .price {
  font-size: 14px;
  font-weight: 500;
}
.curate-def p,
.curate-def span {
  position: relative;
  display: inline-block;
  z-index: 1;
  line-height: 0.8;
  padding: 5px;
  /* background: #fff; */
  color: #fff;
}
.curate-def p::before,
.curate-def span::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 101%;
  height: 100%;
  background: #4258bf;
  transform: scale3d(1, 0, 1);
  transform-origin: 50% 100%;
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
/* .cover-parent:hover .curate-def p::before,
.cover-parent:hover .curate-def span::before {
  transform: scale3d(1, 1, 1);
  transform-origin: 50% 0%;
}
.cover-parent:hover .curate-def {
  color: #000;
}
.cover-parent:hover .curate-def p,
.cover-parent:hover .curate-def span {
  color: #fff;
} */

.blu-section {
  background: rgba(189, 208, 255, 0.25);
  padding: 40px 0;
  margin: 50px 0 0 0;
}
.gradient-box ul {
  padding: 10px 0 10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gradient-box ul li {
  width: 22.6%;
  margin: 0 3.2% 10px 0;
}
.gradient-box ul li:last-child {
  margin-right: 0;
}
.gradient-box ul li a {
  border-radius: 20px;
  padding: 20px 45px 20px 25px;
  overflow: hidden;
  position: relative;
  display: flex;
  background-image: linear-gradient(
    107deg,
    #ffcab0 0%,
    rgba(255, 192, 63, 0.6) 100%
  );
  transition: 0.3s linear all;
  height: 100%;
  align-items: flex-end;
}
.gradient-box ul li a:hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}
.gradient-box ul li:nth-child(2) a {
  background-image: linear-gradient(
    106deg,
    #29ffc6 0%,
    rgba(12, 235, 235, 0.6) 100%
  );
}
.gradient-box ul li:nth-child(3) a {
  background-image: linear-gradient(
    109deg,
    #ffffff 0%,
    rgba(251, 255, 184, 0.6) 100%
  );
}
.gradient-box ul li:nth-child(4) a {
  background-image: linear-gradient(
    -80deg,
    #ee9ca7 0%,
    rgba(255, 221, 225, 0.6) 100%
  );
}
.gradient-box ul li h4 span {
  display: block;
  color: #4258bf;
}
.gradient-box ul li h4 {
  margin: 0;
  font-size: 24px;
  color: #342f41;
}
.gradient-box ul li a img {
  width: 26px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.vacation-box-top {
  padding: 50px 0;
}
.vacation-box-top ul {
  list-style: none;
  padding: 0 0 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.vacation-box-top ul li {
  width: 23.125%;
  margin: 0 2.5% 20px 0;
}
.vacation-box-top ul li:last-child {
  margin-right: 0;
}
.vacation-box-top {
  padding: 50px 0;
}

.vb-def {
  color: #ffffff;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.2px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}
.vb-img {
  position: relative;
  z-index: 1;
}
.vb-def p {
  margin: 0;
}

.vb-img:after {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 100%;
}
.center-btn .button {
  margin: 0 auto;
}
.home-vacation-mid {
  background-image: linear-gradient(
    98deg,
    rgba(246, 155, 97, 0.5) 0%,
    rgba(246, 155, 97, 0.2) 100%
  );
}
.vacation-box-btm {
  min-height: 250px;

  padding: 30px 40px 30px 70px;
}
.vacation-box-txt {
  max-width: 500px;
}
.vacation-box-img {
  position: absolute;
  right: 15px;
  top: 35px;
  max-width: 430px;
}
.vacation-box-img img {
  -webkit-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}
@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.vacation-box-txt .button {
  margin-bottom: 0;
  min-width: 180px;
  background: rgba(255, 255, 255, 0.28);
  border-color: #fff;
  color: #111733;
}
.vacation-box-txt h2 {
  color: #342f41;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 3.2px;
  margin: 0 0 10px 0;
  line-height: 1.2;
}
.vacation-box-txt p {
  letter-spacing: -0.16px;
  margin-bottom: 10px;
}
.vacation-testimonials {
  padding: 60px 0 90px;
}
.vacation-testimonials .owl-carousel {
  max-width: 720px;
  padding: 15px 100px;
  margin: 0 auto;
}
.review-parent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px;
  border: 2px solid #4258bf;
  padding: 20px;
}

.testi-av {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

.testi-desc {
  width: calc(100% - 60px);
  padding: 0 0 0 18px;
  line-height: 20px;
  letter-spacing: 0.8px;
}
.testi-desc p {
  margin-bottom: 10px;
}
.testi-desc em {
  color: #4258bf;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.16px;
  margin-right: 10px;
}
.testi-desc i {
  color: #f7a928;
  margin: 0 5px 0 0;
}
.testi-name-rating strong {
  font-weight: 600;
  margin-right: 15px;
}
.vacation-testimonials .owl-carousel .owl-stage {
  display: block;
}
.vacation-testimonials .owl-prev,
.vacation-testimonials .owl-next {
  position: absolute;
  top: calc(50% - 19px);
  width: 20px;
  height: 38px;
  overflow: hidden;
  font-size: 0;
  background: url(../images/review-arrow.svg) no-repeat;
}
.vacation-testimonials .owl-prev {
  left: 0;
}
.vacation-testimonials .owl-next {
  right: 0;
  transform: rotate(180deg);
}
.vacation-testimonials .owl-dots {
  text-align: center;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
}

.vacation-testimonials .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #4258bf;
  margin: 0 5px;
  overflow: hidden;
  background: #fff;
}
.vacation-testimonials .owl-dots .owl-dot.active {
  background: #4258bf;
}
.home-newsletter {
  background: rgba(189, 208, 255, 0.25);
  padding: 50px 0 60px;
}
.formboy {
  max-width: 520px;
  margin: 0 auto;
}
.formboy h2 span {
  color: #4258bf;
}
.formboy h2 {
  margin: 0 0 15px;
}
.newsletter-form {
  margin: 27px auto 0;
  position: relative;
  width: 100%;
}
.newsletter-form .subscripe_email {
  margin: 0;
  padding-right: 140px;
  height: 60px;
  border-radius: 60px;
}
.newsletter-form .button {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 48px;
  margin: 0;
  border-radius: 60px;
}
.platform-step ul {
  padding: 60px 0 30px 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.step-slide {
  display: none;
  padding: 10px 0 25px 0;
}
.platform-step ul li {
  width: 31%;
  margin: 0 0 20px 0;
  background: #f7f9ff;
  padding: 40px 35px;
  border-radius: 20px;
  text-align: center;
}
.platform-step .owl-item {
  background: #f7f9ff;
  padding: 30px 20px;
  border-radius: 20px;
  text-align: center;
}
.platform-step ul li figure {
  margin: 0 0 40px 0;
}
.platform-step ul li figure img {
  max-height: 86px;
}
.platform-step .owl-carousel figure {
  margin: 0 0 15px 0;
  min-height: 88px;
}
.platform-step ul li h5 {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px 0px 15px 0px;
}
.platform-step .owl-item h5 {
  color: #000000;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0px 0px 10px 0px;
}
.platform-step ul li p {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.7px;
  line-height: 22px;
  margin: 0;
}
.platform-step .owl-item p {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin: 0;
  color: #000000;
}
.platform-step {
  padding: 60px 0;
}
.platform-step h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 2.25px;
}
.platform-step .button {
  padding: 16px 35px;
  font-size: 16px;
}
.step-slide .owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}

.step-banner {
  position: relative;
  height: 600px;
  background-image: linear-gradient(
    -57deg,
    #f1f4ff 0%,
    #fafbff 60%,
    #ffffff 100%
  );
}
.step-banner .container {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
}
.step-banner-form {
  width: 100%;
  box-shadow: rgb(66 88 191 / 28%) 0px -18px 29px 0px;
  border-radius: 20px;
  background-color: #ffffff;
  max-width: 480px;
  padding: 45px 35px 35px 35px;
}
.step-banner-form h3 {
  font-weight: 700;
  letter-spacing: 1.15px;
}
.step-banner-form > div {
  position: relative;
  margin-bottom: 5px;
}
.step-banner-whom > img {
  position: absolute;
  top: 12px;
  left: 17px;
}
.step-banner-content .step-banner-whom > input,
.step-banner .step-banner-form .step-banner-stay .pac-target-input,
.step-banner .step-banner-form .step-banner-stay .css-vj8t7z,
.step-banner .step-banner-form .step-banner-stay .css-2o5izw {
  padding-left: 50px;
  border-color: #f0f2ff;
  background: #f0f2ff;
}
.step-banner .step-banner-form .step-banner-stay .css-10nd86i {
  margin-bottom: 15px;
}
.step-banner-from > img,
.step-banner-to > img {
  position: absolute;
  top: 13px;
  left: 17px;
}
.step-banner-merge {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.step-banner-merge > div {
  width: 48%;
  position: relative;
}
.step-banner-from .react-datepicker__input-container input,
.step-banner-to .react-datepicker__input-container input,
.step-banner-content .step-banner-stay > input {
  padding-left: 50px;
  border-color: #f0f2ff;
  background: #f0f2ff;
}

.step-banner-stay > img {
  position: absolute;
  top: 13px;
  left: 17px;
  width: 16px;
  height: 20px;
}
.step-banner-form .button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
}
body .react-datepicker-wrapper {
  width: 100%;
  display: block;
}
.step-banner-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.step-banner-lhs {
  width: calc(100% - 580px);
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 50px 0 0 0;
}
.step-banner-lhs h1 {
  color: #000;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 55px;
  margin-bottom: 20px;
}
.step-banner-lhs h1 span {
  color: #4258bf;
  display: block;
}
.land-banner .step-banner-lhs h1 {
  color: #4258bf;
}
.land-banner .step-banner-lhs h1 span {
  color: #000;
  display: inline;
}
.step-banner-lhs p {
  color: #111733;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 26px;
  margin-bottom: 0;
}
.full-width {
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
}
#createpackage .step-banner-form {
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  background-color: #ffffff;
  max-width: 480px;
  padding: 0;
}
#createpackage {
  max-width: 480px;
  padding: 50px 40px 30px 40px;
}
.package-tools .title-with-description {
  margin: 0;
}
.package-tools {
  margin: 0 0 35px 0;
}
.pt-button .button {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pt-button .button img {
  margin: 0 10px 0 0;
  width: 12px;
}
/* .pt-button .button:hover {
  background: #000;
  color: #fff;
  border-color: #000;
} */

.creator-board {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.creator-img {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
}
.creator-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.creator-def h3 {
  color: #1b252c;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  margin: 0 0 2px 0;
}
.creator-def {
  width: 100%;
  margin: 10px 0 0 0;
  border-bottom: 1px solid #e7edff;
  padding: 0 0 30px 0;
}
.creator-def p {
  color: #939393;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: normal;
  margin: 0 0 5px 0;
}
.creator-def strong {
  color: #4258bf;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
}
.creator-def span {
  color: #444444;
  font-size: 12px;
  letter-spacing: -0.12px;
  display: block;
  margin: 0 0 2px 0;
}

.stpei-box {
  width: 55px;
}
.be-box p {
  width: calc(100% - 70px);
  margin: 0;
}
.promo-creator-img {
  width: calc(100% - 400px);
  min-height: 250px;
  position: relative;
  display: flex;
  align-items: flex-end;
  border-radius: 20px;
  overflow: hidden;
}

.creator-top {
  width: 350px;
  box-shadow: 0 3px 40px rgb(171 170 170 / 16%);
  border-radius: 20px;
  border: 1px solid #4258bf;
  background-color: #f0f4ff;
  padding: 25px;
}
.promo-creator-img img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.promo-creator-img figcaption {
  width: 100%;
  padding: 0 30px 30px 30px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.promo-creator-img figcaption h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 30px;
  margin: 0;
  color: #fff;
}
.promo-creator-img figcaption .button {
  margin: 0;
  background: #fff;
  color: #4258bf;
  min-width: 150px;
  border: 0;
}
.creot {
  margin: 50px 0 0;
}
.creator-top ul {
  padding: 0;
  list-style: none;
}

.creator-top ul li {
  padding: 0 0 10px 0;
  margin: 0 0 13px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.top-creator-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.top-creator-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.creator-top h5 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  margin-bottom: 20px;
}

.top-creator-def p {
  color: #1b252c;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  margin: 0;
}

.top-creator-def {
  width: calc(100% - 45px);
}
.top-creator-def span {
  color: #939393;
  font-size: 13px;
  display: block;
}
.top-creator-def strong {
  color: #4258bf;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;
  line-height: 19px;
}
.creator-top ul li:last-child {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.create-vac {
  padding: 40px 0 40px 0;
}
.crete-def img {
  width: 40px;
  margin: 0 0 18px;
}
.create-vac ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.create-vac ul li {
  position: relative;
  box-shadow: 0 10px 40px rgba(121, 121, 121, 0.16);
  border-radius: 20px;
  background: #d2deff;
  padding: 23px 23px 30px 23px;
  width: 30.46875%;
}
.crete-def p {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.07px;
  line-height: 20px;
  color: #171725;
  margin: 0;
}
.crete-def p span {
  color: #4258bf;
  letter-spacing: 0;
}
.crete-shape {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 0 0;
}
.learn-link {
  position: absolute;
  left: 23px;
  bottom: 25px;
  font-size: 14px;
  color: #4258bf;
  display: flex;
  align-items: center;
  line-height: 1;
}
.learn-link img {
  margin-left: 6px;
}

@keyframes pheasantBounce {
  0% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(5px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-5px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}
.learn-link:hover img {
  animation: pheasantBounce 1s infinite linear;
}

.unique-package-stay .hsr-lhs,
.package-details-lhs .hsr-lhs {
  border-right: 0;
}

.stp-info {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: normal;
  line-height: 1;
  position: absolute;
  bottom: -35px;
  right: 20px;
}
.unique-package-stay .hotel-search-result > ul > li {
  margin-bottom: 50px;
}
.flag-stay span {
  border-radius: 20px;
  background-color: #e9efff;
  color: #1b252c;
  letter-spacing: 0.8px;
  display: inline-flex;
  padding: 12px 5px;
  margin: 0 0 50px 0;
  min-width: 180px;
  text-align: center;
  justify-content: center;
  border: 2px solid #fff;
  font-weight: 500;
  outline: 1px solid #4258bf;
  align-items: center;
}
.flag-stay-transfer .hsr-lhs-img img {
  height: auto;
}
.flag-stay-transfer ul.mainpoints {
  display: flex;
  flex-wrap: wrap;
}
.flag-stay-transfer ul.mainpoints li {
  width: auto;
  margin: 0 25px 10px 0;
}
.unique-package-list {
  position: relative;
  margin: 30px 0 0 0;
}

.scroll-cntnr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.scroll-cntnr::-webkit-scrollbar {
  display: none;
}
.scroll-cntnr > .scroll-row {
  width: 100%;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .scroll-cntnr {
    overflow-x: auto;
    border-top: 0.5px solid #dfe1e5;
    border-bottom: 0.5px solid #dfe1e5;
    justify-content: left;
  }
  .scroll-cntnr > .list-mainpoints {
    flex-wrap: nowrap !important;
    width: max-content;
    margin: 0.5rem 0 0 0 !important;
  }
  .scroll-cntnr .scroll-row {
    flex-wrap: nowrap !important;
  }
  .scroll-cntnr > .scroll-row li {
    margin: 0 25px 0 0 !important;
  }
  .summary-scroll-cntnr {
    width: 100%;
    justify-content: left;
    overflow-x: auto;
  }
  .summary-scroll-cntnr::-webkit-scrollbar {
    display: none;
  }
  .summary-scroll-cntnr .new-tags {
    flex-wrap: nowrap;
    padding: 0.75rem 1rem !important;
    margin: 0;
    border-bottom: 0.5px solid #dfe1e5 !important;
  }
  .summary-scroll-cntnr .new-tags .c-tags {
    white-space: nowrap;
  }
  .summary-flight-lhs {
    z-index: 1;
    background: #ffffff;
    border-radius: 1rem 1rem 0 0;
  }
  .summary-fsl-top .price {
    width: 100%;
    padding: 1rem 1rem 0;
  }
  .summary-stay-transfer {
    position: relative;
  }
  .summary-stay-transfer .hsr-lhs-desc h4 {
    margin: 0 1rem;
  }
  .summary-stay-transfer .hsr-lhs-desc .transfer-address {
    width: 100%;
    margin: 0.12rem 1rem 0.75rem;
  }
  .summary-stay-transfer .hsr-lhs-desc .transfer-address img {
    transform: translateY(2.5px);
    margin: 0 0.25rem 0 0;
  }
  .summary-stay-transfer .hsr-lhs-desc ul {
    padding: 0.75rem 1rem;
  }
  .summary-stay-transfer .hsr-lhs-desc ul li {
    height: auto;
  }
  .summary-transfer-card {
    width: auto;
    margin: 3rem 1rem 0;
  }
  .summary-transfer-footer {
    flex-wrap: wrap;
    padding: 0;
    border-top: 0;
    margin: 0 !important;
  }
  .summary-transfer-footer .tags {
    width: 100%;
    flex: none !important;
    padding: 0.75rem 1rem;
    border-bottom: 0.5px solid #dfe1e5;
  }
  .summary-transfer-footer .summary-transfer-price {
    width: 100% !important;
    justify-content: right;
    margin: 0;
    padding: 0.75rem 1rem 0;
  }
  .summary-transfer-footer .summary-transfer-price p {
    margin: 0;
  }
}

/* .unique-package-list:before {
  content: "";
  position: absolute;
  left: 75px;
  top: 0;
  width: 2px;
  bottom: 0;
  z-index: -1;
  background-image: linear-gradient(
    to bottom,
    #4258bf 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background-size: 2px 12px;
  background-repeat: repeat-y;
} */

.danger {
  color: #ff0000;
}
@media (max-width: 780px) {
}
@media (max-width: 480px) {
  .destination-list-main .row {
    width: 100%;
    height: 120px;
    overflow-x: scroll;
    white-space: nowrap;
    display: block;
  }
  .destination-list-main .row button {
    background: transparent;
    box-shadow: none;
    border: none;
    margin: 0px;
    width: 50%;
    border-radius: 0;
    text-align: left;
    padding: 0px;
    letter-spacing: 0;
    line-height: 1.3;
    margin-bottom: 1rem;
  }
  .flight-search-main {
    flex-direction: column;
  }
  .flight-search-main .flight-results {
    padding: 0px;
  }
  .flight-search-main .flight-results .rdrMonthAndYearWrapper {
    top: 50px;
    width: 83%;
  }
  .flight-search-main .flight-results .btnMain {
    text-align: center;
    margin-right: 0px;
  }
  .flight-results {
    position: initial;
    box-shadow: none;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-stay
    .input-main
    .dateMain {
    width: 100%;
  }
  .fs-search-cntnr .flight-search-container .flight-search .label {
    font-size: 8px;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-from,
  .fs-search-cntnr .flight-search-container .flight-search .fs-location .fs-to {
    padding-left: 1rem;
    width: 100% !important;
  }
  .fs-search-cntnr .flight-search-container .flight-search .fs-location .fs-to {
    border-top: 0.699999988079071px solid #b2b7c1;
    border-bottom: 0.699999988079071px solid #b2b7c1;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-whom
    input[type="text"],
  .fs-search-cntnr .flight-search-container .flight-search .fs-stay input,
  .fs-search-cntnr .flight-search-container .flight-search .fs-location input,
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    input::placeholder,
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-stay
    input::placeholder {
    font-size: 14px;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .sub-text,
  .fs-search-cntnr .flight-search-container .flight-search .fs-stay .sub-text {
    font-size: 10px;
  }
  .fs-search-cntnr .flight-search-container .flight-search {
    gap: 0 !important;
  }
  .fs-search-cntnr .flight-search-container .flight-search .fs-location {
    border-right: none !important;
  }
  .fs-search-cntnr .flight-search-container .MuiFormControlLabel-root {
    font-size: 12px;
    padding: 0.5rem 0.75rem !important;
  }
  .fs-search-cntnr {
    padding: 1rem;
  }
  .fs-search-cntnr .flight-way {
    margin: 1.5rem 0 0.75rem;
    padding: 0 0.75rem;
  }
  .fs-search-cntnr .flight-search-container {
    padding: 0 !important;
  }
  .fs-search-cntnr .flight-search-container .flight-search-box {
    border-radius: 1rem;
    padding: 0;
  }
  .fs-search-cntnr .flight-search-container .flight-search .fs-location {
    width: 100% !important;
  }
  .fs-search-cntnr .flight-search-container .flight-search {
    padding: 0px;
    border: 0.699999988079071px solid #b2b7c1 !important;
    border-radius: 1rem !important;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-whom
    .pass-dropdown {
    width: 100%;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-to
    input[type="text"],
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-location
    .fs-from
    input[type="text"] {
    height: auto !important;
  }
  .fs-search-btn-card {
    width: 100%;
    padding: 0.75rem;
    border-top: 0.699999988079071px solid #b2b7c1;
  }
  .fs-search-btn-card .search_flight {
    width: 100% !important;
    position: initial !important;
  }
  .fs-search-cntnr .flight-search-container .flight-search .fs-whom {
    width: 100%;
    opacity: 1;
    padding: 0.75rem;
    margin: 0 !important;
    border-top: 0.699999988079071px solid #b2b7c1;
    border-radius: 0;
  }
  .fs-search-cntnr .flight-search-container .flight-search .fs-stay {
    width: 50%;
    opacity: 1;
    padding: 0.75rem;
    margin: 0 !important;
  }
  .fs-search-cntnr .flight-search-container .flight-search .mob-fs-stay {
    border-left: 0.699999988079071px solid #b2b7c1;
  }
  .fs-search-cntnr
    .flight-search-container
    .flight-search
    .fs-whom
    .step-content-passenger
    .addcart_row {
    flex-wrap: nowrap;
  }
  .vacation-banner-main .title,
  .vacation-banner-main1 .title {
    font-size: 20px !important;
  }
  .cr-row-togg {
    font-size: 13px;
  }
  .cr-awarness {
    width: 100%;
  }
  .cr-table-border {
    width: 100%;
  }
}

.flag-stay {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.unique-package-stay {
  margin: 30px 0;
}
.up-accordion-header {
  border-radius: 20px;
  background-color: #e9efff;
  font-weight: 500;
  padding: 22px 50px 22px 40px;
  line-height: 1;
  color: #1b252c;
  font-size: 17px;
  letter-spacing: 0.9px;
  position: relative;
  cursor: pointer;
}
.up-accordion-header:after {
  position: absolute;
  content: "\f078";
  right: 20px;
  top: 20px;
  font-family: FontAwesome;
  transition: 0.3s linear all;
  font-weight: normal;
  font-size: 16px;
}
.up-accordion-parent.active .up-accordion-header:after {
  transform: rotate(180deg);
}
.day-wise .up-accordion-content {
  background-color: #f5f7ff;
  margin-top: 0px;
}
.day-wise .up-accordion-parent {
  background-color: #f5f7ff;
}
.day-wise
  .up-accordion-content.last-day
  .hotel-search-result
  .active
  .hsr-lhs-img {
  width: 140px;
  height: 90px;
}
.day-wise
  .up-accordion-content.last-day
  .hotel-search-result
  .active
  .hsr-lhs-desc {
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.day-wise .up-accordion-content.last-day .end-trip-txt {
  color: #4258bf;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 15px;
}
.day-wise .up-accordion-content.last-day .hotel-search-result ul li {
  align-items: center;
  justify-content: space-between;
}
.day-wise .up-accordion-content.last-day .hotel-search-result ul li .check-out {
  color: #4258bf;
  font-weight: bold;
  font-size: 20px;
  padding-right: 2rem;
}
.up-accordion-content {
  background: #fff;
  padding: 0;
  position: relative;
  margin: 30px 0 0 0;
  display: none;
}
.up-accordion-parent.active .up-accordion-content {
  display: block;
}
.up-accordion-content > ul {
  padding: 0;
}

.up-accordion-content > ul > li {
  border-radius: 20px !important;
  /*box-shadow: 0 2px 10px rgb(0 0 0 / 16%);*/
  box-shadow: 0 2px 23px rgb(66 88 191 / 20%) !important;
  padding: 15px !important;
  margin: 0 0 30px 0 !important;
  letter-spacing: 0;
  display: flex !important;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  z-index: 1;
  width: 100% !important;
}
.pdr-rhs strong.product-strickout-price {
  font-size: 75%;
  opacity: 0.6;
  text-decoration: line-through;
  display: block;
  line-height: 1;
}
.up-accordion-content ul li .pdr-rhs {
  line-height: 1;
}
.up-accordion-content ul li .pdr-rhs .button {
  margin: 0;
  font-size: 12px;
  padding: 11px 20px;
}

.pack-ack-text {
  font-weight: 600;
  padding: 0 70px 15px 10px;
  line-height: 1;
}
.up-accordion-parent {
  margin: 0 0 40px 0;
  border-radius: 20px;
}
.up-accordion-content .stp-info {
  bottom: -25px;
}
.up-accordion-content .hsr-lhs {
  border-right: 0;
}
.up-accordion-content .hsr-lhs-desc h5 {
  font-size: 18px;
  letter-spacing: 0.18px;
  margin: 0 0 15px 0;
}
.up-accordion-content .hsr-lhs-desc .product-description-tag {
  padding: 15px 0 0 0;
}

.days-text {
  position: absolute;
  right: 40px;
  top: 20px;
  font-weight: 600;
}
.days-text span {
  color: #4258bf;
}
.pac-container {
  z-index: 99999;
  font-family: "Poppins", sans-serif;
}
.package-list-row {
  display: flex;
  flex-direction: row;
}
.package-list-row .col {
  flex: 1;
}
.package-list-row .left-col {
  flex: 0.18;
}
.cp-list-content .package-list-row .left-col {
  flex: 0.25;
}
.cp-list-content .package-list-row .right-col {
  flex: 0.75;
  padding-left: 1.5rem;
}
.cp-list-content .package-list-row .left-col .filter-main-sticky {
  border: 1px solid #dfe1e5;
  border-radius: 16px;
  position: initial;
}
.cp-list-content .package-list-row .right-col .showing-header {
  margin-top: 0;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-title
  .action-row {
  display: flex;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-title
  .action-row
  .apply {
  background: #4258bf;
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-title
  .action-row
  .clear {
  background: #fff;
  border: 0.5px solid #4258bf;
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 12px;
  cursor: pointer;
}
.cp-list-content .package-list-row .left-col .filter-main-sticky .filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 24px 24px 18px 24px;
  border-bottom: 1px solid #dfe1e5;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-title
  div {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: #2f3e88;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content {
  padding: 24px;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content
  .filter-head {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #6a7487;
  padding-right: 0px;
  margin-bottom: 0.5rem;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content
  .fliter-option-row {
  display: flex;
  flex-wrap: wrap;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content
  .cat-filter {
  margin-left: 0;
  margin-top: 1.2rem;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content
  .fliter-option-row
  .box.active {
  border: 0.800000011920929px solid #4258bf;
  background: #f4f6ff;
  color: #3c50ae;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content
  .fliter-option-row
  .box {
  padding: 8px 14px;
  border: 0.4000000059604645px solid #a5acb7;
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: #33425b;
  margin: 0 10px 10px 0;
  cursor: pointer;
}
.cp-list-content
  .package-list-row
  .left-col
  .filter-main-sticky
  .filter-content
  .filter-head
  .showMoreBtn {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #21314d;
}
.package-list-row .right-col {
  flex: 0.82;
}
.package-list-row .left-col .filter-head {
  font-size: 18px;
  color: #120c0f;
  font-weight: 600;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
}
.package-list-row .left-col .filter-head button {
  padding: 0px;
  min-width: 0px !important;
}
.package-list-row .left-col .filter-head button span {
  margin-left: 0px;
}
.package-list-row .left-col .filter-head .showMoreBtn {
  color: #4258bf;
  font-size: 12px;
  cursor: pointer;
}
.package-ul,
.package-ul1,
.traveler-main-package-list > ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.hyper-customize-main {
  display: flex;
  background: #2b0457;
  border-radius: 24px;
  padding: 1.2rem 1.8rem;
  justify-content: space-between;
  margin-top: 25px;
}
.hyper-customize-main .main-content .product-description {
  padding: 15px 5px 5px 0px;
}
.hyper-customize-main .product-list-parent .cp-main {
  margin-top: 5px;
}
.hyper-customize-main .product-list-parent .cp-main button {
  border-radius: 24px;
  height: 48px;
}
.hyper-customize-main .package-trend {
  padding: 0px;
}
.hyper-customize-main .package-trend .product-list-parent {
  padding: 10px;
}
.hyper-customize-main .main-content .product-img {
  border-radius: 20px;
}
.hyper-customize-main .package-trend .package-ul > li {
  margin: 0px;
}
.hyper-customize-main .col-1 {
  /* flex: 0.25; */
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.hyper-customize-main .col-1 .heading {
  color: #fcc400;
  font-size: 20px;
  font-weight: bold;
}
.hyper-customize-main .col-1 .list {
  margin: 1rem 0 2rem 0;
}
.hyper-customize-main .col-1 .list div {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.hyper-customize-main .col-1 .action-btns {
  padding: 4px 13px;
  background: #ffffff;
  width: 60px;
  height: 32px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.hyper-customize-main .col-1 .action-btns .prevBtn i,
.hyper-customize-main .col-1 .action-btns .nextBtn i {
  font-size: 1.5rem;
  cursor: pointer;
}
.hyper-customize-main .col-1 .list div img {
  margin-right: 5px;
}
.hyper-customize-main .col-1 .watch-btn {
  border-radius: 20px;
  background: #eceef9;
  color: #2b0457;
  font-size: 14px;
  font-weight: 500;
  text-transform: initial;
  padding: 0.5rem 1rem;
}
.hyper-customize-main .col-2 {
  /* flex: 0.75; */
  width: 75%;
}
.vacation-banner-main1 {
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}
.vacation-banner-main {
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}
.vacation-banner-main .content,
.vacation-banner-main1 .content {
  /* background: transparent
    linear-gradient(
      270deg,
      #59595900 0%,
      #00000000 15%,
      #3132159a 37%,
      #515322 100%
    )
    0% 0% no-repeat padding-box; */
  padding: 25px;
  border-radius: 20px;
}
/* .vacation-banner-main1 .content {
  background: transparent
    linear-gradient(
      270deg,
      #59595900 0%,
      #56595911 0%,
      #3d5858b7 37%,
      #325858 80%
    )
    0% 0% no-repeat padding-box;
} */
.vacation-banner-main1 .content .img-group {
  display: flex;
  align-items: center;
}
.vacation-banner-main1 .content .img-group .MuiAvatar-root {
  width: 30px;
  height: 30px;
}
.vacation-banner-main .title,
.vacation-banner-main1 .title {
  color: #feea0e;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 1rem;
}
.vacation-banner-main .text,
.vacation-banner-main1 .text {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}
.vacation-banner-main button,
.vacation-banner-main1 button {
  border-radius: 27px;
  background: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-transform: initial;
  box-shadow: none;
}
.vacation-banner-main button:hover,
.vacation-banner-main1 button:hover {
  background: #fff;
}
.vacation-banner-main1 button {
  margin-left: 1rem;
}
.offer-main {
  margin: 2rem 5rem 0 5rem;
  position: relative;
}
.offer-detail-main {
  position: relative;
}
.offer-detail-main .offer-design-card {
  background: #efffe0;
  border-radius: 20px;
  padding: 1rem 1rem;
  text-align: center;
}
.offer-main .offer-design-card .cy-pack {
  right: 50px;
}
.offer-detail-main .offer-design-card .text1 {
  text-align: center;
  color: #111733;
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5rem;
}
.offer-detail-main .offer-design-card .cy-pack {
  position: initial;
}
.offer-detail-main .offer-design-card .text2 {
  color: #111733;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.offer-detail-main .offer-design-card .text3 {
  color: #111733;
  font-size: 10px;
  text-align: center;
}
.offer-main .offer-design-card {
  background: #efffe0;
  border-radius: 20px;
  padding: 2rem 4rem;
}
.offer-main .offer-design-card .text1 {
  color: #111733;
  font-size: 23px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.offer-main .offer-design-card .text1 span,
.offer-detail-main .offer-design-card .text1 span {
  color: #4258bf;
}
.offer-main .offer-design-card .text2 {
  color: #111733;
  font-size: 16px;
  font-weight: 500;
}
.offer-main .offer-design-card .text2 span,
.offer-detail-main .offer-design-card .text2 span {
  color: #4258bf;
}
.offer-main .offer-design-card .text3 {
  color: #111733;
  font-size: 10px;
}
.filter-main-sticky {
  position: sticky;
  top: 150px;
  z-index: 1;
  background: #fff;
}
.fliter-option-row {
  margin-left: -1.3rem;
}
.fliter-option-row .label {
  font-weight: 400;
  color: #3f4d65;
  font-family: "poppins";
}
.filter-title {
  color: #120c0f;
  font-weight: 600;
  font-size: 18px;
  margin: 1rem 0;
}
.package-ul > li {
  width: 32%;
  margin: 0 2% 35px 0;
  position: relative;
  border-radius: 20px;
  box-shadow: rgba(66, 88, 191, 0.17) 0px 8px 20px;
  cursor: pointer;
  transition: 0.3s linear all;
  overflow: hidden;
}
.traveler-main-package-list > ul > li {
  width: 23%;
  margin: auto;
  margin-bottom: 30px;
  position: relative;
  border-radius: 20px;
  box-shadow: rgba(66, 88, 191, 0.17) 0px 8px 20px;
  cursor: pointer;
  transition: 0.3s linear all;
  overflow: hidden;
}
.main-content .package-ul > li {
  overflow: visible;
  box-shadow: none;
}
.category-filter {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
}
.cp-list-content .category-filter {
  justify-content: flex-start;
}
.category-filter .col-1 {
  display: flex;
}
.category-filter .col-2 {
  flex: 1;
  width: 50%;
  padding-left: 1rem;
}
.category-filter .col-2 .tf-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.category-filter .col-2 .tf-header .title {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #33425b;
}
.category-filter .col-2 .tf-header .action button {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #4258bf;
  box-shadow: none;
  text-transform: none;
}
.category-filter .col-2 .tf-row {
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.category-filter .col-2 .tf-row .active {
  border: 0.800000011920929px solid #4258bf !important;
  background: #f4f6ff;
  color: #3c50ae !important;
}
.category-filter .col-2 .tf-row .tf-label {
  padding: 12px 20px;
  border: 0.20000000298023224px solid #4258bf;
  border-radius: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #21314d;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.category-box {
  background: #f0f3ff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 16px 24px;
  margin-right: 0.8rem;
  width: 196px;
  border: 2px solid #f0f3ff;
  text-align: left;
}
/* Orientation Screen Issue Style  */
@media (min-width: 481px) and (max-width: 800px) {
  .package-ul1 .m-row .col2 {
    justify-content: flex-start;
  }
  .hotel-search-result {
    padding: 2rem 0 0 0;
  }
  .unique-package-stay .hotel-search-result .flag-stay .view-more-link {
    top: -20px;
    right: 10px;
  }
  .traveler-main-package-list > ul > li {
    width: 30%;
  }
  .vacationSummary__row {
    flex-direction: column;
  }
}
@media (min-width: 481px) and (max-width: 600px) {
  .category-box {
    width: 45vw;
  }
  .form-group-orien {
    width: 33% !important;
  }
}
@media (min-width: 601px) and (max-width: 800px) {
  .category-box {
    width: 35vw;
  }
  .form-group-orien {
    width: 25% !important;
  }
}
/* ------------------------------------ */
.category-box.active {
  border: 2px solid #4258bf;
}
.category-box .ctitle {
  color: #2f3e88;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.category-box .cdesp {
  color: #4e5b71;
  font-size: 12px;
  font-weight: 400;
  font-weight: normal;
}
.box-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.filter-row-new {
  flex-direction: row;
  display: flex;
  margin: 0px;
  align-items: center;
}
.filter-row-new h5 {
  font-size: 14px;
  margin: 0px;
  font-weight: 400;
  line-height: 1.6;
  color: #2f3e88;
}
.filter-row-new select {
  border: none;
  margin: 0px;
}
.package-ul1 .notavailable-msg {
  background: #f5f7ff;
  border-radius: 10px;
  color: #111733;
  font-size: 14px;
  font-weight: 500;
  margin: 1rem 0 1.5rem 0;
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  width: 100%;
}
.package-ul1 .notavailable-msg svg {
  margin-right: 0.8rem;
  color: #4258bf;
}
.package-ul1 > li {
  width: 100%;
  margin: 0 0 1.5rem 0;
  position: relative;
  border-radius: 20px;
  /* box-shadow: rgba(66, 88, 191, 0.17) 0px 8px 20px; */
  cursor: pointer;
  transition: 0.3s linear all;
  overflow: hidden;
  border: 0.5px solid #dfe1e5;
  border-radius: 28px;
  padding: 16px;
}
.package-ul1 > li .product-list-parent {
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  height: 315px;
  /* border: 0.6px solid #4258bf; */
}
.package-ul1 > li .product-img {
  width: 35%;
}
.package-ul1 > li .product-list-parent .product-img .pt-img {
  height: 100%;
  padding-bottom: 0px;
  /* overflow: initial; */
}
.package-ul1 > li .product-list-parent .product-description {
  width: 65%;
  position: relative;
  padding-top: 5px;
}
.package-ul1 .new-tags {
  position: initial;
  margin-bottom: 0.25rem;
}
.package-ul1 .new-tags > div.e-tags,
.package-ul1 .new-tags > div {
  background: #fff;
  color: #4258bf;
  margin: 0;
  padding-left: 0px;
  color: #4e5b71;
  font-size: 12px;
  font-weight: 400;
}
.package-ul1 .new-tags > div.cp-tags {
  background: #efffe0;
}
.package-ul1 .product-description-review .pdr-rhs .cp-tags {
  padding: 4px 12px;
  background: #f4f6ff;
  border: 0.20000000298023224px solid #122441;
  border-radius: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #2f3e88;
}
.package-ul1 .created-av-title {
  justify-content: start;
}
.package-ul1 .creator-badge {
  margin-left: 1rem;
  background-color: transparent;
}
.package-ul1 .creator-badge strong {
  padding: 0px;
}
.package-ul1 .creator-badge span {
  background: transparent;
  width: 25px;
}
.package-ul1 .m-row {
  flex-direction: row;
  display: flex;
  margin-bottom: 0.5rem;
}
.package-ul1 .m-row .col1 {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}
.package-ul1 .m-row .col3 {
  flex: 0.25;
}
.package-ul1 .m-row .col2 {
  flex: 0.25;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.package-ul1 .m-row .col1 .sites-content ul li {
  background-color: transparent;
  font-size: 12px;
  margin: 0px;
  padding: 7px 7px;
  color: #33425b;
  font-weight: 400;
}
.package-ul1 .product-description-review {
  position: absolute;
  bottom: 8px;
}
.package-ul1 .product-description-review .pdr-lhs {
  margin-bottom: 5px;
}
.package-ul1 .pdr-rhs .button {
  margin: 0px;
}
.package-ul1 .m-row em,
.package-ul1 .m-row .per-adult {
  font-size: 10px;
}
.package-ul1 .package-discount {
  font-size: 12px;
  background: #ff1c00;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  position: initial;
  padding: 5px 10px;
  &::after {
    content: " *";
    color: #fff;
  }
}
.package-ul1 .price-row {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
}
.package-ul1 .price-row strong {
  font-size: 20px;
}
.package-ul > li:hover {
}
.package-ul > li:nth-child(3n),
.traveler-main-package-list > ul > li:nth-child(3n) {
  margin-right: 0;
}
.package-ul > li .pdr-rhs span,
.traveler-main-package-list > ul > li .pdr-rhs span {
  font-size: 10px;
  font-weight: normal;
  margin-left: 3px;
}
.package-ul > li .pdr-lhs .package-source {
  font-size: 12px;
  font-weight: 500;
  color: #4258bf;
  padding: 6px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.package-ul > li .pdr-lhs .package-source.bg-clr {
  background: transparent
    linear-gradient(
      89deg,
      #f2f5ff 0%,
      #eff3ffb5 12%,
      #ebf0ffa8 32%,
      #e8edffb0 61%,
      #ebefff4f 70%,
      #f2f5ff 100%
    )
    0% 0% no-repeat padding-box;
  border: 0.4000000059604645px solid #4258bf14;
}
.package-ul > li .pdr-lhs .package-source.bg-clr1 {
  background: #efffe0 0% 0% no-repeat padding-box;
  border: 0.4000000059604645px solid #4258bf14;
}
.package-ul > li .pdr-rhs,
.traveler-main-package-list > ul > li .pdr-rhs {
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
}
.package-ul > li .pdr-rhs strong,
.traveler-main-package-list > ul > li .pdr-rhs strong {
  font-size: 18px;
  line-height: 1;
  margin: -1px 0 0 0;
  display: flex;
  display: flex;
  align-items: baseline;
  letter-spacing: -1px;
}
.package-ul > li .pdr-rhs em,
.traveler-main-package-list > ul > li .pdr-rhs em {
  font-style: normal;
  font-size: 12px;
  color: #444;
  display: block;
}
.btm-load .button.ghost-button {
  border-radius: 20px;
  min-width: 180px;
  padding: 17px 27px;
  line-height: 1.016;
}
.package-av-list h2 {
  font-size: 28px;
  margin: 0px 0px 15px 0px;
}
.sort-avilable {
  margin: 0 0 15px 0;
}
.av-result {
  font-size: 14px;
  font-weight: 400;
  color: #2f3e88;
}
.search-nav {
  background-color: #cad9ff;
  padding: 25px 0;
  display: none;
}
.created-av {
  width: 38px;
  height: 38px;
  overflow: hidden;
  border-radius: 50%;
}

.created-av-title h4 {
  font-size: 12px;
  letter-spacing: 0.16px;
  font-weight: 500;
  margin: 0;
  color: #21314d;
}

.main-content .created-av-title h4 span,
.ml-view .created-av-title h4 span {
  color: #797c8d;
}

.created-av-title h4 span {
  font-size: 11px;
  font-weight: normal;
  display: block;
  letter-spacing: 0.14px;
  line-height: 1;
  color: #87909f;
}

.product-created-content {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
}

.main-content .product-created-content {
  padding: 0 0 10px 12px;
}

.created-av-title {
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creator-badge {
  border-radius: 10px 0 10px 10px;
  background-color: #e7edff;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  line-height: 1;
  display: flex;
  overflow: hidden;
}
.main-content .creator-badge,
.ml-view .creator-badge {
  background-color: #fafbff;
}
.creator-badge strong {
  padding: 7px 13px 7px 10px;
  display: inline-flex;
  align-items: center;
}
.main-content .creator-badge strong,
.ml-view .creator-badge strong {
  padding-left: 0px;
}

.main-content .creator-badge span,
.ml-view .creator-badge span {
  background-color: #fafbff;
}

.creator-badge span {
  background: #8ca0ff;
  height: 32px;
  padding: 5px;
  width: 32px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.creator-badge em {
  font-style: normal;
  color: #4258bf;
  margin-left: 4px;
}

.package-top-filters {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 24px 0;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  top: 80px;
  z-index: 2;
}
.package-top-filters .ptf {
  margin: 0 25px 0 0;
}
.ptf-select-inner {
  display: flex;
  flex-wrap: wrap;
}
.package-top-btn .button {
  margin: 0;
  letter-spacing: 0.3px;
}
.package-details-rhs {
  width: 336px;
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
}

.package-details-lhs {
  width: calc(100% - 396px);
}

.package-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.detail-top-detail-parent .sites-content {
  padding: 0;
  width: 100%;
}
.detail-top-detail-parent .sites-content ul li {
  padding: 0;
  margin: 0;
  width: 25%;
}
.detail-top-detail-parent .sites-content ul li img {
  position: static;
}
.detail-top-detail-parent .sites-content ul li figurecaption {
  font-size: 16px;
}
.detail-top-detail-parent .sites-content ul li figure {
  display: flex;
}
.detail-top-detail-parent .sites-content ul li figcaption {
  padding: 15px 0 0 0;
  text-align: center;
}
.detail-top-detail-parent .sites-content ul li figcaption:before {
  display: none;
}

.unique-package-tab {
  padding: 20px 0 20px 0;
}
.unique-package-tab a {
  font-size: 18px;
  font-weight: 600;
  color: #342f41;
  letter-spacing: 0.9px;
  border-bottom: 2px solid #fff;
  margin: 0 40px 0 0;
}
@media (max-width: 480px) {
  .unique-package-tab > a {
    font-size: 14px !important;
  }
}
.unique-package-tab a.active {
  color: #4258bf;
  border-bottom: 2px solid #4258bf;
}
.rhs-white-box .earn-green {
  max-width: 100%;
  margin: 30px 0 0 0;
}
.detail-bottom-rhs-price ul li {
  flex-wrap: wrap;
  padding: 0;
}
.rhs-white-box .sub-taxes {
  width: 100%;
  padding-top: 10px;
}
.detail-bottom-rhs-price .sub-taxes > li {
  padding: 0;
  color: #767676;
  font-size: 14px;
  margin: 0 0 10px 0;
}
.detail-bottom-rhs-price .sub-taxes > li .service-amount {
  text-align: right;
}
.pack-creator-box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 25px 0;
}

.pcreator-img {
  width: 100px;
  height: 100px;
}

.pcreator-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.pcreator-def {
  width: calc(100% - 110px);
}

.pcreator-def h5 {
  color: #767676;
  font-size: 14px;
  letter-spacing: 0.14px;
  font-weight: normal;
  margin: 0 0 10px 0;
}

.pcreator-def h5 strong {
  font-weight: 500;
  color: #444444;
  font-size: 16px;
}

.star-ratin-creator {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.star-ratin-creator span {
  letter-spacing: -0.12px;
  font-size: 12px;
}

.star-ratin-creator strong {
  color: #4258bf;
  font-size: 17px;
}

.main-creator {
  background-color: #d3dfff;
  border-radius: 20px;
  text-align: center;
  color: #000;
  font-size: 14px;
  line-height: 1;
  padding: 10px 5px;
  margin: 10px 0 0 0;
  max-width: 210px;
}
.summary-box {
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 40px 0;
}
.summary-rhs-img {
  width: 40%;
  border-radius: 20px;
  overflow: hidden;
}
.summary-rhs-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 220px;
}
.summary-rhs-content {
  width: 60%;
  padding: 0 0 0 35px;
}
.summary-rhs-content h4 {
  letter-spacing: 0.8px;
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #000000;
}
.summary-rhs-content .sites-content {
  padding: 10px 0 0 0;
}
.screator-img img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 15px 0 0;
}

.review-txt p {
  font-size: 14px;
  margin: 0;
}

.review-txt span {
  font-weight: 500;
}

.screator-img {
  display: flex;
  flex-wrap: wrap;
}

.topmaury {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 0 0 0;
  align-items: center;
}
.summary-rhs-content .main-creator {
  display: inline-flex;
  padding: 10px 25px;
  margin: 25px 0 0 0;
}
.chkout-title {
  letter-spacing: 1.6px;
  font-size: 28px;
}
.tvbox {
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  padding: 25px;
  margin: 20px 0 35px 0;
}
.tvbox .form-group input,
.tvbox .form-group select {
  border-color: #e0e0e0;
  border-radius: 5px;
}
body .tvbox .form-group .css-vj8t7z,
body .tvbox .form-group .css-2o5izw {
  border-color: #c1c5cd;
  border-radius: 12px;
  height: 44px;
  border-width: 1px;
}

.tvbox .form-group label {
  display: block;
  margin: 0 0 5px 0;
  font-size: 15px;
}
.div-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.div-group .form-group {
  width: 48%;
  margin-bottom: 20px;
}
.tvbox h5 {
  color: #4258bf;
  letter-spacing: 0.08px;
}
.tb-inner-top,
.tb-inner-btm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tb-inner-top .form-group {
  width: 38.5%;
}
.tb-inner-btm .form-group {
  width: 26%;
}
.tb-inner-top .select-fg,
.tb-inner-btm .select-fg {
  width: 18%;
}
.tb-inner-btm .select-fg1 {
  width: 22%;
}
.why-book {
  border: 1px solid #4258bf;
  background-color: #f0ffee;
  border-radius: 20px;
  padding: 25px;
  margin: 30px 0 0 0;
}
.why-book ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.why-book ul li {
  width: auto;
  margin: 0 0 15px 0;
  padding: 0 30px 0 20px;
  font-size: 14px;
  color: #444;
  letter-spacing: 0;
  position: relative;
}
.why-book ul li:before {
  width: 14px;
  height: 11px;
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  background: url(../images/why-tick.svg) no-repeat;
}
.booking-steps ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 860px;
  margin: 0 auto;
}
.booking-steps ul li {
  background: #d3dfff;
  border-radius: 20px;
  letter-spacing: 0.16px;
  color: #000000;
  font-weight: 600;
  width: 200px;
  position: relative;
  line-height: 1;
  padding: 12px 40px;
}
.booking-steps ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 860px;
  margin: 0 auto;
  gap: 10px;
}
.booking-steps ul li {
  background: #d3dfff;
  border-radius: 20px;
  letter-spacing: 0.16px;
  color: #000000;
  font-weight: 600;
  width: 200px;
  position: relative;
  line-height: 1;
  padding: 12px 40px;
}
.booking-tpt h2 {
  font-size: 25px;
  margin: 0 0 2px;
}
.booking-tpt p {
  font-size: 14px;
  color: #767676;
  margin: 0;
}
.booking-tpt {
  padding: 0 0 20px 0;
}
.main-step-inner {
  max-width: 828px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 25px 20px;
  border: 2px solid #4258bf;
  border-radius: 20px;
}
.flight-padding-top {
  padding-top: 3rem;
}
.step-inner-lhs {
  width: calc(100% - 258px);
  font-size: 15px;
  border-right: 1px solid #4258bf;
  padding-right: 20px;
}

.step-inner-rhs {
  width: 258px;
  padding-left: 30px;
  padding-right: 20px;
}
.flight-step-lhs .fsl-logo {
  width: 100px;
}
.flight-step-lhs .fsl-path {
  width: calc(100% - 110px);
}
.flight-step-lhs .fsl-btm {
  padding-top: 30px;
}
.booking-sticky {
  margin-bottom: 40px;
}
.step-success-msg {
  padding: 15px;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-success-msg img {
  margin-left: 6px;
}
.step-success-msg span {
  margin-left: 4px;
}
.step-inner-rhs ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.step-inner-rhs ul li strong {
  display: block;
  font-weight: 500;
  color: #120c0f;
}

.step-inner-rhs ul li span {
  font-size: 14px;
  color: #767676;
}

.step-inner-rhs ul li {
  margin: 0 0 20px 0;
}
.step-inner-rhs p {
  font-size: 12px;
  margin: 0;
  color: #767676;
  display: flex;
  align-items: flex-start;
  line-height: 15px;
}
.step-inner-rhs p img {
  margin-right: 7px;
  position: relative;
  top: 2px;
  width: 12px;
}
.green-color {
  color: #4bb543 !important;
}
.step-inner-rhs h4 {
  color: #4258bf;
  font-size: 18px;
  letter-spacing: 0.18px;
  margin: 0 0 15px 0;
  font-weight: 500;
}
.transport-step-inner .hsr-lhs-img {
  max-height: 180px;
  width: 220px;
}
.transport-step-inner .hsr-lhs-desc {
  width: calc(100% - 220px);
  padding: 0 0 0 20px;
}
.transport-step-inner .hsr-lhs-desc h4 {
  font-size: 16px;
}
.flight-step2,
.flight-step3,
.flight-step4 {
  padding-top: 30px;
}
.transport-step-inner ul.mainpoints li img {
  margin-right: 10px;
}
.stay-step-inner .hsr-lhs-desc {
  width: calc(100% - 220px);
}
.stay-step-inner .hsr-lhs-img {
  width: 220px;
  height: 190px;
}
.stay-step-inner .hsr-lhs-desc p {
  color: #1c2460;
  font-size: 14px;
  margin: 0;
  padding-top: 10px;
  letter-spacing: 0;
}
.flight-step4 .hsr-lhs-desc > span {
  color: #342f41;
  font-weight: 500;
  letter-spacing: 0;
}
.booked-ticket-inner {
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  padding: 25px;
  max-width: 828px;
  margin: 0 auto;
}
.booked-ticket-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btt-rhs {
  width: 38.5%;
  height: 180px;
  border-radius: 20px;
  overflow: hidden;
}
.btt-rhs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btt-lhs {
  width: 57%;
}
.btt-lhs h5 {
  letter-spacing: 0.8px;
  font-size: 16px;
  margin: 0;
  color: #000;
}
.tickt-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tickt-content ul li strong {
  display: block;
  font-weight: 600;
}
.tickt-content ul li {
  padding: 8px 10px 0 0;
  width: 33%;
  font-size: 14px;
}
.tickt-content {
  padding: 22px 0 0 0;
}
.booked-ticket-btm-rhs .button {
  margin: 0 0 0 15px;
}

.booked-ticket-btm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 38px 0 0 0;
}

.booked-ticket-btm-lhs p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
}

.booked-ticket-btm-lhs p strong {
  color: #4258bf;
}
.booked-ticket-info {
  max-width: 828px;
  margin: 20px auto 0;
  font-size: 12px;
  letter-spacing: 0;
  color: #767676;
  display: flex;
  align-items: flex-start;
  padding: 0 30px;
}

.booked-ticket-info img {
  margin-right: 7px;
  position: relative;
  top: 2px;
}
.booking-gif {
  text-align: center;
}
.load-success {
  position: absolute;
  right: 10px;
  top: 10px;
}

.normal-loader {
  width: 20px;
  height: 20px;
  border: 3px dotted #4258bf;
  border-radius: 50%;
  display: inline-flex;
  position: relative;
  -webkit-animation: rotation 2s linear infinite;
  animation: rotation 2s linear infinite;
}
/* keyFrames */
@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.load-success .done-img {
  display: none;
  width: 20px !important;
}
.load-success.success .done-img {
  display: block;
}
.load-success.success .normal-loader {
  display: none;
}
.left-ic {
  position: absolute;
  left: 15px;
  transform: translateY(-50%);
  top: 50%;
}
.flight-ic img {
  width: 22px;
}
.transfer-ic img {
  width: 23px;
}
.stay-ic img {
  width: 25px;
}
.things-ic img {
  width: 21px;
}
.booking-steps {
  padding: 10px;
  background: #fff;
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  text-align: center;
  margin: 0 0 30px 0;
}
.ufs-box .fsl-path {
  width: 100%;
}
.ufs-box .fsl-from {
  width: 240px;
}
.ufs-box .fsl-to {
  width: 240px;
}
.ufs-box .fsl-path:after {
  left: 250px;
  right: 250px;
}
.ufs-box .fsl-from p,
.ufs-box .fsl-to p {
  margin-bottom: 0;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
.ufs-box .fsl-from p strong,
.ufs-box .fsl-to p strong {
  font-weight: 500;
  display: block;
  padding: 7px 0 0 0;
}
.ufs-box .fsl-from p span,
.ufs-box .fsl-to p span {
  font-size: 15px;
  color: #444444;
  font-weight: normal;
}
.ufs-inner .color-header {
  font-size: 16px;
  margin: 0px 0px 12px 0px;
}
.ufs-box .fsl-direct {
  top: -20px;
}
.ufs-box .fsl-time {
  bottom: -20px;
}
.ufs-box {
  border-radius: 20px;
  border: 1px solid #4258bf;
  padding: 25px 20px;
  position: relative;
  min-height: 150px;
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
  justify-content: space-between;
}
.ufs-inner-top {
  padding: 0;
}
.ufs-inner {
  padding: 0 0 20px 0;
}
.ufs-box-lhs {
  width: calc(100% - 140px);
}
.ufs-info span {
  background: rgb(246 155 97 / 25%);
  border-radius: 10px;
  color: #120c0f;
  font-size: 14px;
  line-height: 1;
  padding: 10px 10px;
  display: inline-flex;
  margin: 0 20px 8px 0px;
  min-width: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.ufs-info .cancel {
  background: rgba(16, 161, 148, 0.25) !important;
}
.ufs-info .non-cancel {
  background: rgb(246 155 97 / 25%) !important;
}
.ufs-info span:nth-child(even) {
  background: rgba(16, 161, 148, 0.25);
}
.ufs-info span:first-child {
  margin-left: 0;
}
.ufs-info-btm h6 {
  letter-spacing: 0.8px;
  margin-bottom: 10px;
  padding-top: 5px;
}
.ufs-info-btm p {
  margin-bottom: 0;
}
.ufs-info-btm p strong {
  font-weight: 500;
  color: #342f41;
}
.ufs-info-btm p span {
  margin-right: 25px;
}
.ufs-inner-btm-lhs {
  width: 100%;
  padding-bottom: 20px;
  position: relative;
}

.ufs-inner-btm-rhs {
  width: 100%;
}

.ufs-inner-btm {
  display: flex;
  flex-wrap: wrap;
}

.ufs-inner-btm-title {
  width: 100%;
}
.ufs-inner-btm-lhs ul,
.ufs-inner-btm-rhs ul {
  padding: 0;
  list-style: none;
}
.ufs-inner-btm-lhs ul li,
.ufs-inner-btm-rhs ul li {
  position: relative;
  padding-left: 26px;
  margin: 0 0 10px 0;
  letter-spacing: normal;
  font-size: 14px;
}
.ufs-inner-btm-lhs ul li:last-child,
.ufs-inner-btm-rhs ul li:last-child {
  margin-bottom: 0;
}
.ufs-inner-btm-lhs ul li:before,
.ufs-inner-btm-rhs ul li:before {
  width: 17px;
  height: 17px;
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  background: url(../images/blue-tick.svg) no-repeat;
  background-size: contain;
}
.product-description-action .ghost-button {
  margin-left: 15px;
}

.product-description-action .button {
  margin-top: 0;
  margin-bottom: 0;
}
.pb40 {
  padding-bottom: 40px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb10 {
  padding-bottom: 10px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb0 {
  margin-bottom: 0px;
}
.remove-btn {
  padding: 12px 20px;
  background: #fef2f1;
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 1.5;
  color: #d92c20;
  border: none;
  width: auto;
  margin-bottom: 0;
  width: 48%;
}
.remove-btn:hover {
  cursor: pointer;
  background: #fef2f1;
  color: #d92c20;
}
.hsr-lhs-desc h5 {
  margin-bottom: 15px;
}
.uss-inner-slider .owl-carousel .owl-stage {
  padding-left: 0 !important;
}
.uss-inner-top {
  padding: 30px 35px 30px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  margin: 0 0 50px 0;
}
.uss-inner-slider {
  margin: 25px 0 0 0;
}
.uss-inner-top h4 {
  color: #342f41;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 10px 0;
}
.uss-inner-top h4 span {
  color: #444444;
  font-size: 16px;
  font-weight: normal;
}
.uss-about p {
  letter-spacing: normal;
  line-height: 32px;
}
.uss-about h3,
.uss-choice h3 {
  font-weight: 500;
}
.uss-choice > ul {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}
.uss-choice > ul > li {
  position: relative;
  font-size: 15px;
  border-radius: 20px;
  overflow: hidden;
  width: 48%;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  margin-bottom: 30px;
}
.uss-choice > ul > li.active .rcf-rhs .button {
  background: #4258bf;
  color: #fff;
  border-color: #4258bf;
}
.uss-choice > ul > li.active {
  border-color: #4258bf;
}
.room-choice-two h5,
.room-choice-three h5,
.room-capacity h5 {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.room-choice-two h5 img,
.room-choice-three h5 img {
  margin-left: 5px;
}
.room-capacity {
  padding-bottom: 20px;
}
.room-choice-one ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  overflow: hidden;
}

.room-choice-one ul li {
  margin: 0 20px 10px 0;
  display: flex;
  align-items: flex-start;
}

.room-choice-one ul li i {
  margin: 2px 7px 0 0;
  color: #4258bf;
}
.room-choice-one,
.room-choice-two,
.room-choice-three {
  padding: 0 0 20px 0;
}
.room-choice-desc {
  padding: 15px 25px 25px;
}
.room-choice-two ul,
.room-choice-three ul {
  padding: 0;
  list-style: none;
}
.room-choice-two ul li,
.room-choice-three ul li {
  position: relative;
  padding-left: 35px;
  min-height: 22px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.room-choice-two ul li:last-child,
.room-choice-three ul li:last-child {
  margin-bottom: 0;
}
.room-choice-two ul li input,
.room-choice-three ul li input {
  position: absolute;
  left: 0;
  top: -1px;
}
.room-choice-desc {
  padding: 15px 25px 85px;
  position: relative;
  flex: 1 1 auto;
}
.room-choice-two ul,
.room-choice-three ul {
  padding: 0;
  list-style: none;
}
.room-choice-two ul li,
.room-choice-three ul li {
  position: relative;
  padding-left: 35px;
  min-height: 22px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.room-choice-two ul li:last-child,
.room-choice-three ul li:last-child {
  margin-bottom: 0;
}
.room-choice-two ul li input,
.room-choice-three ul li input {
  position: absolute;
  left: 0;
  top: -1px;
}
.room-choice-desc {
  padding: 15px 25px 85px;
  position: relative;
  flex: 1 1 auto;
}
.room-exceed h5 {
  color: #d32f2f;
  color: #d32f2f;
}

.roomerror {
  max-width: 580px;
  color: #000;
  line-height: 1.5;
}
.roomerror .cp-header h3 {
  font-size: 19px;
  color: #d32f2f;
}
.roomerror .cp-header {
  margin: 0 0 15px 0;
}
.room-capacity ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.room-capacity ul li {
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px 5px 0;
}
.room-capacity ul li img {
  width: 19px;
  margin: 0 6px 0 0;
}
.room-choice-two ul,
.room-choice-three ul {
  padding: 0;
  list-style: none;
}
.room-choice-two ul li,
.room-choice-three ul li {
  position: relative;
  padding-left: 35px;
  min-height: 22px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.room-choice-two ul li:last-child,
.room-choice-three ul li:last-child {
  margin-bottom: 0;
}
.room-choice-two ul li input,
.room-choice-three ul li input {
  position: absolute;
  left: 0;
  top: -1px;
}
.room-choice-two ul li strong,
.room-choice-three ul li strong {
  width: 130px;
  text-align: right;
  font-weight: 600;
  color: #342f41;
}
.room-choice-two ul li span,
.room-choice-three ul li span {
  width: calc(100% - 135px);
}
.room-choice-four {
  padding: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
}

.rcf-rhs .button {
  margin: 0;
  padding: 10px 24px;
}

.rcf-lhs p {
  margin: 0;
  color: #767676;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.rcf-lhs strong {
  color: #120c0f;
  font-size: 23px;
  letter-spacing: 0;
  font-weight: 600;
  margin: 0 6px 0 0;
}
.rcf-lhs span {
  color: #767676;
  font-size: 12px;
  display: block;
  line-height: 1.1;
}
.room-choice-img {
  position: relative;
}

.room-choice-img .owl-prev,
.room-choice-img .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  overflow: hidden;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
}
.room-choice-img .owl-prev {
  left: 13px;
}
.room-choice-img .owl-next {
  right: 13px;
}
.room-choice-img .owl-prev:after,
.room-choice-img .owl-next:after {
  position: absolute;
  left: 13px;
  top: 3px;
  color: #4258bf;
  font-size: 26px;
  font-family: FontAwesome;
  content: "\f104";
}
.room-choice-img .owl-next:after {
  content: "\f105";
  left: 16px;
}
.room-choice-one h4 {
  font-size: 18px;
  letter-spacing: normal;
}
.step-banner-stay ul,
.step-banner-whom ul {
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 3;
  padding: 30px 15px 15px;
  list-style: none;
  top: 44px;
  border-radius: 10px;
  box-shadow: 0 0px 8px rgb(0 0 0 / 8%);
  max-height: 230px;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: 0.3s linear all;
}
.step-banner-stay ul.active,
.step-banner-whom ul.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.step-banner-stay ul li a {
  display: block;
  color: #1b252c;
  font-size: 14px;
}

.step-banner-stay ul li {
  margin: 0 0 5px 0;
}
.step-banner-stay ul li a:hover {
  color: #4258bf;
}
.step-banner-whom .sel-quality {
  font-size: 14px;
}
.step-banner-whom .sel-quality .price-select {
  font-size: 13px;
  color: #8c8c8c;
}
.step-banner-stay ul li a:hover {
  color: #4258bf;
}
.step-banner-whom .sel-quality {
  font-size: 14px;
}
.step-banner-whom .sel-quality .price-select {
  font-size: 13px;
  color: #8c8c8c;
}
.passenger-body .sel-quality {
  font-weight: 500;
}
.passenger-body .sel-quality .price-select {
  font-size: 13px;
  color: #8c8c8c;
  font-weight: normal;
}

.passenger-body ul {
  padding: 0;
  list-style: none;
}
/* Passenger Popup Style */
.add-room-cntnr {
  width: 60vw;
  height: 80vh;
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
}
.add-room-cntnr .css-10nd86i {
  width: 20%;
}
.add-room-cntnr .addcart_row {
  margin: 0;
}
.add-room-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: left;
  padding: 0 2rem;
  margin-bottom: 1.5rem;
}
.add-room-heading {
  font-weight: 600;
  line-height: 1.3;
  color: #21314d;
  margin: 0;
}
.add-room-err-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #d92c20;
}
.add-room-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 2rem;
}
.new-room-btn {
  margin: 0;
  padding: 0.75rem 2rem;
  background: #eceef9;
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #2f3e88;
  border: none;
}
.update-btn {
  padding: 12px 32px;
  background: #4258bf;
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
}
.add-room-scroll-cntnr {
  width: 100%;
  height: 65%;
  overflow-y: auto;
}
.add-room-scroll-row {
  height: max-content;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #44444438;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #4444446e;
}
.add-room-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafb;
  padding: 0.5rem 2rem;
}
.add-room-top h4 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #4258bf;
}
.add-room-delete {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  color: #ff0000 !important;
  text-transform: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.add-room-delete:hover {
  cursor: pointer;
}
.add-room-list {
  padding: 1rem;
  position: relative;
}
.add-room-list ul {
  padding: 0;
}
.add-room-list li {
  list-style: none !important;
  padding: 1rem 0;
}
.sel-quality {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #243069;
}
.sel-quality > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #4e5b71;
  margin-top: 6px;
}
.qty-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.qty-box > .qty-input {
  width: 40px;
  padding: 0;
  margin: 0;
  border: none;
  text-align: center;
}
.qty-box > span {
  border: none;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
}
.qty-box > span:hover {
  cursor: pointer;
  background-color: #eceef9;
  border-radius: 4px;
}
.add-room-child-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 !important;
}
.add-room-child-row span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #4e5b71;
  margin-top: 6px;
}
.add-room-child-row label {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #243069;
}

.add-room-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #d92c20;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 4px;
  margin-top: 6px;
}
.add-room-btm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.add-room-btm button {
  margin: 0;
}
.add-room-btm > button {
  margin: 2.5rem 0 0 0;
  padding: 16px 27px;
  background-color: #fff;
  color: #4258bf;
  cursor: pointer;
}
.add-room-btm > button:hover {
  background-color: #4258bf;
  color: #ffffff;
}
.child-age-select {
  margin: 0 0 20px 0;
}
.child-age-select label {
  display: block;
  margin: 0 0 5px 0;
  font-weight: 500;
}
.passenger-body .disable {
  opacity: 0.5;
  cursor: not-allowed;
}
.age-sel > div {
  margin: 0 0 15px 0;
}
@media (max-width: 480px) {
  .add-room-cntnr {
    width: 100%;
    padding: calc(2rem - 5px) 0;
  }
  .add-room-header {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  .add-room-top h4,
  .sel-quality,
  .add-room-child-row label {
    font-size: 14px;
  }
  .sel-quality > span,
  .add-room-child-row span {
    font-size: 12px !important;
  }
  .add-room-heading {
    font-size: 16px;
  }
  .add-room-footer {
    flex-direction: column;
    gap: 0;
    padding: 0.5rem 1rem;
  }
  .add-room-top {
    padding: 0.75rem 1rem;
    border-top: 0.5px solid #dfe1e5;
    border-bottom: 0.5px solid #dfe1e5;
    background: #ffffff;
  }
  .add-room-list {
    padding: 1rem;
  }
  .add-room-list li {
    padding: 0;
  }
  .add-room-list li:not(:last-child) {
    margin-bottom: 1rem;
  }
  .add-room-delete {
    width: 24px;
    height: 24px;
  }
  .new-room-btn {
    width: 100%;
  }
  .update-btn {
    width: 100%;
  }
  .add-room-child-row {
    margin: 1rem 0 0 0 !important;
  }
  .child-age-select {
    margin: 0px;
  }
}
/* Flight Search Popup Style */
.flight-search-popup-cntnr {
  width: 60vw;
  height: 90vh !important;
  padding: 2rem !important;
  max-width: unset !important;
}
.flight-search-popup-cntnr > h3,
.callender-popup-cntnr > h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #21314d;
  margin: 0;
  padding: 0;
}
.flight-search-popup-cntnr .step-content-search {
  box-shadow: none;
  padding: 0 2rem;
}
.flight-search-popup-cntnr .for-step-rel {
  padding: 1rem 0;
}
.flight-search-popup-cntnr .dd-text {
  border: 0.6px solid #b2b7c1 !important;
  border-radius: 12px !important;
  color: #122441;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.3;
  height: auto !important;
  padding: 1rem 1rem 1rem 3rem !important;
  margin: 0;
}
.flight-search-popup-cntnr .dd-text:hover {
  background: #fafafb;
}
.flight-search-popup-cntnr .dd-text:focus {
  border: 0.6px solid #3c50ae !important;
  box-shadow: 0 0 0 4px #dbe2ff;
}
.flight-search-popup-cntnr .search-glass {
  left: 1rem;
}
.flight-search-popup-cntnr .clear-button {
  color: #b2b7c1;
}
.flight-search-popup-cntnr .clear-button:hover {
  cursor: pointer;
  color: #000000;
}
.flight-search-popup-cntnr ul {
  padding: 0 !important;
}
.flight-search-popup-cntnr ul li {
  margin: 0 !important;
  list-style: none;
}
.flight-option-cntnr {
  box-shadow: 0px 0px 5.300000190734863px rgba(63, 77, 101, 0.25);
  padding: 4px 0;
  background: #ffffff;
  border-radius: 8px;
  max-height: 58vh;
  overflow-y: auto;
}
.flight-option-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
}
.flight-option-row:hover {
  cursor: pointer;
  background: #f4f6ff;
}
.flight-option-lhs {
  display: flex;
  gap: 0.74rem;
}
.flight-option-lhs .figure {
  width: 40px;
  height: 40px;
  background: #f4f6ff;
  display: grid;
  place-items: center;
  border-radius: 6px;
}
.flight-option-lhs p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #21314d;
  margin: 0;
}
.flight-option-lhs span {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #2f3e88;
}
.flight-option-rhs {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: #4258bf;
}
.flight-search-popup-cntnr .clear-button {
  top: 2rem;
  right: 1rem;
}
@media (max-width: 480px) {
  .flight-search-popup-cntnr {
    width: 100%;
    padding: 2rem 1rem !important;
  }

  .flight-search-popup-cntnr .close-btn {
    display: block !important;
    right: 1rem !important;
    transform: scale(0.9);
  }
  .flight-search-popup-cntnr > h3 {
    font-size: 16px;
  }
  .flight-option-cntnr {
    box-shadow: none;
    max-height: 65vh;
  }
  .flight-option-cntnr::-webkit-scrollbar {
    display: none;
  }
  .flight-option-row {
    padding: 0.75rem 0;
  }
  .flight-option-lhs p {
    font-size: 14px;
  }
  .flight-option-lhs .figure {
    width: 32px;
    height: 32px;
  }
  .flight-option-rhs {
    font-size: 12px;
  }
}
/* Calender Popup Style */
.calender-popup-cntnr {
  width: 60vw;
  height: max-content !important;
  padding: 1.5rem !important;
}
.calender-popup-cntnr h3 {
  margin-bottom: 1rem;
  font-size: 20px;
  color: #21314d !important;
}
.calender-popup-cntnr .step-content-search {
  box-shadow: 0px 0px 5.300000190734863px rgba(63, 77, 101, 0.25);
  border-radius: 8px !important;
}
.calender-popup-cntnr .rdrCalendarWrapper {
  padding: 0 !important;
}
.calender-popup-cntnr .rdrMonthAndYearWrapper {
  left: 16px !important;
  right: 16px !important;
  display: flex;
}
.calender-popup-cntnr .rdrMonth {
  padding: 0 24px 24px !important;
  width: 45%;
  margin: 0 auto;
}
.calender-popup-cntnr .rdrMonthName {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1.3;
  color: #21314d !important;
  margin: 10px 0 0 0 !important;
  padding: 16px 16px 26px;
}
.calender-popup-cntnr .rdrWeekDays {
  margin: 0 0 1rem 0 !important;
}
.calender-popup-cntnr .rdrWeekDay {
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 1.3;
  color: #3c50ae !important;
}
.calender-popup-cntnr .rdrDay {
  aspect-ratio: 1;
  height: auto !important;
}
.calender-popup-cntnr .rdrDay:hover {
  background: #edf0ff !important;
  border-radius: 4px;
}
.calender-popup-cntnr .rdrDayNumber > span {
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 1.3;
  color: #21314d;
}
.calender-popup-cntnr .rdrSelected {
  border-radius: 4px !important;
  inset: 0 !important;
  color: #ffffff !important;
}
.calender-popup-cntnr .rdrSelected:hover {
  background: #4258bf !important;
  color: #ffffff;
}
.calender-popup-cntnr .btn-min-width {
  padding: 0 !important;
}
.calender-continue-btn {
  padding: 12px 32px !important;
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  border: 0;
  margin: 1rem 0 0 0 !important;
}
.calender-popup-cntnr .rdrDayStartPreview,
.calender-popup-cntnr .rdrDayInPreview,
.calender-popup-cntnr .rdrDayEndPreview {
  border: 0 !important;
}

body .react-datepicker__day-name,
body .react-datepicker__day,
body .react-datepicker__time-name {
  width: 2.1rem;
}
@media (max-width: 480px) {
  .calender-popup-cntnr {
    width: 100vw;
    padding: 2rem 1rem 1rem !important;
  }
  .calender-popup-cntnr h3 {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .calender-continue-btn {
    width: 100%;
    margin-top: 0.75rem;
  }
  .calender-popup-cntnr .rdrMonth {
    padding: 0 8px 8px !important;
  }
  .calender-popup-cntnr .rdrWeekDays {
    margin: 0.5rem 0 1rem 0 !important;
  }
  .calender-popup-cntnr .rdrMonthAndYearWrapper {
    position: relative !important;
    top: 35px !important;
    left: 0 !important;
  }
  .calender-popup-cntnr .rdrMonthAndYearWrapper .rdrNextPrevButton {
    background: #ffffff !important;
  }
  .calender-popup-cntnr .rdrMonthAndYearWrapper .rdrPprevButton {
    margin-left: 0.75rem;
  }
  .calender-popup-cntnr .rdrMonthAndYearWrapper .rdrNextButton {
    margin-right: 0.75rem;
  }
  .calender-popup-cntnr .rdrDay {
    height: auto !important;
  }
}

body .react-datepicker__month-select,
body .react-datepicker__year-select {
  height: 38px;
  padding: 8px 14px;
}

.pay-om-box {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  padding: 20px;
}
.alert-pob {
  background: #e9efff;
  border-radius: 20px;
  text-align: center;
  color: #333333;
  font-size: 14px;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert-pob i {
  color: #4258bf;
  margin: 0 10px 0 0;
  font-size: 16px;
}

.pop-radio input[type="radio"]:after {
  width: 13px;
  height: 13px;
  left: 0;
  top: 0;
  transform: none;
  bottom: 0;
  margin: auto;
  right: 0;
}
.payment-opt {
  padding: 40px 25px;
}

.payment-opt ul {
  padding: 0;
  list-style: none;
}

.payment-opt > ul > li {
  margin: 0 0 35px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pop-radio-img ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 340px;
}
.pop-radio {
  display: flex;
  align-items: center;
  letter-spacing: 0.8px;
  color: #000000;
  font-weight: 600;
}
.pop-radio input {
  margin: 0 20px 0 0;
}
.pop-radio-img ul li {
  margin: 0 5px;
}
.payment-opt > ul > li:last-child {
  margin-bottom: 0;
}
.omise_pay_body {
  padding: 0 25px;
}
.omisepop_in {
  background: #e9efff;
  padding: 40px;
  border-radius: 20px;
}
.omise_pay_body .form_sec .form-control {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.blue-pay-label {
  display: block;
  padding-bottom: 12px;
  color: #212529;
  letter-spacing: 0;
}
.blue-pay-label span {
  color: #ff2d16;
}
.uname-box,
.card-numbr-div {
  margin: 0 0 20px 0;
  max-width: 70%;
  position: relative;
}
.vb-hidden {
  visibility: hidden;
}
.expire_row_inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.expire_left {
  width: 31%;
}

.expire_mdl {
  width: 31%;
}

.expire_right {
  width: 31%;
}
.card-numbr-div .fa-lock {
  color: #4258bf;
  position: absolute;
  right: -35px;
  bottom: 20px;
  font-size: 20px;
}
.agree-txt {
  font-size: 13px;
  color: #333;
  width: calc(100% - 140px);
}

.agree-pay {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 25px 0 0 0;
}

.agree-txt .alt_btns {
  width: 140px;
  padding: 0 0 0 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #4258bf;
}

.agree-txt .alt_btns p {
  margin: 0 0 10px 0;
  line-height: 1;
}

.agree-txt .alt_btns .button {
  margin: 0;
  width: 100%;
}

.three-activity .up-accordion-content > ul > li {
  width: 30.90128755364807%;
  margin: 0 3.648068669527897% 20px 0 !important;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  display: block;
  margin: 0 0 10px 0;
}

.three-activity .up-accordion-content > ul {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  padding: 50px 40px 70px 40px;
}
.three-activity .up-accordion-content > ul > li:nth-child(3n) {
  margin-right: 0 !important;
}
.three-activity .up-accordion-content > ul > li .product-list-parent {
  height: calc(100% - 35px);
  border: 1px solid #4258bf;
}
.activities-add-btn {
  position: absolute;
  right: 40px;
  bottom: 20px;
}
.activities-add-btn .button {
  margin: 0;
  min-width: 150px;
}
.sticky-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 30px 0;
}

.sticky-options-lhs {
  width: 100%;
}

.sticky-options-rhs {
  width: 190px;
  text-align: right;
}

.sticky-options-lhs ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.sticky-options-lhs ul li {
  width: 32.5%;
  margin: 0 1% 10px 0;
  border-radius: 15px;
  overflow: hidden;
  transition: 0.3s linear all;
  padding: 10px;
  position: relative;
  border: 1px solid #4258bf;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sticky-options-lhs ul li:last-child {
  margin-right: 0;
}
.sop-img {
  width: 68px;
  height: 54px;
  border-radius: 10px;
  overflow: hidden;
}

.sop-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sop-text {
  width: calc(100% - 78px);
  padding: 0 30px 0 0;
  max-height: 54px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.sop-text h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  max-height: 54px;
  overflow: hidden;
  letter-spacing: 0;
}

.sop-text h5 a {
  color: #000;
}

.remove-sticky {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.cheader-with-text {
  padding: 0 0 25px 0;
}

.cheader-with-text h3 {
  margin-bottom: 3px;
}

.cheader-with-text p {
  margin: 0;
}
.things-change-pack .hsr-lhs-img {
  width: 210px;
  min-height: 140px;
}
.things-change-pack .hsr-lhs-desc {
  width: calc(100% - 210px);
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.things-change-pack .product-description-tag {
  margin: 16px 0 0 0;
}
.things-change-pack .hsr-rhs {
  text-align: right;
  align-items: flex-end;
}
.things-change-pack .hsr-rhs .button {
  margin: 10px 10px 0 0;
  width: auto;
  min-width: 130px;
}

.unique-package-info {
  width: 100%;
}

.p-deail-col-rhs.prd_imguplod {
  flex-wrap: wrap;
  display: flex;
  padding: 40px 0 0 0;
  width: 100%;
  justify-content: space-between;
}

.cover-div {
  width: 49%;
}

.cover-div ul.gallery-img {
  padding: 5px;
  list-style: none;
  display: grid;
  background: #f1f1f1;
  border-radius: 10px;
  margin: 20px 0 0 0;
  grid-template-columns: 1fr 1fr;
}

.cover-div ul.gallery-img li {
  padding: 5px;
  position: relative;
}

.cover-div ul.gallery-img li a {
  width: 20px;
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #000;
}

.cover-div ul.gallery-img li > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 120px;
  border-radius: 10px;
}
.add-new-box {
  border: 2px dashed #4258bf;
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.add-new-box-lhs p {
  margin: 0;
}

.add-new-box-lhs p span {
  color: #4258bf;
}

.add-new-box-lhs {
  font-size: 17px;
  font-weight: 500;
}
.more-link {
  display: inline-flex;
  color: #0000ee;
  letter-spacing: 0.8px;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 15px;
}

.more-link img {
  margin-left: 5px;
}
.social-media-parent {
  padding: 15px 0 0 0;
}
.mobile-banner-search {
  padding: 10px 0;
}
.mobile-banner-search .smie-em {
  width: 27px;
}
.cc-search {
  position: relative;
}
.mobile-banner-search {
  display: none;
}

.mobile-banner-search .m-search {
  margin-bottom: 0;
  padding-left: 50px;
}
.cc-img {
  position: absolute;
  left: 15px;
  top: 11px;
}
.cc-img1 {
  position: absolute;
  left: 15px;
  top: 20px;
  width: 20px;
}
a.date-package-popup {
  width: 18px;
  height: 18px;
  margin: 0;
  line-height: 1;
  right: 40px;
  top: 25px;
  position: absolute;
}
/* #pacakge_popup .mfp-close {
  display: none;
} */
.package-rhs-inner p {
  font-size: 12px;
  margin: 5px 0;
  line-height: 1.1;
}
.package-rhs-inner p i {
  margin-right: 5px;
}
.prd_imguplod .cover-div .form-control {
  padding: 8px 8px;
  color: #222;
  border-radius: 5px;
  border: 1px solid #4258bf;
  background: #fff;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  max-width: 50%;
}

.product-middle-uldiv {
  padding: 3px;
  margin: 0;
  background: #fff;
  border: 1px solid #e4e4e4;
  /* position: absolute; */
  top: 101%;
  left: 0;
  list-style: none;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
}

.product-middle-uldiv li {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
  border-radius: 2px;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 2px;
  padding: 3px;
  padding-left: 6px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-middle-uldiv li a {
  padding: 0 4px;
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-middle-uldiv li img {
  width: 7px;
  height: 7px;
}

.product-middle-uldiv li a:hover {
  background: #ffbdad;
  color: #de350b;
}
.gallerybig-img {
  width: 100%;
  margin: 20px 0 0 0;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 10px;
  max-width: 278px;
}
.gallerybig-img img {
  width: 100%;
  border-radius: 10px;
}
.done-btn .button {
  margin: 0;
  padding: 13px 27px;
  font-size: 13px;
  width: 140px;
  border-radius: 30px;
}

.done-btn {
  text-align: center;
  padding: 25px 0 0 0;
  border-top: 1px solid #ededed;
  margin-top: 25px;
}
.flag-stay span img {
  margin-left: 7px;
}
.ffs-icon {
  width: 22px;
  height: 19px;
}

.hfs-icon {
  width: 19px;
  height: 21px;
}

.cfs-icon {
  width: 20px;
  height: 17px;
}

.tfs-icon {
  width: 20px;
  height: 16px;
}
a.clr-close {
  width: 10px;
  height: 10px;
  display: block;
}

.close-list {
  position: absolute;
  top: 10px;
  right: 15px;
}
.unique-package-stay .hotel-search-result > ul > li.active {
  border: 1px solid #4258bf;
}

.uss-inner-slider .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  background: #d6d6d6;
  opacity: 0.5;
  border-radius: 4px;
  margin: 0 3px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease width;
}

.uss-inner-slider .owl-dots {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}
.uss-inner-slider .owl-dots .owl-dot.active {
  width: 28px;
  opacity: 1;
}
.stayholder {
  height: 100%;
}

.stayholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 230px;
}
.ribbon-left {
  position: absolute;
  display: block;
  font: 600 12px "Poppins", Arial, sans-serif;
  background: #f02e88;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  width: 230px;
  z-index: 100;
  transform: rotate(-45deg);
  top: 30px;
  left: -70px;
}

.cal-price .button {
  margin: 0 auto;
}
.cal-price {
  text-align: center;
  padding: 20px 0 0 0;
}
.view-more-link {
  top: 10px;
  position: relative;
  font-size: 14px;
}
.view-more-link a img {
  margin-right: 10px;
}
.view-more-link a {
  display: inline-flex;
  align-items: center;
}

.uss-about {
  margin: 0 0 0 0;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 6;
  transition: all 0.5s linear;
}
.uss-about.active {
  -webkit-line-clamp: unset;
}

.popup-ame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner-ame {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
}
.popup-inner-ame ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: right;
}
.popup-inner-ame ul li {
  margin-bottom: 10px;
}
.popup-inner-ame h5 {
  text-align: center;
  margin-top: 0;
}
.popup-inner-ame button {
  display: grid;
  flex-direction: column;
  align-items: end;
}
.popup-inner-ame input[type="checkbox"] {
  margin-right: 7px;
}
.popup-inner-ame .close-button {
  position: absolute;
  left: 0;
  bottom: 10px;
}
.transfer-disclaimer-popup {
  max-width: 580px;
}
.stay-list-popup {
  max-width: 100%;
}
.stay-list-popup.unique-package-stay {
  margin: 0 auto;
}
.mfp-close::after {
  width: 14px;
  height: 14px;
}
.land-banner {
  overflow: hidden;
  height: 680px;
}
.right-corner {
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.land-banner .container {
  height: 100%;
}
.land-banner .step-banner-content {
  position: relative;
  height: 100%;
}

.land-banner .step-banner-lhs {
  position: absolute;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  left: 0;
  align-items: flex-start;
  padding: 0;
}
.land-banner .step-banner-lhs .button {
  margin: 25px 0 0 0;
  padding: 16px 27px;
}
.passive-income {
  background: #f7f9ff;
  padding: 55px 0;
}
.passive-income .vacation-box-img {
  bottom: 0;
  top: 0;
  margin: auto;
  max-width: 540px;
  right: 0;
}
.passive-income .vacation-box-txt h3,
.ct-rhs h3,
.fiz-mid-lhs h3 {
  margin: 0;
  color: #010101;
  font-size: 35px;
  font-weight: 700;
}
.passive-income .vacation-box-txt em,
.ct-rhs em,
.fiz-mid-lhs h3 em {
  color: #4258bf;
  letter-spacing: 0;
}
.passive-income .vacation-box-txt span,
.ct-rhs span,
.fiz-mid-lhs span {
  color: #111733;
  font-size: 20px;
  font-weight: 400;
  opacity: 0.7;
  letter-spacing: 1px;
  margin-bottom: 20px;
  display: block;
}
.creator-tool {
  padding: 100px 0 60px;
}
.creator-tool .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ct-rhs {
  width: 345px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
}
.ct-lhs {
  width: calc(100% - 345px);
  padding: 0 40px 0 0;
}

.ct-rhs ul {
  padding: 25px 0 0 0;
  list-style: none;
}
.ct-rhs ul li {
  letter-spacing: 0.8px;
  color: #000;
  opacity: 0.7;
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
  margin: 0 0 17px 0;
}
.ct-rhs ul li:last-child {
  font-size: 14px;
}
.ct-rhs .button {
  margin-bottom: 0;
  padding: 20px 46px;
}

.media-box {
  background-color: #fff2e8;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  width: 46%;
}
.media-box-center {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
}
.comparsion-fiz {
  padding: 70px 0;
}
.mb-green {
  background-color: #e5fce5;
}
.media-box h4 {
  font-size: 25px;
  font-weight: 700;
}
.media-box h4 span {
  color: #4258bf;
}

.media-box ul {
  padding: 0;
  list-style: none;
}
.media-box ul li {
  letter-spacing: 0.32px;
  padding: 10px;
  font-size: 16px;
}
.media-box ul li:last-child {
  padding-bottom: 0;
}
.passive-income .vacation-box-btm {
  padding-left: 0;
  padding-right: 0;
}
.fiz-understand {
  background-color: #f7f9ff;
  padding: 80px 0;
}
.fiz-mid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fiz-mid-center h3 {
  color: #4258bf;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 2px 0;
}
.fiz-mid-center p {
  margin: 0;
  letter-spacing: 0.36px;
  font-size: 18px;
  color: #010101;
  font-weight: 500;
  font-style: italic;
}
.fiz-mid-center ul {
  padding: 0;
  list-style: none;
}

.fiz-mid-center ul li:first-child {
  margin: 0 0 30px 0;
}
.fiz-mid-rhs img {
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
.fiz-mid-rhs {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  width: 34%;
}
.fiz-mid-center {
  width: 22%;
}
.fiz-mid-lhs {
  width: 42%;
}
.fmr-img-two {
  margin: 40px 0 0 20%;
}
#createpackage .step-banner-whom .css-vj8t7z {
  padding-left: 50px;
}
#createpackage .step-banner-whom .css-2o5izw {
  padding-left: 50px;
}
#createpackage .css-10nd86i {
  margin-bottom: 15px;
}
.create-whom input[type="text"],
#createpackage .step-banner-stay input[type="text"] {
  padding-left: 50px;
}

.inner-ff {
  width: 100%;
  display: flex;
}
.ctable-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ctable-row-head {
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: 1px solid #e4e4e4;
}
.inner-ff > div,
.ctable-row-head > div {
  padding: 15px 10px;
}

.package-cell {
  width: 18%;
}
.action-cell {
  width: 5%;
}
.dest-cell {
  width: 13%;
}
.clicks-cell {
  width: 9%;
}
.bookings-count-cell {
  width: 9%;
}
.booking-cell {
  width: 13%;
}
.commission-cell {
  width: 13%;
}
.rating-cell {
  width: 7%;
}
.status-cell {
  width: 9%;
}
.action-cell a {
  display: block;
  padding: 4px 0 0 0;
}
.ctable-row .inner-ff:nth-child(even) {
  background: #f8f9ff;
}

.details-policy {
  padding: 10px 0 15px 0;
}
.details-policy a {
  display: flex;
  align-items: center;
}
.details-policy a span {
  margin-right: 5px;
  border-bottom: 1px solid #4258bf;
  line-height: 1.1;
  font-size: 14px;
}
.room-view-right {
  text-align: right;
  font-size: 12px;
}
.clearall {
  padding: 20px 0 0;
}
.things-do-list .pt-img {
  padding-bottom: 65.25%;
}
.things-do-list-search .choose-loc {
  width: 100%;
}
.choose-transport.things-do-list-search {
  max-width: 804px;
  position: relative;
}
.choose-transport.things-do-list-search .search-place {
  top: 105px;
}
.two-btn-merge {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.two-btn-merge .button {
  max-width: 100%;
  width: 48.5%;
  padding: 14px 10px;
  margin: 0;
  letter-spacing: 0.4px;
}

.na-ribon {
  background: #797979;
  padding: 5px 10px;
  position: absolute;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-radius: 0 0 10px 0;
}
.activity-not-available {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  cursor: not-allowed;
}
.disabled-link {
  pointer-events: none;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  cursor: not-allowed;
}
.hotel-moredetails-popup {
  max-width: 720px;
}
.hotel-moredetails-popup h4 {
  font-size: 16px;
  margin: 0px 0 15px 0;
  font-style: italic;
  padding: 10px 0 5px 0;
}
.hotel-moredetails-popup h5 {
  margin: 0 0 5px 0;
  padding: 10px 0 0;
  font-size: 16px;
}
.hotel-moredetails-popup .cp-header {
  margin: 0;
}
.title-nav .button {
  margin: 0;
}

.avlbt-checking img {
  max-width: 210px;
  margin: 0 0 20px 0;
}
.avlbt-checking {
  padding: 150px 10px 150px 10px;
}
.avlbt-checking p {
  margin: 0;
  font-weight: 500;
}

.omise-pay-now-qr-code {
  width: 100%;
  background: #e9efff;
  padding: 40px;
  border-radius: 20px;
  margin: 20px 0 0 0;
  text-align: center;
}
.omise-pay-now-qr-code .paynow-seconds {
  margin: 0;
  padding: 10px 0 15px;
}
.omise-pay-now-qr-code .paynow-notes h2 {
  font-size: 18px;
  margin: 10px 0 10px 0;
}

.blue-step {
  background-color: #ebf0ff;
  padding: 20px 0;
}
.stepper-wrapper {
  display: flex;
  justify-content: space-between;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #767676;
  width: 100%;
  top: 40px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #767676;
  width: 100%;
  top: 40px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #cad9ff;
  border: 10px solid #ebf0ff;
}

.stepper-item.completed .step-counter:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../images/greeen-tick.svg) no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.stepper-item.completed .step-counter img {
  display: none;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #08b371;
  width: 100%;
  top: 40px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.step-name {
  padding: 0;
}

.booking-gif img {
  max-width: 150px;
}
.activity-slot span {
  display: inline-flex;
  padding: 7px 14px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #bebebe;
  line-height: 1;
  font-size: 12px;
  margin: 0 5px 5px 0;
}

.activity-slot {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
}

.activity-slot .active {
  background: #d3dfff;
  border: 1px solid #d3dfff;
  color: #000000;
  font-weight: 500;
}
.more-less {
  margin: 5px 0 30px;
}

.honeymoon-policy a {
  background: #d6e1ff;
  color: #3d4fa5;
  border-color: #bdd0ff;
  text-transform: capitalize;
  font-size: 13px;
  display: inline-flex;
  padding: 8px 15px;
  line-height: 1;
  border-radius: 8px;
  font-weight: 500;
}
.honeymoon-policy {
  margin: 0 0 20px 0;
}
.honeymoon-list {
  list-style: none;
  padding: 0;
}
.honeymoon-list li {
  margin: 0 0 15px 0;
  position: relative;
  padding: 0 0 0 30px;
}
.honeymoon-list li:before {
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  background: url(../images/blue-circle.svg) no-repeat;
}
.hotel-moredetails-popup h3 {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: capitalize;
  padding: 0 0 15px 0;
}
.color-head-merge {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0px 0 10px 0; */
  justify-content: space-between;
  align-items: center;
}
.ptf-select-inner {
  display: none;
}

.ptf-options ul {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 15px;
}
.ptf-options ul li a {
  background: #4258bf;
  border: 1px solid #4258bf;
  padding: 10px 25px;
  display: flex;
  font-size: 16px;
  color: #fff;
  border-radius: 25px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  line-height: 1;
}
.active-options {
  position: absolute;
  background: #c04258;
  color: #fff;
  font-size: 10px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: -7px;
  top: -5px;
}
.ptf-options ul li a img {
  width: 20px;
  margin-left: 5px;
}
.filter-stick-popup {
  max-width: 580px;
  padding: 50px 25px 0px 25px;
}
.filter-action {
  text-align: center;
  border-top: 1px solid #e4e4e4;
  padding: 15px 0;
  line-height: 1;
  gap: 15px;
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 90%;
  background: #fff;
  position: absolute;
}
.filter-action .button {
  margin: 0;
  padding: 13px 15px;
  font-size: 13px;
  min-width: 120px;
}
.month-filter {
  align-items: center;
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}
.month-filter-font {
  margin: 0px 5px;
}
.month-showMoreBtn {
  color: #4258bf !important;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline !important;
}
.filter-body {
  display: flex;
  flex-wrap: wrap;
  height: 280px;
  overflow-y: auto;
  justify-content: space-between;
}
.filter-row {
  width: 100%;
  margin: 0 0 20px 0;
}

.filter-row h5 {
  padding: 0 0 15px 0;
  font-size: 17px;
  margin: 0px 0px 10px 0px;
}
.filter-stick-popup .cp-header h3 {
  font-size: 26px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
}

.viewMorePopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: 25%;
  height: 100vh;
  border-radius: 20px;
}
.viewMorePopup h3 {
  text-align: center;
  margin: 0 0 20px;
}

.viewMorePopup ul {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: scroll;
  height: 100vh;
}

.viewMorePopup ul li {
  margin: 0 auto 10px;
  text-align: start;
}

.view-more-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.view-more-close-icon img {
  width: 20px;
  height: 20px;
}
.unique-package .form-group .control-label em,
.pwd-body-form .form-group .control-label em {
  font-style: normal;
  color: #db0000;
}

.offer-card {
  background: #be2842;
  position: absolute;
  left: 20px;
  top: 20px;
  color: #fff;
  padding: 5px 12px;
  line-height: 1;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
}
.hsr-rhs .tl-span {
  font-size: 12px;
  color: #000;
}
.sr-review {
  margin-left: 5px;
}
.star-rating img {
  margin: 0 2px 0 0;
}
.things-do-list .product-img img {
  height: 140px;
  width: 100%;
  object-fit: cover;
}

.filter-row-ul {
  padding: 0;
  list-style: none;
}
.filter-row-ul li {
  position: relative;
  padding-left: 30px;
  margin: 0 0 13px 0;
  min-height: 20px;
}
.filter-row-ul li input {
  position: absolute;
  left: 0;
  top: 0;
}
.avlbt-checking p span {
  font-weight: bold;
  color: #4258bf;
}
.tvbox .form-group label em {
  color: #f00;
  font-style: normal;
}
.hotel-info-popup {
  max-width: 600px;
}
.hotel-info-popup .cp-body ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hotel-info-popup .cp-body ul li {
  position: relative;
  width: 49%;
  margin: 0 0 15px 0;
  display: flex;
}
.hotel-info-popup .cp-body ul li::before {
  content: "\f192";
  font-family: FontAwesome;
  color: #4258bf;
  margin: 0 12px 0 0;
}
.room-parent {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.created-av img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preferred-room {
  padding: 0 0 30px 0;
}
.preferred-room ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.preferred-room ul li a {
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
  border-bottom: 2px solid #fff;
  letter-spacing: 1px;
}
.preferred-room ul li {
  margin: 0 25px 0 0;
}
.preferred-room ul li.active a {
  color: #4258bf;
  border-color: #4258bf;
}
.profile-imgdiv a {
  height: 100%;
  display: block;
}
.profile-imgdiv a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.hbc-rhs .button {
  margin: 0;
}
.hbc-rhs .ghost-button {
  margin-right: 10px;
}
.header-back-continue {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flight-way-search.choose-stay {
  max-width: 620px;
}
.flight-way-search.choose-stay .choose-loc {
  width: 100%;
  margin: 0;
}

.suggestion-list {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgb(0 0 0/8%);
  padding: 20px 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  z-index: 9;
  overflow: auto;
  max-height: 320px;
}
.suggestion-list.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.suggestion-list li {
  margin: 0 0 15px 0;
  padding: 0 10px;
}
.suggestion-list li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.suggestion-list li figure {
  height: 36px;
}
.bk-modify {
  background-color: #f8f9ff;
  margin: 30px 0;
  padding: 25px 25px 15px;
}
.bk-modify ul {
  justify-content: center;
}
.bk-modify.why-book ul li {
  margin: 0 0 7px 0;
}
.travler-box .react-tel-input .form-control {
  border-radius: 12px !important;
  border: 1px solid #e0e0e0 !important;
  height: 44px !important;
}
.unique-package-rhs .react-tel-input .form-control {
  border-width: 1px !important;
  height: 44px !important;
}
.travler-box .react-tel-input .flag-dropdown {
  border: 1px solid #e0e0e0 !important;
  border-radius: 12px 0 0 12px !important;
}
.unique-package-rhs .react-tel-input .flag-dropdown {
  border-width: 1px !important;
}
.react-tel-input .country-list .country {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.tb-inner-btm .iss-co {
  width: 35%;
}
.tb-inner-btm .iss-po {
  width: 30%;
}
.tb-inner-btm .iss-pe {
  width: 30%;
}

.processpayment-chk {
  text-align: center;
}
.processpayment-chk .processpayment {
  max-width: 70%;
  margin: 0 auto 10px;
  width: 100%;
}
.processpayment-chk .agree-txt {
  width: 100%;
  font-size: 12px;
  color: #727272;
}
.processpayment-chk .agree-txt p {
  display: inline;
}
.header-main {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-main .heading {
  color: #333130;
  font-size: 36px;
  font-weight: 600;
  padding: 0 15px;
}
.header-main .divider {
  flex: 0.45;
  height: 1px;
  background: transparent
    linear-gradient(270deg, #ffffff 0%, #c69f53 49%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
}
.sub-header-main {
  display: flex;
  align-items: center;
  padding: 0rem 40px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  border: 0.1px solid #d9d9d9;
}
.sub-header-main .col1 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub-header-main .divider {
  border-left: 0.1px solid #707070;
  height: 44px;
}
.sub-header-main .col2 {
  flex: 0.4;
  display: flex;
  justify-content: space-between;
}
.sub-header-main .col3 {
  flex: 0.35;
}
.sub-header-main .col2 .actionBtn,
.sub-header-main .col2 .searchBtn {
  color: #4258bf;
  font-size: 14px;
  font-weight: 500;
  text-transform: initial;
  box-shadow: none;
  padding: 0.2rem 1.3rem;
}
.sub-header-main .col2 .active {
  border: 0.4px solid #4258bf;
  border-radius: 24px;
  background: #f7f9ff;
}
.sub-header-main .col1 .location-list {
  width: calc(100vw - 250px);
}
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--scroll-container {
  margin-top: 0px !important;
}
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--arrow-left,
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--arrow-right {
  align-items: center;
}
.sub-header-main .col1 .location-list .menu > li > a {
  padding-top: 0px;
}
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--arrow-left,
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--arrow-right {
  width: 50px;
}
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--inner-wrapper,
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--scroll-container {
  overflow: hidden;
}
.sub-header-main
  .col1
  .location-list
  .react-horizontal-scrolling-menu--scroll-container {
  margin-top: 5px;
}
.sub-header-main .col1 .location-list::-webkit-scrollbar {
  display: none;
}
.sub-header-main .col1 .viewBtn {
  text-transform: initial;
  box-shadow: none;
  color: #4258bf;
  font-size: 14px;
  font-weight: 500;
}
.flight-routes-main {
  margin: 1.5rem 0px;
}
.flight-routes-main .content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 25px;
}
.flight-routes-main .content .row {
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dfe1e5;
  border-radius: 16px;
  padding: 10px;
}
.flight-routes-main .content .row:hover {
  background: #fff8f0;
  border-color: rgba(189, 208, 255, 0.25);
}
.flight-routes-main .content .row .col1 {
  flex: 0.2;
}
.flight-routes-main .content .row .col1 img {
  width: 70px;
  height: 70px;
  border-radius: 12px;
}
.flight-routes-main .content .row .col2 {
  flex: 0.8;
  flex-direction: column;
  padding-left: 10px;
}
.flight-routes-main .content .row .col2 .from {
  color: #3f4d65;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.flight-routes-main .content .row .col2 .from img {
  margin-left: 5px;
}
.flight-routes-main .content .row .col2 .to {
  color: #33425b;
  font-size: 20px;
  font-weight: 500;
}
.flight-routes-main .content .row .col2 .price {
  color: #6a7487;
  font-size: 12px;
}
.flight-routes-main .content .row .col2 .price span {
  color: #4258bf;
  font-size: 14px;
  font-weight: 600;
}
.flight-routes-main .content .row .col3 .discount {
  font-size: 12px;
  font-weight: 600;
  background: transparent linear-gradient(91deg, #ff1c00 0%, #ff8f10 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
  &::after {
    content: " *";
    color: #fff;
  }
}
.flight-routes-main .content .row .col3 {
  flex: 0.3;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}
.reviews-main {
  display: flex;
  background-color: #f6f7ff;
  height: 413px;
  border-radius: 20px;
  margin-top: 2rem;
}
.reviews-main .col1 {
  flex: 0.6;
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.reviews-main .col1 img {
  position: absolute;
  top: 0;
  left: 0;
}
.reviews-main .col1 .text {
  color: #4258bf;
  font-size: 48px;
}
.reviews-main .col1 .text1 {
  color: #2a387a;
  font-size: 73px;
  font-weight: 600;
}
.reviews-main .col2 {
  flex: 0.4;
  overflow-y: auto;
  padding: 0 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.reviews-main .col2 .review-row {
  display: flex;
  padding: 1rem;
  flex: 1;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 1rem;
}
.reviews-main .col2 .review-row .col3 {
  flex: 0.15;
}
.reviews-main .col2 .review-row .col3 img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.reviews-main .col2 .review-row .col4 {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reviews-main .col2 .review-row .col4 .name {
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #21314d;
  font-weight: 500;
  margin-bottom: 10px;
}
.reviews-main .col2 .review-row .col4 .review {
  font-size: 16px;
  color: #4e5b71;
}
.reviews-main .col2 .review-row .col4 .MuiAvatarGroup-root {
  margin-top: 5px;
}
.reviews-main .col2 .review-row .col4 .name .dot {
  width: 5px;
  height: 5px;
  margin: 0 5px;
  background: #b2b7c1 0% 0% no-repeat padding-box;
  border-radius: 50%;
}
.company-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 3.5rem 2.5rem;
  margin-top: 2rem;
  background: transparent linear-gradient(90deg, #4258bf 0%, #212c60 100%) 0% 0%
    no-repeat padding-box;
}
.company-banner .text {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
.company-banner .text span {
  font-style: italic;
}
.destination-list-main .row .location-row {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: left;
  padding: 5px 10px;
  border: 0.2px solid transparent;
  gap: 14px;
}
.destination-list-main .row .location-row .col1 {
  /* flex: 0.9; */
  display: flex;
  align-items: center;
}
.destination-list-main .row .location-row .col1 img {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}
.destination-list-main .row .location-row .col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.2rem;
  width: auto;
}
.destination-list-main .row .location-row .col2 .location {
  color: #5a6080;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.destination-list-main .row .location-row .col2 .country {
  color: #33425b;
  font-size: 14px;
  font-weight: 600;
}
.destination-list-main .row .location-row:hover {
  color: #4258bf;
  cursor: pointer;
  background: rgba(189, 208, 255, 0.25);
  border-radius: 12px;
  border: 0.2px solid #4258bf;
}
.step-banner-rounded {
  position: relative;
  /* background-color: rgba(241, 244, 255, 0.35); */
  padding: 40px 0;
  /* overflow: hidden; */
}
.step-banner-rounded.vacation-bg {
  background-image: url("https://images.fizota.com/Image-landing.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 768px) {
  .step-banner-rounded.vacation-bg {
    background-image: url("https://images.fizota.com/Image-landing.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

/* (mobile) */
@media (max-width: 767px) {
  .step-banner-rounded.vacation-bg {
    background-image: url("https://images.fizota.com/Vacation.webp.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.step-banner-rounded.ttd-bg {
  background-image: url("https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/new/TTD.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.step-banner-rounded .container {
  height: 100%;
}
.hero-content-top h1 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.4;
  margin-bottom: 30px;
  color: #fff;
}
.hero-content-top h1 span {
  color: #fff;
  display: block;
}
.hero-content-top .smie-em {
  width: 52px;
  margin-left: 10px;
  position: relative;
  top: -2px;
}
.hero-content-top h1 em {
  font-style: normal;
  display: flex;
  align-items: center;
}
.hero-content-search input {
  background: #f2f5ff;
  margin: 0;
  height: 64px;
  border-radius: 24px;
  font-size: 16px;
}
.hero-content-search {
  /* padding: 12px; */
  background: #fff;
  box-shadow: rgba(66, 88, 191, 0.28) 0px 0px 8px 0px;
  border-radius: 20px;
  max-width: 720px;
}
.img-abs {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 518px;
  margin: auto;
}

.img-fl {
  width: 517px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.img-fl1 {
  right: -52px;
  top: 103px;
}
.img-fl1,
.img-fl2,
.img-fl3,
.img-fl4 {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}
.img-fl4 {
  bottom: 16px;
  right: 104px;
  width: 135px;
  height: 135px;
}

.img-fl2 {
  width: 209px;
  height: 209px;
  left: 12px;
  top: 46px;
}
.img-fl3 {
  left: 1px;
  bottom: 74px;
  width: 220px;
  height: 220px;
}

.hero-content-promt {
  padding: 25px 0 0 0;
}
.hero-content-promt ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.hero-content-promt ul li {
  margin: 0 20px 10px 0;
}

.hero-content-promt ul li a {
  border-radius: 12px;
  font-weight: normal;
  padding: 16px 22px;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #111733;
  font-size: 14px;
  background: #fff;
  transition: 0.3s linear all;
}

.hero-content-promt ul li a img {
  width: 15px;
  height: 15px;
  margin-left: 14px;
}
.hero-content-promt ul li a:hover {
  background: #bdd0ff;
  color: #000;
}
.hero-quote {
  max-width: 400px;
  font-size: 12px;
  font-style: italic;
  margin: 0;
  position: absolute;
  bottom: 40px;
  left: 0;
}
.hero-quote p {
  margin: 0;
}
.hero-quote span {
  color: #4258bf;
}
.hero-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 0 7rem;
  z-index: 2;
}
.hero-quote {
  max-width: 400px;
  font-size: 12px;
  font-style: italic;
  margin: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
}
.step-banner-steps {
  max-width: 920px;
  margin: 0 auto;
  padding: 100px 0;
}
.step-banner-steps .container .error-msg {
  color: red;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
}
.center-promt ul {
  justify-content: center;
}
.step-content-search .platform-step {
  padding: 0px;
}
.step-content-search .platform-step ul {
  justify-content: center;
}
.step-content-search .platform-step ul li {
  width: 41%;
  text-align: left;
}
.step-content-search .platform-step ul li figure img {
  height: 65px;
}
.step-content-search .platform-step ul li:first-child {
  margin-right: 2rem;
}
.step-content-search .platform-step ul li.active {
  border: 1px solid #4258bf;
}
.step-content-search .dd-text {
  margin: 0;
  height: 68px;
  border-radius: 15px;
  font-size: 16px;
  border: 0;
  padding: 8px 40px 8px 80px;
  font-weight: 500;
  background-color: #f7f9ff;
}
.for-step-rel {
  padding: 10px;
}
.step-content-search {
  box-shadow: rgba(66, 88, 191, 0.25) 0px 5px 26px 0px;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
}
.step-content-search .month-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  list-style: none;
  padding: 2rem 4rem;
}
.step-content-search .month-list .month-box {
  border-radius: 12px;
  border: 1px solid #4258bf;
  font-weight: 500;
  padding: 16px 22px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111733;
  font-size: 14px;
  background: #fff;
  cursor: pointer;
}
.step-content-search .month-list .month-box.active {
  background: #4258bf;
  color: #fff;
}
.s-title h2 {
  color: #111733;
  font-size: 28px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 0 0 10px;
}
.s-title-back {
  padding: 0 0 15px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.center-promt {
  padding: 40px 0 0 0;
}
.search-glass {
  position: absolute;
  left: 40px;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}
.step-indicator-merge {
  padding: 0 0 40px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.step-indicator ul {
  padding: 0;
  list-style: none;
  display: flex;
}
.step-indicator ul li {
  margin: 0 12px 0 0;
  cursor: pointer;
}
.step-indicator ul li span {
  width: 16px;
  height: 16px;
  background: #e6ecff;
  border-radius: 10px;
  overflow: hidden;

  transition: 0.3s ease width;
  margin: 0 12px 0 0;
  display: block;
}
.step-indicator ul li.active span {
  width: 70px;
  opacity: 1;
  background: #4258bf;
}

.search-with-img-result ul {
  padding: 0px 10px 0px;
  list-style: none;
}
.search-with-img-result ul li {
  margin: 0 0 10px 0;
}
.search-with-img-result ul li span {
  display: flex;
  padding: 10px;
  color: #111733;
  font-weight: 500;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.search-with-img-result ul li a img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.search-with-img-result ul li {
  margin: 0 0 10px 0;
}
.search-with-img-result ul li span {
  width: calc(100% - 70px);
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
}
.search-with-img-result ul li span em {
  color: #999;
  font-style: normal;
  font-size: 15px;
}
.search-with-img-result ul li span strong {
  font-weight: 500;
}
.search-with-img-result ul li a:hover,
.search-with-img-result ul li.active a {
  background: #f7f9ff;
}
.search-with-img-result ul li a:hover figure {
  background: #efedfd;
}
.s-t-back-link {
  color: #c4cae6;
  font-size: 24px;
  font-weight: 500;
  position: relative;
  padding-left: 22px;
  line-height: 0.8;
}
.s-t-back {
  display: flex;
  align-items: center;
}
.s-t-back-link:before {
  position: absolute;
  left: 0;
  top: 1px;
  content: "";
  background: url(../images/back-grey.svg) no-repeat;
  background-size: contain;
  width: 11px;
  height: 19px;
}
.s-t-back-link:hover {
  color: #4258bf;
}
.s-t-back-link:hover:before {
  background: url(../images/back-grey-blue.svg) no-repeat;
  background-size: contain;
}

.hmd-trip span {
  border-radius: 10px;
  background-color: #4258bf;
  color: #fff;
  padding: 9px 10px;
  font-size: 12px;
  letter-spacing: 0.8px;
  min-width: 120px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  margin-left: 10px;
}
.s-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.step-content-passenger {
  padding: 20px 10px 20px 10px;
}
.step-content-passenger .passenger-body {
  padding: 0;
}
.step-content-passenger .passenger-body .qty-bx {
  width: 140px;
  padding: 0 40px;
  height: 40px;
}
.step-content-passenger .passenger-body .qty-bx span {
  background: #e6ecff;
  border: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-content-passenger .passenger-body .qty-bx .qty-input {
  height: 40px;
  color: #111733;
  font-weight: 600;
  font-size: 18px;
  background: none;
}
.step-content-passenger .passenger-body .qty-bx span img {
  width: 18px;
}
.step-content-passenger .sel-quality {
  font-weight: 600;
  font-size: 20px;
}
.step-content-passenger .addcart_row {
  color: #111733;
  padding: 0;
  margin: 0;
}
.step-content-passenger .passenger-body .sel-quality .price-select {
  color: #111733;
  font-size: 12px;
}
.uss-choice > ul > li.active .rcf-rhs .rmv-button {
  border-color: #db4437;
  background-color: #fff;
  color: #db4437;
}

.step-content-passenger .passenger-body ul li {
  padding: 25px 40px;
  margin: 0 0 10px 0;
  background: #fff;
  border-radius: 15px;
}
.step-content-passenger .passenger-body ul li:hover,
.step-content-passenger .passenger-body ul li.active {
  background: #f7f9ff;
}
.step-content-passenger .passenger-body .age-sel > div {
  margin: 0 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.step-content-passenger .passenger-body .age-sel > div:last-child {
  margin-bottom: 0;
}
.step-content-passenger .passenger-body .age-sel {
  padding: 40px 0 0 0;
}
.step-content-passenger .child-age-select label span {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: #111733;
  padding-top: 3px;
}
.step-content-passenger .child-age-select label {
  color: #4258bf;
  font-size: 20px;
  margin: 0;
}
.step-content-passenger .child-age-select {
  margin: 0;
}
body .step-content-passenger .css-vj8t7z {
  border-color: #fff;
  border-radius: 8px;
}
body .step-content-passenger .css-10nd86i {
  width: 160px;
}
body .step-content-passenger .css-2o5izw {
  border: 1px solid #fff;
  border-radius: 8px;
}
body .step-content-passenger .css-d8oujb {
  display: none;
}
body .step-content-passenger .css-1ep9fjw,
body .step-content-passenger .css-1uq0kb5 {
  padding: 0;
  color: #4258bf;
}
body .step-content-passenger .css-xp4uvy {
  color: #111733;
  font-size: 16px;
  font-weight: 500;
}
body .step-content-passenger .css-1hwfws3 {
  top: 0;
}
/* new passenger popup style */
.passenger-popup-cntnr {
  padding: 1.5rem;
}
.passenger-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #21314d;
}
.passenger-popup-cntnr .passenger-body ul {
  padding: 1.5rem 0 0 0;
}
.passenger-popup-cntnr .passenger-body ul li {
  padding: 0 0 1rem 0;
  margin: 0;
}
.passenger-popup-cntnr .passenger-body ul li:hover {
  background: #ffffff;
}
.passenger-popup-cntnr .sel-quality {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #243069;
}
.passenger-popup-cntnr .passenger-aged {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #4e5b71 !important;
  display: block;
  margin: 0.5rem 0 !important;
}
.passenger-popup-cntnr .error {
  color: #ef0000 !important;
  font-size: 12px !important;
}
.passenger-popup-cntnr .btn-min-width {
  padding: 0;
}
@media (max-width: 480px) {
  .passenger-popup-cntnr {
    padding: 1.5rem !important;
  }
  .passenger-popup-cntnr .passenger-body {
    padding: 0 !important;
  }
  .passenger-popup-cntnr .passenger-body ul li {
    padding: 0 0 1rem 0 !important;
  }
  .passenger-popup-cntnr .btn-min-width .button {
    width: 100%;
  }
}

.img-fl1 img,
.img-fl2 img,
.img-fl3 img,
.img-fl4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-fl1 {
  width: 350px;
  height: 350px;
}
.img-fl1 img {
  object-position: bottom;
}
.img-fl4 img {
  object-position: right;
}
.destination-popup {
  max-width: 800px;
}
.login-popup {
  max-width: 500px;
}
.search-popup {
  max-width: 580px;
  /* min-height: 480px; */
  padding: 1.5rem 1rem 1rem;
}
.search-popup > h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #21314d;
  margin: 0;
}
.search-pop {
  max-width: 500px;
  width: 100%;
  position: relative;
}
.search-pop input {
  border: 0.6px solid #b2b7c1;
  border-radius: 12px;
  color: #122441;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  height: auto;
  padding: 1rem 1rem 1rem 3rem;
  margin: 0;
}
.search-pop img {
  width: 14px;
  position: absolute;
  left: 14px;
  top: 15px;
}
.search-popup .search-pop {
  max-width: 100%;
  margin-top: 1rem;
}
.search-popup .title {
  color: #373b4d;
  font-size: 16px;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
}
.search-popup .destin-title {
  margin: 2rem 0 0 0;
}
.search-popup .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.search-popup .actions .clearBtn {
  color: #5a6080;
  font-size: 16px;
  text-transform: initial;
  box-shadow: none;
}
.search-popup .actions .applyBtn {
  color: #fff;
  background: #4258bf;
  font-size: 16px;
  text-transform: initial;
  box-shadow: none;
  border-radius: 24px;
  padding: 10px 4.3rem;
}
@media (min-width: 481px) {
  .primary-button:hover {
    background-color: #4258bf50;
    cursor: pointer;
  }
}
@media (max-width: 480px) {
  .primary-button {
    width: 100%;
  }
}
.primary-button {
  background-color: #4258bf !important;
  padding: 0.5rem 1.5rem !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border: none !important;
  border-radius: 48px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}
.search-popup .option-row {
  display: flex;
  margin: 1rem 0;
}
.search-popup .option-row1 {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  align-items: center;
}
.search-popup .option-row1 input {
  width: 47%;
  border-radius: 28px;
  border: 0.2px solid #4258bf;
}
.search-popup .option-row1 .option {
  margin-bottom: 1rem;
}
.search-popup .option {
  border: 1.5px solid #dbe2ff;
  border-radius: 8px;
  padding: 12px 25px;
  font-size: 14px;
  color: #33425b;
  white-space: nowrap;
  margin-right: 1.5rem;
  font-weight: 400;
  cursor: pointer;
}
.search-popup .active {
  border: 1.5px solid #dbe2ff;
  background: #f5f8ff;
  font-weight: 500;
  color: #4258bf;
}
.drs-parent ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.drs-parent ul li {
  margin: 0 20px 15px 0;
  position: relative;
}
.drs-parent ul li a {
  border: 1px solid #4258bf;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 5px 20px 5px 5px;
  color: #342f41;
  font-size: 12px;
  align-items: center;
  transition: 0.3s linear all;
}
.drs-parent ul li a img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.drs-parent h4 {
  font-weight: 500;
  letter-spacing: 0;
  font-size: 18px;
  margin: 0 0 15px 0;
}
.drs-parent {
  padding: 0 0 15px 0;
}
.drs-parent ul li a:hover {
  background: #4258bf;
  color: #fff;
}
.destin-title h3 {
  letter-spacing: 0;
  margin: 0 0 15px 0;
}
.destin-reult-show {
  height: 355px;
  padding: 40px 0 0 0;
  overflow-y: scroll;
}

.package-top-filters .filter-row {
  max-width: 240px;
  display: flex;
  align-items: center;
  margin: 0;
}

.package-top-filters .filter-row h5 {
  padding-bottom: 0;
  font-size: 15px;
  margin: 0 10px 0 0;
  white-space: nowrap;
}

.package-top-filters .filter-row select {
  margin: 0;
  height: 38px;
  padding: 4px 12px;
  font-size: 13px;
  border: 1px solid #c1c1c1;
}
.Airline-Image {
  border-radius: 4px;
}
.ufs-inner-btm-lhs h5,
.ufs-inner-btm-rhs h5 {
  margin-bottom: 15px;
  font-size: 15px;
}
.ufs-inner-btm-lhs h5 {
  margin-top: 10px;
}
.ufs-inner-btm-title h4 {
  font-size: 13px;
  background: #ebeeff;
  display: inline-flex;
  padding: 6px 15px;
  line-height: 1;
  border-radius: 5px;
  margin: 0 0 10px 0;
}
.mb35 {
  margin-bottom: 40px;
}
.timer-rhs {
  position: relative;
}
.pac-inner-a h4 {
  letter-spacing: 0.8px;
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #000000;
}
.pac-inner-a {
  max-width: 500px;
}
.timer-rhs img {
  margin: 0 10px 0 0;
  width: 17px;
}
.clk-red {
  display: inline-flex;
  align-items: center;
  background: #ffcfc9;
  color: #ff1c00;
  padding: 10px 12px;
  min-width: 120px;
  justify-content: center;
  border-radius: 15px;
  letter-spacing: 0.18px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  font-weight: 600;
  font-size: 17px;
  line-height: 1;
}
.search-with-img-result {
  max-height: 300px;
}
#search-popup .search-with-img-result {
  max-height: 460px;
}
#calendar-date-popup .rdrMonthAndYearWrapper {
  align-items: flex-start;
}
#calendar-date-popup .searchContainer__bottomSheetMain__container__date {
  margin-top: 0px;
}
#calendar-date-popup .step-content-calender .rdrCalendarWrapper {
  padding: 10px 0px 30px 0px;
}
#calendar-date-popup .title {
  color: #111733;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2rem;
}
.step-content-calender .rdrDay {
  box-shadow: none;
  letter-spacing: 0;
  margin: 0;
  border-radius: 0;
  line-height: 5em;
  height: 5em;
}
.step-content-calender .rdrNextPrevButton {
  box-shadow: none;
  letter-spacing: 0;
}
.step-content-calender .rdrMonthAndYearPickers select {
  height: auto;
  margin: 0;
}
.step-content-calender .rdrCalendarWrapper {
  width: 100%;
  padding: 10px 40px 30px 40px;
  position: relative;
}
.step-content-calender .rdrMonth {
  width: 50%;
}
.step-content-calender .rdrDayNumber {
  font-weight: 500;
}
.step-content-calender .rdrMonthName {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}
.step-content-calender .rdrWeekDay {
  font-weight: 500;
  color: #4258bf;
}

.step-content-calender .rdrStartEdge {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.step-content-calender .rdrEndEdge {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.step-content-calender .rdrSelected {
  border-radius: 6px;
}

.step-content-calender .rdrDayStartOfMonth .rdrInRange,
.step-content-calender .rdrDayStartOfMonth .rdrEndEdge,
.step-content-calender .rdrDayStartOfWeek .rdrInRange,
.step-content-calender .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.step-content-calender .rdrDayEndOfMonth .rdrInRange,
.step-content-calender .rdrDayEndOfMonth .rdrStartEdge,
.step-content-calender .rdrDayEndOfWeek .rdrInRange,
.step-content-calender .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.step-content-calender .rdrDayStartOfMonth .rdrDayInPreview,
.step-content-calender .rdrDayStartOfMonth .rdrDayEndPreview,
.step-content-calender .rdrDayStartOfWeek .rdrDayInPreview,
.step-content-calender .rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.step-content-calender .rdrDayEndOfMonth .rdrDayInPreview,
.step-content-calender .rdrDayEndOfMonth .rdrDayStartPreview,
.step-content-calender .rdrDayEndOfWeek .rdrDayInPreview,
.step-content-calender .rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.step-content-calender .rdrDayStartPreview {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.step-content-calender .rdrDayEndPreview {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.step-content-calender .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: 600;
}
.step-content-calender .rdrStaticRange:hover .rdrStaticRangeLabel,
.step-content-calender .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: red;
}

.step-content-calender
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  content: "";
  border: 1px solid red;
  border-radius: 6px;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.step-content-calender .rdrInRange {
  background: #e6ecff;
}
.step-content-calender .rdrDateDisplayWrapper {
  display: none;
}
.step-content-calender .rdrMonth .rdrWeekDays {
  margin: 0 0 20px 0;
}
.step-content-calender .rdrNextPrevButton {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background-color: #f7f9ff;
  margin: 0;
}
.step-content-calender .rdrNextPrevButton i {
  display: none;
}

.range-bg {
  margin: 0 auto;
  height: 68px;
  border-radius: 15px;
  font-size: 16px;
  border: 0;
  padding: 8px 20px 8px 20px;
  font-weight: 500;
  background-color: #f7f9ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range-bg-parent {
  padding: 10px;
}
.step-content-calender .rdrNextPrevButton:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../images/nav_arrow.svg) no-repeat;
  background-size: contain;
  width: 9px;
  height: 14px;
  top: 50%;
  margin-top: -7px;
}
.step-content-calender .rdrNextButton:after {
  transform: rotate(180deg);
}
.btn-min-width {
  padding: 40px 0 0 0;
}

.btn-min-width .button {
  min-width: 180px;
  margin-bottom: 0;
  padding: 16px 27px;
}
.search-with-icon-result figure {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;
  background: #f8f7ff;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4258bf;
}
.step-content-passenger .passenger-body ul li.number-room {
  background: #f3f6ff;
}
.ctable-scroll {
  height: 52vh;
}
.search-with-padd {
  padding: 20px 0 30px 0;
}
.mCSB_scrollTools .mCSB_draggerRail,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 20px;
}
.search-with-padd .mCSB_scrollTools {
  right: 10px;
}
.search-with-padd-col {
  display: flex;
  align-items: center;
  justify-content: left;
}
.step-content-calender span.rdrMonthAndYearPickers {
  display: none;
}
.step-content-search .rdrMonthAndYearWrapper {
  height: auto;
  padding-top: 0;
  position: absolute;
  top: 18px;
  right: 40px;
  left: 40px;
  margin: auto;
}
.step-indicator ul li img {
  width: 16px;
  height: 16px;
}
.step-indicator ul li .active-st {
  display: none;
}
.step-indicator ul li.active .active-st {
  display: block;
}
.step-indicator ul li.active .normal-st {
  display: none;
}
.selecting-content {
  padding: 0 0 30px 0;
}
.selecting-content ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.selecting-content ul li {
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  color: rgba(17, 23, 51, 0.7);
  font-size: 15px;
  padding: 9px 45px 9px 22px;
  position: relative;
  margin: 0 20px 10px 0;
  cursor: pointer;
  transition: 0.3s linear all;
}
.remove-sl {
  width: 10px;
  line-height: 1;
  position: absolute;
  right: 25px;
  top: 13px;
  display: flex;
}
.remove-sl img {
  width: 10px;
}
.selecting-content ul li:hover {
  background: #ffffff;
}
.trip-type--active .css-1hbvpl3-MuiSvgIcon-root {
  color: #4258bf !important;
}
.trip-type--normal .css-1hbvpl3-MuiSvgIcon-root {
  color: #ffffff !important;
}
.creator-profile .admin-profile-rhs {
  width: 100%;
}
.creator-profile .profile-detail-left {
  width: 310px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  border: 1px solid #4258bf;
  border-radius: 12px;
  background: #fff;
  min-height: 350px;
  text-align: center;
  padding: 30px 40px;
}
.creator-profile .profile-detail-right {
  width: calc(100% - 370px);
  border: 0;
  padding: 0;
}
.creator-profile .profile-detail-innerdiv {
  align-items: flex-start;
}
.creator-profile .profile-edtbtn {
  margin: 0;
}
.creator-profile .profile-detail-right li:first-child {
  padding-top: 0px;
}
.creator-profile .profile-imgdiv {
  margin: 0 auto;
}
.cfie-rate-cre {
  border-radius: 5px;
  background-color: #d3dfff;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #444;
  padding: 8px 10px;
}
.creator-details-edit .title-with-nav {
  width: 100%;
}
.covers-custm input[type="file"] {
  padding: 16px 20px;
  color: #222;
  border-radius: 5px;
  border: 1px solid #4258bf;
  background: #fff;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  position: relative;
  height: 54px;
  font-family: "Poppins", sans-serif;
}
.covers-custm input[type="file"]::file-selector-button {
  border: none;
  background: #4258bf;
  padding: 5px 20px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  position: absolute;
  right: 10px;
  top: 10px;
  bottom: 10px;
  margin: auto;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

.covers-custm input[type="file"]::file-selector-button:hover {
  background: #bdd0ff;
  color: #000;
}
.covers-custm span {
  color: #868e96;
  font-size: 12px;
  display: block;
  padding: 5px 0 0 0;
}
.height-txt textarea {
  margin: 0;
  height: 168px;
}
.upload-custm {
  margin-bottom: 0;
}
.social-profile-details {
  width: 100%;
  padding: 30px 0 0;
}
.soc-off {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.button.soc-btn {
  border: 1px solid #4258bf;
  background-color: #4258bf;
  border-radius: 5px;
  margin: 0;
  padding: 2px 2px;
  width: 44px;
  height: 44px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  font-weight: normal;
  line-height: 1.1;
  /* position: absolute; */
  right: 0;
  top: 26px;
}
.button.soc-btn:hover {
  background: #bdd0ff;
}
.soc-add {
  padding-right: 64px;
}
.without-chk-save,
.with-chk-save {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  color: #454545;
  padding: 20px 0 0 0;
}

.without-chk-save p,
.with-chk-save p {
  letter-spacing: 0.12px;
  font-size: 12px;
  margin: 0;
  width: calc(100% - 185px);
}

.without-chk-save .button {
  margin: 0;
  padding: 14px 18px;
  width: 148px;
}

.without-chk-save p span {
  color: #db0000;
}
.password-con-box {
  box-shadow: 0 10px 40px rgba(140, 140, 140, 0.16);
  border-radius: 20px;
  border: 1px solid #4258bf;
  padding: 30px;
  position: relative;
  margin: 38px 0;
}
.pwd-body-form {
  max-width: 450px;
}
.dis-button {
  border-radius: 10px;
  background-color: #fff3f2;
  border: 1px solid #fff3f2;
  color: #db4437;
  font-size: 14px;
  box-shadow: none;
  padding: 14px 15px;
  width: 208px;
  margin: 0;
}
.dis-button:hover {
  background-color: #db4437;
  border: 1px solid #db4437;
  color: #fff;
}
.with-chk-save {
  position: relative;
}
.with-chk-save p {
  width: calc(100% - 285px);
  display: flex;
  align-items: center;
  padding: 0 0 0 40px;
  position: relative;
}
.with-chk-save input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 2px;
}
.deactive-form-txt {
  padding: 0 0 20px 0;
  font-size: 14px;
}

.deactive-form-txt ul {
  padding: 0 0 0 30px;
}

.deactive-form-txt ul li {
  margin: 0 0 8px 0;
}

.deactive-form-txt h6 {
  font-weight: 500;
  letter-spacing: 0;
}
.deactive-form .form-group {
  max-width: 680px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.deactive-form .form-group label.control-label {
  width: 55%;
  padding: 0;
}

.deactive-form select {
  width: 42%;
  margin: 0;
}
.upl-full {
  width: 100%;
}
.fg-quarter {
  width: 48%;
}
.upl-full select {
  margin: 0;
}
.payout-ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.payout-ul li {
  box-shadow: 0 10px 40px rgba(140, 140, 140, 0.16);
  border-radius: 20px;
  border: 1px solid #4258bf;
  padding: 20px;
  position: relative;
  width: 48.5%;
}

.payedit .button img {
  width: 12px;
  margin: 0 3px 0 0;
}

.payedit .button {
  margin: 0;
  padding: 12px 26px;
  box-shadow: none;
}
.payedit .button:hover img {
  filter: invert(1);
}
.paynames h4 {
  margin: 0 0 3px 0;
  font-size: 15px;
}

.paynames p {
  margin: 0 0 3px 0;
  font-size: 14px;
}

.paynames span {
  color: #868d96;
  font-size: 13px;
  display: block;
}

.paynames {
  padding: 0 100px 0 0;
}

.payedit {
  line-height: 1;
  position: absolute;
  right: 20px;
  bottom: 15px;
}
#payoutconfirm {
  max-width: 600px;
}
#payoutconfirm h4 {
  font-size: 16px;
  letter-spacing: 0;
}
.pt-crm-form .form-group {
  text-align: left;
  margin: 0 0 10px 0;
}
.small-by {
  letter-spacing: 0;
  font-size: 10px;
  padding: 20px 0 5px 0;
  text-align: left;
}
.small-by span {
  color: #ff2d16;
}
.pt-crm-form .control-label {
  font-weight: 500;
}
.pt-crm-form .control-label em {
  color: #ff2d16;
  font-style: normal;
}

.earning-tab-nav ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #ededed;
  margin: 0 0 30px 0;
}

.earning-tab-nav ul li a {
  font-weight: 600;
  padding: 10px 20px;
  display: block;
  border: 2px solid #ededed;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  color: #666666;
  background: #f3f3f3;
}

.earning-tab-nav ul li {
  margin: 0 15px 0 0;
}
.earning-tab-nav ul li.active a {
  background: #fff;
  color: #4258bf;
  position: relative;
}

.earning-tab-nav ul li.active a:after {
  height: 2px;
  background: #ffffff;
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
}
.tearn-container {
  overflow-y: auto;
  background-color: #fff;
  color: #222;
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto;
  margin: 10px auto;
  box-shadow: 0 10px 40px rgba(143, 143, 143, 0.16);
  border-radius: 20px;
  padding: 40px;
  grid-template-columns: 150px 1fr;
}
.btab .tearn-container {
  display: inline-grid;
}
.tearn-container li {
  list-style: none;
}

.table-body {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto;
  width: 100%;
  overflow-x: auto;
}

.table-row {
  display: grid;
  grid-gap: 0;
  min-width: 200px;
}
.table-row-title {
  font-weight: 500;
}
.table-row-title .item-cell {
  padding: 11px 20px 11px 0;
}
.table-row:nth-child(2n) {
  background-color: #fafafa;
}

.item-cell {
  align-items: flex-start;
  display: flex;
  padding: 10px 10px 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-head {
  padding-bottom: 15px;
}
.table-head .table-row {
  min-width: 150px;
}
.grn-color {
  color: #00df00;
}
.red-clr {
  color: #e64f4f;
}
@media screen and (min-width: 760px) {
  .tearn-container {
    grid-template-columns: auto;
    grid-template-rows: 40px auto;
  }

  .table-body {
    grid-template-columns: auto;
    overflow: visible;
    width: 100%;
  }

  .table-row {
    grid-template-columns: 180px 300px 140px 180px 140px 180px;
    grid-template-rows: auto;
  }
  .btab .table-row {
    grid-template-columns: 220px 230px 215px 190px;
    grid-template-rows: auto;
  }
  .table-row-title .item-cell {
    padding: 5px 20px 5px 0;
  }
}
@media screen and (max-width: 780px) {
  .tearn-container {
    padding: 30px 20px;
  }
}
.designer-card {
  overflow: hidden;
  border: 1px solid #4258bf;
  background-image: linear-gradient(90deg, #edf2ff 0%, #bdd0ff 100%);
}
.dcard-text {
  /* background: #e7edff; */
  padding: 40px 30px 70px 30px;
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -30deg);
}
.dcard-text-inner {
  transform: skew(0deg, 30deg);
  padding-right: 25%;
}
.dcard-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 75%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}
.no-package-container {
  width: 100%;
  border: 1px solid #4258bf;
  background: linear-gradient(90deg, #edf2ff 0%, #bdd0ff 100%);
  border-radius: 20px;
  box-shadow: rgba(66, 88, 191, 0.17) 0px 8px 20px;
  transition: 0.3s linear all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 5rem 0;
  padding: 2rem 0;
}
.no-package-icon {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem 0 2rem;
}
.no-package-icon img {
  width: 50%;
  animation: dance3D 5s infinite;
  transform-style: preserve-3d;
}
@keyframes dance3D {
  0% {
    transform: rotateY(0deg) rotateX(0deg) translateZ(0) translate(0, 0)
      rotate(0);
    opacity: 1;
  }
  25% {
    transform: rotateY(15deg) rotateX(10deg) translateZ(20px)
      translate(-10px, -10px) rotate(-2deg);
    opacity: 0.9;
  }
  50% {
    transform: rotateY(-15deg) rotateX(-10deg) translateZ(-20px)
      translate(10px, 10px) rotate(2deg);
    opacity: 1;
  }
  75% {
    transform: rotateY(15deg) rotateX(10deg) translateZ(20px)
      translate(-5px, 5px) rotate(-1deg);
    opacity: 0.9;
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg) translateZ(0) translate(0, 0)
      rotate(0);
    opacity: 1;
  }
}
.no-package-text {
  text-align: center;
  padding: 2rem 1rem;
}
.no-package-text h3 {
  color: #4258bf;
  font-weight: 700;
  letter-spacing: 0;
  margin: 0 0 20px 0;
}
.no-package-text p {
  color: #5a6080;
  font-size: 14px;
}
@media (max-width: 480px) {
  #root {
    overflow: hidden;
  }
  .cp-list-content .category-box {
    width: 47%;
    margin-right: 0px;
  }
  .cp-list-content .category-filter {
    justify-content: space-between;
  }
  .cp-list-content .container {
    padding: 0 16px !important;
  }
  .no-package-icon {
    width: 75%;
  }
  .no-package-text h3 {
    font-size: 18px;
  }
  .no-package-text p {
    font-size: 12px;
  }
}

.over-link {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
}
.dcard-text-inner h3 {
  color: #4258bf;
  font-weight: 700;
  letter-spacing: 0;
  margin: 0 0 20px 0;
}
.dcard-text-inner p {
  margin: 0;
  color: #5a6080;
  font-size: 14px;
}
.cy-pack {
  margin: 0;
  z-index: 1;
  position: absolute;
  right: 30px;
  bottom: 30px;
  color: #4258bf;
  font-size: 13px;
  background: #e7edff;
  border-color: #e7edff;
  letter-spacing: 0;
  padding: 13px 24px;
}
.cy-pack {
  background: #4258bf;
  color: #fff;
  border-color: #4258bf;
}
.ptd-day {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #fff;
  line-height: 1;
  font-size: 10px;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 15px;
  color: #4258bf;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.new-tags {
  position: absolute;
  left: 20px;
  bottom: 12px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}
.ml-view .new-tags {
  margin-top: 0 !important;
}
.main-content .new-tags,
.ml-view .new-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  position: initial;
}
.flight-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  width: 100%;
}
.new-tags > div,
.flight-tags > div {
  font-size: 11px;
  padding: 7px 13px;
  border-radius: 15px;
  margin: 3px 10px 0 0;
  background-color: #fff7e0;
  line-height: 1;
  color: #000;
}
.main-content .new-tags > div,
.ml-view .new-tags > div {
  padding: 0px;
}
.flight-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
}
.new-tags > div,
.flight-tags > div {
  font-size: 11px;
  padding: 7px 13px;
  background: #efefef;
  border-radius: 15px;
  margin: 3px 10px 0 0;
  background-color: #ebeeff;
  line-height: 1;
  color: #000;
}
.main-content .new-tags > div,
.ml-view .new-tags > div {
  font-size: 12px;
  margin: 3px 10px 0 0;
  line-height: 1;
  color: #8f95b3;
  background: transparent;
}
.main-content .new-tags > div.e-tags,
.ml-view .new-tags > div.e-tags {
  background: transparent;
}
.new-tags > div.e-tags {
  background: #f6d8e1;
}

.clk-red {
  position: absolute;
  right: 30px;
  top: 50px;
}
.pac-inner-a p {
  margin: 0;
}
#activity_remove_popup,
#remove-fligh-popup,
#transfer_remove_popup {
  max-width: 350px;
  padding: 50px 25px 20px 25px;
}

#activity_remove_popup .alert_body,
#remove-fligh-popup .alert_body,
#transfer_remove_popup .alert_body {
  padding: 0;
}
#remove-fligh-popup {
  border-radius: 20px;
}
.sumry-box-top {
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 25px 30px;
  margin: 0 0 25px 0;
}

.sumry-box-top .mini-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 15px 0;
  align-items: center;
}
.sumry-box-top .mini-header .action-btns {
  display: flex;
}
.sumry-box-top .mini-header svg {
  cursor: pointer;
}
.sumry-box-top .mini-header button {
  margin-right: 10px;
}

.sumry-box-top .mini-header h5 {
  margin: 0;
}
.sumry-down-btn {
  padding: 1rem;
}

.mini-box-body {
  display: flex;
  flex-flow: column wrap;
  gap: 5px 0;
}
.mini-box-body > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}
.mini-box-body a {
  background-color: #bdd0ff50;
  border: 1px solid #4258bf;
  border-radius: 5px;
  display: flex;
  padding: 2px 4px;
  font-size: 12px;
  color: #4258bf;
}

.mini-box-body a > img {
  width: 25%;
  margin: 0 5px;
}
.mini-header button img,
.mini-box-body button img {
  width: 13px;
  margin: 0 5px 0 0;
}

.mini-header button {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0;
  padding: 0;
  background: none;
  color: #4258bf;
  border: 0;
  box-shadow: none;
  overflow: visible;
}
.mini-header button:hover {
  opacity: 0.7;
}

.mini-header-right {
  display: flex;
  align-items: center;
}
.three-merge-btn {
  border-radius: 4px;
  border: 1px solid #d7dbec;
  background-color: #d7dbec;
  line-height: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 0.5px;
}

.recharts-text {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0px;
}
.recharts-legend-wrapper {
  display: none !important;
}

.fbg-inner.active {
  background-color: #e9efff;
}
.fbg-inner h6 {
  font-weight: normal;
  letter-spacing: 0;
  font-size: 14px;
  margin: 0 0 1px 0;
}

.fbg-inner p {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: #131523;
  letter-spacing: -1px;
  line-height: 1;
  padding: 3px 0 5px;
}

.fbg-inner span {
  color: #939393;
  font-size: 12px;
  letter-spacing: 0;
  display: block;
  line-height: 1;
}

.fbg-inner strong {
  color: #14f014;
  font-weight: 500;
  font-size: 14px;
}

.fbg-inner span img {
  margin: 0 2px;
  width: 9px;
}
.fbg-inner.down-bx strong {
  filter: brightness(0) saturate(100%) invert(26%) sepia(95%) saturate(5404%)
    hue-rotate(353deg) brightness(97%) contrast(127%);
}
.fbg-inner.down-bx span img {
  transform: rotate(180deg);
}
.ctable-row.ctable-scroll .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.gradient-pro {
  background-image: linear-gradient(to left, #fef6c9 0%, #cad3ff 100%);
  height: 290px;
  position: relative;
}
.pro-spacer {
  padding-top: 90px;
}

.traveler-pinfo {
  display: flex;
  justify-content: space-between;
  margin: 0 0 60px 0;
  flex-wrap: wrap;
}

.traveler-pinfo-lhs {
  position: relative;
  width: 40%;
  padding: 1rem;
}

.pr-main-info {
  display: flex;
  flex-flow: column wrap;
  padding-right: 10px;
}

.circle-pro-img {
  position: absolute;
  top: -165px;
  left: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #fff;
}
.traveler-pinfo-rhs {
  width: 60%;
  background: #f9f9f9;
  padding: 30px;
  border-radius: 20px;
}
.traveler-pinfo-rhs-full {
  width: 100%;
  background: #f9f9f9;
  padding: 2rem 1rem;
  border-radius: 20px;
}
.pr-main-info h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 12px 0;
}

.pr-main-info > span {
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
}

.pr-main-info > span img {
  margin-right: 15px;
  width: 35px;
  border-radius: 3px;
}
.pr-main-info a {
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  margin: 0;
}
.pr-main-info a img {
  margin-right: 15px;
  width: 15px;
}
.pr-main-info a {
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  position: relative;
  padding-left: 25px;
}
.pr-main-info a svg {
  position: absolute;
  left: 0;
  top: 3px;
}
.earnpop-details {
  max-width: 520px;
}

.ed-body ul {
  padding: 0;
  list-style: none;
}

.ed-body ul li {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
  line-height: 1.1;
  color: #000;
  align-items: center;
  font-size: 16px;
}
.ed-body ul li strong {
  font-weight: 500;
  width: 120px;
}
.ed-body ul li span {
  font-weight: 500;
  width: calc(100% - 130px);
}
.ed-body ul li:last-child strong {
  font-size: 20px;
  font-weight: 600;
  color: #4258bf;
}
.ed-body ul li:last-child {
  border-top: 1px solid #c5c5c5;
  padding: 15px 0 0 0;
  margin: 10px 0 0 0;
}
.ed-body ul li.p-charge {
  color: #767676;
}
.ed-body ul li:first-child {
  border-top: 1px solid #c5c5c5;
  padding-top: 15px;
}

.ed-header h4 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #4258bf;
  letter-spacing: 0;
}

.ed-header h3 {
  font-size: 20px;
  margin: 0 0 15px 0;
  font-weight: 600;
  letter-spacing: 0;
}
.title-nav .ghost-button {
  margin: 0 10px 0 0;
  border-color: #ff0000;
  color: #ff0000;
}
.title-nav .ghost-button:hover {
  border-color: #ff0000;
  color: #fff;
  background: #ff0000;
}
.step-content-calender
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  display: none;
}
.search-loader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.circle-pro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.traveler-pinfo-lhs .creator-badge {
  font-size: 16px;
  display: inline-flex;
}
.traveler-pinfo-lhs .creator-badge span {
  width: 38px;
  height: 38px;
}
.traveler-pinfo-lhs .creator-badge span img {
  width: 18px;
}
.about-profiler {
}

.about-profiler h5 {
  margin: 0 0 12px 0;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: #4258bf !important;
}
.rdrDayToday .rdrDayNumber span:after {
  background: #4258bf !important;
}
.step-content-calender .rdrDayEndPreview,
.step-content-calender .rdrDayStartPreview {
  color: rgb(66 88 191) !important;
}

.slider-gallery .owl-carousel .owl-item {
  filter: grayscale(0%) blur(1px);
  -webkit-filter: grayscale(0%) blur(1px);
  -moz-filter: grayscale(0%) blur(1px);
  transition: 0.3s ease all;
}
.slider-gallery .owl-carousel .owl-item.active {
  filter: grayscale(0%) blur(0px);
  -webkit-filter: grayscale(0%) blur(0px);
  -moz-filter: grayscale(0%) blur(0px);
}
.sample-edit {
  background: #f7f9ff;
  padding: 35px 30px;
  border-radius: 20px;
  position: relative;
  border: 2px solid #dbdbdb;
  margin: 0 0 40px 0;
}

.slider-gallery {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.slider-gallery .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
}
.slider-gallery .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
}
.sample-edit-lhs {
  position: relative;
  padding-right: 130px;
}

.sample-edit-lhs .button {
  margin: 0;
  padding: 11px 24px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.sample-edit-lhs .button img {
  width: 12px;
  margin: 0 5px 0 0;
}
.sample-edit-lhs h4 {
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.sample-edit-lhs span {
  color: #868d96;
  font-size: 14px;
}

.sample-edit-lhs p {
  margin: 0 0 10px 0;
}
.sample-edit-lhs .button:hover img {
  filter: invert(1);
}

.force-overflow {
  min-height: 450px;
}

.destin-reult-show::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  overflow: hidden;
  border-radius: 20px;
}

.destin-reult-show::-webkit-scrollbar {
  width: 6px;
  background-color: #f2f2f2;
  border-radius: 20px;
}

.destin-reult-show::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 20px;
  overflow: hidden;
}
.earning-main .four-box-graph {
  margin: 20px 0 50px 0;
}
.earning-main .fbg-inner {
  background: #e9efff;
  padding: 20px 20px;
  cursor: inherit;
}
.earning-main .fbg-inner h6 {
  font-size: 15px;
}
.earning-main .fbg-inner span {
  font-size: 13px;
}
.earning-main .fbg-inner strong {
  font-size: 15px;
}
.earning-main .fbg-inner p {
  font-size: 21px;
}
.title-merge-btn .color-header {
  margin: 0;
}
.title-merge-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px 0;
}
.pr-main-info-title {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
.pr-main-info-title h3 {
  margin: 0 15px 0 0;
}
.service-amount.new-amnt {
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 0 5px 0;
  color: #000000;
  font-size: 17px;
}
.service-amount.new-amnt strong {
  font-weight: 500;
}
.earning-main .four-box-graph {
  margin: 20px 0 50px 0;
}
.earning-main .fbg-inner {
  background: #e9efff;
  padding: 20px 20px;
  cursor: inherit;
}
.earning-main .fbg-inner h6 {
  font-size: 15px;
}
.earning-main .fbg-inner span {
  font-size: 13px;
}
.earning-main .fbg-inner strong {
  font-size: 15px;
}
.earning-main .fbg-inner p {
  font-size: 21px;
}
.title-merge-btn .color-header {
  margin: 0;
}
.title-merge-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 10px 0;
}
.pr-main-info-title {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
.pr-main-info-title h3 {
  margin: 0 15px 0 0;
}
.service-amount.new-amnt {
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 0 5px 0;
  color: #000000;
  font-size: 17px;
}
.service-amount.new-amnt strong {
  font-weight: 600;
}
.cp-list-content .container {
  max-width: none;
  padding: 0 50px;
}
.cp-list-img.map-banner-parent {
  max-width: 94%;
}
.cp-list-img .banner-map {
  display: block;
}
.cp-list-img .pl-name {
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(34, 34, 34, 0.1) 0%,
    rgba(27, 27, 27, 0) 25.17%,
    rgba(0, 0, 0, 0.5) 59.35%,
    rgba(34, 34, 34, 0.7) 100%
  );
}
.cp-list-img .pl-name h2 {
  color: #fff979;
  font-weight: 600;
  font-size: 32px;
}
.cp-list-img .pl-name p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #f5f6f7;
  opacity: 0.9;
}
.map-banner-parent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px 0 0 0;
}
.banner-map {
  display: grid;
  grid-template-columns: 77% 23%;
}
.bm-lhs {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  line-height: 0;
  border-right: 5px solid #fff;
  height: 340px;
  z-index: 1;
}
.bm-lhs:after {
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.25) 48%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.25) 48%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.25) 48%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#73000000', endColorstr='#00000000',GradientType=1 );
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.bmr-top {
  border-bottom: 5px solid #fff;
}
.bmr-top,
.bmr-btm {
  overflow: hidden;
  border-radius: 15px;
}
.bm-rhs {
  height: 340px;
  display: grid;
  grid-template-rows: 50% 50%;
}
.bm-lhs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pl-name {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  line-height: 1.1;
  z-index: 1;
  right: 0;
  padding: 32px;
}

.pl-name h2 {
  color: #fff;
  letter-spacing: 1.75px;
  font-size: 35px;
  margin: 0 0 5px 0;
}
.pl-name p {
  margin: 0;
}
.slider-gallery .owl-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: 470px;
}
@media (max-width: 480px) {
  .slider-gallery .owl-item img {
    height: 260px;
    width: 100%;
    object-fit: cover;
  }
}
.timer-sticky {
  z-index: 9;
  position: sticky;
  top: 80px;
  background: #fff;
  transition: 0.3s linear all;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.align-flex-start {
  justify-content: flex-start !important;
}
.timerShow .timer-sticky {
  padding: 7px 0;
  background: #f9f9f9;
}
.timerShow .timer-sticky .clk-red {
  opacity: 1;
  visibility: visible;
}
.timer-sticky .chkout-title {
  margin: 0;
}
.timer-sticky .clk-red {
  background: #ff1c00;
  color: #fff;
  position: static;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s linear all;
  font-weight: normal;
}
.timer-sticky .clk-red img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(16%)
    hue-rotate(285deg) brightness(106%) contrast(104%);
  margin: 0 5px 0 0;
}

.timer-sticky {
  z-index: 9;
  position: sticky;
  top: 80px;

  padding: 10px 0;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.timerShow .timer-sticky:after {
  background: #f9f9f9;
  content: "";
  position: absolute;
  left: 0;
  width: 1000%;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.timerShow .timer-sticky:before {
  background: #f9f9f9;
  content: "";
  position: absolute;
  right: 0;
  width: 1000%;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.wishlisth-header-filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.filter-ge {
  display: flex;
  align-items: center;
}

.filter-ge label {
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
}

.filter-ge select {
  margin: 0 0 0 5px;
  height: 36px;
  padding: 5px 10px;
  font-size: 13px;
}
.wishlisth-header-filter .wishlisth-header h5 {
  margin: 0;
}
.wishlisth-header-filter {
  margin: 20px 0 30px 0;
}
.mybooking-rhs .booked-ticket {
  width: 100%;
}

.mybooking-rhs .mybooking-inner-main {
  border: 0;
  padding: 0;
}
.pack-and-step {
  margin: 0 0 40px 0;
}
.pack-and-step .container {
  max-width: none;
}
.neutral-box {
  color: black !important;
}
.wishlisth-header-filter .wishlisth-header h5 strong {
  font-weight: normal;
  font-size: 14px;
  opacity: 0.8;
}
.outlet-listing-rhs > ul.wish-my-row {
  justify-content: space-between;
}

.outlet-listing-rhs > ul.wish-my-row > li {
  width: 48.5%;
  margin: 0 0 30px 0;
  padding-bottom: 0;
}
.mybooking-rhs .loadmore {
  margin: 5px auto 0;
  max-width: 180px;
  color: #fff;
}
.mybooking-rhs .loadmore:hover {
  color: #000;
}

.edit-date-popup .rdrCalendarWrapper {
  padding: 0;
}

#calendar_popup .step-content-search {
  box-shadow: none;
}

#calendar_popup .step-content-search .step-content-calender {
  width: 100%;
}

#calendar_popup .step-content-calender .rdrMonth {
  width: 100%;
}
#calendar_popup .step-content-search .rdrMonthAndYearWrapper {
  width: 90%;
  top: 9px;
  left: 25px;
}
#calendar_popup .step-content-calender .rdrMonth .rdrWeekDays {
  margin-bottom: 0px;
}
#calendar_popup .step-content-search {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.edit-date-popup {
  max-width: 720px;
  height: 688px;
  padding: 50px 30px 30px 30px;
}
#alert_popup.edit-date-popup {
  height: auto;
}
.choose-traveller-popup {
  max-height: 580px;
  max-width: 720px;
}
.choose-traveller-popup ul {
  max-height: 355px;
  overflow-y: auto;
}
#edit_calendar_popup .step-content-search,
#select-source-country-popup .step-content-search,
#select-source-airort-popup .step-content-search {
  box-shadow: none;
}
#edit_calendar_popup.edit-date-popup {
  padding: 35px 30px 30px 30px;
}
#calendar_popup.edit-date-popup {
  width: 600px;
}
#calendar_popup.edit-date-popup .step-2 {
  max-height: calc(100% - 240px);
  overflow-y: auto;
}
#calendar_popup.edit-date-popup .step-2 .ttd-p-rhs ul {
  margin-bottom: 0px;
}
#calendar_popup.edit-date-popup .step-2 .actions {
  position: absolute;
  bottom: 5px;
  right: 30px;
  display: flex;
  align-items: center;
}
#calendar_popup.edit-date-popup .step-2 .actions .selectbtn {
  margin-left: 1rem;
}
#calendar_popup.edit-date-popup .step-2 .slot-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: 1rem 10px;
  margin-bottom: 1.5rem;
}
#calendar_popup.edit-date-popup .step-2 .slot-list .slot-col {
  border-radius: 20px;
  border: 1px solid #737373;
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
}
#calendar_popup.edit-date-popup .step-2 .slot-list .slot-col.selected {
  background: #4258bf;
  color: #fff;
}
#calendar_popup.edit-date-popup .step-2 .title {
  letter-spacing: 0.18px;
  color: #111733;
  font-size: 18px;
  font-weight: 600;
}
#calendar_popup.ttd-edit-popup .s-title-back {
  justify-content: flex-start;
}
#calendar_popup.ttd-edit-popup .s-title-back .s-title h2 {
  font-weight: 600;
}
#calendar_popup.ttd-edit-popup .step-content-calender .rdrDay {
  height: 3em;
  margin-bottom: 0.8rem;
}
/* #calendar_popup.ttd-edit-popup .rdrDay {
  width: calc(100% / 9);
} */
#calendar_popup.ttd-edit-popup {
  width: 775px;
}
#select-source-country-popup .search-with-img-result,
#select-source-airort-popup .search-with-img-result {
  max-height: 500px;
}
#select-passenger-popup .passenger-body ul {
  height: 520px;
  overflow-y: auto;
}
#select-passenger-popup .passenger-body .btn-min-width {
  padding: 15px 0 0 0;
}
/* TTD select date popup style */
.ttd-edit-popup {
  padding: 2rem;
  width: 856px;
  max-width: none;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
}
.ttd-edit-popup .ttd-calender-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #21314d;
  margin-bottom: 1rem;
}
.ttd-banner-card1 {
  background: linear-gradient(
    180deg,
    rgba(34, 34, 34, 0.5) 0%,
    rgba(27, 27, 27, 0.4) 24.15%,
    rgba(0, 0, 0, 0) 49%,
    rgba(34, 34, 34, 0.5) 100%
  );
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
}
.ttd-banner-card1 .ttd-banner-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: #ffffff;
}
.ttd-banner-card1 .ttd-banner-sub {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #ffffff;
  opacity: 0.7;
}
.ttd-banner-card2 {
  background: #f4f6ff;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
}
.ttd-banner-card2 .ttd-banner-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #243069;
}
.ttd-banner-card2 .ttd-banner-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #3c50ae;
}
.ttd-banner-col2 button {
  font-weight: 400;
  line-height: 1.3;
  text-decoration: underline;
  color: #3c50ae;
  background-color: transparent;
  box-shadow: none;
  text-transform: capitalize;
}
.ttd-calendar-action {
  display: grid;
  place-items: center;
}
.ttd-calendar-action button {
  margin: 1.5rem 0;
}
.ttd-edit-popup .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #122441;
  margin: 1rem 0;
}
.ttd-slot-option {
  flex-flow: row-reverse !important;
  width: 100% !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.ttd-slot-option ul {
  margin: 0 !important;
  width: 100%;
}
.ttd-popup-action-footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 0 0;
  border-top: 1px solid #dfe1e5;
}
.ttd-popup-action-footer .button {
  margin: 0;
}
.ttd-footer-total p {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #3f4d65;
}
.ttd-qty-bx {
  border: 0 !important;
}
.ttd-qty-bx > span:hover {
  background: #eceef9;
  border-radius: 4px !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.ttd-qty-bx .qty-input {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 1;
  color: #2f3e88;
}
.slot-list {
  gap: 0.75rem;
}
.slot-col {
  width: max-content;
  padding: 0.5rem 1rem;
  background: #ffffff;
  border: 0.5px solid #c1c5cd;
  border-radius: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #21314d;
}
@media (min-width: 481px) {
  .ttd-banner-card1 {
    height: 200px;
    padding: 1.5rem;
  }
  .ttd-banner-card2 {
    padding: 1.5rem 1rem;
    align-items: center;
  }

  .ttd-banner-col2 button {
    font-size: 16px;
  }
  .slot-list {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  .ttd-popup-action-footer {
    align-items: center;
  }
}
@media (max-width: 480px) {
  .ttd-banner-card1 {
    height: 160px;
    padding: 1rem;
  }
  .ttd-banner-card2 {
    flex-direction: column;
    align-items: start;
    padding: 1rem;
  }
  .ttd-banner-card2 .ttd-banner-title {
    margin-bottom: 0.25rem;
  }
  .ttd-date-card .rdrDay {
    margin: 0;
  }
  .ttd-banner-col2 button {
    font-size: 14px;
    padding: 0 0 1rem 0;
  }
  .ttd-time-slot-list {
    display: flex !important;
    flex-direction: row;
    width: 100%;
    margin: 0 !important;
    overflow-x: auto;
  }
  .ttd-time-slot-list::-webkit-scrollbar {
    display: none;
  }
  .ttd-time-slot-list .slot-col {
    white-space: nowrap;
    padding: 0.5rem 1rem !important;
  }
  .ttd-slot-option {
    padding: 0 !important;
  }
  .ttd-slot-option ul {
    padding: 0 !important;
  }
  .ttd-slot-option .ad-ingo p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #3c50ae;
  }
  .ttd-popup-action-footer {
    align-items: end;
    flex-direction: column;
    padding: 1.5rem 0 0 0;
    margin-top: 1.5rem;
  }
  .ttd-popup-action-footer .step-content-search {
    text-align: right;
    border-radius: 0 !important;
  }
  .ttd-popup-action-footer strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #21314d;
    margin-bottom: 0.25rem;
  }
  .ttd-popup-action-footer .step-content-search p {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    color: #3f4d65;
  }
  .ttd-popup-action-footer .selectbtn,
  .ttd-popup-action-footer .selectbtn a {
    width: 100%;
  }
}

#calendar_popup .s-title-back {
  justify-content: center;
}
#calendar_popup .ttd-header {
  border-radius: 10px;
  background: #f5f7ff;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
#calendar_popup .ttd-header .ttd-title {
  color: #111733;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  margin-bottom: 5px;
}
#calendar_popup .ttd-header .p-title {
  letter-spacing: 0.16px;
  color: #111733;
  font-size: 16px;
}
#calendar_popup .ttd-header .edit-visitdate {
  text-transform: none;
  font-family: "poppins";
  color: #4258bf;
  font-size: 16px;
  box-shadow: none;
}

#calendar_popup .ttd-header .p-date {
  letter-spacing: 0.16px;
  color: #111733;
  font-size: 16px;
  font-style: italic;
  margin-top: 5px;
}
#calendar_popup .s-title-back .s-title h2 {
  font-size: 20px;
}
.rdrMonthAndYearWrapper {
  height: auto;
  padding-top: 0;
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
  margin: auto;
}
.pack-info-box a {
  font-size: 13px;
}

.pack-info-box h5 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.abt-banner h1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  font-weight: 700;
  max-width: 1200px;
  margin: 0 auto;
  transform: translateY(-50%);
  color: #fcfdff;
  font-size: 48px;
}
.abt-why {
  background-color: #fffae0;
  padding: 80px 0 70px;
}
.abt-why {
  background-color: #fffae0;
  padding: 80px 0 70px;
}

.abt-why ul {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
  gap: 60px;
  padding: 40px 0 0 0;
  list-style: none;
}
.abt-why ul li {
  margin: 0 0 10px 0;
}
.abt-why ul li h5 {
  margin: 0 0 10px 0;
  color: #111733;
  font-size: 16px;
}

.abt-why ul li p {
  margin: 0;
  color: #4258bf;
  font-weight: 600;
  line-height: 25px;
  font-size: 16px;
}
.abt-why h3 {
  font-size: 40px;
}
.abt-content {
  padding: 70px 0;
}
.abt-content p {
  margin: 0 auto;
  color: #5a6080;
  font-size: 18px;
  max-width: 710px;
  line-height: 1.5;
}
.abt-content h2 {
  font-size: 40px;
}
.abt-banner:after {
  content: "";
  background-image: linear-gradient(to right, #000000 0%, #545454 100%);
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0.2;
}

.abt-banner h1 {
  z-index: 1;
}

.ttd-package-card > ul {
  padding: 0;
  list-style: none;
}

.ttd-package-card > ul > li {
  border-radius: 20px;
  border: 1px solid #4258bf;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 0 35px 0;
}

.ttd-p-lhs {
  padding: 20px;
  border-right: 1px solid #4258bf;
  width: calc(100% - 240px);
}
#calendar_popup.edit-date-popup .ttd-p-rhs {
  width: 100%;
}
#calendar_popup.edit-date-popup .ttd-p-rhs.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
#calendar_popup.edit-date-popup .ttd-p-rhs ul {
  width: 100%;
  flex-direction: column;
  background-color: #f7f9ff;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ttd-p-rhs {
  width: 240px;
  /* background-color: #f7f9ff; */
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ttd-p-lhs h4 {
  font-size: 18px;
  margin: 0 0 13px 0;
}
.vist-info ul {
  display: flex;
  padding: 0;
  list-style: none;
  gap: 13px;
}
.vist-date,
.aftr-req,
.vw-more a {
  border-radius: 10px;
  background: rgba(189, 208, 255, 0.25);
  color: #120c0f;
  font-size: 14px;
  padding: 10px 18px;
}
.aftr-req {
  background: rgb(246 155 97 / 25%);
}
.vw-more a {
  display: block;
  color: #4258bf;
}
.vw-more a span {
  text-decoration: underline;
}
.tit-info {
  padding: 15px 0 25px 0;
}
@media (max-width: 480px) {
  .tit-info {
    padding: 0.75rem 0;
  }
}
.tit-info span {
  font-size: 14px;
  display: block;
  margin: 0 0 10px 0;
}

.vi-cover a {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 14px;
  border-radius: 20px;
  border: 1px solid #4258bf;
  color: #342f41;
  font-size: 12px;
  line-height: 1;
}

.vi-cover {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
}
.ttd-total-pack p {
  color: #767676;
  font-size: 12px;
  font-style: italic;
  margin: 0;
}
.ttd-total-pack p i {
  margin-right: 5px;
}
.ttd-total-pack strong {
  color: #111733;
  font-size: 18px;
  letter-spacing: 0.18px;
  padding: 0 0 5px 0;
  display: block;
  font-weight: 600;
}
.ttd-total-pack strong span {
  color: #4258bf;
}
.ttd-p-rhs ul {
  padding: 16px;
  list-style: none;
  margin-bottom: 4rem;
}
.ad-ingo-rhs .qty-bx {
  border-radius: 10px;
  border: 1px solid #4258bf;
  background-color: #ffffff;
  margin: auto;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
}

.ad-ingo-rhs .qty-bx span {
  border: 0;
  border-radius: 0;
  height: 100%;
  width: 36px;
}

.ad-ingo-rhs .qty-bx * {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
}
.ad-ingo-rhs .qty-bx input {
  font-size: 18px;
  font-weight: 600;
}
.ad-ingo p,
.persons p {
  margin: 0;
  color: #111733;
  font-weight: 600;
  font-size: 16px;
}
.ad-ingo p em,
.persons p em {
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  display: block;
}
.ttd-p-rhs ul li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 30px 0;
}
.ttd-p-rhs ul li:last-child {
  margin-bottom: 0;
}
.ad-ingo {
  padding-right: 10px;
}

.whats-include {
  padding: 16px 0 20px;
}
.whats-include span {
  color: #111733;
  font-size: 14px;
  margin: 0 0 2px 0;
  display: block;
}

.wi-cover {
  border-radius: 10px;
  background-color: rgb(189 208 255 / 25%);
  padding: 20px 15px;
  color: #120c0f;
  font-size: 14px;
}
.include-tt h4 {
  color: #111733;
  font-size: 18px;
}
.persons p {
  margin: 0;
  width: 50%;
}
.persons p strong {
  font-weight: 600;
}
.include-tt .button {
  margin-bottom: 0;
  margin-top: 25px;
}
.persons {
  display: flex;
  justify-content: center;
}
#checkin-time-earliest-popup {
  border-radius: 20px;
  max-width: 520px;
}
.help-content ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  gap: 30px;
}

.help-content {
  padding: 60px 0;
  background: #f9f9f9;
}
.help-content ul li a {
  padding: 30px 30px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  display: block;
  color: #565656;
  box-shadow: rgb(0 0 0 / 2%) 0px 0px 5px 0px, rgb(0 0 0 / 2%) 0px 0px 1px 0px;
}
.help-contnt h4 {
  margin: 0 0 12px 0;
}

.help-contnt p {
  margin: 0;
}
.help-icn {
  margin: 0 0 10px 0;
  font-size: 20px;
}
.help-content-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 0 40px;
  align-items: flex-start;
}
.hci-lhs {
  width: 300px;
  background: #eef3ff;
  border-radius: 20px;
  position: sticky;
  top: 90px;
}
.hci-rhs {
  width: calc(100% - 360px);
}
.mCustomScrollBox {
  width: 100%;
}
.main-titl {
  border-bottom: 1px solid #e4e4e4;
  margin: 0 0 35px 0;
  padding: 0 0 15px 0;
}

.main-titl p {
  margin: 0;
}
.main-titl h2 {
  margin: 0 0 10px 0;
}
.main-question ul,
.relted-qstins ul {
  padding: 0;
  list-style: none;
}

.main-question ul li a,
.relted-qstins ul li a {
  color: #333333;
}

.main-question ul li,
.relted-qstins ul li {
  margin: 0 0 12px 0;
}

.hci-lhs ul {
  padding: 25px 30px;
  list-style: none;
}
.hci-lhs ul li a {
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: block;
  padding: 10px 0 10px 26px;
  font-size: 14px;
  position: relative;
}
.hci-lhs ul li:last-child a {
  border-bottom: 0;
  padding-bottom: 0;
}
.hci-lhs ul li a:after {
  position: absolute;
  left: 0;
  top: 9px;
  font-family: "FontAwesome";
  font-size: 15px;
  color: #4258bf;
  content: "\f0a4";
}
.relted-qstins {
  padding: 30px 0 0;
}

.acticle-helpful {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding: 20px 0;
  color: #000;
}

.acticle-helpful a {
  font-weight: 500;
  margin: 0 5px;
}
.main-txt {
  line-height: 1.5;
}
.main-txt p {
  padding: 0 0 10px 0;
}

.main-txt ul {
  padding-bottom: 20px;
}
.main-txt ul li {
  padding-bottom: 5px;
}
.clear-button {
  position: absolute;
  right: 30px;
  top: 34px;
  font-weight: bold;
}
.toggle-main {
  width: 320px;
  background: #fff;
  padding: 15px;
  box-shadow: 0 2px 12px rgb(0 0 0 / 13%);
  border-radius: 10px;
}
.toggle-parent {
  padding: 20px 0 0 0;
  display: block;
  width: 320px;
  position: absolute;
  right: 0;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s linear all;
  transform: translateY(30px);
}
.show-info-full:hover .toggle-parent {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.checkbox {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  position: relative;
  color: black;
  overflow: hidden;
  background-color: #f2f5ff;
}

#checkbox_toggle {
  display: none;
}

.checkbox .toggle {
  width: 50%;
  height: 40px;
  position: absolute;
  border-radius: 30px;
  left: 5px;
  cursor: pointer;
  background: #fff;
  transition: 0.4s;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
}

.checkbox .slide {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.checkbox .slide .text {
  font-size: 15px;
  font-weight: 500;
  z-index: 1;
  cursor: pointer;
}

.check:checked + .checkbox .slide .toggle {
  transform: translateX(135px);
  background: #fff;
}

.travelr-log-info {
  display: block;
}
.common-info {
  padding: 20px 15px 0 15px;
  font-size: 13px;
}
.common-info .button {
  width: 100%;
}
.common-info .button.ghost-button {
  margin: 10px 0 0 0;
}
.header-links ul li.show-info-full {
  margin: 0;
}
.header-links ul li.show-info-full > a {
  border: 2px solid #e4e4e4;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.header-links ul li.show-info-full > a img {
  width: 20px;
  height: 20px;
}
.topics-serch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.search-topics {
  max-width: 580px;
}

.search-topics input[type="text"] {
  margin: 0;
  height: 50px;
  border: 1px solid #dadada;
  font-size: 15px;
}
.topics-serch h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0.5px;
  margin: 0px 0px 15px 0px;
}

.title-with-description.title-with-back-icon {
  padding-left: 50px;
  position: relative;
}

.title-with-description.title-with-back-icon a {
  position: absolute;
  left: 0;
  font-size: 24px;
  color: #000;
  top: 2px;
  /* transform: translateY(-50%); */
}
.forgot-login-popup .button {
  margin: 0 auto;
}

.toggle-parent .common-info p a {
  padding: 0;
  display: inline-block;
}
.form-group > span i:first-child {
  display: block;
}
.form-group > span i:last-child {
  display: none;
}

.form-group > span.active i:first-child {
  display: none;
}
.form-group > span.active i:last-child {
  display: block;
}
.change-group > span i:first-child {
  display: block;
}
.change-group > span i:last-child {
  display: none;
}

.change-group > span.active i:first-child {
  display: none;
}
.change-group > span.active i:last-child {
  display: block;
}
.slide label:nth-child(2) {
  padding-left: 6px;
}
.slide label:nth-child(3) {
  padding-right: 8px;
}
.posab,
#deactiveConfirm span.active {
  position: absolute;
  right: 15px;
  top: 38px;
}
#processDeactivate.debutton {
  filter: grayscale(1);
}

.ad-ingo {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.arrow-back-title {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 25px 0;
}

.arrow-back-title h2 {
  margin: 0;
}

.ab-back-icon {
  font-size: 22px;
  margin: 0 15px 0 0;
  position: relative;
  top: 2px;
}
.alert-package-places {
  max-width: 540px;
}

.alert-package-places ul {
  padding: 30px 50px 10px;
  list-style: none;
  text-align: left;
}

.alert-package-places ul li {
  margin: 0 0 8px 0;
  padding-left: 24px;
  position: relative;
}

.alert-package-places ul li:before,
.alert-package-places ul li:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  height: 2px;
  background: #dc5656;
  border-radius: 4px;
  width: 12px;
}

.alert-package-places ul li:before {
  transform: rotate(45deg);
}

.alert-package-places ul li:after {
  transform: rotate(-45deg);
}

.alert-package-places button {
  margin-bottom: 0;
}

.alert-package-places .cp-header {
  margin: 0;
}
#checkin-time-earliest-popup .alt_btns a {
  min-width: 126px;
  margin-bottom: 0;
  margin-top: 30px;
}
.filter-stick-popup ul.filter-row-ul {
  display: grid;
  gap: 10px 10px;
  grid-template-columns: 1fr 1fr 1fr;
}
.ttd-p-rhs-select {
  flex-flow: column;
}
.nxt-row-btn {
  position: absolute;
  bottom: 5px;
  max-width: 170px;
  width: 100%;
}
.nxt-row-btn .button {
  margin: 15px auto 0 auto;
  padding: 12px 26px;
  border-radius: 20px;
  width: 100%;
}
.nxt-row-btn.align-center {
  left: 0;
  right: 0;
  margin: 0 auto;
}
.ok-green {
  position: absolute;
  right: 10px;
  top: 33px;
}

.vi-cover a.active {
  background: #4258bf;
  color: #fff;
}

.pwd-tick-show .pwd-tick {
  position: absolute;
  right: 15px;
  bottom: 13px;
  color: #09d509;
}

/*New Admin Screens*/

.main-blue-bg {
  background: #f7f9ff;
}
.main-blue-bg .container-full {
  max-width: 100%;
  /* padding: 0 50px 0 0; */
}
.textdd {
  box-shadow: 0px 3px 10px #00000029;
}
.creator-board-lhs {
  width: 250px;
  height: calc(100vh - 80px);
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  overflow-y: auto;
}

.creator-board-lhs::-webkit-scrollbar {
  display: none;
}

.creator-board-rhs {
  width: calc(100% - 250px);
  padding: 50px 35px;
}

.creator-board-rhs::-webkit-scrollbar {
  display: none;
}
.creator-def a {
  color: #1b252c;
  font-size: 16px;
}
.box-graph-merge {
  background: #ffffff;
  box-shadow: 0px 2px 10px #4258bf29;
  padding: 20px;
  border-radius: 20px;
  width: calc(100% - 190px);
}
.four-box-graph {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px 10px;
  margin: 0 0 20px 0;
}

.fbg-inner {
  background: #fff;
  border-radius: 20px;
  padding: 15px 15px;
  overflow: hidden;
  cursor: pointer;
}
.yellblue-actives {
  width: 175px;
}
.box-graph-actives {
  display: flex;
  justify-content: space-between;
}
.three-merge-btn {
  border-radius: 20px;
  border: 4px solid #d7dbec;
  background-color: #d7dbec;
  line-height: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 3px;
}
.three-merge-btn button {
  margin: 0;
  background: #fff;
  border-color: #d7dbec;
  color: #000;
  letter-spacing: 0;
  border-radius: 20px;
  font-weight: 500;
  padding: 10px 14px;
  line-height: 1;
  font-size: 14px;
  box-shadow: none;
}
.three-merge-btn button.active {
  background: #4258bf;
  color: #fff;
}
.creator-package ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  gap: 15px;
}
.creator-package ul li {
  padding: 20px;
  box-shadow: 0px 2px 10px #4258bf29;
  border-radius: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.creator-package ul li span {
  color: #939393;
  font-size: 12px;
  letter-spacing: 0;
  display: block;
  line-height: 1;
}
.creator-package ul li strong {
  display: block;
  color: #14f014;
  font-weight: 500;
  font-size: 14px;
}
.creator-package ul li p {
  font-size: 16px;
  color: #1b252c;
}
.creator-package ul li h2 {
  letter-spacing: 0.15px;
  color: #4258bf;
  margin: 0 0 10px 0;
  font-weight: 600;
}
.creator-package {
  height: 100%;
}

.creator-board-nav ul {
  padding: 0 20px;
  list-style: none;
  margin: 0 0 50px 0;
}
.creator-board-nav ul li {
  margin: 0 0 10px 0;
}
.creator-board-nav ul li a {
  padding: 11px 22px 11px 30px;
  display: flex;
  align-items: center;
  color: #1b252c;
  font-size: 14px;
  background: #fff;
  border-radius: 15px;
}

.creator-board-nav ul li a img {
  width: 18px;
  margin: 0 25px 0 0;
}
.creator-board-nav ul li.active a {
  color: #4258bf;
  background: #e7edff;
  font-weight: 500;
}
.creator-board-nav ul li.active a img {
  filter: brightness(0) saturate(100%) invert(39%) sepia(93%) saturate(546%)
    hue-rotate(193deg) brightness(77%) contrast(94%);
}
.creator-box {
  padding: 25px;
  margin: 0 0 5px 0;
  display: flex;
  flex-flow: column;
}
.acard-bg {
  position: absolute;
  left: 0px;
  top: -1px;
  width: 120%;
  height: 120%;
  object-fit: cover;
  z-index: -1;
}
.chart-parent {
  position: relative;
  padding-bottom: 250px;
  width: 100%;
}
.chart-resposive {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.pagination-main {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
}
.pagination-main .Mui-selected {
  background-color: #4258bf !important;
  color: #fff;
}
.my-product-listing > ul {
  padding: 0;
  list-style: none;
}
.my-product-listing > ul > li {
  background: #ffffff;
  box-shadow: 0px 2px 10px #4258bf29;
  border-radius: 10px;
  padding: 18px 30px 18px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: center;
}
.my-product-listing .header-row {
  display: flex;
  box-shadow: none;
  background: transparent;
  margin-bottom: 0px;
}
.my-product-listing .header-row .col1 {
  flex: 1;
  color: #343434;
  font-size: 14px;
  font-weight: 500;
}
.my-product-listing .header-row .col2 {
  display: flex;
  flex: 9;
  color: #343434;
  font-size: 14px;
  font-weight: 500;
}
.my-product-listing .header-row .col3 {
  flex: 3;
}
.my-product-listing .header-row .col4 {
  flex: 1;
}
.my-pro-img figure {
  width: 80px;
  height: 65px;
  overflow: hidden;
  border-radius: 6px;
}

.my-pro-maindesc .my-pro-score {
  flex: 1;
}

.my-pro-maindesc .my-pro-score svg {
  width: 60px;
  height: 60px;
}

.my-pro-maindesc .my-pro-type {
  flex: 1;
}

.my-pro-maindesc .my-pro-state {
  flex: 1;
}

.my-pro-maindesc .my-pro-revenue {
  flex: 1;
}

.my-pro-img figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.my-pro-img {
  width: 80px;
  flex: 1;
}

.my-pro-maindesc {
  flex: 9;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 100px);
  align-items: center;
}
.my-pro-maindesc .my-pro-action {
  flex: 1;
}
.my-pro-action a img {
  width: 10px;
  margin: 0 5px 0 0;
  height: 10px;
}

.my-pro-maindesc ul {
  display: flex;
  padding: 0px;
}

.my-pro-maindesc .my-pro-desc {
  flex: 3;
}

.my-pro-maindesc .my-pro-id {
  flex: 1;
}

.my-pro-maindesc .my-pro-desc p {
  color: #343434;
  font-size: 14px;
}

.my-pro-maindesc ul li {
  background: #f0f3ff;
  border: 0.4000000059604645px solid #8ca0ff;
  border-radius: 5px;
  letter-spacing: 0.5px;
  color: #111733;
  font-size: 10px;
  list-style: none;
  margin-right: 0.6rem;
  padding: 3px 10px;
}

.my-pro-action a {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.live-box {
  background: #d1fadc;
  border: 0.4000000059604645px solid #46cc6a;
  border-radius: 5px;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: #111733;
  max-width: 70px;
  text-align: center;
  padding: 4px 10px;
  display: block;
  width: 100%;
  line-height: 1;
}

.live-box.pending {
  background: #ffc2c2;
  border: 0.4000000059604645px solid #e64f4f;
  color: #000;
}

.search-and-add-filter {
  border: 1px solid #4258bf;
  border-radius: 25px;
  background: #fff;
  max-width: 650px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.search-and-add {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 40px 0;
}
.search-and-add-form input[type="text"] {
  border: 0;
  border-radius: 0;
  padding-left: 55px;
  margin-bottom: 0;
  height: 50px;
}
.search-and-add-form {
  position: relative;
  width: calc(100% - 150px);
}
.search-and-add-form .search-lft {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 18px;
  height: 18px;
  filter: brightness(0) saturate(100%) invert(78%) sepia(0%) saturate(1816%)
    hue-rotate(134deg) brightness(79%) contrast(66%);
}
.search-and-filter a img {
  width: 12px;
  height: 12px;
  margin: 0 5px 0 0;
}
.search-and-filter a {
  color: #1b252c;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.search-and-add-btn .ghost-button {
  margin: 0;
  letter-spacing: 0.7px;
  color: #4258bf;
  border-radius: 25px;
  box-shadow: 0px 2px 10px #00000029;
  height: 50px;
  display: flex;
  align-items: center;
  &:hover {
    color: #fff;
  }
}
.search-and-filter {
  width: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.search-and-filter:before {
  left: 0;
  height: 25px;
  width: 1px;
  background: #4258bf;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.search-and-filter a:after {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 17px;
  margin-left: 10px;
}
.search-and-add-btn .ghost-button img {
  width: 12px;
  margin: 0 10px 0 0;
}

.pro-ser-list ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.pro-ser-list ul li a {
  background: transparent linear-gradient(0deg, #fff7e0 0%, #ffe8a7 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 2px 10px #4258bf29;
  display: block;
  border-radius: 20px;
  overflow: hidden;
  padding: 25px 20px 20px 20px;
}

.glyphum {
  box-shadow: 0px 2px 10px #4258bf29;
  background: #fff;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 12px;
}
.dek h4 {
  letter-spacing: 0.16px;
  color: #1b252c;
  font-size: 16px;
  margin: 0;
  line-height: 20px;
}
.dek {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 40px 0 0 0;
}

.glyp-plus {
  background: #4258bf;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.glyp-plus img {
  width: 14px;
}
.standfirst {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  align-items: center;
}

.standfirst .color-header {
  margin: 0;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
}
.standfirst .color-header span {
  margin-left: 10px;
}
.standfirst .btn-scoi {
  margin: 0;
  letter-spacing: 0px;
  padding: 15px 32px;
}
.standfirst .arrow-big {
  width: 20px;
  margin: 0 15px 0 0;
  height: 20px;
  display: block;
}
.tabloid ul {
  padding: 0 0 0 15px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.tabloid ul li {
  margin: 0 20px;
}
.tabloid ul li a {
  letter-spacing: 0.7px;
  color: #1b252c;
  font-weight: 500;
  transition: 0.2s linear all;
  border-bottom: 1px solid #f7f9ff;
}
.tabloid ul li a:hover,
.tabloid ul li.active a {
  color: #4258bf;
  border-color: #4258bf;
}
.tabloid {
  padding: 0 0 30px 0;
}
.billboard {
  background: #ffffff;
  box-shadow: 0px 2px 10px #4258bf29;
  border-radius: 20px;
  padding: 35px 35px 10px 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.billboard .flight-results {
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 2px 10px #4258bf29;
  position: absolute;
  z-index: 6;
  width: 100%;
  padding: 10px;
}
.billboard .pHightlights {
  display: flex;
  justify-content: space-between;
}
.billboard .pHightlights .highlight-btn {
  position: initial;
}
.billboard .soc-add {
  padding-right: 0px;
  margin-bottom: 16px;
}
.billboard .pHightlights input {
  width: 78%;
}
.billboard .pHightlights .button.soc-btn {
  top: 0px;
}
.billboard input,
.billboard textarea,
.billboard select,
.iteinery-stay-popup .input-border,
.iteinery-stay-popup select {
  border-color: #e0e0e0;
  border-radius: 5px;
}
.billboard .pHightlights select {
  margin: 0 10px;
  width: 30%;
}
.billboard .control-label em,
.abstract-title em,
.iteinery-stay-popup em {
  font-style: normal;
  color: #ff2d16;
}
.billboard .control-label {
  color: #212529;
  font-weight: 500;
}
.billboard textarea {
  height: 140px;
}
.billboard-rhs {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}
.abstract ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(6, 150px);
  grid-template-rows: repeat(auto, 100px);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.abstract ul li {
  border: 2px dashed #4258bf;
  border-radius: 5px;
  background: #e7edff;
}

.abstract ul li a,
.fgp-add-row ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0;
}

.abstract ul li a img,
.fgp-add-row ul li a img {
  width: 25px;
  margin: 0 0 7px 0;
}

.abstract ul li figcaption,
.fgp-add-row ul li figcaption {
  letter-spacing: 0.5px;
  font-size: 10px;
}
.abstract {
  padding: 0 0 30px 0;
}
.abstract-title {
  margin: 0 0 20px 0;
}
.abstract-title h4 {
  margin: 0 0 2px 0;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 500;
}
.abstract-title p {
  margin: 0;
  letter-spacing: 0px;
  color: #868e96;
  font-size: 12px;
}
.brochure {
  padding: 35px 35px 15px 35px;
  background: #ffffff;
  box-shadow: 0px 2px 10px #4258bf29;
  border-radius: 20px;
}
.broadsheet {
  background: #ffffff;
  box-shadow: 0px 2px 10px #4258bf29;
  border-radius: 10px;
  padding: 22px 22px 16px 22px;
  margin: 0 0 15px 0;
}
.broadsheet h4 {
  color: #343434;
  letter-spacing: 0;
  margin: 0 0 10px 0;
  font-size: 14px;
  font-weight: 500;
}
.bs-merge {
  position: relative;
  padding: 0 50px 0 0;
  display: grid;
  grid-template-columns: 60% 40%;
}

.room-editn {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  font-size: 14px;
}

.room-editn img {
  width: 10px;
  margin: 0 5px 0 0;
  height: 10px;
}
.bs-option ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.bs-option ul li {
  border-radius: 5px;
  letter-spacing: 0.5px;
  color: #111733;
  font-size: 10px;
  background: #f0f3ff;
  border: 1px solid #8ca0ff;
  padding: 4px 7px;
  line-height: 1;
  margin: 0 6px 5px 0;
}
.bs-people {
  padding: 0 0 0 10px;
}
.bs-option .breakfast {
  background: #ffdb7580;
  border-color: #ffdb75;
}
.bs-option .lunch {
  background: #46cc6a80;
  border-color: #46cc6a80;
}
.bs-option .diner {
  background: #e64f4f80;
  border-color: #e64f4f80;
}
.bs-option-one {
  margin: 0 0 5px 0;
}
.add-r-type {
  border: 2px dashed #4258bf;
  border-radius: 5px;
  background: #e7edff;
  margin: 22px 0 0 0;
}

.add-r-type a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}

.add-r-type a img {
  width: 25px;
  margin: 0 0 7px 0;
}

.add-r-type figcaption {
  letter-spacing: 0.5px;
  font-size: 11px;
}
.fgp-add-row ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 0fr 0fr 0fr 0fr 0fr;
  gap: 10px;
}
.fgp-add-row ul li a {
  border: 2px dashed #4258bf;
  border-radius: 5px;
  background: #e7edff;
}
.fgp-add-img {
  margin: 0 0 20px 0;
}

.fgp-add-img p {
  font-size: 12px;
  color: #868e96;
  padding-left: 6px;
}

.fgp-add-img .control-label {
  padding-bottom: 2px;
}
.sub-tabloid ul li.add-stay-plus a {
  background: #fff;
  letter-spacing: 0;
  padding: 5px 10px;
  border: 1px solid #4258bf;
  border-radius: 25px;
  color: #4258bf;
  font-size: 14px;
}
.sub-tabloid ul li.add-stay-plus a:hover {
  background: #4258bf;
  color: #fff;
}
.billboard-nav {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 10px 0;
}
.billboard-navrhs select {
  margin: 0;
  border: 1px solid #4258bf;
  border-radius: 25px;
  color: #4258bf;
  font-size: 14px;
  height: 40px;
}

.spread ul {
  padding: 0;
  padding-top: 2rem;
  list-style: none;
}

.spread-box {
  background: #ffffff;
  border: 2px dashed #4258bf;
  border-radius: 10px;
  padding: 21px 20px;
  display: flex;
  justify-content: space-between;
  max-width: 850px;
  width: 100%;
  margin: 0 0 25px 0;
  align-items: center;
}

.spread-box-button {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.spread-box-content {
  width: calc(100% - 320px);
  font-size: 16px;
  letter-spacing: 0.16px;
  font-weight: 500;
  color: #1b252c;
  line-height: 20px;
}

.spread-box-button .button {
  margin: 0;
  letter-spacing: 0px;
  font-size: 12px;
  width: 100%;
  padding: 11px 20px;
  text-transform: inherit;
}

.spread-box-content span {
  color: #4258bf;
}
.iteinery-stay-popup {
  max-width: 520px;
  padding: 30px;
}

.inline-checkbox ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 24px;
}

.inline-checkbox ul li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.inline-checkbox ul li span {
  margin-left: 12px;
}
.fb-box-chk label {
  margin: 0 0 20px 0;
}
.isp-header {
  padding: 0 0 25px 0;
}
.isp-header h3 {
  margin: 0;
  font-size: 18px;
}
.isp-body .control-label {
  color: #212529;
}
.isp-footer .button {
  margin-bottom: 0;
  padding: 12px 22px;
}

.highlight-btn {
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 0rem;
  left: 26rem;
}

.file-input {
  opacity: 0;
}

.faq {
  border-radius: 20px;
  background-color: none;
  font-weight: 500;
  padding: 22px 50px 22px 40px;
  line-height: 1;
  color: #1b252c;
  font-size: 17px;
  letter-spacing: 0.9px;
  position: relative;
  cursor: pointer;
}
.faq:after {
  position: absolute;
  content: "\f078";
  right: 20px;
  top: 20px;
  font-family: FontAwesome;
  transition: 0.3s linear all;
  font-weight: normal;
  font-size: 16px;
}
.incl-exclu-card {
  display: flex;
  justify-content: space-between;
}

.incl-exclu-card h3 {
  margin-top: 0;
}

.incl-exclu-card ul {
  padding-left: 20px;
}

.incl-exclu-card li {
  margin-bottom: 8px;
}

.incl-exclu-card li:last-child {
  margin-bottom: 0;
}

.incl-exclu-card > * {
  flex: 1;
  padding: 0 8px;
}
.no-close-btn .mfp-close {
  display: none;
}
.input_valid_error {
  color: rgb(255, 86, 86);
  font-size: 12px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .flight-search {
    flex-wrap: wrap;
  }
}

.input_valid_error {
  color: rgb(255, 86, 86);
  font-size: 12px;
  font-weight: 500;
}
.flight-results > h3 {
  text-align: center;
  margin: 0px;
}
.loadmorepackage {
  text-align: center;
}
/* View fare break down style */
.view-fare-cntnr {
}
.view-fare-row {
  padding: 1rem;
  border-bottom: 1px solid #dfe1e5;
}
.view-fare-row ul {
  padding: 0;
  margin: 0;
}
.view-fare-row ul li {
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: #33425b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.view-fare-row .service-content strong {
  font-weight: 400;
}
.view-fare-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #33425b;
  margin: 0;
}
.view-fare-row .service-content,
.view-fare-discount .service-content {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.25rem;
}
.view-fare-row .service-content img {
  width: 14px;
  aspect-ratio: 1;
}
.view-fare-row .service-amount {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #21314d;
}
.view-fare-cntnr .details {
  padding: 1rem;
  border-bottom: 1px solid #dfe1e5;
}
.view-fare-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
  color: #33425b;
}
.view-fare-discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #039754;
  margin-bottom: 0.5rem;
}
.view-fare-cntnr .action {
  padding: 1.5rem 1rem 1rem;
}
.view-fare-cntnr .action button {
  width: 100%;
  padding: 12px 20px;
  background: #4258bf;
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  text-transform: capitalize;
}
.price-change-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 37px;
  width: 538px;
  height: 100%;
  border-radius: 16px;
}
.price-change-container {
  justify-content: center;
  align-items: center;
  /* padding: 16px;
    gap: 16px;
    width: 464px;
    height: 126px; */
}
.cp-pricechange-body h4 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #243069;
}
.cp-pricechange-body span {
  color: #4258bf;
}
.price-change-flight {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.price-change-flight h4 {
  font-weight: 500;
  font-size: 12px;
  color: #33425b;
  margin: 0px;
}
.price-change-route {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0 0;
}
.price-change-route > div {
  width: 105px;
}

.price-change-from {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.from-date {
  font-weight: 400;
  font-size: 12px;
  color: #4e5b71;
}
.from-time {
  font-weight: 600;
  font-size: 20px;
  color: #33425b;
}
.from-departure {
  font-weight: 600;
  font-size: 14px;
}
.price-change-top .to-departure {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #33425b;
}
.previous-price > h6 {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  margin: 0px;
  color: #21314d;
}
.price-changed-price h4 {
  font-weight: 600;
  font-size: 16px;
  margin: 0px;
  text-align: center;

  color: #3c50ae;
}
.price-changed-price {
  border: 0.5px solid #dfe1e5;
  /* border-radius: 0px 0px 12px 12px; */
  display: flex;
  justify-content: space-around;
  padding: 8px;
}
.previous-price-image {
  margin: auto 0px;
}
.price-changed-price img {
  width: 17.97px;
  height: 14px;
}
.price-continue {
  padding: 1rem 0;
}
.price-continue p {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #243069;
}
.price-change-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}
.price-change-button button {
  padding: 12px 20px;
  width: 213px;
  margin: 0px 2px;
  height: 48px;
  /* background: #ECEEF9; */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 48px;
}
.price-change-flight span {
  width: 4px;
  height: 4px;
  background: #a5acb7;
  border-radius: 12px;
  margin: 0px 12px;
}
.price-change-flight .Airline-Image {
  margin-right: 6px;
}
.price-change-button button:first-child {
  background: #eceef9;
  color: #2f3e88;
  border: none;
}
.price-change-stop {
  position: relative;
}
.price-change-stop .from-date,
.stop-type {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #33425b;
}
.stop-image {
  margin: 6px 0;
}
.stop-image img {
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 1;
}
.price-change-stop:after {
  bottom: 32px;
  left: -40px;
  right: -40px;
  background-image: linear-gradient(to right, #dbdbe0 50%, transparent 0%);
  background-size: 14px 2px;
  background-repeat: repeat-x;
  background-position: 0% bottom;
  animation-name: border-dance;
  color: #b2b7c1;
  animation-duration: 45s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  margin: auto;
}
.price-change-type-route {
  border-radius: 12px 12px 0px 0px;
}
.price-border {
  padding: 1rem;
  border: 0.5px solid #dfe1e5;
}
.price-change-type-route-customize {
  padding: 1rem;
  border: 0.5px solid #dfe1e5;
  border-top: none;
}
.original-price > h4 {
  color: "#21314d" !important;
}
.price-change-to {
  text-align: right;
}
.price-border {
  border-bottom: none !important;
}
.price-change-border {
  border-radius: 0px 0px 12px 12px;
}
.onlyReturn-border {
  border-radius: 12px 12px 0px 0px;
  border: 0.5px solid #dfe1e5;
}
.onlyOnward-border {
  border-radius: 0px 0px 12px 12px;
}
@media (max-width: 480px) {
  .alert-package-places {
    max-width: 100%;
    height: 100%;
  }
  .cp-pricechange-body {
    max-width: 100%;
  }
  .price-change-button {
    flex-direction: column-reverse;
  }
  .price-change-button button {
    padding: 12px 20px;
    width: 328px;
    height: 48px;
  }
  .price-change-button button:last-child {
    margin-bottom: 12px;
  }
  .price-change-stop:after {
    bottom: 34px;
    left: -30px;
    right: -30px;
    display: block;
    height: 1px;
    margin: auto;
  }
  .from-date {
    font-size: 10px;
  }
  .price-change-stop .from-date,
  .stop-type {
    font-size: 8px;
  }
  .stop-image img {
    width: 12px;
    height: 12px;
  }
}
