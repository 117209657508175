.asst-popup-main {
  &__row {
    width: 65vw;
    height: 512px;
    display: flex;
    background: #ffffff;
    @media (max-width: 480px) {
      width: 100vw;
      height: auto;
      flex-wrap: wrap;
    }
    &__col1 {
      width: 50%;
      background: #12278d;
      padding: 1.5rem;
      position: relative;
      overflow: hidden;
      @media (max-width: 480px) {
        width: 100%;
        height: auto;
        padding: 1.5rem 1rem 1rem;
      }
      &__bgVec {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.5);
      }
      &__heading {
        font-weight: 700;
        font-size: 28px;
        line-height: 1.2;
        color: #ffffff;
        margin-bottom: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        column-gap: 0.5rem;
        @media (max-width: 480px) {
          font-size: 20px;
          width: 75%;
        }
        > P {
          margin: 0;
        }
        .css-1wlk0hk-MuiAvatar-root,
        .css-3i9vrz {
          width: 20px;
          height: 20px;
          border: 3px solid #eceef9;
        }
      }
      &__subTxt {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: #ffffff;
        margin-bottom: 1.5rem;
        @media (max-width: 480px) {
          font-size: 12px;
          margin-bottom: 0;
          width: 75%;
        }
      }
      &__service {
        box-shadow: 0px 0px 49.20000076293945px rgba(255, 255, 255, 0.25);
        background: linear-gradient(22.5deg, #ffffff, #203289, #ffffff);
        border-radius: 1rem;
        padding: 1px;
        position: inherit;
        z-index: 1;
        ul {
          background-color: #0f2071;
          padding: 1.5rem 1.5rem 1.5rem 3rem;
          border-radius: 1rem;
          li {
            font-weight: 600;
            font-size: 14px;
            line-height: 1;
            color: #ffffff;
            list-style-image: url("../../../common/images/list-star-Icon.svg");
            &:not(:last-child) {
              padding-bottom: 1.5rem;
            }
          }
        }
      }
    }
    &__col2 {
      width: 50%;
      padding: 1.5rem 0;
      @media (max-width: 480px) {
        width: 100%;
        height: fit-content;
        padding: 1rem 0;
      }
      &__heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.3;
        color: #21314d;
        margin: 0 1.5rem 1rem;
        @media (max-width: 480px) {
          font-size: 14px;
          margin: 0 1rem 1rem;
        }
      }
      &__inputs {
        max-height: 70%;
        overflow-y: auto;
        padding: 0 1.5rem;
        @media (max-width: 480px) {
          height: 37vh;
          overflow-y: auto;
          padding: 0 1rem;
        }
        > label,
        .css-u4tvz2-MuiFormLabel-root,
        .css-1vovazs {
          display: block;
          margin: 1rem 0 0.75rem 0;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.4;
          color: #122441;
        }
        > input {
          height: 48px;
          margin-bottom: 0;
          border: 1px solid #c1c5cd;
          padding-left: 1rem;
          transition: box-shadow 0.15s ease-out, border-color 0.15s ease-in;
          &:hover {
            background: #fafafb;
          }
          &:focus {
            border: 0.6px solid #3c50ae;
            box-shadow: 0 0 0 4px #dbe2ff;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
        .css-1nrlq1o-MuiFormControl-root {
          width: 100%;
        }
        .css-r47a1p-MuiFormControl-root,
        .css-kzv9dm {
          width: 100%;
          margin: 0;
        }
        .css-j204z7-MuiFormControlLabel-root {
          font-family: "Poppins" !important;
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 1.4 !important;
          color: #33425b !important;
          margin-right: 0.75rem !important;
        }
        .css-dmmspl-MuiFormGroup-root {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          margin-bottom: 1rem;
        }
        .css-1m9pwf3 {
          display: none;
        }
        .css-qfz70r-MuiFormGroup-root,
        .css-p58oka {
          margin-bottom: 1.75rem;
          row-gap: 1rem;
        }
        .css-qfz70r-MuiFormGroup-root:last-child,
        .css-p58oka:last-child {
          margin-bottom: 1.5rem;
        }
        .react-tel-input {
          .form-control {
            border: 1px solid #c1c5cd !important;
            padding-left: 4.5rem;
            &:hover {
              background: #fafafb;
            }
            &:focus {
              border: 0.6px solid #3c50ae;
              box-shadow: 0 0 0 4px #dbe2ff;
            }
          }
          .selected-flag {
            padding: 0;
          }
          .flag-dropdown {
            width: 56px;
            border: 1px solid #c1c5cd !important;
            border-radius: 0.75rem 0 0 0.75rem !important;
            background: #ffffff;
            padding: 0 1rem 0;
            &:hover {
              background: #fafafb;
            }
            &:focus {
              border: 0.6px solid #3c50ae;
              box-shadow: 0 0 0 4px #dbe2ff;
            }
            .arrow {
              display: none;
            }
            .flag {
              transform: scale(1.5);
            }
            .css-i4bv87-MuiSvgIcon-root {
              width: 20px;
              height: 20px;
              fill: #798293;
            }
          }
        }
        .css-ahj2mt-MuiTypography-root,
        .css-9l3uo3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.4;
          color: #33425b;
        }
        .css-vqmohf-MuiButtonBase-root-MuiRadio-root,
        .css-1a5icme {
          padding: 6px;
        }
        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
          color: #3c50ae;
        }

        &__checkbox-grp {
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          row-gap: 0.75rem;
          &:last-child {
            margin-bottom: 1.75rem;
          }
          > p {
            margin: 1rem 0 0 0;
            width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: left;
            color: #122441;
          }
          > label {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.4;
            color: #33425b;
            display: flex;
            align-items: center;
          }
          @supports (-webkit-appearance: none) or (-moz-appearance: none) {
            input[type="checkbox"] {
              width: 20px;
              height: 20px;
              border: 0.3333333432674408px solid #798293;
              border-radius: 5px;
              --focus: 0;
              margin-right: 6px;
              &:not(:checked):hover {
                background: #eceef9;
                @media (max-width: 480px) {
                  background: #ffffff;
                }
              }
            }
          }
          input[type="checkbox"]:not(.switch):after {
            top: 4px;
            left: 7px;
          }
        }
        &__errorText {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 0.25rem;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.2;
          color: #d92c20;
          margin-top: 0.5rem;
        }
        .errorInput {
          border: 0.6px solid #d92d20;
          box-shadow: 0 0 0 4px #ffe3e8;
          border-radius: 12px;
        }
      }
      &__btns {
        padding: 0 1.5rem 0;
        position: relative;
        @media (max-width: 480px) {
          padding: 0 1rem;
        }
        &__scroll-shadow {
          // @media (min-width: 481px) {
          position: absolute;
          z-index: 10;
          left: 0;
          bottom: 100%;
          width: 100%;
          height: 32px;
          background: linear-gradient(
            0deg,
            #f5f5f5 23.99%,
            hsla(0, 0%, 96.1%, 0.6) 63.97%,
            hsla(0, 0%, 96.1%, 0.0001)
          );
          // }
        }
        Button {
          width: 100%;
          padding: 0.75rem 1.25rem;
          background: #4258bf;
          border-radius: 48px;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          color: #ffffff;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          margin-bottom: 1rem;
          transition: background-color 0.07 ease-in-out;
          &:hover {
            background-color: #2f3e88;
            color: #ffffff;
            @media (max-width: 480px) {
              background: #4258bf;
            }
          }
        }
        &__agreeTxt {
          display: flex;
          align-items: start;
          justify-content: left;
          gap: 0.5rem;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #4e5b71;
          margin: 0;
          > p {
            margin: 0;
          }
        }
      }
    }
  }
}
