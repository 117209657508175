.transfer-card {
  padding: 16px 16px;
  background: #ffffff;
  border: 1px solid #ebedef;
  border-radius: 16px;
  box-shadow: none;
  margin: 0 0 30px 0;
  letter-spacing: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  z-index: 1;
  @media (max-width: 480px) {
    padding: 0;
  }
  .hsr-lhs {
    width: 100%;
    .star-rating-review {
      margin-top: 5px;
    }
    .hsr-lhs-desc > h4 {
      @media (max-width: 480px) {
        padding: 0 1rem;
      }
    }
  }
  .hsr-rhs {
    width: 100%;
  }
}
.transfer-footer {
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start !important;
    border: 0 !important;
    > .tags {
      width: 100%;
      padding: 0.5rem 1rem 0.75rem !important;
      border-bottom: 0.5px solid #dfe1e5 !important;
    }
    > .hsr-rhs {
      padding: 1rem !important;
      justify-content: right;
      > p {
        margin: 0 !important;
      }
    }
  }
  > .button {
    @media (max-width: 480px) {
      width: 90%;
      margin: 0 1rem 1rem;
    }
  }
}
