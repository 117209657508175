@media (max-width: 1200px) {
  /*.step-banner img.full-width {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: top;
    height: 100%;
} */
  .step-banner {
    position: relative;
    padding: 25px 0;
  }
  .step-banner .container {
  }
  .gradient-box ul li h4 {
    font-size: 18px;
  }
}
@media (max-width: 1180px) {
  .package-ul > li {
    width: 31.901288%;
    margin: 0 1.648069% 40px 0;
  }
}
@media (max-width: 1120px) {
  .menu > li {
    margin: 0px 10px 0;
  }
  .detail-bottom-lhs {
    width: calc(100% - 370px);
  }
  .step-banner-lhs h1 {
    font-size: 40px;
    letter-spacing: 1.75px;
    line-height: 48px;
    margin-bottom: 15px;
  }
  .step-banner-lhs p {
    font-size: 18px;
  }
}

@media (max-width: 1080px) {
  .container {
    padding: 0 15px;
  }
  header .container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-top {
    flex-wrap: wrap;
  }
  .footer-nav {
    width: 100%;
  }
  .footer-logo {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
  .filter-icon {
    display: inline-flex;
  }
  .explore-img:after {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
  .promo-def h4 {
    font-size: 20px;
  }
  .social-media {
    margin-bottom: 15px;
  }
  .outlet-listing-lhs {
    display: none;
  }
  .outlet-listing-rhs {
    width: 100%;
  }
  .outlet-listing-filter ul li {
    width: 18%;
    margin: 0 1% 5px 0;
  }
  .mobile-filter {
    display: block;
    position: sticky;
    bottom: 0;
    z-index: 3;
  }
  .profile-detail-right li {
    font-size: 14px;
    padding: 12px 0;
  }
  .admin-profile-rhs {
    width: calc(100% - 290px);
  }
  .wishlisth-rsh {
    width: calc(100% - 300px);
  }
  .profile-edtbtn button {
    font-size: 14px;
    padding: 12px 20px;
    margin: 0;
  }
  .profile-edtbtn {
    margin: 30px 0 0;
  }
  .profile-detail-left {
    width: 200px;
  }
  .profile-detail-right {
    width: calc(100% - 205px);
  }
  .profile-menu li > a {
    font-size: 14px;
  }
  .admin-profile-lhs {
    padding: 0 0px 30px;
  }
  .mybooking-inner-main {
    width: 100%;
  }
  .packagelist-lhs {
    flex-flow: row;
    margin: 0 5px 10px 0;
    width: 100%;
    align-items: center;
  }
  .packagelist-lhs span {
    padding-right: 10px;
  }
  .packagelist-lhs strong {
    margin: 0;
  }
  .mybooking-inner-left {
    width: calc(100% - 180px);
  }
  .wishlisth-rsh.outlet-listing-rhs > ul {
    justify-content: space-between;
  }
  .wishlisth-rsh.outlet-listing-rhs > ul > li {
    width: 49%;
    margin: 0 0 20px 0;
  }
  .payment-lhs {
    width: calc(100% - 350px);
  }
  .aproduct-img {
    margin: 0 auto 10px;
  }
  .promo-banner-lhs h1 {
    font-size: 30px;
  }
  .promo-banner-lhs p {
    font-size: 16px;
  }
  .detail-top-detail-parent {
    width: 100%;
    padding: 0 0 10px 0;
    border-right: 0;
    border-bottom: 3px solid #4258bf;
  }
  .detail-top-detail-parent-rating {
    width: 100%;
    text-align: center;
    padding: 10px 0 0 0;
  }
  .detail-bottom-package-title .bold-with-blue {
    margin-bottom: 10px;
  }
  .reward-total-note {
    width: 100%;
  }
  .reward-total-rhs ul li {
    font-size: 13px;
  }
  .reward-total-rhs {
    padding: 0 0 0 25px;
  }
  .package-details {
    flex-wrap: wrap;
  }
  .package-details-rhs {
    width: 100%;
  }
  .package-details-lhs {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .fs-location {
    width: 100%;
    margin-right: 0;
  }
  .fs-stay {
    width: 33%;
    margin-right: 2%;
  }
  .fs-whom {
    width: 30%;
  }
  .flight-search .fg-btn {
    bottom: 0;
    top: inherit;
  }
  .package-ul {
    justify-content: space-between;
  }
  .package-ul > li {
    width: 48.5%;
    margin: 0 0 30px 0;
  }
}
@media (max-width: 980px) {
  .vb-def {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.1;
  }
  .step-banner-lhs h1 {
    font-size: 36px;
    letter-spacing: 1.75px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .step-banner-lhs p {
    font-size: 18px;
    letter-spacing: 0.1px;
    line-height: 1.3;
  }
  .platform-step h1 {
    font-size: 35px;
    letter-spacing: 1.25px;
  }
}
@media (max-width: 920px) {
  .vacation-box-img {
    display: none;
  }
  .vacation-box-btm {
    min-height: inherit;
  }
  .vacation-box-txt {
    max-width: 100%;
    text-align: center;
  }
  .step-banner-form {
    max-width: 420px;
    padding: 25px 25px 25px 25px;
  }
  .step-banner-lhs {
    width: calc(100% - 450px);
  }
  .step-banner {
    height: 580px;
  }
}

@media (max-width: 880px) {
  .navigation,
  .header-links,
  .explore-inner {
    display: none;
  }
  .nav-top {
    display: block !important;
  }
  header {
    padding: 15px 0;
  }
  .explore-inner-mobile {
    display: block;
  }
  .detail-bottom-lhs {
    width: 100%;
  }
  .detail-bottom-rhs-map {
    display: none;
  }

  .detail-top-header h1 {
    font-size: 26px;
  }
  .mybooking-inner-left {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .mybooking-inner-righ {
    width: 100%;
  }
  .mybooking-btn-div {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
  }
  .mybooking-btn-div .button {
    width: 48.5%;
    padding: 12px 10px;
    font-size: 13px;
  }
  .mycalcel-btn-div {
    padding: 20px 0 0 0;
  }
  .admin-profile-rhs h5,
  .wishlisth-header h5 {
    margin: 0px 0 20px;
    font-size: 18px;
    letter-spacing: 0.5px;
  }
  .profile-detail-right {
    width: 100%;
    padding-top: 20px;
  }
  .profile-detail-footer button {
    width: auto;
  }
  .humbur {
    display: block;
  }
  .detail-bottom-rhs {
    width: 100%;
    position: inherit;
    top: inherit;
  }
  .detail-bottom-rhs .package-starts {
    display: none;
  }
  .detail-bottom-lhs .package-starts {
    display: block;
    text-align: center;
  }
  .pricing-details {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: #fff;
    top: inherit;
    box-shadow: 0 -10px 55px rgba(0, 0, 0, 0.16);
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }
  .pricing-details:before {
    background: rgb(27 37 44 / 20%);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    top: 0;
    content: "";
  }

  .expolre-promo > ul > li {
    width: 32.03125%;
    margin: 0 1.953125% 40px 0;
  }
  .expolre-promo > ul > li:nth-child(3n) {
    margin-right: 0;
  }
  .expolre-promo > ul > li:nth-child(4n) {
    margin-right: 1.953125%;
  }
  .expolre-list p br {
    display: none;
  }
  .expolre-list h4 {
    font-size: 20px;
    margin: 0 0 15px 0;
  }
  .promo-banner-lhs h1 {
    font-size: 22px;
  }
  .detail-bottom-rhs-promo {
    margin-bottom: 10px;
  }
  .earn-green {
    border-radius: 0px;
    max-width: 100%;
    margin: 0 auto 0px;
    justify-content: center;
  }
  .three-activity .up-accordion-content > ul {
    padding: 50px 15px 70px 15px;
  }
  .days-text {
    right: 15px;
  }
}
@media (max-width: 780px) {
  .header-top .container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .banner .hero-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .banner .owl-carousel .owl-item {
    padding: 150px 0;
  }
  .banner .owl-dots {
    bottom: 15px;
  }
  .banner .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
  }
  .banner-form {
    top: inherit;
    transform: none;
    bottom: 50px;
    text-align: center;
  }
  .banner-form-parent h1 {
    font-size: 32px;
  }
  .outlet-listing-filter h2 {
    font-size: 22px;
    letter-spacing: 1px;
  }
  .innersection {
    padding: 20px 0 20px 0;
  }
  .outlet-listing-rhs > ul {
    justify-content: space-between;
  }
  .outlet-listing-rhs > ul > li {
    width: 48%;
    margin: 0 0 30px 0;
  }
  .profile-detail-footer {
    padding: 30px 0 0;
  }
  .profile-detail-footer p {
    margin: 0 0 15px 0;
    width: 100%;
    font-size: 14px;
  }
  .payment-lhs {
    width: 100%;
    order: 2;
  }
  .payment-rhs {
    width: 100%;
    order: 1;
    position: relative;
    top: inherit;
    margin-bottom: 30px;
  }
  .payment-header h2 {
    font-size: 26px;
  }
  .payment-header h2 br {
    display: none;
  }
  .formone-header {
    display: block;
    padding-bottom: 20px;
    width: 100%;
  }
  .form-one,
  .form-two-inner,
  .form-three-inner {
    box-shadow: none;
  }
  .form-one,
  .form-two,
  .form-three {
    border-radius: 10px;
    border: 1px solid #4258bf;
    padding: 30px;
    margin: 0 0 25px 0;
  }
  .form-two-inner,
  .form-three-inner {
    padding: 0;
    border-radius: 0;
    margin: 0;
  }
  .form-two h4,
  .form-three h4 {
    font-size: 20px;
    font-weight: 600;
  }
  .formone-header h4,
  .form-two h4 {
    margin-bottom: 5px;
    letter-spacing: 0;
  }
  .formone-header p {
    margin-bottom: 0;
    font-size: 13px;
  }
  .fg-textarea .control-label {
    font-size: 13px;
  }
  .promo-banner-lhs,
  .singapore .promo-banner-lhs {
    order: 2;
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  .promo-banner-rhs {
    order: 1;
    width: 100%;
  }

  .promo-banner-rhs img {
    position: inherit;
    width: 100%;
  }

  .promo-banner .container {
    padding: 0;
    position: relative;
  }

  .promo-banner-lhs:after,
  .promo-banner-lhs h1 br {
    display: none;
  }
  .promo-banner-lhs h1 {
    font-size: 20px;
    margin: 0px 0px 15px 0px;
  }
  .promo-banner-lhs p {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .vacation-box-top ul {
    justify-content: space-between;
  }
  .vacation-box-top ul li {
    width: 48.5%;
    margin: 0 0 20px 0;
  }
  .platform-step ul li {
    width: 32%;
    margin: 0 0 20px 0;
  }
  .platform-step ul li p {
    font-size: 15px;
    line-height: normal;
    margin-bottom: 0;
  }
  .platform-step ul {
    padding: 30px 0 10px 0;
  }
  .platform-step h1 {
    font-size: 30px;
  }
  .gradient-box ul li {
    width: 49%;
    margin: 0 0 15px 0;
  }
  .step-banner-lhs h1 {
    font-size: 25px;
  }
  .step-banner-lhs p {
    font-size: 16px;
  }
  .hsr-lhs {
    width: 100%;
    padding: 0 0 0 0;
  }

  .unique-package-stay .hsr-lhs-desc {
    padding: 0 0 50px 20px;
    width: calc(100% - 260px);
  }
  .unique-package-stay .hsr-lhs-img {
    width: 260px;
  }
  .uss-inner-top {
    padding: 20px 20px 20px;
    border-radius: 10px;
    margin: 0 0 30px 0;
  }
  .room-choice-desc {
    padding: 15px 15px 20px;
  }
  .flight-search-rhs,
  .hsr-rhs {
    width: 100%;
    flex-flow: row;
    border-top: 1px solid #e4e4e4;
    padding-top: 10px;
    margin-top: 10px;
  }
  .flight-search-lhs {
    width: 100%;
  }
  .flight-search-rhs .button,
  .hsr-rhs .button {
    margin: 0 0 0 10px;
    width: auto;
    padding: 10px 12px;
    min-width: 110px;
  }
}

@media (max-width: 720px) {
  .admin-profile-lhs {
    width: 100%;
    padding: 0 0px 30px;
  }
  .admin-profile-rhs,
  .wishlisth-rsh {
    width: 100%;
  }
  .profile-menu li > a {
    padding: 35px 5px 5px 5px;
    font-size: 13px;
    justify-content: center;
    flex-flow: column wrap;
    text-align: center;
    position: relative;
    justify-content: flex-start;
    height: 100%;
    font-weight: 600;
  }
  .profile-menu li {
    width: auto;
    margin: 0 15px;
  }
  .profile-menu li:first-child {
    margin-left: 0;
  }
  .profile-menu li a img {
    margin-right: 0;
  }
  .profile-menu li > a img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 5px;
  }
  .profile-menu {
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    padding: 15px 20px;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    border-radius: 5px;
  }
  .profile-menu li > a:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: 0px;
    background: #4258bf;
    height: 1px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .profile-menu li.active > a:before,
  .profile-menu li > a:hover:before {
    left: 0;
    right: 0;
  }
  .wishlisth-rsh,
  .mybooking-rhs {
    padding: 15px 15px;
  }
  .product-image-more span {
    font-size: 12px;
  }
  .reward-main-rhs {
    width: 100%;
    padding: 0;
  }
  .step-banner-lhs {
    width: 100%;
    text-align: center;
    margin: 0 0 20px 0;
  }
  .step-banner-form {
    margin: 0 auto;
  }
  .package-ul > li {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .rcf-lhs strong {
    font-size: 20px;
  }
}

@media (max-width: 680px) {
  .form-three-lhs {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .form-three-lhs:last-child {
    margin-bottom: 0;
  }

  .platform-step ul li figure img {
    max-height: 60px;
  }
  .platform-step ul li figure {
    margin: 0 0 20px 0;
  }
  .platform-step ul li h5 {
    font-size: 18px;
    margin: 0px 0px 10px 0px;
  }
  .platform-step ul li {
    text-align: center;
  }
  .blue-nav ul li {
    margin: 0 10px 0 0;
  }
  .ufs-box .fsl-from {
    width: 160px;
  }
  .ufs-box .fsl-to {
    width: 160px;
  }
  .ufs-box .fsl-path:after {
    left: 170px;
    right: 170px;
  }
  .ufs-box .fsl-from p,
  .ufs-box .fsl-to p {
    font-size: 13px;
  }
  .ufs-box .fsl-from p span,
  .ufs-box .fsl-to p span {
    font-size: 13px;
  }
}
@media (max-width: 620px) {
  .detail-top-lhs {
    width: 100%;
    order: 2;
  }
  .detail-top-rhs {
    order: 1;
    width: 100%;
    margin-bottom: 30px;
  }
  .detail-top-rhs .owl-carousel .owl-next,
  .detail-top-rhs .owl-carousel .owl-prev {
    background-size: contain;
    width: 32px;
    height: 32px;
  }
  .thankyou-box {
    padding: 15px;
  }
  .fsl-path {
    width: 100%;
  }
  .fsl-logo span {
    padding: 5px 0 0 0;
  }
  .fsl-btm {
    padding-top: 40px;
  }
  .fsl-logo {
    width: 100%;
    padding: 0 0 15px 0;
  }
  .fsl-logo.ufs-box-rhs {
    width: 140px;
    padding: 0 0 0 10px;
  }
  .best-flag {
    border-radius: 6px;
    right: 20px;
    top: 10px;
    padding: 6px 20px;
    font-size: 12px;
  }
  .fsl-from,
  .fsl-to {
    min-height: 60px;
  }
  .flight-search-result > ul > li {
    border-radius: 20px;
    padding: 35px 20px 15px 20px;
  }
}

@media (max-width: 580px) {
  body {
    font-size: 14px;
  }
  .footer-nav-inner,
  .footer-nav-inner:last-child {
    width: 50%;
    padding-right: 20px;
  }
  footer {
    padding: 20px 10px 0 10px;
  }
  .pp-activitie ul li {
    width: 48%;
    margin: 0 0 18px 0;
  }
  .banner-form-parent h1 {
    font-size: 25px;
    letter-spacing: 1.25px;
    margin-bottom: 15px;
  }
  .banner-form-inner input[type="text"] {
    height: 44px;
    font-size: 10px;
    letter-spacing: 0.26px;
    border-radius: 10px;
  }
  .banner-form-inner .button {
    width: 60px;
    padding: 0;
    border-radius: 10px;
  }
  .banner-form .container {
    padding: 0 30px;
  }
  .pp-activitie {
    padding: 30px 0 20px;
  }
  .title-with-description {
    margin: 0 0 20px 0;
  }
  .title-with-description h2 {
    font-size: 18px;
    letter-spacing: 0.45px;
    margin: 0;
  }
  .title-with-description p {
    letter-spacing: -0.11px;
  }
  .promo-def h4 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
  }
  .header-rhs {
    position: absolute;
    right: 15px;
  }
  header .container {
    position: relative;
  }
  .footer-nav-inner {
    margin-bottom: 15px;
  }
  .footer-top {
    padding: 0 0 10px 0;
  }
  .showing-header a {
    display: none;
  }
  .outlet-listing-filter ul {
    white-space: nowrap;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .outlet-listing-filter ul li a {
    height: 40px;
    line-height: 1;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 12px;
    padding: 5px 15px;
  }
  .outlet-listing-filter ul li {
    width: auto;
    margin: 0 5px 5px 5px;
  }
  .showing-header h4 {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.33px;
    color: #120c0f;
  }
  .showing-header {
    margin-bottom: 10px;
  }
  .detail-top-header h1 {
    font-size: 20px;
  }

  .form-one,
  .form-two,
  .form-three {
    padding: 30px 20px;
  }
  .fg-textarea textarea {
    height: 100px;
  }
  .detail-bottom-rhs-price.rhs-white-box {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .detail-bottom-rhs-select.rhs-white-box {
    padding-bottom: 10px;
  }
  .thankyou-box-inner-lhs {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #d6d6d6;
    border-right: 0;
  }

  .thankyou-box-inner-rhs {
    width: 100%;
    padding: 15px 0 0 0;
  }
  .thankyou-box-inner-rhs figure {
    margin: 0 auto;
  }
  .thankyou-box-btm-rhs {
    width: 100%;
    margin: 15px 0 0 0;
    text-align: center;
  }
  .thankyou-box-btm-lhs {
    width: 100%;
    text-align: center;
  }
  .expolre-thank h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .thankyou-top {
    margin-bottom: 40px;
  }
  .thankyou-header h3 {
    font-size: 20px;
  }
  .thankyou-box h5 {
    font-size: 18px;
  }
  .thankyou-header {
    margin: 0 0 20px 0;
  }
  .tbi-lhs-header h6 {
    font-size: 16px;
  }
  .search-place {
    top: 48px;
  }
  .review-form-inner {
    padding: 20px;
  }
  .promo-banner-lhs h1 {
    font-size: 18px;
    margin: 0px 0px 10px 0px;
  }
  .expolre-promo > ul {
    justify-content: space-between;
  }
  .expolre-promo > ul > li {
    width: 49%;
    margin: 0 0 20px 0 !important;
  }
  .expolre-list h4 {
    font-size: 18px;
  }
  .aexplore-def p {
    font-size: 14px;
  }
  .aexplore-def p strong {
    font-size: 16px;
  }
  .reward-total-lhs {
    width: 100%;
  }
  .reward-total-rhs {
    width: 100%;
    padding: 10px 0 0 0;
  }
  .vacation-testimonials .owl-prev,
  .vacation-testimonials .owl-next {
    display: none;
  }
  .vacation-testimonials .owl-carousel {
    padding: 5px 10px 10px;
  }
  .vacation-testimonials {
    padding: 30px 0 50px;
  }
  .vacation-testimonials .color-header {
    margin: 0 0 15px 0;
    font-size: 20px;
  }
  .vacation-box-btm {
    padding: 25px 20px;
  }
  .vacation-box-txt h2 {
    font-size: 23px;
    letter-spacing: 1px;
  }
  .vacation-box-top {
    padding: 30px 0 20px;
  }
  .center-btn {
    padding: 0px 0 10px 0;
  }
  .gradient-box ul li h4 {
    font-size: 14px;
  }
  .blu-section {
    padding: 30px 0 15px;
    margin: 30px 0 0 0;
  }
  .sites-content {
    padding: 10px 0 0 0;
  }
  .product-description {
    padding: 15px 10px 10px;
  }
  .product-description-title {
    margin-bottom: 10px;
  }
  .package-trend .product-list-parent {
    padding-bottom: 65px;
  }
  .package-trend .product-description-review {
    bottom: 15px;
  }
  .package-trend {
    padding: 30px 0 0px 0;
  }
  .platform-step {
    padding: 30px 0;
  }
  .platform-step .button {
    padding: 12px 24px;
    font-size: 13px;
  }
  .platform-step ul {
    justify-content: center;
  }
  .platform-step ul li {
    width: 50%;
    margin: 0 0 20px 0;
    padding: 0 10px;
  }
  .platform-step h1 {
    font-size: 22px;
    letter-spacing: 0.25px;
  }
  .unique-package-stay .hsr-rhs {
    position: static;
    align-items: flex-end;
    width: 100%;
  }
  .unique-package-stay .hsr-lhs-desc {
    padding: 10px 0 0 0;
    width: 100%;
  }
  .hsr-lhs-desc {
    padding: 10px 0 0 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .hsr-lhs-img,
  .unique-package-stay .hsr-lhs-img {
    width: 100%;
  }
  .flag-stay-transfer .hsr-lhs-img {
    border-radius: 0;
    height: 200px !important;
    display: grid;
    place-items: center;
    margin: 0 12px;
  }
  .hsr-lhs-img figcaption {
    text-align: left;
  }
  .three-activity .up-accordion-content > ul > li {
    width: 100%;
    margin: 0 0 15px 0 !important;
  }
  .title-with-nav {
    padding: 0 0 15px 0;
  }
  .title-with-nav .title-with-description {
    width: 100%;
    padding: 0 0 10px 0;
  }
  .unique-package-stay {
    margin: 15px 0;
  }
  .pt-button {
    padding: 10px 0 0 0;
  }
  .blue-nav ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .blue-nav ul li {
    width: 49%;
    margin: 0 0 10px 0;
  }
  .blue-nav {
    padding: 15px 0 5px 0;
  }
  .flag-stay span {
    margin: 0 0 30px 0;
  }
  .unique-package-list {
    margin: 10px 0 0 0;
  }
  .step-slide {
    display: block;
  }
  .platform-step ul {
    display: none;
  }
  .flight-way-search {
    padding: 20px 15px 20px 15px;
  }
  .fs-whom {
    width: 100%;
    margin-bottom: 10px;
  }
  .flight-search .fg-btn {
    position: static;
    bottom: inherit;
    width: 100%;
  }
  .room-choice-one h4 {
    font-size: 17px;
    margin: 0 0 15px 0;
  }
  .uss-choice > ul > li {
    width: 100%;
  }
  .uss-about p {
    line-height: 1.3;
  }
}

@media (max-width: 480px) {
  .booking-screen .owl-theme .owl-dots .active span {
    background: none repeat scroll 0 0 #4258bf !important;
  }
  .admin-profile .mybooking-inner-main {
    padding: 10px;
  }
  .booked-ticket .booked-ticket-inner {
    padding: 25px;
  }
  .booked-ticket .booked-ticket-inner .booked-ticket-top {
    flex-direction: column;
  }
  .booked-ticket .booked-ticket-inner .booked-ticket-top .btt-lhs {
    width: 100%;
  }
  .booked-ticket .booked-ticket-inner .booked-ticket-top .btt-rhs {
    width: 100%;
  }
  .booked-ticket .tickt-content ul li span {
    margin-right: 5px;
  }
  .booked-ticket-btm .booked-ticket-btm-lhs {
    margin-bottom: 1rem;
  }
  .booked-ticket-btm .booked-ticket-btm-rhs {
    text-align: center;
    margin: 0 auto;
  }
  .booked-ticket-btm .booked-ticket-btm-rhs .ghost-button {
    margin-top: 8px !important;
  }
  .booked-ticket-btm .booked-ticket-btm-rhs .button {
    width: 100%;
    margin: 0px;
  }
  .mobile-view-scroll {
    overflow: auto;
    margin-bottom: 30px;
    background-color: #eef3ff;
  }
  .mobile-view-scroll .admin-profile-lhs {
    padding: 0px;
  }
  .mobile-view-scroll .profile-menu {
    overflow-x: initial;
  }
  .sumry-box-top {
    padding: 10px 30px 10px 30px;
  }
  .over-link {
    height: 55vh;
  }
  .dcard-text {
    padding: 40px 0px 27px 30px;
  }
  .transport-search-result .hsr-lhs .row {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  .transport-search-result .hsr-lhs .row .col-1 {
    flex-direction: column;
    flex: 0.65;
  }
  .transport-search-result .hsr-lhs .row .star-rating-review {
    align-items: flex-end;
  }
  .transport-search-result .hsr-lhs .row .col-2 {
    flex-direction: column;
    flex: 0.35;
  }
  .transport-search-result .hsr-rhs {
    justify-content: space-between;
  }
  .flag-stay-transfer .hsr-lhs .row {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  .flag-stay-transfer .hsr-lhs .row .col-1 {
    flex-direction: column;
    flex: 0.7;
  }
  .flag-stay-transfer .hsr-lhs .row .star-rating-review {
    align-items: flex-end;
  }
  .flag-stay-transfer .hsr-lhs .row .col-2 {
    flex-direction: column;
    flex: 0.3;
  }
  .unique-package-stay .flag-stay-transfer .hsr-rhs {
    justify-content: space-between;
  }
  input[type="text"] {
    height: 45px;
  }
  .package-details-lhs .detail-top-header .detail-top-detail-parent-rating {
    width: auto;
    padding: 0;
    margin-top: -1.1rem;
  }
  .package-details-lhs .detail-top-detail .detail-top-detail-parent {
    border-bottom: none;
  }
  .package-details-lhs .detail-top-detail {
    padding: 15px 0px 5px 0px;
    margin: 20px 0 30px 0;
  }
  .detail-top-detail-parent .sites-content {
    padding: 0px 15px;
  }
  .header-transparent {
    box-shadow: none;
  }
  .header-bg {
    background: #fafbfe;
    z-index: 11;
    position: relative;
  }
  .header-lhs a img {
    width: 120px;
  }
  header {
    height: 60px;
    background: #fff;
  }
  .small_sticky {
    align-items: center;
    display: flex;
  }
  header .m-search {
    margin-bottom: 0;
    padding-left: 50px;
  }
  header .cc-search {
    width: 90%;
  }
  header .container {
    position: absolute;
  }
  header .cc-search .m-search {
    width: 84vw;
  }
  .platform-step .button {
    padding: 16px 35px;
  }
  .platform-step h1 {
    font-size: 20px;
  }
  .footer-nav h5 {
    color: #342f41;
    font-size: 16px;
    letter-spacing: -0.11px;
  }
  .footer-nav ul li a {
    font-size: 14px;
    letter-spacing: -0.1px;
    margin-bottom: 10px;
  }
  .outlet-listing-rhs > ul > li {
    width: 100%;
    margin: 0 0 25px 0;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }
  .filter-group {
    width: 210px;
  }
  .outlet-listing-filter h2 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.45px;
  }
  .common-popup {
    padding: 50px 20px 30px 20px;
  }
  .cp-header h3 {
    font-size: 24px;
  }
  .mfp-close {
    right: 20px;
  }
  .mfp-close:after {
    width: 15px;
    height: 15px;
    background-size: contain;
  }
  .package-lhs {
    width: 100%;
    border-right: 0;
    padding-right: 0;
  }
  .admin-profile-rhs {
    padding: 0;
  }
  .package-rhs {
    width: 100%;
    padding: 10px 0 0 0;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .bold-with-blue {
    font-size: 19px;
    margin-bottom: 20px;
  }
  .detail-bottom-package > ul > li {
    padding: 20px;
  }
  .package-rhs strong {
    font-size: 16px;
    margin-bottom: 0;
  }
  .package-rhs .button {
    margin: 0;
    padding: 12px 5px;
    width: 49%;
  }
  .package-lhs h6 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .wishlisth-rsh.outlet-listing-rhs > ul > li {
    width: 100%;
  }
  .admin-profile-rhs h5,
  .wishlisth-header h5 {
    font-size: 17px;
  }
  .mybooking-inner-left h5 {
    font-size: 16px;
    margin: 0px 0 15px;
  }
  .mobile-menu {
    max-width: 280px;
    padding: 30px 25px;
  }
  .form-one .form-group {
    width: 100%;
    margin-bottom: 20px;
  }
  .form-two h4,
  .form-three h4,
  .formone-header h4 {
    font-size: 18px;
  }
  .fg-textarea .control-label {
    line-height: 1.3;
  }
  .payment-header h2 {
    font-size: 20px;
  }
  .payment-header h2 br {
    display: block;
  }
  body .mfp-wrap {
    top: 20px;
  }
  .funthings ul li {
    width: 100%;
    margin: 0 0 18px 0;
  }
  .explore {
    padding: 0 0 30px 0;
  }
  .bottom-button {
    padding: 20px 0 0 0;
  }
  .product-image-more span {
    font-size: 9px;
  }
  .det-banner {
    padding: 2px;
  }
  .det-banner-lhs,
  .det-banner-rhs-bottom-lhs,
  .det-banner-rhs-top,
  .det-banner-rhs-bottom-rhs {
    border: 2px solid #fff;
  }
  .profile-detail-left {
    width: 100%;
    text-align: center;
  }
  .profile-edtbtn button,
  .profile-imgdiv {
    margin: 0 auto;
  }
  ul.detail-top-detail-lhs,
  ul.detail-top-detail-rhs {
    width: 100%;
    padding-right: 0;
  }
  ul.detail-top-detail-lhs {
    margin-bottom: 15px;
  }
  .detail-top-detail-parent {
    padding-bottom: 15px;
  }
  .package-starts {
    padding: 20px;
  }
  .detail-top-lhs {
    margin: 0 0 30px 0;
  }
  .form-three h4 {
    margin-bottom: 15px;
  }
  .form-three-lhs .control-label {
    padding-left: 0;
    font-size: 14px;
  }
  .form-group-apply {
    top: 32px;
  }
  .form-group-apply .button {
    font-size: 12px;
  }
  .package-description-inner {
    max-height: 64px;
  }
  .pp-info-blue {
    padding: 20px;
  }
  .pp-info-blue ul li {
    margin: 0 0 12px 0;
  }
  .pp-info-blue ul li:last-child {
    margin-bottom: 0;
  }
  .pp-info-blue ul li img {
    top: 0;
  }
  .pp-info-text h5 {
    padding: 15px 0 0 0;
    margin: 0 0 10px 0;
  }
  .pp-info-text ul li {
    margin: 0 0 10px 0;
  }
  .pp-info-text h5 {
    font-size: 16px;
  }
  .package-rhs-inner {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .step-banner-form > div {
    margin-bottom: 0;
  }
  .step-banner-merge > div {
    width: 100%;
  }
  .step-banner-form {
    padding: 20px 15px 15px 15px;
  }
  .step-banner-form h3 {
    font-weight: 600;
    letter-spacing: 0.15px;
    margin: 0px 0px 15px 0px;
    font-size: 18px;
  }
  .step-banner-from .react-datepicker__input-container input,
  .step-banner-to .react-datepicker__input-container input,
  .step-banner-stay > input,
  .step-banner-whom input {
    padding-left: 40px;
    font-size: 12px;
  }
  .gradient-box ul li a {
    border-radius: 15px;
    padding: 15px 35px 15px 15px;
  }
  .gradient-box ul li a img {
    width: 20px;
    right: 10px;
    bottom: 15px;
  }
  .hotel-search-result > ul > li {
    padding: 10px;
  }
  .hsr-lhs-desc h4 {
    font-size: 15px;
  }
  .hsr-lhs-desc > span {
    font-size: 13px;
  }
  .hsr-lhs-desc > .distance {
    order: 3;
  }
  .free-box {
    font-size: 13px;
  }
  .unique-package-stay .hsr-rhs {
    justify-content: center;
  }
  .unique-package-stay .hsr-rhs .button {
    font-size: 12px;
    padding: 15px 18px;
    min-width: 120px;
  }
  .step-banner {
    display: none;
  }
  .mobile-banner-search {
    display: block;
    overflow: hidden;
  }
  .mobile-banner-search .container {
    position: absolute;
    padding-top: 80px;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 2;
    max-height: 380px;
  }
  [data-rsbs-overlay] {
    z-index: 92 !important;
  }
  .step-banner-lhs {
    padding-top: 4rem;
  }
  .step-banner-lhs h1 {
    color: #fff;
    line-height: 1.5;
    font-size: 24px;
    margin-bottom: 0px;
    text-align: left;
    font-weight: bold;
  }
  .step-banner-lhs p {
    color: #fff;
    margin-top: 0.2rem !important;
    font-size: 12px;
  }
  .fs-stay {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 10px;
  }
  .fs-from {
    width: 100%;
    padding-right: 0;
    padding-bottom: 19px;
  }
  .fs-to {
    width: 100%;
    padding-left: 0;
  }
  .flight-search {
    padding-right: 0;
  }
  .fs-swap {
    width: 18px;
    transform: rotate(90deg);
    z-index: 1;
  }
  .package-ul > li .pdr-rhs strong {
    font-size: 18px;
  }
  .created-av-title h4 {
    font-size: 14px;
  }
  .created-av-title h4 span {
    font-size: 13px;
  }
  .creator-badge {
    font-size: 12px;
  }
  .product-description-title h5 {
    font-size: 16px;
    font-weight: 600;
    color: #1c2550;
  }
  .fsl-from,
  .fsl-to {
    width: 60px;
    font-size: 13px;
    background: transparent;
  }
  .flight-search-result > ul > li {
    padding-left: 10px;
    padding-right: 10px;
  }
  .fsl-img img {
    width: 22px;
  }
  .fsl-path:after {
    left: 70px;
    right: 70px;
    background-size: 20px 2px;
  }
  .fsl-logo {
    padding: 0 0 20px 0;
  }
  .fsl-top li,
  .fsl-btm li {
    font-size: 14px;
  }
  .unique-package-tab a {
    font-size: 15px;
    margin: 0 20px 0 0;
  }
  .hsr-rhs {
    justify-content: center;
  }
  .up-accordion-header {
    border-radius: 10px;
    padding: 16px 40px 16px 25px;
    font-size: 15px;
  }
  .up-accordion-header:after {
    font-size: 13px;
    top: 15px;
  }
  .hsr-rhs .button {
    font-size: 12px;
  }
  .flight-search-rhs .button {
    margin: 0 5px;
    padding: 9.4px 15px;
    font-size: 14px;
  }
  .ufs-main {
    flex-direction: column;
    min-height: 90px;
    padding: 10px 5px;
    display: block;
  }
  .ufs-box .f-row {
    flex: 1;
    flex-direction: row;
    display: flex;
    padding: 5px 5px 0 5px;
  }
  .ufs-box .f-row .col-1 {
    flex: 0.5;
    flex-direction: column;
    display: flex;
  }
  .ufs-box .f-row .col-2 .fsl-time {
    position: static;
  }
  .ufs-box .f-row .col-1 .fsl-logo.ufs-box-rhs {
    padding: 0px !important;
  }
  .ufs-box .f-row .col-2 {
    flex: 0.5;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
  }
  .ufs-box .row {
    flex: 1;
    flex-direction: row;
    display: flex;
  }
  .ufs-box .row .col-1 {
    flex: 4.5;
    flex-direction: column;
  }
  .ufs-box .row .col-2 {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .ufs-box .row .col-3 {
    flex: 4.5;
    flex-direction: column;
  }
  .ufs-box,
  .ufs-box .fsl-path {
    flex-wrap: wrap;
  }
  .ufs-box .fsl-from {
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    width: 100%;
    /* margin: 0 0 20px 0; */
  }
  .ufs-box .fsl-to {
    flex-direction: column;
    align-items: flex-end;
    background-color: transparent;
    text-align: right;
    width: 100%;
    /* margin: 20px 0 0 0; */
  }
  .ufs-box .fsl-path::after {
    display: none;
  }
  .ufs-box .fsl-img {
    position: static;
  }
  /* .ufs-box .fsl-img img {
    transform: rotate(90deg);
  } */
  .ufs-box-lhs {
    width: 100%;
    order: 2;
  }
  .fsl-logo.ufs-box-rhs {
    width: 100%;
    padding: 0 0 20px 0;
    order: 1;
  }
  .ufs-inner-btm-lhs {
    width: 100%;
    padding: 0 0 10px 0;
  }
  .ufs-inner-btm-lhs::after {
    display: none;
  }
  .ufs-inner-btm-rhs {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 420px) {
  .profile-detail-right li {
    padding: 5px 0;
  }
  .profile-Infield {
    width: 100%;
  }
  .profile-Outfield {
    width: 100%;
  }
  .cp-social .google-btn,
  .cp-social .kep-login-facebook.metro {
    font-size: 10px !important;
    height: 44px !important;
  }
  .expolre-promo > ul > li {
    width: 100%;
  }
  .detail-bottom-package > ul > li {
    padding: 15px 10px;
  }
}
