.vacationSummary {
  margin-top: 2rem;
  padding: 0 40px;
  margin-bottom: 5rem;
  @media (min-width: 1600px) {
    margin: 2rem auto;
    max-width: 1400px; /* Set a larger max-width if needed */
  }
  @media (max-width: 480px) {
    padding: 0 20px;
    margin-bottom: 0;
  }
  &__heading {
    color: #342f41;
    display: flex;
    align-items: center;
    &__title {
      font-weight: 600;
      font-size: 20px;
      color: #21314d;
      &__sub {
        margin-top: 5px;
        font-weight: 400;
        font-size: 12px;
        color: #5b677b;
      }
    }
    svg {
      color: #3c50ae;
      margin-right: 8px;
      cursor: pointer;
    }
    @media (max-width: 480px) {
      font-size: 22px;
    }
    font-weight: 600;
  }
  &__row {
    flex: 1;
    display: flex;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    flex-direction: row;
    margin-top: 2rem;
    &__col1 {
      flex-direction: column;
      width: 73%;
      @media (max-width: 480px) {
        width: 100%;
      }
      &__box {
        flex-direction: row;
        display: flex;
        border: 1px solid #dfe1e5;
        border-radius: 20px;
        overflow: hidden;
        flex-direction: column;
        margin-bottom: 2rem;
        &__priceMain {
          padding: 24px;
          border-top: 1px solid #dfe1e5;
        }
        &__price {
          letter-spacing: 0.18px;
          color: #21314d;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        &__info {
          letter-spacing: 0.12px;
          color: #767676;
          font-size: 12px;
          font-style: italic;
          display: flex;
          align-items: center;
          svg {
            font-size: 1rem;
            color: #767676;
            margin-right: 5px;
          }
        }
        &__row1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 24px;
          background: #f4f6ff;
          &__title {
            letter-spacing: 0.18px;
            color: #3c50ae;
            font-weight: 500;
            font-size: 16px;
            padding-right: 10px;
            span {
              letter-spacing: 0.14px;
              color: #21314d;
              font-size: 14px;
              font-weight: normal;
              margin-left: 10px;
            }
          }
          &__edit {
            cursor: pointer;
            color: #3c50ae;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            text-decoration: underline;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              font-size: 1rem;
              margin-right: 5px;
            }
          }
        }
        &__row2 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 12px 24px;
          @media (max-width: 480px) {
            flex-direction: column;
          }
          &__col {
            width: 50%;
            @media (max-width: 480px) {
              width: 100%;
            }
            flex-direction: column;
            &.marginBtm {
              margin-bottom: 10px;
            }
            &__title {
              letter-spacing: 0.14px;
              color: #243069;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 10px;
            }
            ul {
              padding: 0 16px;
            }
            li {
              letter-spacing: 0.14px;
              color: #21314d;
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    &__col2 {
      @media (max-width: 480px) {
        display: none;
      }
      width: 27%;
      padding: 0 1rem;
      position: sticky;
      height: fit-content;
      top: 90px;
      &__box {
        padding: 24px 24px;
        background: #ffffff;
        border-radius: 12px;
        border: 1px solid #dfe1e5;
        .transfer-cntnr {
          padding: 1rem;
        }
        .MuiAvatar-root {
          width: 25px;
          height: 25px;
        }
        &__title {
          font-family: "poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          color: #33425b;
        }
        &__content {
          &__row {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            &__col1 {
              display: flex;
              align-items: center;
              img {
                margin-right: 6px;
                max-width: 12px;
              }
              &__label {
                font-family: "poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.6;
                color: #33425b;
              }
              &__label1 {
                font-family: "poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.5;
                color: #33425b;
              }
              &__label2 {
                font-family: "poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.6;
                color: #175cd3;
              }
            }
            &__col2 {
              &__val {
                font-family: "poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.4;
                color: #21314d;
                padding-left: 16px;
                text-align: right;
              }
              &__price {
                font-family: "poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 1.6;
                color: #33425b;
              }
              &__discount {
                font-family: "poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.4;
                color: #175cd3;
              }
            }
          }
          &__checkout-btn {
            padding: 12px 20px !important;
            background: #4258bf !important;
            border-radius: 48px !important;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
            font-family: "poppins" !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 16px !important;
            line-height: 1.5;
            color: #ffffff !important;
            text-transform: initial !important;
            width: 100%;
            margin-top: 0.9rem !important;
          }
        }
        &__divider {
          margin: 10px 0;
          border-bottom: 0.5px solid #969eab;
        }
      }
    }
  }
}
