.transfer-selection-main {
  padding: 1.5rem;
  @media (max-width: 480px) {
    padding: 0;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #44444438;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #4444446e;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    @media (max-width: 480px) {
      padding: 1rem;
      margin: 0;
    }
    &__col1 {
      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #3c50ae;
      }
      &__sub {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #33425b;
      }
    }
    &__col2 {
      display: flex;
      align-items: center;
      text-align: right;
      @media (max-width: 480px) {
        > div:first-child {
          display: none;
        }
      }
      &__close {
        cursor: pointer;
        @media (max-width: 480px) {
          background: rgb(235, 237, 239);
          border-radius: 48px;
          padding: 6px;
          display: grid;
          place-items: center;
          width: 25px;
          height: 25px;
        }
      }
      &__price {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #21314d;
        span {
          color: #3c50ae;
        }
      }
      &__breakDown {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #5b677b;
      }
      img {
        margin-left: 1rem;
        @media (max-width: 480px) {
          margin: 0;
        }
      }
    }
  }
  &__filterRow {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    @media (max-width: 480px) {
      padding: 0 1rem;
    }
    &__col {
      display: flex;
      &__filter {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        background: #f4f6ff;
        border: 1px solid #dfe1e5;
        border-radius: 48px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #33425b;
        margin-right: 1rem;
      }
      &__active {
        border: 0.800000011920929px solid #4258bf;
        background: #f4f6ff;
      }
    }
    &__col1 {
      &__menu-item {
        position: absolute;
        right: 2%;
        margin: 5px 0;
        padding: 4px 0;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 5.3px 0 rgba(63, 77, 101, 0.25);
        list-style: none;
        z-index: 100;
        transition: 0.5s ease-in-out;
        li {
          padding: 12px 24px;
          font-size: 12px;
          &:hover {
            background-color: rgba(255, 255, 255, 1);
            cursor: pointer;
          }
        }
      }
      &__sort-by {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border: 1px solid #dfe1e5;
        border-radius: 48px;
        padding: 8px 12px;
        color: #33425b;
        gap: 5px;
        font-size: 12px;
        background-color: #ffffff;
        cursor: pointer;
        position: relative;
        p {
          margin: 0;
        }
        .open-sevron {
          transform: rotate(180deg);
        }
        // @media (min-width: 481px) {
        //   padding: 8px 12px;
        // }
        // @media (max-width: 480px) {
        //   padding: 8px;
        // }
      }
    }
  }
  &__filterList {
    display: flex;
    overflow-x: auto;
    margin-bottom: 1rem;
    padding: 8px 24px;
    background: #f5f6f7;
    &__option {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      background: #ffffff;
      border: 1px solid #dfe1e5;
      border-radius: 48px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: #33425b;
      margin-right: 1rem;
      white-space: nowrap;
    }
    &__active {
      border: 0.800000011920929px solid #4258bf;
      background: #f4f6ff;
    }
  }
  &__searchReslt {
    margin: 1rem 0;
    @media (max-width: 480px) {
      margin: 1rem;
    }
    &__txt {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: #33425b;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6;
        color: #4258bf;
        margin-left: 5px;
      }
      .clearall {
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    h4 {
      font-size: 12px;
      color: #33425b;
      margin: 0;
    }
  }
  &__noData {
    font-size: 12px;
    color: #33425b;
    font-weight: 400;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flag-stay-transfer {
    list-style: none;
    padding: 0;
    @media (max-width: 480px) {
      padding: 0 1rem;
    }
    .hsr-lhs-desc h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: #21314d;
    }
    .hsr-lhs-desc > span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: #4e5b71;
    }
    ul.mainpoints {
      padding: 0 0 0 16px;
    }
    ul.mainpoints li {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: #4e5b71;
      display: list-item;
      list-style-type: disc;
      @media (max-width: 480px) {
        height: auto;
        padding: 0.5rem 0 1rem 0;
      }
    }
    @media (max-width: 480px) {
      .hsr-lhs-desc ul li:first-child {
        margin-left: 8px !important;
      }
    }
    .hsr-rhs p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: #21314d;
    }
    .footer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #dbe2ff;
      margin-top: 5px;
      .tags {
        display: flex;
        flex: 0.8;
        .tag {
          white-space: nowrap;
          padding: 8px 12px;
          background: #fafafb;
          border: 0.30000001192092896px solid #c1c5cd;
          border-radius: 26px;
          font-family: "poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #21314d;
          margin-right: 1rem;
        }
      }
      .add-btn {
        padding: 10px 18px;
        background: #eceef9;
        border-radius: 48px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #2f3e88;
        border: none;
      }
    }
  }
}
