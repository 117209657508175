.Airline-Image {
    background-position: -303px -1800px;
    min-width: 27px;
    min-height: 25px;
}

.airline-def {
    background-position: -303px -1800px;
    width: 29px;
    height: 25px;
}

.airline-2B {
    background-position: 0 0;
    width: 30px;
    height: 27px;
}

.airline-3K {
    background-position: 0 -57px;
    width: 27px;
    height: 23px;
}

.airline-UK {
	background-position: -307px -1903px;
	width: 27px;
	height: 27px;
}
.airline-4L {
    background-position: 0 -110px;
    width: 27px;
    height: 23px;
}

.airline-4Q {
    background-position: 0 -163px;
    width: 27px;
    height: 23px;
}

.airline-5H {
    background-position: 0 -216px;
    width: 27px;
    height: 23px;
}

.airline-5J {
    background-position: 0 -269px;
    width: 27px;
    height: 23px;
}

.airline-5T {
    background-position: 0 -322px;
    width: 27px;
    height: 23px;
}

.airline-6E {
    background-position: 0 -375px;
    width: 27px;
    height: 27px;
}

.airline-8M {
    background-position: 0 -432px;
    width: 27px;
    height: 23px;
}

.airline-8U {
    background-position: 0 -485px;
    width: 27px;
    height: 23px;
}

.airline-9H {
    background-position: 0 -538px;
    width: 27px;
    height: 23px;
}

.airline-9W {
    background-position: 0 -591px;
    width: 27px;
    height: 23px;
}

.airline-9W1 {
    background-position: 0 -644px;
    width: 27px;
    height: 23px;
}

.airline-A3 {
    background-position: 0 -697px;
    width: 27px;
    height: 23px;
}

.airline-A9 {
    background-position: 0 -750px;
    width: 27px;
    height: 23px;
}

.airline-AA {
    background-position: 0 -803px;
    width: 27px;
    height: 23px;
}

.airline-AB {
    background-position: 0 -856px;
    width: 27px;
    height: 23px;
}

.airline-AC {
    background-position: 0 -909px;
    width: 27px;
    height: 23px;
}

.airline-AE {
    background-position: 0 -962px;
    width: 27px;
    height: 23px;
}

.airline-AF {
    background-position: 0 -1015px;
    width: 27px;
    height: 23px;
}

.airline-AH {
    background-position: 0 -1068px;
    width: 27px;
    height: 21px;
}

.airline-AI {
    background-position: 0 -1119px;
    width: 27px;
    height: 23px;
}

.airline-AK {
    background-position: 0 -1172px;
    width: 31px;
    height: 23px;
}

.airline-AM {
    background-position: 0 -1225px;
    width: 27px;
    height: 23px;
}

.airline-AP {
    background-position: 0 -1278px;
    width: 27px;
    height: 23px;
}

.airline-AQ {
    background-position: 0 -1331px;
    width: 27px;
    height: 23px;
}

.airline-AR {
    background-position: 0 -1384px;
    width: 27px;
    height: 23px;
}

.airline-AS {
    background-position: 0 -1437px;
    width: 27px;
    height: 23px;
}

.airline-AT {
    background-position: 0 -1490px;
    width: 27px;
    height: 23px;
}

.airline-AV {
    background-position: 0 -1543px;
    width: 27px;
    height: 23px;
}

.airline-AY {
    background-position: 0 -1596px;
    width: 27px;
    height: 23px;
}

.airline-AZ {
    background-position: 0 -1649px;
    width: 27px;
    height: 23px;
}

.airline-B3 {
    background-position: 0 -1702px;
    width: 27px;
    height: 23px;
}

.airline-B6 {
    background-position: 0 -1755px;
    width: 27px;
    height: 23px;
}

.airline-BA {
    background-position: 0 -1808px;
    width: 27px;
    height: 23px;
}

.airline-BD {
    background-position: 0 -1861px;
    width: 27px;
    height: 23px;
}

.airline-BE {
    background-position: 0 -1914px;
    width: 27px;
    height: 23px;
}

.airline-BG {
    background-position: 0 -1967px;
    width: 27px;
    height: 23px;
}

.airline-BI {
    background-position: -61px 0;
    width: 27px;
    height: 23px;
}

.airline-BP {
    background-position: -61px -53px;
    width: 27px;
    height: 23px;
}

.airline-BR {
    background-position: -61px -106px;
    width: 27px;
    height: 23px;
}

.airline-BT {
    background-position: -61px -159px;
    width: 27px;
    height: 23px;
}

.airline-BY {
    background-position: -61px -212px;
    width: 27px;
    height: 23px;
}

.airline-CA {
    background-position: -61px -265px;
    width: 27px;
    height: 23px;
}

.airline-CB {
    background-position: -61px -318px;
    width: 27px;
    height: 23px;
}

.airline-CI {
    background-position: -61px -371px;
    width: 27px;
    height: 23px;
}

.airline-CM {
    background-position: -61px -424px;
    width: 27px;
    height: 23px;
}

.airline-CO {
    background-position: -61px -477px;
    width: 27px;
    height: 23px;
}

.airline-CX {
    background-position: -61px -530px;
    width: 27px;
    height: 23px;
}

.airline-CZ {
    background-position: -61px -583px;
    width: 27px;
    height: 23px;
}

.airline-D7 {
    background-position: -61px -636px;
    width: 31px;
    height: 23px;
}

.airline-DC {
    background-position: -61px -689px;
    width: 27px;
    height: 17px;
}

.airline-DJ {
    background-position: -61px -736px;
    width: 27px;
    height: 23px;
}

.airline-DL {
    background-position: -61px -789px;
    width: 27px;
    height: 23px;
}

.airline-DN {
    background-position: -61px -842px;
    width: 27px;
    height: 22px;
}

.airline-EI {
    background-position: -61px -894px;
    width: 27px;
    height: 22px;
}

.airline-EK {
    background-position: -61px -946px;
    width: 27px;
    height: 23px;
}

.airline-ET {
    background-position: -61px -999px;
    width: 27px;
    height: 23px;
}

.airline-EY {
    background-position: -308px -1219px;
    width: 27px;
    height: 23px;
}

.airline-F9 {
    background-position: -61px -1105px;
    width: 27px;
    height: 23px;
}

.airline-FD {
    background-position: -61px -1158px;
    width: 31px;
    height: 23px;
}

.airline-FI {
    background-position: -61px -1211px;
    width: 27px;
    height: 23px;
}

.airline-FJ {
    background-position: -61px -1264px;
    width: 27px;
    height: 23px;
}

.airline-FL {
    background-position: -61px -1317px;
    width: 27px;
    height: 26px;
}

.airline-FM {
    background-position: -61px -1373px;
    width: 27px;
    height: 19px;
}

.airline-FT {
    background-position: -61px -1422px;
    width: 27px;
    height: 23px;
}

.airline-FZ {
    background-position: -61px -1475px;
    width: 27px;
    height: 23px;
}

.airline-G8 {
    background-position: -61px -1528px;
    width: 27px;
    height: 23px;
}

.airline-G9 {
    background-position: -61px -1581px;
    width: 27px;
    height: 23px;
}

.airline-GA {
    background-position: -61px -1634px;
    width: 27px;
    height: 23px;
}

.airline-GE {
    background-position: -61px -1687px;
    width: 27px;
    height: 23px;
}

.airline-GF {
    background-position: -61px -1740px;
    width: 27px;
    height: 23px;
}

.airline-HA {
    background-position: -61px -1793px;
    width: 27px;
    height: 23px;
}

.airline-HM {
    background-position: -61px -1846px;
    width: 27px;
    height: 23px;
}

.airline-HP {
    background-position: -61px -1899px;
    width: 27px;
    height: 23px;
}

.airline-HR,
.airline-H1 {
    background-position: -61px -1952px;
    width: 27px;
    height: 26px;
}

.airline-HU {
    background-position: -122px 0;
    width: 27px;
    height: 23px;
}

.airline-HX {
    background-position: -122px -53px;
    width: 27px;
    height: 23px;
}

.airline-HY {
    background-position: -122px -106px;
    width: 27px;
    height: 23px;
}

.airline-I7 {
    background-position: -122px -159px;
    width: 27px;
    height: 23px;
}

.airline-IA {
    background-position: -122px -212px;
    width: 27px;
    height: 23px;
}

.airline-IB {
    background-position: -122px -265px;
    width: 27px;
    height: 23px;
}

.airline-IC {
    background-position: -122px -318px;
    width: 30px;
    height: 23px;
}

.airline-IG {
    background-position: -122px -371px;
    width: 27px;
    height: 23px;
}

.airline-IR {
    background-position: -122px -424px;
    width: 27px;
    height: 23px;
}

.airline-IT {
    background-position: -122px -477px;
    width: 27px;
    height: 23px;
}

.airline-IX {
    background-position: -122px -530px;
    width: 27px;
    height: 12px;
}

.airline-J9 {
    background-position: -122px -572px;
    width: 25px;
    height: 14px;
}

.airline-JK {
    background-position: -122px -616px;
    width: 27px;
    height: 23px;
}

.airline-JL {
    background-position: -122px -669px;
    width: 27px;
    height: 23px;
}

.airline-JM {
    background-position: -122px -722px;
    width: 27px;
    height: 23px;
}

.airline-JO {
    background-position: -122px -775px;
    width: 27px;
    height: 23px;
}

.airline-JP {
    background-position: -122px -828px;
    width: 27px;
    height: 23px;
}

.airline-KA {
    background-position: -122px -881px;
    width: 27px;
    height: 23px;
}

.airline-KB {
    background-position: -122px -934px;
    width: 27px;
    height: 23px;
}

.airline-KC {
    background-position: -122px -987px;
    width: 27px;
    height: 23px;
}

.airline-KD {
    background-position: -122px -1040px;
    width: 27px;
    height: 23px;
}

.airline-KE {
    background-position: -122px -1093px;
    width: 27px;
    height: 23px;
}

.airline-KL {
    background-position: -122px -1146px;
    width: 27px;
    height: 23px;
}

.airline-KM {
    background-position: -122px -1199px;
    width: 27px;
    height: 23px;
}

.airline-KQ {
    background-position: -122px -1252px;
    width: 27px;
    height: 23px;
}

.airline-KU {
    background-position: -122px -1305px;
    width: 27px;
    height: 23px;
}

.airline-KX {
    background-position: -122px -1358px;
    width: 27px;
    height: 23px;
}

.airline-LA {
    background-position: -122px -1411px;
    width: 27px;
    height: 23px;
}

.airline-LB {
    background-position: -122px -1464px;
    width: 27px;
    height: 23px;
}

.airline-LH {
    background-position: -122px -1517px;
    width: 27px;
    height: 23px;
}

.airline-LO {
    background-position: -122px -1570px;
    width: 27px;
    height: 23px;
}

.airline-LP {
    background-position: -122px -1623px;
    width: 27px;
    height: 23px;
}

.airline-LR {
    background-position: -122px -1676px;
    width: 27px;
    height: 23px;
}

.airline-LX {
    background-position: -122px -1729px;
    width: 27px;
    height: 23px;
}

.airline-LY {
    background-position: -122px -1782px;
    width: 27px;
    height: 21px;
}

.airline-M1 {
    background-position: -122px -1833px;
    width: 27px;
    height: 23px;
}

.airline-MA {
    background-position: -122px -1886px;
    width: 27px;
    height: 23px;
}

.airline-MD {
    background-position: -122px -1939px;
    width: 27px;
    height: 23px;
}

.airline-MF {
    background-position: -183px 0;
    width: 27px;
    height: 23px;
}

.airline-MH {
    background-position: -183px -53px;
    width: 27px;
    height: 23px;
}

.airline-MI {
    background-position: -183px -106px;
    width: 27px;
    height: 23px;
}

.airline-MK {
    background-position: -183px -159px;
    width: 27px;
    height: 23px;
}

.airline-MS {
    background-position: -183px -212px;
    width: 27px;
    height: 23px;
}

.airline-MU {
    background-position: -183px -265px;
    width: 27px;
    height: 23px;
}

.airline-MULT {
    background-position: -183px -318px;
    width: 27px;
    height: 22px;
}

.airline-MV {
    background-position: -183px -370px;
    width: 27px;
    height: 23px;
}

.airline-MX {
    background-position: -183px -423px;
    width: 27px;
    height: 23px;
}

.airline-NF {
    background-position: -183px -476px;
    width: 27px;
    height: 23px;
}

.airline-NH {
    background-position: -183px -529px;
    width: 27px;
    height: 23px;
}

.airline-NU {
    background-position: -183px -582px;
    width: 27px;
    height: 23px;
}

.airline-NW {
    background-position: -183px -635px;
    width: 27px;
    height: 23px;
}

.airline-NX {
    background-position: -183px -688px;
    width: 27px;
    height: 23px;
}

.airline-NZ {
    background-position: -183px -741px;
    width: 27px;
    height: 23px;
}

.airline-OA {
    background-position: -183px -794px;
    width: 27px;
    height: 23px;
}

.airline-OS {
    background-position: -183px -847px;
    width: 27px;
    height: 23px;
}

.airline-OU {
    background-position: -183px -900px;
    width: 27px;
    height: 23px;
}

.airline-OV {
    background-position: -183px -953px;
    width: 27px;
    height: 23px;
}

.airline-OZ {
    background-position: -183px -1006px;
    width: 27px;
    height: 23px;
}

.airline-PC {
    background-position: -183px -1059px;
    width: 27px;
    height: 23px;
}

.airline-PG {
    background-position: -183px -1112px;
    width: 27px;
    height: 23px;
}

.airline-PK {
    background-position: -183px -1165px;
    width: 27px;
    height: 23px;
}

.airline-PR {
    background-position: -183px -1218px;
    width: 27px;
    height: 23px;
}

.airline-PS {
    background-position: -183px -1271px;
    width: 27px;
    height: 23px;
}

.airline-PW {
    background-position: -183px -1324px;
    width: 27px;
    height: 23px;
}

.airline-PX {
    background-position: -183px -1377px;
    width: 27px;
    height: 23px;
}

.airline-QF {
    background-position: -183px -1430px;
    width: 27px;
    height: 23px;
}

.airline-QR {
    background-position: -183px -1483px;
    width: 27px;
    height: 23px;
}

.airline-QV {
    background-position: -183px -1536px;
    width: 27px;
    height: 23px;
}

.airline-QZ {
    background-position: -183px -1589px;
    width: 31px;
    height: 23px;
}

.airline-RA {
    background-position: -183px -1642px;
    width: 27px;
    height: 23px;
}

.airline-RC {
    background-position: -183px -1695px;
    width: 27px;
    height: 23px;
}

.airline-RI {
    background-position: -183px -1748px;
    width: 27px;
    height: 23px;
}

.airline-RJ {
    background-position: -183px -1801px;
    width: 27px;
    height: 23px;
}

.airline-RO {
    background-position: -183px -1854px;
    width: 27px;
    height: 23px;
}

.airline-S2 {
    background-position: -183px -1907px;
    width: 27px;
    height: 23px;
}

.airline-S7 {
    background-position: -183px -1960px;
    width: 27px;
    height: 23px;
}

.airline-SA {
    background-position: -244px 0;
    width: 27px;
    height: 23px;
}

.airline-SB {
    background-position: -244px -53px;
    width: 27px;
    height: 23px;
}

.airline-SC {
    background-position: -244px -106px;
    width: 27px;
    height: 23px;
}

.airline-SG {
    background-position: -244px -159px;
    width: 27px;
    height: 23px;
}

.airline-SK {
    background-position: -244px -212px;
    width: 27px;
    height: 23px;
}

.airline-SN {
    background-position: -244px -265px;
    width: 27px;
    height: 23px;
}

.airline-SQ {
    background-position: -244px -318px;
    width: 27px;
    height: 23px;
}

.airline-SR {
    background-position: -244px -371px;
    width: 27px;
    height: 23px;
}

.airline-SS {
    background-position: -244px -424px;
    width: 27px;
    height: 23px;
}

.airline-SU {
    background-position: -244px -477px;
    width: 27px;
    height: 23px;
}

.airline-SV {
    background-position: -244px -530px;
    width: 27px;
    height: 23px;
}

.airline-SY {
    background-position: -244px -583px;
    width: 25px;
    height: 25px;
}

.airline-T3 {
    background-position: -244px -638px;
    width: 27px;
    height: 23px;
}

.airline-TA {
    background-position: -244px -691px;
    width: 27px;
    height: 23px;
}

.airline-TC {
    background-position: -244px -744px;
    width: 27px;
    height: 23px;
}

.airline-TE {
    background-position: -244px -797px;
    width: 27px;
    height: 23px;
}

.airline-TG {
    background-position: -244px -850px;
    width: 27px;
    height: 23px;
}

.airline-TK {
    background-position: -244px -903px;
    width: 27px;
    height: 23px;
}

.airline-TN {
    background-position: -244px -956px;
    width: 27px;
    height: 23px;
}

.airline-TP {
    background-position: -244px -1009px;
    width: 27px;
    height: 23px;
}

.airline-TR {
    background-position: -244px -1062px;
    width: 27px;
    height: 23px;
}

.airline-TU {
    background-position: -244px -1115px;
    width: 27px;
    height: 23px;
}

.airline-TZ {
    background-position: -244px -1168px;
    width: 27px;
    height: 23px;
}

.airline-U2 {
    background-position: -244px -1221px;
    width: 27px;
    height: 23px;
}

.airline-U7 {
    background-position: -244px -1274px;
    width: 27px;
    height: 23px;
}

.airline-UA {
    background-position: -244px -1327px;
    width: 27px;
    height: 23px;
}

.airline-UL {
    background-position: -244px -1380px;
    width: 27px;
    height: 23px;
}

.airline-UN {
    background-position: -244px -1433px;
    width: 27px;
    height: 23px;
}

.airline-UO {
    background-position: -244px -1486px;
    width: 27px;
    height: 23px;
}

.airline-UP {
    background-position: -244px -1539px;
    width: 27px;
    height: 23px;
}

.airline-US {
    background-position: -244px -1592px;
    width: 27px;
    height: 23px;
}

.airline-UX {
    background-position: -244px -1645px;
    width: 27px;
    height: 23px;
}

.airline-VA {
    background-position: -244px -1698px;
    width: 27px;
    height: 23px;
}

.airline-VG {
    background-position: -244px -1751px;
    width: 27px;
    height: 23px;
}

.airline-VH {
    background-position: -244px -1804px;
    width: 27px;
    height: 23px;
}

.airline-VI {
    background-position: -244px -1857px;
    width: 27px;
    height: 23px;
}

.airline-VK {
    background-position: -244px -1910px;
    width: 27px;
    height: 19px;
}

.airline-VN {
    background-position: -244px -1959px;
    width: 27px;
    height: 23px;
}

.airline-VS {
    background-position: -305px 0;
    width: 27px;
    height: 19px;
}

.airline-VSPL {
    background-position: -305px -49px;
    width: 27px;
    height: 23px;
}

.airline-VT {
    background-position: -305px -102px;
    width: 27px;
    height: 23px;
}

.airline-VV {
    background-position: -305px -155px;
    width: 27px;
    height: 23px;
}

.airline-VW {
    background-position: -305px -208px;
    width: 27px;
    height: 23px;
}

.airline-VX {
    background-position: -305px -261px;
    width: 27px;
    height: 23px;
}

.airline-W3 {
    background-position: -305px -314px;
    width: 27px;
    height: 23px;
}

.airline-W5 {
    background-position: -305px -367px;
    width: 27px;
    height: 23px;
}

.airline-WB {
    background-position: -305px -420px;
    width: 27px;
    height: 23px;
}

.airline-WF {
    background-position: -305px -473px;
    width: 27px;
    height: 23px;
}

.airline-WP {
    background-position: -305px -526px;
    width: 27px;
    height: 23px;
}

.airline-WX {
    background-position: -305px -579px;
    width: 27px;
    height: 23px;
}

.airline-WY {
    background-position: -305px -632px;
    width: 27px;
    height: 23px;
}

.airline-XR {
    background-position: -305px -685px;
    width: 27px;
    height: 23px;
}

.airline-YM {
    background-position: -305px -738px;
    width: 27px;
    height: 23px;
}

.airline-YX {
    background-position: -305px -791px;
    width: 27px;
    height: 23px;
}

.airline-Z2 {
    background-position: -305px -844px;
    width: 27px;
    height: 23px;
}

.airline-Z5 {
    background-position: -305px -897px;
    width: 27px;
    height: 23px;
}

.airline-ZB {
    background-position: -305px -950px;
    width: 27px;
    height: 23px;
}

.airline-ZG {
    background-position: -305px -1003px;
    width: 30px;
    height: 19px;
}

.airline-ZH {
    background-position: -305px -1052px;
    width: 27px;
    height: 23px;
}

.airline-ZL {
    background-position: -305px -1105px;
    width: 27px;
    height: 23px;
}

.airline-ZQ {
    background-position: -305px -1158px;
    width: 27px;
    height: 23px;
}

.airline-VF {
    background-position: -304px -1642px;
    width: 27px;
    height: 23px;
}

.airline-HG {
    background-position: -301px -1696px;
    width: 31px;
    height: 23px;
}

.airline-DY {
    background-position: -302px -1752px;
    width: 31px;
    height: 22px;
}

.airline-OD {
    background-position: -309px -1955px;
    width: 24px;
    height: 24px;
}

.airline-SL {
    background-position: -309px -1955px;
    width: 23px;
    height: 26px;
}

.airline-VZ {
    background-position: -308px -1998px;
    width: 27px;
    height: 27px;
}

.airline-WE {
    background-position: -244px -1994px;
    width: 31px;
    height: 22px;
}



.longlayover {
    background-position: -305px -1211px;
    width: 26px;
    height: 25px;
}

.overnight {
    background-position: -305px -1266px;
    width: 26px;
    height: 25px;
}

.redeye {
    background-position: -305px -1321px;
    width: 26px;
    height: 25px;
}

.remove {
    background-position: -305px -1376px;
    width: 75px;
    height: 60px;
}

.select {
    background-position: -305px -1466px;
    width: 75px;
    height: 60px;
}

.shortconnecting {
    background-position: -305px -1556px;
    width: 26px;
    height: 26px;
}

.Airline-Image {
    background-image: url(../images/airline.png);
    display: inline-block;
}
