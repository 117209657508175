.transfer-list-main {
  padding: 1.5rem;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #44444438;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #4444446e;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    &__col1 {
      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #21314d;
      }
      &__sub {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #33425b;
      }
    }
    &__col2 {
      display: flex;
      align-items: center;
      text-align: right;
      &__close {
        cursor: pointer;
      }
      &__price {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #21314d;
        span {
          color: #3c50ae;
        }
      }
      &__breakDown {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #5b677b;
      }
      img {
        margin-left: 1rem;
      }
    }
  }
}
