::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #44444438;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #4444446e;
}
.searchContainer {
  height: 100vh;
  padding: 5px 15px 10px 15px;
  overflow-y: scroll;
  padding-bottom: 5rem;
  &__header {
    display: flex;
    flex-direction: row;
  }
  &__closeIcon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #4258bf;
  }
  .range-bg-parent {
    margin-top: -2.5rem;
  }
  .selectBtn {
    border: 1px solid #4258bf;
    border-radius: 10px;
    padding: 2px 10px;
    background: #fff;
  }
  span.rdrMonthAndYearPickers {
    display: none;
  }
  .hero-content-promt {
    margin-top: 0.5rem;
  }
  .select-link {
    width: 50%;
    text-align: right;
  }
  strong {
    font-size: 16px;
    font-weight: 500;
  }
  em {
    color: #999;
    font-style: normal;
    font-size: 15px;
  }
  &__heading {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1.75px;
    line-height: 1.2;
    margin: 0 0 2rem 0;
    span {
      font-size: 1rem;
      color: #4258bf;
    }
  }
  .pac-target-input {
    border-color: #f0f2ff !important;
    background: #f0f2ff !important;
  }
  &__submitBtn {
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 100%;
    margin-left: -15px;
    justify-content: center;
    &__back {
      border: 1px solid #4258bf !important;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16) !important;
      font-size: 14px;
      color: #4258bf !important;
      width: 60px;
      height: 60px;
    }
  }
  &__cancelBtn {
    background: #fff;
    color: #4258bf;
    text-decoration: underline;
    margin-bottom: 1rem;
    display: flex;
    flex: 0.5;
    &__backIcon {
      font-size: 1rem !important;
    }
  }
  &__row {
    &__input {
      padding-left: 50px;
      border-color: #f0f2ff !important;
      background: #f0f2ff !important;
    }
    margin-bottom: 10px;
  }
  &__bottomSheetMain {
    @media (max-width: 480px) {
      [data-rsbs-overlay],
      [data-rsbs-backdrop],
      [data-rsbs-root]:after {
        z-index: 93 !important;
      }
    }
    &__container {
      @media (max-width: 480px) {
        padding: 10px 15px;
      }
      .month-list {
        grid-gap: 25px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        list-style: none;
        padding: 2rem 0;
      }
      .month-box {
        align-items: center;
        background: #fff;
        border: 1px solid #4258bf;
        border-radius: 12px;
        color: #111733;
        cursor: pointer;
        display: flex;
        font-size: 10px;
        font-weight: 500;
        justify-content: center;
        line-height: 1;
        padding: 16px 22px;
      }
      .month-box.active {
        background: #4258bf;
        color: #fff;
      }
      .ttd-p-lhs {
        padding-left: 0px;
        padding-right: 0px;
      }
      .selectBtn {
        text-align: center;
      }
      .selectBtn .button {
        float: initial;
        margin-bottom: 0px;
      }
      &__package {
        .platform-step ul {
          display: inline;
          padding: 0px;
        }
        .platform-step {
          padding: 0px;
        }
        .platform-step ul {
          justify-content: center;
          align-items: center;
          display: flex;
        }
        .platform-step ul li {
          width: 100%;
          text-align: left;
          padding: 40px 35px;
        }
        .platform-step ul li figure img {
          height: 65px;
        }
        .platform-step ul li.active {
          border: 1px solid #4258bf;
        }
      }
      &__date {
        text-align: center;
        margin-top: -2rem;
        .error-msg {
          color: red;
          font-size: 14px;
          font-weight: 500;
        }
        .rdrCalendarWrapper {
          width: 100%;
          .rdrMonthAndYearWrapper {
            width: 100%;
            position: initial;
            height: auto;
            padding-top: 0px;
          }
        }
        .rdrMonthsHorizontal {
          .rdrMonthName {
            text-align: center;
            margin-top: -1.3rem;
            padding: 0 0 1rem 0 !important;
          }
        }
        .rdrMonth {
          width: 100%;
        }
        .rdrDay {
          height: 4.38em;
        }
        .rdrDayNumber::after {
          border: none !important;
        }
        .rdrStartEdge {
          // border-radius: 50%;
        }
        .rdrDayEndPreview,
        .rdrDayHovered,
        .rdrDayEndPreview {
          border: none !important;
        }
        .rdrDayActive .rdrDayNumber {
          border-bottom-left-radius: 50%;
          background: #f0f2ff;
          border-top-left-radius: 50%;
        }
        .rdrSelected {
          background: rgb(66, 88, 191);
          border-radius: 50%;
        }
        .rdrDayActive .rdrDayNumber span {
          background: rgb(66, 88, 191);
          width: 92.8%;
          // border-radius: 50%;
          height: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
          border-top-right-radius: 1.042em;
          border-bottom-right-radius: 1.042em;
        }
        .rdrStartEdge + .rdrDayNumber {
          border-bottom-left-radius: 50%;
          // background: #f0f2ff;
          border-top-left-radius: 50%;
        }
        .rdrEndEdge + .rdrDayNumber {
          border-bottom-right-radius: 50%;
          // background: #f0f2ff;
          border-top-right-radius: 50%;
        }
        .rdrEndEdge + .rdrDayNumber span,
        .rdrStartEdge + .rdrDayNumber span {
          background: rgb(66, 88, 191);
          width: 92.8%;
          // border-radius: 50%;
          height: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        .rdrEndEdge {
          // border-radius: 50%;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        .rdrStartEdge {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        .rdrInRange {
          background-color: #f0f2ff;
        }
        .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
          color: #fff;
        }
      }
      .doneBtn {
        display: flex;
        justify-content: center;
        .button {
          width: 140px;
          border-radius: 20px;
        }
      }
      &__height {
        height: 100vh;
        overflow-y: scroll;
      }
      padding: 10px 15px;
      &__sourceAir {
        .rdrMonth {
          width: 93vw;
        }
        &__row {
          &__imgSrc {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            margin-right: 1rem;
          }
          figure {
            width: 50px;
            border-radius: 10px;
            overflow: hidden;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #4258bf;
          }
          background: #f0f2ff;
          padding: 13px 15px;
          border-radius: 15px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          &__active {
            background: #4258bf;
            color: #fff;
          }
        }
      }
      .MuiPickersLayout-actionBar,
      .MuiPickersArrowSwitcher-button {
        display: none;
      }
      button {
        border-radius: 0px;
        box-shadow: none !important;
      }
      .MuiPickersToolbar-root {
        display: none;
      }
    }
    .button {
      float: right;
      z-index: 0;
    }
    .child-age-select {
      padding: 10px 16px 25px 16px;
    }
  }
  .selecting-content {
    padding: 0px;
  }
  .selecting-content ul {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
  }
}
