.modal-popup-main,
.pacakge_popup {
  min-width: 40%;
  overflow: auto !important;
  border-radius: 1rem !important;
  .close-btn {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    box-shadow: none;
    border-radius: 5px;
    .css-i4bv87-MuiSvgIcon-root {
      color: #21314d;
    }
  }
}
.modal-popup-main:has(.asst-popup-main__row) {
  .close-btn {
    top: 1rem;
    right: 1rem;
  }
}
@media (min-width: 481px) {
  .modal-popup-main:has(.asstCard-main) {
    width: 398px;
    height: max-content;
    min-width: auto;
    .close-btn {
      top: 0.5rem;
      right: 0.5rem;
      .css-i4bv87-MuiSvgIcon-root {
        color: #dfe1e5;
      }
    }
    .asstCard-main {
      margin: 0;
    }
  }
}
@media (max-width: 480px) {
  .modal-popup-main {
    width: 100%;
    bottom: 0;
    top: auto !important;
    left: 0 !important;
    border-radius: 16px 16px 0 0 !important;
    &::before {
      position: absolute;
      content: "";
      width: 24px;
      height: 3px;
      background: #b2b7c1;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      box-shadow: none;
      border-radius: 5px;
      .css-i4bv87-MuiSvgIcon-root {
        color: #21314d;
      }
    }
  }
  .pacakge_popup {
    .close-btn {
      display: block;
      top: 0.5rem;
      right: 1rem;
    }
  }
  .passenger-body {
    padding: 4rem 1rem !important;
  }
  .passenger-cntnr {
    padding: 2rem 1rem !important;
  }
  .step-content-search {
    display: block !important;
  }
  .modal-popup-main:has(.customer-pk-detail-main__content__col2__asstCard),
  .modal-popup-main:has(.asst-popup-main__row) {
    .close-btn {
      padding: 0px;
      background: #ffffff50;
      border-radius: 50%;
    }
  }
}
