.footer-container {
  background: #101828;
  padding: 30px 40px 20px 40px;
  border-radius: 20px;
  .destination {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: #6a7487;
    }
    ul {
      display: flex;
      margin-right: 1.4rem;
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      li {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6;
        color: #eceef9;
        margin-right: 1.4rem;
        &:first-child {
          list-style: none;
        }
      }
    }
  }
  .divider {
    border-bottom: 1px solid #1d2639;
    margin: 1rem 0;
  }
  .row {
    display: flex;
    .col1 {
      flex: 0.8;
      .container {
        margin-top: 10px;
        padding: 0;
        max-width: none;
        .footer-top {
          padding: 0px;
        }
        .footer-nav {
          display: flex;
          .footer-nav-inner {
            width: auto;
          }
          h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            color: #6a7487;
          }
          ul {
            li {
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.6;
                color: #eceef9;
              }
            }
          }
        }
      }
    }
    .col2 {
    }
  }
  .copyright-section {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    .container {
      max-width: none;
      width: auto;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #667185;
      }
    }
    .social-section {
      display: flex;
      align-items: center;
      margin-right: -10px;
      img {
        margin: 0 10px;
      }
    }
  }
}
