.login-popup {
  iframe {
    margin: 0px !important;
  }
  .form-group {
    margin-bottom: 1rem;
    .pwd-inp {
      position: relative;
      span {
        position: absolute;
        right: 21px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 20px;
        height: 16px;
        cursor: pointer;
        i {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }
        i:first-child {
          display: block;
        }
        i:last-child {
          display: none;
        }
        &.active i:first-child {
          display: none;
        }
        &.active i:last-child {
          display: block;
        }
      }
    }
  }
  input[type="text"],
  input[type="password"] {
    border: 1px solid #dadce0;
    margin-bottom: 0;
  }
  input.errorInput {
    border: 0.6px solid #d92d20 !important;
    box-shadow: 0 0 0 4px #ffe3e8;
    border-radius: 12px;
  }
  .errorText {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    color: #d92c20;
    margin-top: 0.5rem;
  }
}
