* {
}
.packageLead_cntnr {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }
  @media (min-width: 481px) {
    top: 50%;
    left: 50%;
    border-radius: 16px;
  }
  &__close {
    position: absolute !important;
    top: 10px;
    right: 10px;
    padding: 5px;
    color: #969eab;
    cursor: pointer;
  }
  &__row1 {
    @media (max-width: 480px) {
      width: 100%;
      height: 20vh;
    }
    @media (min-width: 481px) {
      width: 40%;
    }
    padding: 1rem;
    background-size: cover;
    background-position: center;
    &__col1 {
      h4 {
        color: #ffffff;
        margin: 0 0 15px 0;
      }
      &__details {
        display: flex;
        flex-wrap: wrap;
        &__content {
          color: #fafafb;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 12px;
          margin: 5px 4px;
          gap: 4px;
          p {
            margin: 0 0 0 2px;
          }
        }
      }
    }
  }
  &__row2 {
    @media (max-width: 480px) {
      width: 100%;
    }
    @media (min-width: 481px) {
      width: 60%;
    }
    padding: 1rem 1.5rem;
    &__col1 {
      h4 {
        margin: 0 25px 25px 0;
        span {
          color: #4258bf;
        }
      }
    }
    &__col2 {
      input {
        height: 48px;
      }
    }
    &__col3 {
      .phone {
        width: 100%;
        margin: 0.5rem 0 0 0;
        .react-tel-input .flag-dropdown {
          border: 0.3000000119px solid #4258bf !important;
        }
        input {
          border: 0.3000000119px solid #4258bf !important;
          height: 50px !important;
          margin-top: 10px;
        }
        .MuiOutlinedInput-root {
          height: 50px;
          width: 100%;
          border-color: #4258bf;
          border-radius: 10px;
        }
      }
    }
    &__col4 {
      display: flex;
      align-items: start;
      margin: 1rem 0 0 0;
      p {
        font-size: 12px;
        margin: 0.5rem 0;
        span {
          color: #4258bf;
          &hover {
            color: #000000;
          }
        }
      }
    }
    &__col5 {
      .continue-btn {
        width: 100%;
        height: 48px;
        text-align: center;
        border-radius: 48px;
        padding: 12px 20px;
        margin-top: 1rem;
        background-color: #4258bf !important;
        color: #fff !important;
        text-transform: initial;
        font-size: 16px;
        box-shadow: none;
        &:hover {
          background: #4258bf 0% 0% no-repeat padding-box;
        }
      }
    }
  }
  &__errMsg {
    padding: 0 0 0 5px;
    color: #d8000c;
    font-size: 12px;
    p {
      margin: 0;
    }
  }
}
.css-176wh8e-MuiCircularProgress-circle {
  stroke: #ffffff !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 748px !important;
  border-radius: 16px !important;
}
.react-tel-input .country-list {
  max-height: 125px !important;
}
@media (max-width: 480px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 0 !important;
    height: 100vh !important;
    transform: translateY(35%) !important;
  }
}
