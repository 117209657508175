.activity-selection-main {
  height: 100vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #44444438;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #4444446e;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    @media (max-width: 480px) {
      padding: 1rem;
    }
    &__row {
      display: flex;
      align-items: center;
      &__col {
        img {
          cursor: pointer;
        }
      }
      &__col1 {
        padding-left: 0.5rem;
        &__title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
          color: #3c50ae;
          margin: 0 0 0.25rem 0;
          @media (max-width: 480px) {
            margin: 0;
          }
        }
        &__sub {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #33425b;
        }
      }
    }
    &__col2 {
      display: flex;
      align-items: center;
      text-align: right;
      @media (max-width: 480px) {
        > div:nth-child(1) {
          display: none;
        }
        > div:nth-child(2) {
          background: rgba(235, 237, 239, 1);
          border-radius: 48px;
          padding: 0;
          display: grid;
          place-items: center;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
      &__close {
        @media (max-width: 480px) {
          margin: 0 !important;
        }
      }
      &__price {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #21314d;
        span {
          color: #3c50ae;
        }
      }
      &__breakDown {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #5b677b;
      }
      img {
        margin-left: 1rem;
      }
    }
  }
  &__searchReslt {
    padding: 0 1.5rem;
    margin-top: 1rem;
    &__txt {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: #33425b;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6;
        color: #4258bf;
        margin-left: 5px;
      }
      .clearall {
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    h4 {
      font-size: 12px;
      color: #33425b;
      margin: 0;
    }
  }
  &__search {
    padding: 16px 16px;
    background: #f4f6ff;
    margin-bottom: 1rem;
    position: relative;
    @media (max-width: 480px) {
      padding: 1rem 0.75rem;
      margin-bottom: 0.75rem;
    }
    &__close {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 2.5rem;
      cursor: pointer;
    }
    &__box {
      padding: 0 36px;
      background: #ffffff;
      border: 0.5px solid #b2bfff;
      border-radius: 56px;
      width: 100%;
      height: 56px;
      @media (max-width: 480px) {
        font-size: 14px;
        line-height: 1.6;
        padding: 0 1rem;
        height: 48px;
      }
      &::placeholder {
        font-family: "poppins";
        color: #33425b;
      }
    }
  }
  &__filterRow {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    @media (max-width: 480px) {
      padding: 0 1rem;
    }
    &__col {
      display: flex;
      &__filter {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        border: 1px solid #dfe1e5;
        border-radius: 48px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #33425b;
        margin-right: 1rem;
        @media (max-width: 480px) {
          margin-right: 0.75rem;
        }
      }
      &__active {
        border: 0.800000011920929px solid #4258bf;
        background: #f4f6ff;
      }
    }
    &__col1 {
      &__menu-item {
        position: absolute;
        right: 2%;
        margin: 5px 0;
        padding: 4px 0;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 5.3px 0 rgba(63, 77, 101, 0.25);
        list-style: none;
        z-index: 100;
        transition: 0.5s ease-in-out;
        li {
          padding: 12px 24px;
          font-size: 12px;
          &:hover {
            background-color: rgba(255, 255, 255, 1);
            cursor: pointer;
          }
        }
      }
      &__sort-by {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border: 1px solid #dfe1e5;
        border-radius: 48px;
        padding: 8px 12px;
        color: #33425b;
        gap: 5px;
        font-size: 12px;
        background-color: #ffffff;
        cursor: pointer;
        position: relative;
        p {
          margin: 0;
        }
        .open-sevron {
          transform: rotate(180deg);
        }
        // @media (min-width: 481px) {
        //   padding: 8px 12px;
        // }
        // @media (max-width: 480px) {
        //   padding: 8px;
        // }
      }
    }
  }
  &__filterList {
    display: flex;
    overflow-x: auto;
    margin-top: 1rem;
    padding: 8px 24px;
    background: #f5f6f7;
    @media (max-width: 480px) {
      padding: 0.5rem 1rem;
    }
    &__priceRange {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      &__col {
        flex: 0.15;
        color: #33425b;
        font-weight: 500;
        font-size: 14px;
        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
      &__col1 {
        flex: 0.65;
        @media (max-width: 480px) {
          padding: 0 1rem;
        }
      }
      &__col2 {
        flex: 0.15;
        color: #33425b;
        font-weight: 500;
        font-size: 14px;
        text-align: right;
        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
    &__option {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      background: #ffffff;
      border: 1px solid #dfe1e5;
      border-radius: 48px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: #33425b;
      margin-right: 1rem;
      white-space: nowrap;
      @media (max-width: 480px) {
        margin-right: 0.75rem;
      }
    }
    &__active {
      border: 0.800000011920929px solid #4258bf;
      background: #f4f6ff;
    }
  }
  &__content {
    padding: 1.5rem;
    &__noData {
      font-size: 12px;
      color: #33425b;
      font-weight: 400;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__card {
      cursor: pointer;
      padding: 16px 16px;
      background: #ffffff;
      border: 1px solid #ebedef;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      @media (max-width: 480px) {
        flex-wrap: wrap;
        padding: 0;
        border-radius: 12px;
      }
      &__lhs {
        width: 45%;
        @media (max-width: 480px) {
          width: 100%;
        }
        img {
          width: 266px;
          height: 199px;
          border-radius: 12px;
          @media (max-width: 480px) {
            width: 100%;
            border-radius: 12px 12px 0 0;
          }
        }
      }
      &__rhs {
        width: 53%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        @media (max-width: 480px) {
          width: 100%;
          padding: 0.75rem;
        }
        &__title {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.4;
          color: #33425b;
          @media (max-width: 480px) {
            margin: 0.5rem 0;
            font-weight: 600;
          }
        }
        &__tag {
          color: #3c50ae;
          font-size: 12px;
          margin: 4px 5px 5px 0;
          @media (max-width: 480px) {
            margin: 0;
            font-weight: 500;
          }
          > span {
            position: relative;
            &:not(:first-child) {
              margin-left: 20px;
              &::before {
                position: absolute;
                left: -0.85rem;
                top: 55%;
                transform: translateY(-50%);
                content: "" !important;
                width: 4px !important;
                height: 4px !important;
                background: #3f4d65 !important;
                border-radius: 50% !important;
              }
            }
          }
        }
        &__distance {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #3f4d65;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 0.25rem;
          @media (max-width: 480px) {
            display: flex;
            column-gap: 0.25rem;
          }
        }
        &__footer {
          position: absolute;
          right: 0px;
          bottom: 0px;
          @media (max-width: 480px) {
            position: unset;
            width: 100%;
          }
          &__priceMain {
            text-align: right;
            padding-right: 1rem;
            margin-bottom: 10px;
            @media (max-width: 480px) {
              margin-bottom: 1rem;
            }
            span {
              font-size: 12px;
              color: #3f4d65;
            }
            &__price {
              color: #21314d;
              font-size: 16px;
              font-weight: 600;
            }
          }
          button {
            background: #4258bf;
            border-radius: 48px;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5;
            color: #ffffff;
            width: 176px;
            height: 48px;
            text-transform: none;
            @media (max-width: 480px) {
              width: 100%;
            }
            &:hover {
              background: #4258bf;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
          }
        }
      }
    }
  }
}
