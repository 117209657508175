.room-selection-main {
  position: relative;
  &__header {
    @media (max-width: 480px) {
      padding: 0 1rem;
    }
    &__sub {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      column-gap: 1rem;
      @media (max-width: 480px) {
        margin-bottom: 1rem;
      }
      &__col {
        flex: 0.075;
        img {
          cursor: pointer;
        }
      }
      &__col1 {
        flex: 1;
        &__title {
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
          color: #3c50ae;
          margin-bottom: 0.25rem;
        }
        &__sub {
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #33425b;
        }
      }
      &__col2 {
        display: flex;
        align-items: center;
        text-align: right;
        &__close {
          cursor: pointer;
        }
        &__price {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
          color: #21314d;
          span {
            color: #3c50ae;
          }
        }
        &__breakDown {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #5b677b;
        }
        img {
          margin-left: 1rem;
        }
      }
    }
    &__tabs {
      padding: 16px 24px 0 16px;
      background: #ffffff;
      border: 0.800000011920929px solid #dfe1e5;
      border-radius: 12px;
      display: flex;
      margin-bottom: 1rem;
      &__tab {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #4e5b71;
        margin-right: 1.5rem;
        padding-bottom: 10px;
        cursor: pointer;
      }
      &__active {
        color: #3c50ae;
        font-weight: 500;
        border-bottom: 4px solid #4258bf;
      }
    }
  }
  &__content {
    padding-bottom: 2rem;
    @media (max-width: 480px) {
      padding: 0 1rem 2rem;
    }
    ul {
      list-style: none;
      padding-left: 0px;
    }
    &__card {
      padding: 16px 16px;
      background: #ffffff;
      border: 1px solid #ebedef;
      border-radius: 16px;
      margin-bottom: 1rem;
      @media (max-width: 480px) {
        padding: 0;
      }
      &__row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 480px) {
          flex-direction: column;
        }
        &__lhs {
          // flex: 0.23;
          width: 39%;
          @media (max-width: 480px) {
            width: 100%;
            padding: 0.75rem 0.75rem 0;
          }
          .room-parent {
            border-radius: 12px;
            overflow: hidden;
            height: auto;
          }
          //   &__img {
          //     width: 400px;
          //     height: 200px;
          //     @media (max-width: 480px) {
          //       width: 100%;
          //     }
          //     img {
          //       border-radius: 12px;
          //       height: 100%;
          //       width: 100%;
          //     }
          //   }
        }
        &__rhs {
          width: 58%;
          // flex: 0.75;
          flex-direction: column;
          display: flex;
          align-items: flex-start;
          padding: 10px 0;
          @media (max-width: 480px) {
            width: 100%;
            flex-direction: column;
            padding: 0.75rem 0 0;
          }
          &__recomTag {
            background: #eff8ff;
            padding: 2px 8px;
            border: 0.30000001192092896px solid #175cd3;
            border-radius: 26px;
            font-family: "poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.3;
            color: #21314d;
            margin-bottom: 0.5rem;
          }
          &__title {
            font-family: "poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
            color: #21314d;
            margin-bottom: 0.5rem;
            @media (max-width: 480px) {
              padding: 0 0.75rem;
            }
          }
          &__amenities {
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 480px) {
              width: 100%;
              padding: 0.5rem 0.75rem;
              border-bottom: 0.6px solid #dfe1e5;
            }
            &__more {
              cursor: pointer;
            }
            &__val {
              white-space: nowrap;
              padding: 8px 12px;
              background: #fafafb;
              border: 0.30000001192092896px solid #c1c5cd;
              border-radius: 26px;
              font-family: "poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1.3;
              color: #21314d;
              margin: 0 1rem 0.5rem 0;
            }
          }
          .details-policy {
            margin-bottom: 0.5rem;
            @media (max-width: 480px) {
              width: 100%;
              border-top: 0.6px solid #dfe1e5;
              border-bottom: 0.6px solid #dfe1e5;
              padding: 1rem 0.75rem;
            }
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1.3;
              color: #3c50ae;
              img {
                margin-right: 5px;
              }
            }
          }
          &__rmCapacity {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            @media (max-width: 480px) {
              padding: 0 0.75rem;
            }
            &__label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1.3;
              color: #4e5b71;
              margin: 0 5px;
            }
            &__pCount {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 1.3;
              color: #4258bf;
              margin: 0 5px;
            }
          }
          &__col1 {
            flex: 0.75;
            flex-direction: column;
            display: flex;
            align-items: flex-start;
            padding: 10px 0;

            &__address {
              font-family: "poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1.3;
              color: #4e5b71;
              margin-top: 5px;
            }
            &__rooms {
              margin-top: 0.5rem;
              .text1 {
                font-family: "poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.3;
                color: #21314d;
                margin: 5px 0;
              }
            }
            .star-rating i {
              font-size: 13px;
            }
            .sr-review {
              font-family: "poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 1.3;
              color: #21314d;
              margin-left: 0px;
            }
          }
          &__col2 {
            flex: 0.25;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            text-align: right;
            &__days {
              font-family: "poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1.3;
              color: #3f4d65;
            }
            &__price {
              font-family: "poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1.5;
              color: #21314d;
            }
          }
        }
      }
      &__roomCharges {
        margin-top: 1rem;
        @media (max-width: 480px) {
          margin: 0;
        }
        &__title {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.3;
          color: #4e5b71;
          margin-bottom: 1rem;
          @media (max-width: 480px) {
            background: #f5f6f7;
            margin: 0;
            padding: 0.75rem;
          }
          img {
            margin-right: 5px;
          }
        }
        &__content {
          padding: 12px 12px 0 12px;
          background: #f5f6f7;
          border-radius: 12px;
          @media (max-width: 480px) {
            border-radius: 0;
            border-bottom: 0.6px solid #dfe1e5;
          }
          &__chargeList {
            display: grid;
            column-gap: 1%;
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 0px;
            @media (max-width: 480px) {
              grid-template-columns: 1fr;
            }
            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 1rem;
              margin-bottom: 12px;
              input {
                margin-right: 10px;
              }
              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.3;
                color: #122441;
              }
              strong {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 1.3;
                color: #3c50ae;
              }
            }
          }
        }
        .room-choice-four {
          margin-top: 0.5rem;
          position: initial;
          align-items: flex-start;
          @media (max-width: 480px) {
            flex-direction: column;
            align-items: end;
            padding: 0 0.75rem 0.75rem;
          }
          .rcf-lhs {
            span {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1.3;
              color: #3f4d65;
            }
            p {
              @media (max-width: 480px) {
                justify-content: end;
              }
              strong {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.5;
                color: #21314d;
              }
            }
          }
          .rcf-rhs {
            @media (max-width: 480px) {
              width: 100%;
              padding-top: 1rem;
            }
            button {
              padding: 10px 18px;
              background: #eceef9;
              border-radius: 48px;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border: none;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 1.5;
              color: #2f3e88;
              @media (max-width: 480px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    background: #fff;
    z-index: 9;
    margin-left: -25px;
    &__col {
      flex-direction: column;
      &__price {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #21314d;
        span {
          color: #3c50ae;
        }
      }
      &__breakDown {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #5b677b;
      }
      &__continueBtn {
        white-space: nowrap !important;
        padding: 10px 18px !important;
        background: #eceef9 !important;
        border-radius: 48px !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
        font-family: "poppins" !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 1.5 !important;
        color: #2f3e88 !important;
        cursor: pointer !important;
        text-transform: none !important;
        &:hover {
        }
        @media (max-width: 480px) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
        }
      }
    }
  }
}
