.filterSheetMain {
  padding: 16px 20px;
  margin-bottom: 3rem;
  .filter-row {
    .head-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      align-items: center;
    }
    .filter-row-ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        margin-right: 10px;
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.6;
          color: #3f4d65;
        }
        input[type="checkbox"]:not(:checked) {
          border: 0.4px solid #798293;
        }
      }
    }
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #1c2550;
      margin-bottom: 0;
    }
    .f-row {
      display: flex;
      flex-wrap: wrap;
      .filter-box {
        border: 0.4000000059604645px solid #a5acb7;
        border-radius: 48px;
        box-shadow: 0 1px 2px #1018280d;
        color: #33425b;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        margin: 0 10px 10px 0;
        padding: 8px 14px;
        &.active {
          background: #f4f6ff;
          border: 0.800000011920929px solid #4258bf;
          color: #3c50ae;
        }
      }
    }
  }
  .filter-row h5 {
    padding: 0px !important;
  }
  .slider {
    height: auto;
    .filter-row {
      width: 100%;
    }
  }
  .options {
    justify-content: center;
    .filter-row {
      width: 40%;
    }
  }
  .filter-action {
    .button {
      width: 48%;
    }
  }
}
