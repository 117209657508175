.asstCard-main {
  border-radius: 1rem;
  padding: 1rem;
  background: #051c88;
  position: relative;
  overflow: hidden;
  margin-top: 1rem;
  @media (max-width: 480px) {
    border: 0;
    border-radius: 1rem 1rem 0 0;
    margin-top: 0;
  }
  &__bgVec {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
  }
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #ffffff;
    position: inherit;
    z-index: 0;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  &__btns {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    > button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-transform: none;
      background: #ffffff;
      border-radius: 48px;
      padding: 0.75rem 0;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: #091342;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      &:hover {
        background: #d9def2;
      }
      .css-1wlk0hk-MuiAvatar-root,
      .css-3i9vrz {
        width: 20px;
        height: 20px;
      }
    }
  }
}
