.alert-popup-main {
  .MuiDialog-paper {
    border-radius: 20px !important;
    padding: 15px;
  }
  &__title {
    font-size: 20px;
    padding: 0 14px 12px 14px;
    position: relative;
    text-align: center;
    font-weight: 600;
  }
  p {
    text-align: center;
  }
  &__action {
    text-align: center;
    padding: 10px 0;
    button {
      background: #4258bf;
      color: #fff;
      border-radius: 20px;
      &:hover {
        background: #4258bf;
      }
    }
  }
}
