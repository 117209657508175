.cp-activities-main {
  display: flex;
  padding: 0 40px;
  margin-bottom: 5rem;
  @media (min-width: 1600px) {
    margin: 0 auto;
    margin-bottom: 2rem;
    max-width: 1400px; /* Set a larger max-width if needed */
  }
  @media (max-width: 480px) {
    padding: 0 20px;
  }
  &__col1 {
    width: 73%;
    @media (max-width: 480px) {
      width: 100%;
    }
    &__header {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      svg {
        color: #3c50ae;
        cursor: pointer;
      }
      span {
        font-weight: 600;
        font-size: 20px;
        color: #21314d;
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
    &__ttd-list {
      &__card {
        border: 1px solid #dfe1e5;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 2rem;
        &__header {
          display: flex;
          padding: 12px 24px;
          background: #f4f6ff;
          @media (max-width: 480px) {
            flex-direction: column;
          }
          &__col1 {
            flex: 0.7;
            &__pName {
              font-weight: 500;
              font-size: 16px;
              color: #122441;
              margin-bottom: 5px;
            }
            a {
              font-weight: 400;
              font-size: 14px;
              text-decoration-line: underline;
              color: #3c50ae;
            }
          }
          &__col2 {
            flex: 0.3;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 480px) {
              padding: 16px 0 0 0;
              justify-content: flex-start;
            }
            &__vDate {
              font-weight: 500;
              font-size: 14px;
              color: #243069;
              span {
                margin-left: 5px;
              }
            }
          }
        }
        &__content {
          display: flex;
          @media (max-width: 480px) {
            flex-direction: column;
          }
          &__col1 {
            flex: 0.5;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #dfe1e5;
            .tit-info {
              padding: 18px 24px;
              border-top: 1px solid #dfe1e5;
              span {
                font-weight: 500;
                font-size: 16px;
                color: #122441;
                margin-bottom: 14px;
              }
              .vi-cover a {
                border: 0.5px solid #c1c5cd;
                font-size: 12px;
                color: #21314d;
                font-weight: 400;
                &.active {
                  color: #fff;
                  border: 0.5px solid #4258bf;
                }
              }
            }
            .border-top {
              border-top: 1px solid #dfe1e5;
            }
            .ttd-total-pack {
              display: flex;
              justify-content: center;
              flex-direction: column;
              flex: 1;
              padding: 12px 24px;
              border-top: 1px solid #dfe1e5;
              strong {
                font-weight: 600;
                font-size: 16px;
                color: #21314d;
              }
            }
          }
          &__col2 {
            flex: 0.5;
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            &__row {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              @media (max-width: 480px) {
                width: 100%;
                justify-content: space-between;
              }
              .ad-ingo p {
                font-weight: 500;
                font-size: 16px;
                color: #3c50ae;
              }
              &.margin-btm {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
  &__col2 {
    @media (max-width: 480px) {
      display: none;
    }
    width: 27%;
    padding: 1rem;
    position: sticky;
    height: fit-content;
    top: 0px;
    padding-top: 5.6rem;
    &__box {
      padding: 24px 24px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #dfe1e5;
      .transfer-cntnr {
        padding: 1rem;
      }
      .MuiAvatar-root {
        width: 25px;
        height: 25px;
      }
      &__title {
        font-family: "poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #33425b;
      }
      &__content {
        &__row {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          &__col1 {
            display: flex;
            align-items: center;
            img {
              margin-right: 6px;
              max-width: 12px;
            }
            &__label {
              font-family: "poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 1.6;
              color: #33425b;
            }
            &__label1 {
              font-family: "poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1.5;
              color: #33425b;
            }
            &__label2 {
              font-family: "poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 1.6;
              color: #175cd3;
            }
          }
          &__col2 {
            &__val {
              font-family: "poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1.4;
              color: #21314d;
              padding-left: 16px;
              text-align: right;
            }
            &__price {
              font-family: "poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 1.6;
              color: #33425b;
            }
            &__discount {
              font-family: "poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 1.4;
              color: #175cd3;
            }
          }
        }
        &__checkout-btn {
          padding: 12px 20px !important;
          background: #4258bf !important;
          border-radius: 48px !important;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
          font-family: "poppins" !important;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 16px !important;
          line-height: 1.5;
          color: #ffffff !important;
          text-transform: initial !important;
          width: 100%;
          margin-top: 0.9rem !important;
        }
      }
      &__divider {
        margin: 10px 0;
        border-bottom: 0.5px solid #969eab;
      }
    }
  }
}
