.activity-detail-main {
  padding: 1.5rem;
  @media (max-width: 480px) {
    padding: 1rem;
    border-top: 0.4px solid #dfe1e5;
  }
  &__pkMain {
    &__noData {
      font-size: 12px;
      color: #33425b;
      font-weight: 400;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__card {
      padding: 24px 24px;
      background: #f4f6ff;
      border: 1px solid #eceef9;
      border-radius: 12px;
      margin-bottom: 1rem;
      position: relative;
      @media (max-width: 480px) {
        padding: 1rem;
      }
      &__notAvailable {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 7px 20px;
        background: radial-gradient(
          100% 55% at 50% 119%,
          #ff4b33 0%,
          #f62020 100%
        );
        border-radius: 0px 12px 0px 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        color: #ffffff;
      }
      &__selected {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 7px 20px;
        background-image: linear-gradient(to right, #1570ef, #0125d9);
        border-radius: 0px 12px 0px 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        color: #ffffff;
      }
      &__icon {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #3c50ae;
        pointer-events: auto;
        cursor: pointer;
      }
      .ttd-p-lhs {
        padding: 0px;
        border-right: none;
        width: 100%;
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          color: #122441;
          margin-bottom: 5px;
          @media (max-width: 480px) {
            font-size: 14px;
          }
        }
        .vist-info {
          .vw-more {
            a {
              background: transparent;
              padding: 0;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 1.6;
              color: #3c50ae;
            }
          }
        }
        .tit-info {
          .vi-cover {
            flex-wrap: wrap;
            margin-bottom: 1rem;
          }
        }
      }
      .ttd-total-pack {
        text-align: right;
        margin: 0.75rem 0;
      }
      .ttd-p-rhs {
        width: 100%;
        align-items: initial;
        padding: 0px;
        .nxt-row-btn.align-center {
          margin: initial;
        }
        .nxt-row-btn {
          position: initial;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          max-width: none;
          .button {
            margin: initial;
            width: auto;
            @media (max-width: 480px) {
              width: 100%;
            }
          }
        }
        ul {
          padding: 0px;
          margin-bottom: 1.5rem;
          li {
            .ad-ingo-rhs .qty-bx {
              border: none;
              background: transparent;
            }
            .qty-bx span:hover {
              color: #3c50ae;
            }
            .ad-ingo-rhs .qty-bx span {
              // padding: 10px 16px;
              background: #eceef9;
              border-radius: 4px;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
            .ad-ingo-rhs .qty-bx input {
              background: transparent;
              color: #3c50ae;
            }
          }
        }
      }
    }
  }
}
