.pk-search-main {
  border-radius: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 0px;
  position: relative;
  &__divider {
    border-bottom: 0.4px solid #b2b7c1;
    margin: 1.5rem 0;
  }
  &__tabs {
    padding: 1.2rem 1rem 0 1rem;
    display: flex;
    border-bottom: 0.2000000238418579px solid #b2b7c1;
    position: sticky;
    background: #fff;
    z-index: 2;
    top: 0;
    width: 100%;
    &__closeBtn {
      position: absolute;   
      right: 1.5rem;
      color: #000 !important;
      z-index: 3;
      cursor: pointer;
    }
    &__tab {
      flex: 0.3;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      color: hsl(218, 40%, 22%);
      padding-bottom: 0.8rem;
      justify-content: center;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
    .active {
      font-weight: 500;
      color: #3c50ae;
      border: none;
      background: transparent;
      border-bottom: 3px solid #3c50ae;
    }
  }
  &__content {
    height: calc(100% - 20%);
    overflow-y: auto;
  }
  &__row {
    padding: 0 1.5rem;
  }
  .searchContainer__bottomSheetMain__container__date {
    margin: 16px 0;
  }
  &__actions {
    position: sticky;
    bottom: 0px;
    // width: 100%;
    padding: 1rem 1.5rem;
    background: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.2000000238418579px solid #b2b7c1;
    &__clearBtn {
      color: #4258bf !important;
      text-transform: initial !important;
      box-shadow: none;
      font-weight: 500 !important;
      font-size: 16px !important;
    }
    &__searchBtn {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      text-transform: initial !important;
      background: #4258bf !important;
      border-radius: 48px !important;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
      padding: 10px 32px !important;
    }
  }

  .rdrDays {
    padding: 0 14px;
  }
  .searchContainer__bottomSheetMain__container__date .rdrSelected {
    border-radius: 4px;
  }
  .searchContainer__bottomSheetMain__container__date
    .rdrCalendarWrapper
    .rdrMonthAndYearWrapper {
    padding: 16px;
  }
  .step-content-calender .rdrCalendarWrapper {
    box-shadow: 0px 0px 5.300000190734863px rgba(63, 77, 101, 0.25);
    border-radius: 8px;
    padding: 0px;
  }
  .searchContainer__bottomSheetMain__container__date
    .rdrMonthsHorizontal
    .rdrMonthName {
    margin-top: -2.8rem;
    padding: 0px !important;
  }
  &__search-main {
    position: relative;
    margin-bottom: 11px;
    padding: 1.5rem 1.5rem 0 1.5rem;
    .search-results {
      position: absolute;
      width: 92%;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 2px 10px rgba(63, 77, 101, 0.15);
      height: 347px;
      overflow-y: auto;
      z-index: 2;
    }
    .search-pop {
      margin-bottom: 5px;
      input {
        border: 0.6000000238418579px solid #b2b7c1;
        border-radius: 12px;
        height: 56px;
        font-size: 14px;
      }
      img {
        top: 21px;
      }
    }
  }

  &__suggestions {
    display: flex;
    flex-wrap: wrap;
    &__tag {
      padding: 10px 12px;
      background: rgba(235, 237, 239, 0.6000000238418579);
      border-radius: 56px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: #33425b;
      margin: 0 15px 15px 0;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: #21314d;
  }
}
